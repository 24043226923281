import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Text,
  Textarea,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Spinner,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { userInfo } from "../../types/Types";
import API from "../../api/API";
import { motion } from "framer-motion";
import { set } from "date-fns";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.6 } },
};

interface DriverAssistTypes {
  ID: number;
  Created: Date | null;
  DriverCode: string;
  Name: string;
  Email: string | null;
  Phone: string | null;
  ContactMethod: string | null;
  Comments: string | null;
  Department: string | null;
  Completed: Date | null;
  CompletedBy: string | null;
  CompletedByEmail: string | null;
  Note: string | null;
}

// Function to format phone number into (XXX) XXX-XXXX format
const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

// Reusable component to display information
const InfoDisplay: React.FC<{
  label: string;
  value: string | null | undefined;
  isPhone?: boolean; // Added an optional prop to indicate if the value is a phone number
}> = ({ label, value, isPhone }) => (
  <VStack alignItems="flex-start" spacing={0} mt="10px" ml="10px">
    <Text fontSize="xs" color="#1D61AA" fontWeight="semibold">
      {label}
    </Text>
    <Text
      fontSize="md"
      fontWeight="medium"
      color={value ? "blackAlpha.800" : "blackAlpha.400"}
    >
      {isPhone && value
        ? formatPhoneNumber(value)
        : value || "Information not available"}
    </Text>
  </VStack>
);

const DriverAssist: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const { ID } = useParams<{ ID: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<DriverAssistTypes | null>(null);
  const [note, setNote] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  // Fetching data from API
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        process: "driver assist read",
        id: ID,
      };
      const response = await API(payload);
      if (response) {
        setData(response[0]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast({
          title: "Error",
          description:
            "There was an error fetching the action item or it does not exist.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {}
  };

  // Submitting completed record to API
  const submitData = async () => {
    setIsLoading(true);
    try {
      const payload = {
        process: "driver assist complete",
        id: ID,
        note: note,
        user: userName,
        userEmail: userEmail,
      };
      const response = await API(payload);
      if (response) {
        fetchData();
        toast({
          title: "Action Item Completed",
          description: "The action item has been completed.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
        toast({
          title: "Error",
          description: "There was an error completing the action item",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {}
  };

  // Submitting data to API on submit button click
  const handleSubmit = () => {
    submitData();
    onClose();
    setNote?.("");
  };

  // Fetching data from API on component mount
  useEffect(() => {
    fetchData();
  }, [ID]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="start"
        width="100%"
        height="calc(100vh - 55px)"
        padding="20px"
      >
        {/* Driver Information */}
        <HStack
          height="100%"
          width="1200px"
          margin="0 auto"
          borderRadius="2xl"
          spacing="10px"
        >
          <VStack
            width="400px"
            height="100%"
            padding="20px"
            borderRadius="2xl"
            background="white"
            boxShadow="lg"
            justifyContent="flex-start"
            alignItems="start"
          >
            <Flex justifyContent="space-between" width="100%">
              <Text fontSize="xl" color="#1D61AA">
                ℹ Request Information
              </Text>
              <Spinner
                display={isLoading ? "block" : "none"}
                color="blue.500"
                size="md"
              />
            </Flex>
            <Divider borderColor="gray.400" borderWidth="1.5px" />
            {/* Reusable InfoDisplay components */}
            <InfoDisplay label="Name" value={data?.Name} />
            <InfoDisplay label="Driver Code" value={data?.DriverCode} />
            <InfoDisplay label="Email" value={data?.Email} />
            <InfoDisplay label="Phone" value={data?.Phone} isPhone={true} />
            <InfoDisplay label="Contact Method" value={data?.ContactMethod} />
            <InfoDisplay label="Comments" value={data?.Comments} />
          </VStack>
          {/* User's submitted information */}
          {data?.ID && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              style={{
                width: "790px",
                height: "100%",
              }}
            >
              <VStack
                width="590px"
                height="100%"
                padding="20px"
                borderRadius="2xl"
                background="white"
                boxShadow="lg"
                justifyContent="flex-start"
                alignItems="start"
              >
                <Text
                  fontSize="xl"
                  color={data?.Completed ? "green.600" : "red.600"}
                >
                  {data?.Completed
                    ? "✅ Action Item Completed"
                    : "⚡ Action Required"}
                </Text>
                {/* Description of what the user need to do in text */}
                <Text fontSize="md" color="gray">
                  {data?.Completed
                    ? "The action item has been completed. Please review the note below."
                    : "Please reach out to the driver and provide assistance for any inquiries they might have. Feel free to add notes regarding your interaction with the driver if necessary in the notes section."}
                </Text>
                {/* Display note if action item is completed */}
                {data?.Completed && (
                  <Text
                    mt="20px"
                    p="20px"
                    borderRadius="xl"
                    border="1px solid"
                    borderColor="gray.200"
                    width="100%"
                  >
                    {data.Note || "No additional notes provided."}
                  </Text>
                )}
                {/* Textarea for user to add notes, hidden if action item is completed */}
                {!data?.Completed && (
                  <>
                    <Textarea
                      placeholder="Add notes..."
                      _placeholder={{ color: "gray.400" }}
                      focusBorderColor="orange.500"
                      width="100%"
                      height="200px"
                      borderRadius="xl"
                      resize="none"
                      mt="20px"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      maxLength={999}
                      isDisabled={isLoading ? true : false}
                    />
                    <Button
                      alignSelf="flex-end"
                      onClick={onOpen}
                      mt="20px"
                      isDisabled={note.length === 0 || isLoading ? true : false}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </VStack>
            </motion.div>
          )}
        </HStack>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Complete Action Item
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to complete this action item?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={handleSubmit} ml={3}>
                Complete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DriverAssist;
