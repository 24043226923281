import React, { useState, useEffect, useRef } from "react";
import {
  VStack,
  Textarea,
  useToast,
  Box,
  Text,
  Divider,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import API from "../../api/API";
import { IoIosSend } from "react-icons/io";

interface noteType {
  NoteID: number;
  ID: number;
  User: string;
  Note: string;
  Date: string;
  UserEmail: string;
}

interface NoteProp {
  userName: string;
  userEmail: string;
  userDepartment: string;
  id: number | undefined;
  drivercode: string | undefined;
}

const formatDateToLocal = (sqlDate: string) => {
  const date = new Date(sqlDate);
  return date.toLocaleString();
};

const Note: React.FC<NoteProp> = ({
  userName,
  userEmail,
  userDepartment,
  id,
  drivercode,
}) => {
  const [data, setData] = useState<noteType[]>([]);
  const toast = useToast();
  const [note, setNote] = useState("");
  const endOfNotesRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  // Fetch the notes from the database
  const fetchData = async () => {
    try {
      setLoading(true);
      const payload = {
        process: "fleet foundations read",
        id: id,
      };
      const response = await API(payload);
      if (Array.isArray(response)) {
        setData(response);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    endOfNotesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  // Handle the note submission
  const addNote = async () => {
    setLoading(true);
    try {
      const payload = {
        process: "fleet foundations write",
        user: userName,
        userEmail: userEmail,
        id: id,
        note: note,
        drivercode: drivercode,
      };
      await API(payload);
      setNote("");
      toast({
        title: "Note submitted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      fetchData();
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  return (
    <VStack width="100%" height="100%" spacing={4}>
      <Box width="100%" height="100%" overflowY="auto">
        {data.length > 0
          ? data.map((note, index) => (
              <Box key={note.NoteID}>
                <Text fontWeight="semibold" fontSize="xs" color="green.600">
                  {note.User} &middot; {formatDateToLocal(note.Date)}
                </Text>
                <Text mb={2} mt={2} fontSize="md">
                  {note.Note}
                </Text>
                {index < data.length - 1 && (
                  <Divider borderColor="gray" mt="20px" mb="20px" />
                )}
              </Box>
            ))
          : null}
        <div ref={endOfNotesRef}></div>
      </Box>
      <HStack
        width="100%"
        height="100px"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add note..."
          resize="none"
          width="100%"
          focusBorderColor="green.500"
          borderColor="blackAlpha.300"
          _hover={{ borderColor: "green.500" }}
        />
        <IconButton
          icon={<IoIosSend />}
          colorScheme="green"
          aria-label="Send"
          onClick={addNote}
          size="sm"
          borderRadius="full"
          isLoading={loading}
          isDisabled={!note.trim()}
        />
      </HStack>
    </VStack>
  );
};

export default Note;
