


import axios from "axios";

export interface Payload {
  [key: string]: any;
}

const NegativeBalanceAPI = async (payload: Payload) => {
  const url = 'https://prod-23.eastus.logic.azure.com:443/workflows/72efc3e5924e4f36965981b5036dc1f2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5AnWE0Eklgq0WMg1-E1Ikfq1i_0fqArMNFBd1tEyYaY';

  

  const config = {
    headers: {
    },
  };

  try {
    const response = await axios.post(url, payload, config);
    return response;

  } catch (error) {
    console.error("API request failed:", error);
  }
};


export default NegativeBalanceAPI;
