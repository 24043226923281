import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { driverBoardTypes } from "../../../types/Types";

const AccordianItems = [
  {
    index: 1,
    day: "Monday",
  },
  {
    index: 2,
    day: "Tuesday",
  },
  {
    index: 3,
    day: "Wednesday",
  },
  {
    index: 4,
    day: "Thursday",
  },
  {
    index: 5,
    day: "Friday",
  },
  {
    index: 6,
    day: "Saturday",
  },
  {
    index: 7,
    day: "Sunday",
  },
];

const headerProps = {
  color: "#1D61AA",
};

const tableHeaders = [
  {
    key: "Registration",
    label: "Registration",
    dataType: "string",
  },
  {
    key: "OrientationLocation",
    label: "Location",
    dataType: "string",
  },
  { key: "DriverName", label: "Name", dataType: "string" },
  { key: "Status", label: "Status", dataType: "string" },

  {
    key: "DriverCode",
    label: "Driver Code",
    dataType: "string",
  },
  {
    key: "DriverType",
    label: "Type",
    dataType: "string",
  },

  {
    key: "TeamWith",
    label: "Team",
    dataType: "string",
  },

  {
    key: "SecondSeat",
    label: "Second Seat",
    dataType: "string",
  },
  {
    key: "TruckNumber",
    label: "Truck Number",
    dataType: "string",
  },
  {
    key: "TruckStatus",
    label: "Truck Status",
    dataType: "string",
  },
  {
    key: "TruckLocation",
    label: "Truck Location",
    dataType: "string",
  },
  {
    key: "TruckRequest",
    label: "Truck Request",
    dataType: "string",
  },
  {
    key: "TruckPreference",
    label: "Truck Preference",
    dataType: "string",
  },
];

interface Props {
  data: driverBoardTypes[];
  selectedID: number | null;
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  handleSort: (column: keyof driverBoardTypes) => void;
  handleSelect: (id: number) => void;
}

const LeasingDataTable: React.FC<Props> = ({
  data,
  selectedID,
  sortState,
  handleSort,
  handleSelect,
}) => {
  return (
    <Box h="calc(100vh - 145px)" mt="5px" w="100%" pl="5px" pr="5px" pb="5px">
      <Box
        w="100%"
        h="100%"
        background="whiteAlpha.900"
        blur="10px"
        borderRadius="xl"
        shadow="md"
        overflow="auto"
      >
        <Accordion
          allowMultiple
          defaultIndex={Array.from(
            { length: AccordianItems.length },
            (_, i) => i
          )}
        >
          {AccordianItems.sort((a, b) => a.index - b.index).map((item) => {
            const itemCount = data.filter(
              (row) =>
                row.WeekDay === item.day &&
                row.Status !== "Withdrawn" &&
                row.Status !== "New" &&
                row.Status !== "Approved" &&
                row.Status !== "No Show"
            ).length;

            if (itemCount > 0) {
              return (
                <AccordionItem key={item.index}>
                  <h2>
                    <AccordionButton
                      bg="blackAlpha.50"
                      _hover={{ bg: "blackAlpha.50" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight="semibold"
                        color="#EE7700"
                      >
                        {`${item.day} (${itemCount})`}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Table size="sm" w="100%">
                      <Thead>
                        <Tr>
                          {tableHeaders.map((header) => (
                            <Th
                              fontSize="xs"
                              cursor="pointer"
                              w={
                                header.key === "TruckPreference"
                                  ? "300px"
                                  : "100px"
                              }
                              key={header.key}
                              {...headerProps}
                              onClick={() =>
                                handleSort(header.key as keyof driverBoardTypes)
                              }
                            >
                              {header.label}
                              <span
                                style={{
                                  visibility:
                                    sortState.column === header.key
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                {sortState.direction === "asc" ? "↑" : "↓"}
                              </span>
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data
                          .filter(
                            (row) =>
                              row.WeekDay === item.day &&
                              row.Status !== "Withdrawn" &&
                              row.Status !== "New" &&
                              row.Status !== "Approved" &&
                              row.Status !== "No Show"
                          )
                          .map((row) => (
                            <Tr
                              key={row.ID ? row.ID : undefined}
                              style={{ userSelect: "none", cursor: "pointer" }}
                              _hover={{
                                bg:
                                  row.Status === "TDO"
                                    ? "red.100"
                                    : row.Status === "Seated" ||
                                      row.Status === "Upgraded"
                                    ? "green.100"
                                    : row.Status === "Unseated"
                                    ? "yellow.100"
                                    : "blackAlpha.100",
                              }}
                              bg={
                                selectedID === row.ID
                                  ? row.Status === "TDO"
                                    ? "red.200"
                                    : row.Status === "Seated" ||
                                      row.Status === "Upgraded"
                                    ? "green.200"
                                    : row.Status === "Unseated"
                                    ? "yellow.200"
                                    : "blackAlpha.200"
                                  : row.Status === "TDO"
                                  ? "red.50"
                                  : row.Status === "Seated" ||
                                    row.Status === "Upgraded"
                                  ? "green.50"
                                  : row.Status === "Unseated"
                                  ? "yellow.50"
                                  : "white"
                              }
                              onClick={() => row.ID && handleSelect(row.ID)}
                              fontWeight="normal"
                            >
                              <Td>{row.Registration}</Td>
                              <Td>{row.OrientationLocation}</Td>
                              <Td>{row.DriverName}</Td>
                              <Td>
                                {row.Status === "TDO"
                                  ? "🔴"
                                  : row.Status === "Seated" ||
                                    row.Status === "Upgraded"
                                  ? "🟢"
                                  : "🟡"}{" "}
                                {row.Status}
                              </Td>
                              <Td>
                                {row.DriverCodeRegistered
                                  ? "🟢" + row.DriverCode
                                  : row.DriverCode
                                  ? "🟡" + row.DriverCode
                                  : "🟡 Pending"}
                              </Td>
                              <Td>{row.DriverType || ""}</Td>
                              <Td>{row.TeamWith || ""}</Td>
                              <Td>{row.SecondSeat || ""}</Td>
                              <Td>{row.TruckNumber || "Pending"}</Td>
                              <Td>{row.TruckStatus || "Pending"}</Td>
                              <Td>{row.TruckLocation || ""}</Td>
                              <Td>{row.TruckRequest || ""}</Td>
                              <Td>{row.TruckPreference || ""}</Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </AccordionPanel>
                </AccordionItem>
              );
            }
            return null;
          })}
        </Accordion>
      </Box>
    </Box>
  );
};

export default LeasingDataTable;
