import axios from "axios";

export interface Payload {
  [key: string]: any;
}

type ApiKey = "writeAPI" | "readAPI" | "pipelineAPI";

const apiEndpoints: Record<ApiKey, string> = {
  writeAPI:
    "https://process-api-01.azure-api.net/driverboard/write/manual/paths/invoke",
  readAPI:
    "https://process-api-01.azure-api.net/expressapi/core/driverboard/read",
  pipelineAPI:
    "https://process-api-01.azure-api.net/expressapi/core/driverboard/dbdata",
};

const apiKeys: Record<ApiKey, string> = {
  writeAPI: process.env.REACT_APP_LOGIC_APP_DRIVER_BOARD_WRITE_KEY || "",
  readAPI: process.env.REACT_APP_LOGIC_APP_DRIVER_BOARD_PIPELINE_KEY || "",
  pipelineAPI: process.env.REACT_APP_LOGIC_APP_DRIVER_BOARD_PIPELINE_KEY || "",
};

const apiCall = async (
  endpoint: ApiKey,
  payload: Payload,
  method: "get" | "post"
) => {
  const config = {
    headers: {
      "Ocp-Apim-Subscription-Key": apiKeys[endpoint],
    },
  };

  try {
    const response =
      method === "post"
        ? await axios.post(apiEndpoints[endpoint], payload, config)
        : await axios.get(apiEndpoints[endpoint], config);
    return response.data;
  } catch (error) {
    console.error(`API request failed for ${endpoint}:`, error);
    throw error;
  }
};

export const apiRead = (payload: Payload) =>
  apiCall("readAPI", payload, "post");
export const apiWrite = (payload: Payload) =>
  apiCall("writeAPI", payload, "post");
export const apiPipeline = () => apiCall("pipelineAPI", {}, "get");
