import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { driverBoardTypes } from "../../../../types/Types";

const AccordianItems = [
  {
    index: 1,
    day: "Monday",
  },
  {
    index: 2,
    day: "Tuesday",
  },
  {
    index: 3,
    day: "Wednesday",
  },
  {
    index: 4,
    day: "Thursday",
  },
  {
    index: 5,
    day: "Friday",
  },
  {
    index: 6,
    day: "Saturday",
  },
  {
    index: 7,
    day: "Sunday",
  },
];

const headerProps = {
  color: "#1D61AA",
};

const tableHeaders = [
  {
    key: "Registration",
    label: "Registration",
    width: "100px",
    dataType: "string",
  },
  {
    key: "TravelRequestStatus",
    label: "Travel Request",
    width: "120px",
    dataType: "string",
  },
  { key: "Status", label: "Status", width: "100px", dataType: "string" },
  { key: "Name", label: "Name", width: "150px", dataType: "string" },
  { key: "DriverCode", label: "Code", width: "150px", dataType: "string" },
  { key: "Recruiter", label: "Recruiter", width: "100px", dataType: "string" },
  {
    key: "OrientationDate",
    label: "Orientation",
    width: "100px",
    dataType: "string",
  },
  {
    key: "OrientationLocation",
    label: "Location",
    width: "100px",
    dataType: "string",
  },
  {
    key: "BackgroundStatus",
    label: "Background Status",
    width: "100px",
    dataType: "string",
  },
  {
    key: "Hotel",
    label: "Hotel",
    width: "100px",
    dataType: "string",
  },
  {
    key: "Passenger",
    label: "Passenger",
    width: "100px",
    dataType: "string",
  },
  {
    key: "Pet",
    label: "Pet",
    width: "100px",
    dataType: "string",
  },
];

interface Props {
  data: driverBoardTypes[];
  selectedID: number | null;
  selectedDate: string;
  selectedDivision: string;
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  handleSort: (column: keyof driverBoardTypes) => void;
  handleSelect: (id: number) => void;
  backgroundCheckFilter:
    | "All"
    | "In-Progress"
    | "Completed"
    | "Manager Approval";
  subTeam: "Tasha" | "Austin" | "All";
  travelRequestFilter: "All" | "Requested" | "Booked" | "Cancelled";
  orientationLocationFilter: string;
  recruiterFilter: string;
}

const ProcessingDataTable: React.FC<Props> = ({
  data,
  selectedID,
  sortState,
  selectedDate,
  selectedDivision,
  handleSort,
  handleSelect,
  backgroundCheckFilter,
  subTeam,
  travelRequestFilter,
  orientationLocationFilter,
  recruiterFilter,
}) => {
  // Function to format date as MM/dd/yyyy
  const formatDate = (dateString: string): string => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1].padStart(2, "0");
      const day = parts[2].padStart(2, "0");
      return `${month}/${day}/${year}`;
    }
    return dateString;
  };

  return (
    <Box
      height="calc(100vh - 180px)"
      mt="10px"
      fontWeight="semibold"
      overflow="auto"
      width="100%"
    >
      <Accordion
        allowMultiple
        defaultIndex={Array.from(
          { length: AccordianItems.length },
          (_, i) => i
        )}
      >
        {AccordianItems.sort((a, b) => a.index - b.index).map((item) => {
          const itemCount = data.filter(
            (row) =>
              row.WeekDay === item.day &&
              row.OrientationDate === selectedDate &&
              (backgroundCheckFilter === "All" ||
                row.BackgroundStatus === backgroundCheckFilter) &&
              row.Status !== "Withdrawn" &&
              (backgroundCheckFilter === "All" ||
                row.BackgroundStatus === backgroundCheckFilter) &&
              (row.Subteam === subTeam || subTeam === "All") &&
              (row.Division === selectedDivision ||
                selectedDivision === "All Division") &&
              (travelRequestFilter === "All" ||
                row.TravelRequestStatus === travelRequestFilter) &&
              (orientationLocationFilter === "" ||
                row.OrientationLocation === orientationLocationFilter) &&
              (recruiterFilter === "" || row.Recruiter === recruiterFilter)
          ).length;

          if (itemCount > 0) {
            return (
              <AccordionItem key={item.index}>
                <h2>
                  <AccordionButton
                    bg="blackAlpha.50"
                    _hover={{ bg: "blackAlpha.50" }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="semibold"
                      color="#EE7700"
                    >
                      {`${item.day} (${itemCount})`}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        {tableHeaders.map((header) => (
                          <Th
                            cursor="pointer"
                            w={header.width}
                            key={header.key}
                            {...headerProps}
                            onClick={() =>
                              handleSort(header.key as keyof driverBoardTypes)
                            }
                          >
                            {header.label}
                            <span
                              style={{
                                visibility:
                                  sortState.column === header.key
                                    ? "visible"
                                    : "hidden",
                              }}
                            >
                              {sortState.direction === "asc" ? "↑" : "↓"}
                            </span>
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data
                        .filter(
                          (row) =>
                            row.WeekDay === item.day &&
                            row.OrientationDate === selectedDate &&
                            row.Status !== "Withdrawn" &&
                            (row.Division === selectedDivision ||
                              selectedDivision === "All Division") &&
                            (backgroundCheckFilter === "All" ||
                              row.BackgroundStatus === backgroundCheckFilter) &&
                            (travelRequestFilter === "All" ||
                              row.TravelRequestStatus ===
                                travelRequestFilter) &&
                            (orientationLocationFilter === "" ||
                              row.OrientationLocation ===
                                orientationLocationFilter) &&
                            (recruiterFilter === "" ||
                              row.Recruiter === recruiterFilter)
                        )
                        .map((row) => (
                          <Tr
                            key={row.ID ? row.ID : undefined}
                            style={{ userSelect: "none", cursor: "pointer" }}
                            _hover={{ background: "blackAlpha.50" }}
                            bg={
                              selectedID === row.ID ? "blackAlpha.100" : "white"
                            }
                            onClick={() => row.ID && handleSelect(row.ID)}
                            fontWeight="normal"
                          >
                            <Td>{row.Registration}</Td>
                            <Td>
                              {row.TravelRequestStatus === "Booked"
                                ? "🟢 Booked"
                                : row.TravelRequestStatus === "Requested"
                                ? "🟡 Requested"
                                : row.TravelRequestStatus === "Cancelled"
                                ? "🔴 Cancelled"
                                : ""}
                            </Td>
                            <Td>{row.Status}</Td>
                            <Td>{row.DriverName}</Td>
                            <Td>{row.DriverCode}</Td>
                            <Td>{row.Recruiter}</Td>
                            <Td>
                              {" "}
                              {row.OrientationDate
                                ? formatDate(row.OrientationDate)
                                : ""}
                            </Td>
                            <Td>{row.OrientationLocation}</Td>
                            <Td>{row.BackgroundStatus || "Not Started"}</Td>
                            <Td>{row.Hotel}</Td>
                            <Td>{row.Passenger}</Td>
                            <Td>{row.Pet}</Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </AccordionPanel>
              </AccordionItem>
            );
          }
          return null;
        })}
      </Accordion>
    </Box>
  );
};

export default ProcessingDataTable;
