import {
  HStack,
  Input,
  InputLeftElement,
  InputGroup,
  Select,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";

// Types
import { driverBoardTypes } from "../../../types/Types";

// Icons
import { SearchIcon } from "@chakra-ui/icons";
// Icons
import { FaFileExcel } from "react-icons/fa";
import { GrRefresh } from "react-icons/gr";

// Date Picker
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";

// ToolBar Types
interface ToolBarProps {
  filteredData: driverBoardTypes[];
  searchText: string;
  setSearchText: (value: string) => void;
  locationFilter: string;
  setLocationFilter: (value: string) => void;
  setSelectedWeek: (value: string) => void;
  selectedWeek: string;
  lastUpdated: Date | null;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
  isLoading: boolean;
  selectedDivision: string;
  setSelectedDivision: (value: string) => void;
  filterOption: "All" | "Week";
  setFilterOption: (value: "All" | "Week") => void;
  costCenterFilter: string;
  setCostCenterFilter: (value: string) => void;
  selectedStatus: "Active" | "Withdrawn";
  setSelectedStatus: (value: "Active" | "Withdrawn") => void;
}

// bring the props from the parent component
const OrientationFilter: React.FC<ToolBarProps> = ({
  filteredData,
  searchText,
  setSearchText,
  locationFilter,
  setLocationFilter,
  refresh,
  setRefresh,
  selectedWeek,
  setSelectedWeek,
  lastUpdated,
  isLoading,
  selectedDivision,
  setSelectedDivision,
  filterOption,
  setFilterOption,
  costCenterFilter,
  setCostCenterFilter,
  selectedStatus,
  setSelectedStatus,
}) => {
  return (
    <HStack w="100%" h="50px" spacing="10px" pl="20px">
      <InputGroup w="200px">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          variant="flushed"
          placeholder="Name, Code, or ID"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </InputGroup>
      <Select
        w="100px"
        variant="flushed"
        value={selectedStatus}
        onChange={(e) =>
          setSelectedStatus(e.target.value as "Active" | "Withdrawn")
        }
      >
        <option value="Active">Active</option>
        <option value="Withdrawn">Withdrawn</option>
      </Select>
      <Select
        w="100px"
        variant="flushed"
        value={filterOption}
        onChange={(e) => setFilterOption(e.target.value as "All" | "Week")}
      >
        <option value="Week">Week</option>
        <option value="All">All</option>
      </Select>
      {filterOption === "Week" && (
        <DatePicker
          customInput={
            <Input
              ml="20px"
              width="100px"
              focusBorderColor="orange.400"
              variant="flushed"
            />
          }
          selected={new Date(selectedWeek)}
          onChange={(date) =>
            date
              ? setSelectedWeek(
                  date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })
                )
              : setSelectedWeek("")
          }
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          dateFormat="MM/dd/yyyy"
          filterDate={(date) => date.getDay() === 1}
        />
      )}
      <Select
        ml="20px"
        w="150px"
        variant="flushed"
        value={locationFilter}
        onChange={(e) => setLocationFilter(e.target.value)}
      >
        <option value="All">All Locations</option>
        <option value="Dubuque">Dubuque</option>
        <option value="Denton">Denton</option>
        <option value="Dover">Dover</option>
        <option value="Sapulpa">Sapulpa</option>
        <option value="Travel to Truck">Travel to Truck</option>
      </Select>
      <Select
        ml="20px"
        w="150px"
        variant="flushed"
        value={selectedDivision}
        onChange={(e) => setSelectedDivision(e.target.value)}
      >
        <option value="All">All Divisions</option>
        <option value="OTR">OTR</option>
        <option value="Dedicated">Dedicated</option>
        <option value="Student">Student</option>
      </Select>
      {/* Input allowing user to type in the Cost Center Name and set it for filter */}

      <Input
        width="150px"
        variant="flushed"
        placeholder="Cost Center"
        value={costCenterFilter}
        onChange={(e) => setCostCenterFilter(e.target.value)}
      />

      {/* Export to CSV and Refresh Button */}
      <CSVLink
        data={filteredData}
        filename={"driverboard.csv"}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Tooltip
          label="Export what you see to CSV"
          aria-label="Export to CSV"
          openDelay={500}
        >
          <IconButton
            aria-label="Export to CSV"
            icon={<FaFileExcel />}
            colorScheme="green"
            isDisabled={false}
            tabIndex={-1}
          />
        </Tooltip>
      </CSVLink>
      <Tooltip
        label={
          lastUpdated ? `Last Updated: ${lastUpdated.toLocaleString()}` : ""
        }
        aria-label="Refresh"
        openDelay={500}
      >
        <IconButton
          aria-label="Refresh"
          icon={<GrRefresh />}
          colorScheme="blue"
          tabIndex={-1}
          onClick={() => setRefresh(!refresh)}
          isLoading={isLoading}
        />
      </Tooltip>
    </HStack>
  );
};

export default OrientationFilter;
