import { useState, useEffect, useCallback } from "react";
import { apiRead } from "../../api/driverBoardAPI";
import { driverBoardTypes } from "../../types/Types";

const useFetchProfileData = (
  selectedID: number | null,
  manualRefresh: boolean
) => {
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<driverBoardTypes | null>(null);

  // Function to Fetch the Data
  const fetchData = useCallback(async () => {
    setProfileLoading(true);
    try {
      const payload = {
        process: "driverprofile",
        id: selectedID?.toString(),
      };

      const response = await apiRead(payload);
      if (response) {
        setProfileData(response[0]);
      } else {
        setProfileData(null);
      }
    } catch (error) {
      setProfileData(null);
    } finally {
      setProfileLoading(false);
    }
  }, [selectedID]);

  useEffect(() => {
    if (selectedID !== null) {
      fetchData();
    }
  }, [selectedID, fetchData, manualRefresh]);

  return { profileLoading, profileData };
};

export default useFetchProfileData;
