import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";

interface Event {
  id: string;
  title: string;
  start: string;
}

const TrainingCalendar: React.FC = () => {
  const navigate = useNavigate();
  const [events, setEvents] = React.useState<Event[]>([]);

  // Function to fetch data from API
  const fetchEvents = async () => {
    try {
      const payload = {
        process: "fleet foundations calendar",
      };
      const response = await API(payload);
      if (Array.isArray(response)) {
        setEvents(response);
      } else {
        setEvents([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <Flex
        width="100%"
        height="60px"
        alignItems="center"
        onClick={() => navigate("/fleetfoundations")}
        style={{ cursor: "pointer" }}
        padding="10px"
      >
        <IconButton
          colorScheme="gray"
          aria-label="Search database"
          icon={<ChevronLeftIcon />}
          borderRadius="full"
          size="sm"
          fontSize="20px"
        />
        <Text fontWeight="normal" color="gray" ml="10px">
          Fleet Foundations Menu
        </Text>
      </Flex>

      <div
        style={{
          width: "100vw",
          height: "calc(100vh - 115px)",
          padding: "20px",
          fontSize: "12px",
        }}
      >
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          height="100%"
        />
      </div>
    </>
  );
};

export default TrainingCalendar;
