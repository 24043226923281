import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

//Chakra UI
import {
  HStack,
  VStack,
  Flex,
  Text,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Heading,
  Tag,
  TagLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
} from "@chakra-ui/react";

//API
import API from "../../api/API";

// Icons
import { ChevronLeftIcon, SearchIcon, DownloadIcon } from "@chakra-ui/icons";

// Types
import { userInfo } from "../../types/Types";

// Child Components
import Note from "../../components/fleetfoundations/Note";
import FileManagement from "../../components/fleetfoundations/FileManagement";

// Declare the type for the status dropdown and the data table
type dropdownStatus = "New" | "Scheduled" | "Completed";
interface FleetFoundation {
  ID: number;
  Status: string;
  DriverCode: string;
  DriverName: string;
  Options: string;
  Notes: string;
  Created: string;
  Requester: string;
  RequesterEmail: string;
  Location: string | null;
  Schedule: string | null;
  Completed: string | null;
  AvailableDate: string;
}

interface SortConfig {
  key: keyof FleetFoundation | null;
  direction: "ascending" | "descending";
}

const headers = [
  { label: "Status", key: "Status" },
  { label: "Driver Code", key: "DriverCode" },
  { label: "Driver Name", key: "DriverName" },
  { label: "Options", key: "Options" },
  { label: "Notes", key: "Notes" },
  { label: "Created", key: "Created" },
  { label: "Requester", key: "Requester" },
  { label: "Requester Email", key: "RequesterEmail" },
  { label: "Location", key: "Location" },
  { label: "Schedule", key: "Schedule" },
  { label: "Completed", key: "Completed" },
  { label: "Available Date", key: "AvailableDate" },
];

const FDManage: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [searchText, setSearchText] = useState<string>("");
  const [status, setStatus] = useState<dropdownStatus>("New");
  const [filtercreatedDate, setFilterCreatedDate] = useState<string>("");
  const [filteravailableDate, setFilterAvailableDate] = useState<string>("");
  const [filterscheduleDate, setFilterScheduleDate] = useState<string>("");
  const [scheduleChanged, setScheduleChanged] = useState<boolean>(false);
  const [data, setData] = useState<FleetFoundation[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<FleetFoundation>();
  const [selectedDate, setSelectedDate] = React.useState<string>();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "Created",
    direction: "ascending",
  });

  // Open and close drawer
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Open and close schedule confirmation box
  const {
    isOpen: isScheduleConfirmOpen,
    onOpen: onScheduleConfirmOpen,
    onClose: onScheduleConfirmClose,
  } = useDisclosure();
  // Open and close cancel confirmation box
  const {
    isOpen: isCancelConfirmOpen,
    onOpen: onCancelConfirmOpen,
    onClose: onCancelConfirmClose,
  } = useDisclosure();

  // Open and close complete confirmation box
  const {
    isOpen: isCompleteConfirmOpen,
    onOpen: onCompleteConfirmOpen,
    onClose: onCompleteConfirmClose,
  } = useDisclosure();

  // Handle Reset Button to clear all filters and search
  const handleReset = () => {
    setFilterCreatedDate("");
    setFilterAvailableDate("");
    setFilterScheduleDate("");
    setSearchText("");
  };

  // Handle Row Click to open drawer and set selected row
  const handleRowClick = (item: FleetFoundation) => {
    setScheduleChanged(false);
    setSelectedRow(item);
    onOpen();

    if (status === "Scheduled" && item.Schedule) {
      const utcSchedule = item.Schedule;
      const localSchedule = new Date(utcSchedule);
      const offset = localSchedule.getTimezoneOffset() * 60000;
      const localISOString = new Date(localSchedule.getTime() - offset)
        .toISOString()
        .slice(0, 16);
      setSelectedDate(localISOString);
    } else {
      setSelectedDate("");
    }
  };

  // Handle Date Validation for Schedule Confirmation and make sure it isn't in the past
  const handleDateValidation = () => {
    if (!selectedDate) {
      return;
    }
    const jsDate = new Date(selectedDate);
    if (isNaN(jsDate.getTime())) {
      return;
    }
    const currentDate = new Date();
    if (jsDate < currentDate) {
      toast({
        title: "Invalid Schedule Date",
        description: "The Schedule date cannot be in the past.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    onScheduleConfirmOpen();
  };

  // Handle Schedule Confirmation and call API to update schedule once the date is validated
  const handleScheduleConfirm = async () => {
    if (!selectedDate) {
      return;
    }
    const jsDate = new Date(selectedDate);
    if (isNaN(jsDate.getTime())) {
      return;
    }
    const formattedDateTime = jsDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const payload = {
      process: "schedule fleet foundations",
      id: selectedRow?.ID,
      date: formattedDateTime,
      user: userName,
      userEmail: userEmail,
    };

    try {
      setActionLoading(true);
      await API(payload);
      fetchData();
      if (status === "New") {
        setSelectedRow(undefined);
        onClose();
      }
    } catch (error) {
      setActionLoading(false);
    }
    setActionLoading(false);
    setScheduleChanged(false);
    onScheduleConfirmClose();
    toast({
      title: "Schedule Updated.",
      description: "Fleet Foundations has been scheduled.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  // Handle Cancel Confirmation
  const handleCancelConfirm = async () => {
    const payload = {
      process: "cancel fleet foundations",
      id: selectedRow?.ID,
      user: userName,
      userEmail: userEmail,
    };

    try {
      setActionLoading(true);
      await API(payload);
      fetchData();
      setSelectedRow(undefined);
      onClose();
    } catch (error) {
      setActionLoading(false);
    }
    setActionLoading(false);
    onCancelConfirmClose();
    toast({
      title: "Request Cancelled.",
      description: "Fleet Foundations has been cancelled.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  // Handle Complete Confirmation
  const handleCompleteConfirm = async () => {
    const payload = {
      process: "complete fleet foundations",
      id: selectedRow?.ID,
      user: userName,
      userEmail: userEmail,
    };

    try {
      setActionLoading(true);
      await API(payload);
      fetchData();
      setSelectedRow(undefined);
      onClose();
    } catch (error) {
      setActionLoading(false);
    }
    setActionLoading(false);
    onCompleteConfirmClose();
    toast({
      title: "Request Completed.",
      description: "Fleet Foundations has been completed.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  // Fetch data from API and update row data
  const fetchData = async () => {
    setDataLoading(true);
    try {
      const payload = {
        process: "Fleet Foundations",
        status: status,
      };

      const response = await API(payload);
      setData(Array.isArray(response) ? response : []);
      setDataLoading(false);
      if (
        selectedRow &&
        Array.isArray(response) &&
        selectedRow.Status === "Scheduled"
      ) {
        const updatedSelectedRow = response.find(
          (item) => item.ID === selectedRow.ID
        );

        if (updatedSelectedRow) {
          setSelectedRow(updatedSelectedRow);
        }
      }
    } catch (error) {
      setData([]);
      setDataLoading(false);
    }
  };

  const filteredAndSortedData = Array.isArray(data)
    ? data.filter((item) => {
        const searchTerm = searchText.toLowerCase();

        // Function to extract just the date part for comparison
        const toDateString = (date: Date) => date.toISOString().split("T")[0];

        const createdDateMatch = filtercreatedDate
          ? toDateString(new Date(item.Created)) ===
            toDateString(new Date(filtercreatedDate))
          : true;
        const availableDateMatch = filteravailableDate
          ? toDateString(new Date(item.AvailableDate)) ===
            toDateString(new Date(filteravailableDate))
          : true;

        let scheduleDateMatch = true;
        if (status === "Scheduled" && item.Schedule) {
          scheduleDateMatch = filterscheduleDate
            ? toDateString(new Date(item.Schedule)) ===
              toDateString(new Date(filterscheduleDate))
            : true;
        }

        return (
          (item.DriverCode.toLowerCase().includes(searchTerm) ||
            item.DriverName.toLowerCase().includes(searchTerm)) &&
          createdDateMatch &&
          availableDateMatch &&
          scheduleDateMatch
        );
      })
    : [];

  // Sorting the data

  const sortData = (key: keyof FleetFoundation) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    setData((currentData) => {
      let sortedData = [...currentData];
      sortedData.sort((a, b) => {
        // Handling null values
        if (a[key] === null) return 1;
        if (b[key] === null) return -1;

        // Sorting for date columns
        if (
          key === "AvailableDate" ||
          key === "Created" ||
          key === "Schedule"
        ) {
          const dateA = new Date(a[key] as string);
          const dateB = new Date(b[key] as string);
          return direction === "ascending"
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        }
        // Sorting for other columns
        else {
          const valueA = String(a[key]);
          const valueB = String(b[key]);
          return direction === "ascending"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
      });
      return sortedData;
    });
  };

  // Update the Tab Title
  useEffect(() => {
    document.title = "Fleet Foundations";
  });

  // Calling the API for data
  useEffect(() => {
    setData([]);
    fetchData();
  }, [status]);

  return (
    <>
      <HStack
        height="calc(100vh - 55px)"
        width="100%"
        padding="15px"
        spacing="15px"
        overflow="hidden"
      >
        {/* Side bar */}
        <VStack
          height="100%"
          width="350px"
          borderRadius="3xl"
          shadow="md"
          bg="white"
          padding="20px"
          backdropFilter="blur(5px)"
          spacing="25px"
          overflow="hidden"
        >
          {/* Back button to return to the dashboard */}
          <Flex
            width="100%"
            alignItems="center"
            onClick={() => navigate("/fleetfoundations")}
            style={{ cursor: "pointer" }}
          >
            <IconButton
              colorScheme="gray"
              aria-label="Search database"
              icon={<ChevronLeftIcon />}
              borderRadius="full"
              size="sm"
              fontSize="20px"
            />
            <Text fontWeight="normal" color="gray" ml="10px">
              Fleet Foundations Menu
            </Text>
          </Flex>
          <InputGroup mt="20px">
            <InputLeftElement
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              placeholder="Driver code or name"
              _placeholder={{ color: "gray.400" }}
              variant="flushed"
              focusBorderColor="orange.500"
              onChange={handleSearchChange}
              value={searchText}
            />
          </InputGroup>
          <VStack width="100%" alignItems="flex-start">
            <Text
              fontSize="xs"
              fontWeight="bold"
              ml="5px"
              color="blackAlpha.500"
            >
              Status
            </Text>
            <Select
              _placeholder={{ color: "gray.400" }}
              focusBorderColor="orange.500"
              onChange={(e) => setStatus(e.target.value as dropdownStatus)}
              value={status}
              isDisabled={dataLoading}
            >
              <option value="New">New</option>
              <option value="Scheduled">Scheduled</option>
              <option value="Completed">Completed</option>
            </Select>
          </VStack>
          <VStack width="100%" alignItems="flex-start">
            <Text
              fontSize="xs"
              fontWeight="bold"
              ml="5px"
              color="blackAlpha.500"
            >
              Created Date
            </Text>
            <Input
              placeholder="Date"
              type="date"
              _placeholder={{ color: "gray.400" }}
              focusBorderColor="orange.500"
              onChange={(e) => setFilterCreatedDate(e.target.value)}
              value={filtercreatedDate}
            />
          </VStack>
          <VStack width="100%" alignItems="flex-start">
            <Text
              fontSize="xs"
              fontWeight="bold"
              ml="5px"
              color="blackAlpha.500"
            >
              Available Date
            </Text>
            <Input
              placeholder="Date"
              type="date"
              _placeholder={{ color: "gray.400" }}
              focusBorderColor="orange.500"
              onChange={(e) => setFilterAvailableDate(e.target.value)}
              value={filteravailableDate}
            />
          </VStack>
          <VStack width="100%" alignItems="flex-start">
            <Text
              fontSize="xs"
              fontWeight="bold"
              ml="5px"
              color="blackAlpha.500"
            >
              Scheduled Date
            </Text>
            <Input
              placeholder="Date"
              type="date"
              _placeholder={{ color: "gray.400" }}
              focusBorderColor="orange.500"
              onChange={(e) => setFilterScheduleDate(e.target.value)}
              isDisabled={status !== "Scheduled"}
              value={filterscheduleDate}
            />
          </VStack>
          <Button onClick={handleReset}> Reset </Button>
        </VStack>
        {/* Data table */}
        <VStack
          height="100%"
          width="100%"
          borderRadius="3xl"
          shadow="md"
          bg="white"
          padding="10px"
          backdropFilter="blur(5px)"
          overflow="hidden"
        >
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
            background="blackAlpha.100"
            height="40px"
            borderRadius="3xl"
            pl="10px"
          >
            {dataLoading === true ? (
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            ) : filteredAndSortedData.length > 0 ? (
              <CSVLink
                data={filteredAndSortedData}
                headers={headers}
                filename="fleet_data.csv"
                className="btn btn-primary"
              >
                <Button
                  leftIcon={<DownloadIcon />}
                  colorScheme="green"
                  variant="ghost"
                  borderRadius="3xl"
                  size="sm"
                >
                  Export CSV
                </Button>
              </CSVLink>
            ) : null}
          </Flex>

          <TableContainer
            height="100%"
            width="100%"
            overflowY="auto"
            overflowX="auto"
            mt="10px"
          >
            <Table variant="simple" size="sm">
              <Thead
                position="sticky"
                top={0}
                zIndex={1}
                bg="whiteAlpha.500"
                backdropFilter="blur(5px)"
              >
                <Tr>
                  <Th cursor="pointer" onClick={() => sortData("Status")}>
                    Status{" "}
                    {sortConfig.key === "Status"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th cursor="pointer" onClick={() => sortData("DriverCode")}>
                    Driver Code{" "}
                    {sortConfig.key === "DriverCode"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th cursor="pointer" onClick={() => sortData("DriverName")}>
                    Driver Name{" "}
                    {sortConfig.key === "DriverName"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th cursor="pointer" onClick={() => sortData("Options")}>
                    Options{" "}
                    {sortConfig.key === "Options"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th
                    cursor="pointer"
                    onClick={() => sortData("AvailableDate")}
                  >
                    Available Date{" "}
                    {sortConfig.key === "AvailableDate"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th cursor="pointer" onClick={() => sortData("Created")}>
                    Created{" "}
                    {sortConfig.key === "Created"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th cursor="pointer" onClick={() => sortData("Requester")}>
                    Requester{" "}
                    {sortConfig.key === "Requester"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                  <Th cursor="pointer" onClick={() => sortData("Schedule")}>
                    Schedule{" "}
                    {sortConfig.key === "Schedule"
                      ? sortConfig.direction === "ascending"
                        ? "▲"
                        : "▼"
                      : ""}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredAndSortedData.map((item) => (
                  <Tr
                    key={item.ID}
                    onClick={() => handleRowClick(item)}
                    _hover={{
                      background: "blackAlpha.100",
                      cursor: "pointer",
                    }}
                  >
                    <Td>
                      {item.Status === "New"
                        ? "🟡 New"
                        : item.Status === "Scheduled"
                        ? "🟢 Scheduled"
                        : item.Status}
                    </Td>

                    <Td>{item.DriverCode}</Td>
                    <Td>{item.DriverName}</Td>
                    <Td>{item.Options}</Td>
                    <Td>{new Date(item.AvailableDate).toLocaleDateString()}</Td>
                    <Td>
                      {new Date(item.Created).toLocaleString("en-US", {
                        timeZone: "America/Chicago",
                      })}
                    </Td>

                    <Td>{item.Requester}</Td>
                    <Td>
                      {item.Schedule
                        ? `${new Date(
                            item.Schedule
                          ).toLocaleDateString()} ${new Date(
                            item.Schedule
                          ).toLocaleTimeString()}`
                        : ""}
                    </Td>
                    <Td></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      </HStack>
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent
          mt="10px"
          mb="10px"
          mr="10px"
          borderRadius="xl"
          bg="whiteAlpha.700"
          backdropFilter="blur(5px)"
          overflow="auto"
        >
          <DrawerHeader>
            <Heading size="md">{selectedRow?.DriverName}</Heading>
            <Heading size="sm" color="blackAlpha.600" ml="10px">
              #{selectedRow?.DriverCode}
            </Heading>
            <HStack spacing={4} mt="10px">
              {selectedRow?.Options.split(",").map((option, index) => (
                <Tag
                  colorScheme="green"
                  key={index}
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  fontWeight="normal"
                >
                  <TagLabel>{option.trim()}</TagLabel>
                </Tag>
              ))}
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <Flex
              width="100%"
              height="80px"
              shadow="sm"
              justifyContent="space-evenly"
              alignItems="center"
              borderRadius="xl"
              bg="whiteAlpha.800"
            >
              <VStack>
                <Heading size="small" color="blackAlpha.700">
                  Status
                </Heading>
                <Text>
                  {selectedRow?.Status === "New"
                    ? "🟡 New"
                    : selectedRow?.Status === "Scheduled"
                    ? "🟢 Scheduled"
                    : selectedRow?.Status}
                </Text>
              </VStack>
              <VStack>
                <Heading size="small" color="blackAlpha.700">
                  Requester
                </Heading>
                <Text>{selectedRow?.Requester}</Text>
              </VStack>

              <VStack>
                <Heading size="small" color="blackAlpha.700">
                  {selectedRow?.Status === "Scheduled"
                    ? "Schedule"
                    : "Available Date"}
                </Heading>
                <Text>
                  {selectedRow?.Status === "Scheduled"
                    ? selectedRow?.Schedule
                      ? `${new Date(
                          selectedRow.Schedule
                        ).toLocaleDateString()} ${new Date(
                          selectedRow.Schedule
                        ).toLocaleTimeString()}`
                      : "No Schedule Available"
                    : selectedRow?.AvailableDate
                    ? new Date(selectedRow.AvailableDate).toLocaleDateString()
                    : "No Date Available"}
                </Text>
              </VStack>
            </Flex>
            {/* Scheduling & Option Center */}
            <HStack width="100%" height="200px">
              {/* Scheduling component to add or change */}
              <VStack
                shadow="sm"
                borderRadius="xl"
                bg="whiteAlpha.800"
                mt="20px"
                width="60%"
                height="100%"
                padding="15px"
                alignItems="flex-start"
              >
                <Text
                  color="blackAlpha.600"
                  fontWeight="semibold"
                  fontSize="small"
                  display="flex"
                >
                  {selectedRow?.Status === "New"
                    ? "ADD A SCHEDULE"
                    : "CHANGE THE SCHEDULE"}
                  {scheduleChanged && (
                    <Text as="span" color="red.500">
                      - (Unsaved Change)
                    </Text>
                  )}
                </Text>
                <Text fontSize="xs">
                  Choose a date and time for the Fleet Foundations training. Any
                  schedule changes will be communicated via email to driver
                  leader and requester.
                </Text>
                <Input
                  type="datetime-local"
                  focusBorderColor="gray.300"
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                    setScheduleChanged(true);
                  }}
                  value={selectedDate || ""}
                />
                <Button
                  colorScheme="blackAlpha"
                  size="sm"
                  alignSelf="center"
                  mt="15px"
                  onClick={handleDateValidation}
                  isDisabled={!scheduleChanged}
                >
                  {selectedRow?.Status === "New" ? "Schedule" : "Reschedule"}
                </Button>
              </VStack>
              {/* Action Center */}
              <VStack
                shadow="sm"
                borderRadius="xl"
                bg="whiteAlpha.800"
                mt="20px"
                width="40%"
                height="100%"
                padding="15px"
                alignItems="flex-start"
              >
                <Text
                  color="blackAlpha.600"
                  fontWeight="semibold"
                  fontSize="small"
                >
                  ACTION CENTER
                </Text>
                <Text fontSize="xs">
                  Use the action center to cancel or mark Fleet Foundations as
                  completed.
                </Text>
                <Flex width="100%" justifyContent="space-evenly">
                  <Button
                    colorScheme="blackAlpha"
                    size="sm"
                    alignSelf="center"
                    mt="15px"
                    onClick={onCancelConfirmOpen}
                    isDisabled={selectedRow?.Status === "Completed"}
                  >
                    Cancel Request
                  </Button>
                  <Button
                    colorScheme="whatsapp"
                    size="sm"
                    alignSelf="center"
                    mt="15px"
                    onClick={onCompleteConfirmOpen}
                    isDisabled={selectedRow?.Status !== "Scheduled"}
                  >
                    Complete Request
                  </Button>
                </Flex>
              </VStack>
            </HStack>
            {/* Notes */}
            <Tabs
              shadow="sm"
              borderRadius="xl"
              bg="whiteAlpha.800"
              mt="20px"
              width="100%"
              height="calc(100vh - 460px)"
              padding="10px"
              overflow="hidden"
            >
              <TabList>
                <Tab fontWeight="semibold" color="gray">
                  Note
                </Tab>
                <Tab fontWeight="semibold" color="gray">
                  File
                </Tab>
              </TabList>
              <TabPanels height="calc(100% - 50px)" mt="10px">
                <TabPanel height="100%">
                  <Note
                    userEmail={userEmail}
                    userName={userName}
                    userDepartment={userDepartment}
                    id={selectedRow?.ID}
                    drivercode={selectedRow?.DriverCode}
                  />
                </TabPanel>
                <TabPanel height="100%">
                  <FileManagement
                    userEmail={userEmail}
                    userName={userName}
                    userDepartment={userDepartment}
                    id={selectedRow?.ID}
                    drivercode={selectedRow?.DriverCode}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Schedule Confirmation Box */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onScheduleConfirmClose}
        isOpen={isScheduleConfirmOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {selectedRow?.Status === "New"
              ? "Schedule Driver"
              : "Reschedule Driver"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {selectedRow?.Status === "New"
              ? "Are you sure you want to schedule this driver for the selected date?"
              : "Are you sure you want to reschedule this driver for the selected date?"}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onScheduleConfirmClose}>
              No
            </Button>
            <Button
              colorScheme="green"
              ml={3}
              onClick={handleScheduleConfirm}
              isLoading={actionLoading}
              loadingText="Updating..."
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {/* Cancel Confirmation Box */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCancelConfirmClose}
        isOpen={isCancelConfirmOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Cancel Fleet Foundations</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to cancel Fleet Foundations for this driver?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancelConfirmClose}>
              No
            </Button>
            <Button
              colorScheme="green"
              ml={3}
              onClick={handleCancelConfirm}
              isLoading={actionLoading}
              loadingText="Updating..."
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {/* Complete Confirmation Box */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCompleteConfirmClose}
        isOpen={isCompleteConfirmOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Complete Fleet Foundations</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to complete Fleet Foundations for this driver?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCompleteConfirmClose}>
              No
            </Button>
            <Button
              colorScheme="green"
              ml={3}
              onClick={handleCompleteConfirm}
              isLoading={actionLoading}
              loadingText="Updating..."
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FDManage;
