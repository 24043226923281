import React, { useEffect, useState } from "react";
import { userInfo } from "../../types/Types";
import {
  Box,
  Text,
  VStack,
  useToast,
  HStack,
  Image,
  Grid,
  GridItem,
  Divider,
  Input,
  Flex,
  Button,
  Textarea,
} from "@chakra-ui/react";
import Select from "react-select";

// Import the API
import expressAPI from "../../api/expressAPI";
import API from "../../api/API";

// Import date-fns
import { format } from "date-fns";

// Import Types
import { adUsersTypes } from "../../types/Types";

// Form Specific Types

interface StatusChangeFormProps {
  DriverCode?: string | null;
  DriverName?: string | null;
  CurrentCostCenter?: string | null;
  TruckNumber?: string | null;
  TruckLocation?: string | null;
  NewDriverLeader?: string | null;
  NewDriverLeaderEmail?: string | null;
  NewCostCenter?: string | null;
  Comments?: string | null;
  EffectiveDate: string;
}

// Interface for Driver Data to be used in the drop down
interface DriverData {
  DriverCode: string;
  DriverName: string;
  CurrentCostCenter: string;
  TruckNumber: string;
}

// Types for the Driver Code Search Drop down
interface SelectOptions {
  label: string;
  value: string;
}

const StatusChangeForm: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  // End Point
  const endpoint = "core/forms/formsAPI";

  // Components State
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const toast = useToast();

  // Support data for the form
  const [drivers, setDrivers] = useState<DriverData[]>([]);
  const [DropDownedDriver, setDropDownedDriver] = useState<SelectOptions[]>([]);
  const [DriverLeader, setDriverLeader] = useState<adUsersTypes[]>([]);
  const [DriverLeadersOptions, setDriverLeadersOptions] = useState<
    SelectOptions[]
  >([]);

  // Form Data
  const [formData, setFormData] = useState<StatusChangeFormProps>({
    DriverCode: null,
    DriverName: null,
    CurrentCostCenter: null,
    TruckNumber: null,
    TruckLocation: null,
    NewDriverLeader: null,
    NewDriverLeaderEmail: null,
    NewCostCenter: null,
    Comments: null,
    EffectiveDate: format(new Date(), "yyyy-MM-dd"),
  });

  // props for the label
  const labelProps = {
    fontSize: "xs",
    fontWeight: "semibold",
    color: "gray.500",
    ml: "2px",
  };

  // Fetch the drivers
  const fetchDrivers = async () => {
    const payload = {
      process: "status change driver info",
    };

    const response = await expressAPI(endpoint, payload);

    if (response) {
      setDrivers(response);
      const options = response.map((driver: DriverData) => ({
        label: `${driver.DriverCode} - ${driver.DriverName}`,
        value: driver.DriverCode,
      }));
      setDropDownedDriver(options);
    }
  };

  // Fetch Driver Leader
  const fetchDriverLeader = async () => {
    const payload = {
      process: "driver leaders",
    };
    const response = await expressAPI(endpoint, payload);
    if (response) {
      setDriverLeader(response);
      const options = response.map((leader: adUsersTypes) => ({
        label: `${leader.DisplayName}`,
        value: leader.DisplayName,
      }));
      setDriverLeadersOptions(options);
    }
  };

  // Handle the select of a driver
  const handleSelectChange = (selectedOption: any) => {
    const selectedDriver = drivers.find(
      (driver) => driver.DriverCode === selectedOption.value
    );
    if (selectedDriver) {
      setFormData({
        ...formData,
        DriverCode: selectedDriver.DriverCode,
        DriverName: selectedDriver.DriverName,
        CurrentCostCenter: selectedDriver.CurrentCostCenter,
        TruckNumber: selectedDriver.TruckNumber,
      });
    }
  };

  // Function to submit form
  const submitForm = async () => {
    if (
      !formData.DriverCode ||
      !formData.CurrentCostCenter ||
      !formData.NewCostCenter ||
      !formData.EffectiveDate ||
      !formData.Comments ||
      !formData.NewDriverLeader ||
      !formData.TruckLocation ||
      !formData.TruckNumber
    ) {
      toast({
        title: "Error",
        description:
          "Driver Information and status change information are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitted(true);
    const payload = {
      process: "status change submit",
      userName,
      userEmail,
      formData,
    };
    const response = await API(payload);
    if (response) {
      setIsSubmitted(false);
      toast({
        title: "Form Submitted",
        description: "The form has been submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Reset form data
      setFormData({
        DriverCode: null,
        DriverName: null,
        CurrentCostCenter: null,
        TruckNumber: null,
        TruckLocation: null,
        NewDriverLeader: null,
        NewDriverLeaderEmail: null,
        NewCostCenter: null,
        Comments: null,
        EffectiveDate: format(new Date(), "yyyy-MM-dd"),
      });
    }
  };

  // useEffect for initial render
  useEffect(() => {
    document.title = "Status Change Form";
    fetchDrivers();
    fetchDriverLeader();
  }, []);

  return (
    <Box
      height="calc(100% - 90px)"
      m="2vh"
      justifyContent="center"
      alignItems="flex-start"
      display="flex"
    >
      <VStack
        width="1200px"
        height="100%"
        borderRadius="3xl"
        background="whiteAlpha.900"
        shadow="md"
        padding="20px"
        bg="white"
        overflow="auto"
      >
        <Box width="100%">
          <HStack height="40px">
            <Image src="/logo.png" alt="Logo" height="100%" />
            <Text fontSize="lg" fontWeight="semibold" color="#1D61AA" ml="10px">
              Status Change
            </Text>
          </HStack>
          <Divider mt="10px" />
        </Box>

        <Grid
          templateColumns="repeat(4, 1fr)"
          gap={4}
          w="100%"
          h="100%"
          mt="50px"
          alignContent="flex-start"
        >
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Driver Code *
            </Text>
            <Select
              maxMenuHeight={100}
              options={DropDownedDriver}
              isSearchable
              placeholder="Search by Code"
              noOptionsMessage={() => "No matches found"}
              onChange={handleSelectChange}
              value={
                DropDownedDriver.find(
                  (option) => option.value === formData.DriverCode
                ) || null
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Current Cost Center *
            </Text>
            <Input
              value={formData.CurrentCostCenter || ""}
              placeholder="Current Cost Center"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  CurrentCostCenter: e.target.value,
                })
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Truck Number *
            </Text>
            <Input
              value={formData.TruckNumber || ""}
              placeholder="Truck Number"
              onChange={(e) =>
                setFormData({ ...formData, TruckNumber: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Truck Location *
            </Text>
            <Input
              value={formData.TruckLocation || ""}
              placeholder="Truck Location"
              onChange={(e) =>
                setFormData({ ...formData, TruckLocation: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={5} mb="10px" mt="10px">
            <Divider />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              New Cost Center *
            </Text>
            <Input
              value={formData.NewCostCenter || ""}
              placeholder="New Cost Center"
              onChange={(e) =>
                setFormData({ ...formData, NewCostCenter: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              New Driver Leader *
            </Text>
            <Select
              maxMenuHeight={100}
              options={DriverLeadersOptions}
              isSearchable
              placeholder="Select Driver Leader"
              noOptionsMessage={() => "No matches found"}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFormData({
                    ...formData,
                    NewDriverLeader: selectedOption.value,
                  });
                }
              }}
              value={
                DriverLeadersOptions.find(
                  (option) => option.value === formData.NewDriverLeader
                ) || null
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Effective Date *
            </Text>
            <Input
              placeholder="Effective Date"
              type="date"
              value={formData.EffectiveDate}
              onChange={(e) =>
                setFormData({ ...formData, EffectiveDate: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={5}>
            <Text {...labelProps} mb="6px">
              Comments *
            </Text>
            <Textarea
              value={formData.Comments || ""}
              placeholder="Comment"
              onChange={(e) =>
                setFormData({ ...formData, Comments: e.target.value })
              }
            />
          </GridItem>
        </Grid>
        <Flex w="100%" justifyContent="flex-end" mt="50px">
          <Button size="lg" onClick={submitForm} isLoading={isSubmitted}>
            Submit
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default StatusChangeForm;
