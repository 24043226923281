import React, { useEffect, useRef, useState } from "react";
import { FaSadTear, FaMeh, FaSmile, FaRegUserCircle } from "react-icons/fa";
import { VStack, useDisclosure } from "@chakra-ui/react";
import {
  Text,
  CardBody,
  Card,
  Grid,
  GridItem,
  Box,
  List,
  ListItem,
  Divider,
  Flex,
  Button,
  Icon,
  Input,
  FormControl,
  FormLabel,
  Collapse,
  useToast,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  Select,
} from "@chakra-ui/react";
import expressAPI from "../../api/expressAPI";
import { ShopEmployeeDetails } from "../../types/Types";
import { ShopEmployeeInteractionHistory } from "../../types/Types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../api/API";
// Types
import { userInfo } from "../../types/Types";

// ToolBar Types
// interface ToolBarTypes {
//   setSelectedStatus: (
//     value: "All" | "Completed" | "Pending"
//   ) => void;
// }

const setselectedStatus = (statusValue: string) => {

}
// Function to format the date
const formatDate = (dateString: string | null): string => {
  if (dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "";
};

type RowClickParams = {
  id?: number | null; 
  assigneeEmail?: string | null;
  department?: string | null;
  inDays?: string | null; 
  escalatedid?: number | null; 
};

const handleRowClick = ({
  id,  
  assigneeEmail,
  department,
  inDays, 
  escalatedid,
}: RowClickParams): void => {
 console.log('Inside row click');
  if (!department || !id) {
    return;
  }

  // Encode the parameters for the URL
  const encodedDepartment = encodeURIComponent(department);  
  const newId = (id === escalatedid) ? escalatedid : id;
  const encodedId = encodeURIComponent(''+newId); // Include the 'id' in encoding
  
  const encodedInDays = encodeURIComponent(inDays ?? "");  
  const encodedEscalated = encodeURIComponent("Shop Employee History");

  // Determine the URL based on isEscalatedShopEmployee
  const url = `/actionitems/emulate/${encodedEscalated}/id=${encodedId}/${encodedInDays}` // Escalated URL    

  // Open the constructed URL in the same tab
  window.location.href = url;
};

const ShopEmployeeHistory = () => {
  const endpoint = "core/actionitems/data";
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the navigate hook
  const toast = useToast(); // Create toast instance

  const [shopEmpProfileDetails, setShopEmpHistory] = useState<
    ShopEmployeeDetails[]
  >([]);
  
  useEffect(() => {  
       fetchShopEmployeeHistory();   
  }, [location]);
   
  // Fetch the Shop Employee Interaction History
  const fetchShopEmployeeHistory = async () => {    
    try {
      const apiResponse = await expressAPI(endpoint, {
        process: "shopemployeehistory"
      });

      setShopEmpHistory(apiResponse);
    } catch (error) {
      console.error("Failed to fetch Shop Employee History", error);
    }
  };
 
  return (
    <VStack
        width="100%"
        height="670"
        background="white"
        shadow="md"
        borderRadius="2xl"
        padding="10px"
        alignItems="flex-start"
      >  
      <HStack
        width="100%"
        borderRadius="2xl"
        spacing="10px"
        justifyContent="space-between"
      >
          {/* Registration Filter */}
          <GridItem>
            <Text fontSize="md" color="blue.500">Status: </Text>
            <Select
              focusBorderColor="orange.400"
              variant="filled"
              value=''
              onChange={(e) => setselectedStatus(
                e.target.value as "All" | "Completed" | "Pending"
              )}
              size="sm"
              width="100px"
            >
              <option value="All">All</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>           
            </Select>
          </GridItem>
      </HStack>
      <Table size="sm">
        <Thead>
          <Tr>
            {/* <Th width="0px">ID</Th> */}
            <Th width="150px">Candiate</Th>
            <Th width="100px">Title</Th>
            <Th width="100px">Department</Th>
            <Th width="150px">Location</Th>
            <Th width="100px">StartDate</Th>
            <Th width="150px">Assignee</Th>
            <Th width="50px">Feedback Duration</Th>
            <Th width="50px">Status</Th>
            <Th width="50px">Is Escalated</Th>
          </Tr>
        </Thead>
        <Tbody>
          {shopEmpProfileDetails.map((item) => (
            <Tr           
              onClick={() =>
                handleRowClick({
                  id: item.ID,
                  assigneeEmail: item.AssigneeEmail,
                  department: item.Department,
                  inDays: item.DaysDuration,
                  escalatedid: item.EscalatedID              
                })
              }
              style={{
                cursor: "pointer",
              }}
              _hover={{
                bg: "blackAlpha.100",
                transition:
                  "background-color 0.3s ease-in-out",
              }}
            >
              {/* <Td></Td> */}
              <Td>{item.Candidate}</Td>
              <Td>{item.Title}</Td>
              <Td>{item.ShopEmpDepartment}</Td>
              <Td>{item.Location}</Td>
              <Td>{formatDate(item.StartDate)}</Td>
              <Td>{item.Assignee}</Td>
              <Td>{item.DaysDuration}</Td>
              <Td>{item.Status}</Td>
              <Td>{item.Escalate}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>  
  );
};

export default ShopEmployeeHistory;
