import React, { useEffect, useState } from "react";
import { userInfo } from "../../types/Types";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  VStack,
  Divider,
  HStack,
  Image,
  Grid,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  useToast,
  Input,
  Select,
} from "@chakra-ui/react";
import brtAPI from "../../api/brtAPI";
import Receipt from "./Receipt";

// Action Item Types
interface BRTActionItemProps {
  DriverName: string;
  DriverCode: string;
  UniqueID: string;
  HiredDate: string | null;
  TruckNumber: string | null;
  Phone: string | null;
  Email: string | null;
  Completed: string | null;
}

const OutreachDashboard: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const { ID } = useParams<{ ID: string }>();
  const [comment, setComment] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BRTActionItemProps | null>(null);
  const toast = useToast();
  const [inputValue, setInputValue] = useState<string>("");
  const [loadNumber, setLoadNumber] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [response, setResponse] = useState<string | null>(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  // function to fetch action item status
  useEffect(() => {
    document.title = "Building Relationships & Trust";
    fetchActionItemStatus();
  }, [ID]);

  // function to check if action item is completed or not
  const fetchActionItemStatus = async () => {
    setIsLoading(true);

    try {
      const payload = {
        id: ID,
        action: "fetchActionItemStatus",
        driverCode: null,
        orderNumber: null,
        reason: null,
      };

      const response = await brtAPI(payload);
      setResponse(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch driver data from search
  const FetchData = async () => {
    setIsLoading(true);
    setIsSubmitted(true);

    try {
      const payload = {
        id: ID,
        action: "FetchOutreachData",
        driverCode: inputValue,
        loadNumber: loadNumber,
        reason: reason,
      };

      const response = await brtAPI(payload);

      if (response && response.Table1 && response.Table1.length > 0) {
        const dataFromAPI = response.Table1[0];

        setData({
          DriverName: dataFromAPI.DRIVER_NAME,
          DriverCode: dataFromAPI.DRIVER_CODE,
          HiredDate: dataFromAPI.HIRE_DATE,
          TruckNumber: dataFromAPI.ASSIGNED_UNIT,
          Phone: dataFromAPI.DRIVER_CELLPHONE,
          Email: dataFromAPI.DRIVER_EMAIL,
          Completed: dataFromAPI.LAST_ACTIVITY_DATE,
          UniqueID: dataFromAPI.UNIQUE_ID,
        });

        toast({
          title: "Driver Information fetched successfully.",

          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "No data found.",
          description:
            "No data available for the specified driver code or Driver has been termed.",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description:
          "Error fetching data. Please try again or contact support.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsSubmitted(false);
      setIsLoading(false);
    }
  };

  //Handle Form submission
  const handleSubmit = async () => {
    setIsSubmitted(true);

    try {
      // Check if all input fields are filled out
      if (!comment || !loadNumber || !reason || rating === 0) {
        toast({
          title: "Incomplete Form",
          description: "Please fill out all the fields before submitting.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      setIsLoading(true);

      const payload = {
        id: ID,
        userEmail: userEmail,
        userName: userName,
        action: "SubmitOutreachData",
        notes: comment,
        sentiment: rating,
        driverName: data?.DriverName,
        driverCode: data?.DriverCode,
        uniqueID: data?.UniqueID,
        loadNumber: loadNumber,
        reason: reason,
      };

      // Call the API to handle submit
      const response = await brtAPI(payload);

      // Update status message and color
      setResponse("Completed");

      // Hide content upon successful submission
      setData(null);

      // Display success toast
      toast({
        title: "Submission Successful",
        description: "Action item submitted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "An error occurred.",
        description:
          "Error submitting the action item. Please try again or contact support.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
      setIsSubmitted(false);
    }
  };

  return (
    // Outer container for, setting height, margin, and flex properties
    <Box
      height="91vh"
      w="100vw"
      justifyContent="center"
      alignItems="center"
      display="flex"
      overflow="hidden"
    >
      {/* Main content container */}
      <VStack
        width="50vw"
        borderRadius="3xl"
        background="whiteAlpha.900"
        shadow="lg"
        padding="10px"
        height="98%"
        overflow="auto"
      >
        {/* Header section */}
        <Box width="100%">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="80px"
          >
            {/* Logo and application title */}
            <HStack height="50px" width="100%">
              <Image src="/logo.png" alt="Logo" height="100%" />
              <Text
                fontSize="lg"
                fontWeight="semibold"
                color="#1D61AA"
                ml="10px"
              >
                Positive Driver Outreach
              </Text>
            </HStack>
            {/* Loading spinner or status box */}
            {isLoading ? (
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
                alignSelf="center"
              />
            ) : (
              <Box
                width="60%"
                mt="10px"
                shadow="md"
                bg={
                  response === "Completed"
                    ? "green.100"
                    : response === "Incomplete"
                    ? "yellow.100"
                    : "gray.100"
                }
                p="4"
                borderRadius="md"
              >
                <Text
                  fontSize="sm"
                  color="blackAlpha.800"
                  fontWeight="semibold"
                >
                  STATUS
                </Text>
                {/* Display status message based on response */}
                <Text fontSize="md" color="blackAlpha">
                  {response === "Completed"
                    ? "This action item has been completed."
                    : response === "Incomplete"
                    ? "This action item is incomplete."
                    : "The status of the action item is unknown."}
                </Text>
              </Box>
            )}
          </Box>
          {/* Divider */}
          <Divider mt="10px" />
        </Box>

        {/* Search input field */}
        {response !== "Completed" && (
          <FormControl id="inputField">
            <FormLabel fontSize="sm" color="#1D61AA">
              Search Driver Code
            </FormLabel>
            {/* VStack for vertical stacking */}
            <VStack spacing={3} align="flex-start">
              <Input
                placeholder="Type here..."
                value={inputValue}
                onChange={(e) => {
                  const inputValue = e.target.value.slice(0, 6);
                  setInputValue(inputValue);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    FetchData();
                  }
                }}
                size="sm"
                width="100%"
                borderRadius="md"
                maxLength={6}
              />
              {/* Search Button */}
              <Button
                colorScheme="whatsapp"
                isLoading={isLoading}
                onClick={FetchData}
                width="15%"
              >
                Search
              </Button>
            </VStack>
          </FormControl>
        )}

        {/* Display driver information */}
        {data && (
          <>
            {/* Driver information box */}
            <Box
              width="100%"
              mt="10px"
              background="blackAlpha.50"
              visibility={isLoading ? "hidden" : "visible"}
              p="4"
              borderRadius="md"
            >
              <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
                DRIVER INFORMATION
              </Text>

              {/* Grid to display driver details */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="4">
                {/* Driver name */}
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Driver Name
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data?.DriverName}
                  </Text>
                </Box>
                {/* Hired date */}
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Hired Date
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data?.HiredDate
                      ? new Date(data?.HiredDate).toLocaleDateString()
                      : ""}
                  </Text>
                </Box>
                {/* Driver code */}
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Driver Code
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data?.DriverCode}
                  </Text>
                </Box>
                {/* Truck number */}
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Truck Number
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data?.TruckNumber ? data?.TruckNumber : "N/A"}
                  </Text>
                </Box>
                {/* Phone */}
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Phone
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data?.Phone
                      ? `(${data?.Phone.slice(0, 3)}) ${data?.Phone.slice(
                          3,
                          6
                        )}-${data?.Phone.slice(6)}`
                      : "N/A"}
                  </Text>
                </Box>
                {/* Email */}
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Email
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data?.Email ? data?.Email : "N/A"}
                  </Text>
                </Box>
              </Grid>
            </Box>

            {/* Action item section */}
            <Box
              width="100%"
              mt="10px"
              background="blackAlpha.10"
              p="4"
              borderRadius="md"
            >
              {/* Form for submitting action item */}
              <VStack spacing={4} align="stretch">
                <Text
                  fontSize="sm"
                  color="blackAlpha.800"
                  fontWeight="semibold"
                >
                  ACTION ITEM
                </Text>
                <HStack>
                  {/* Load number field */}
                  <FormControl id="LoadNumberField">
                    <FormLabel fontSize="sm" color="#1D61AA">
                      Load Number
                    </FormLabel>
                    <Input
                      placeholder="Type here..."
                      value={loadNumber}
                      onChange={(e) => setLoadNumber(e.target.value)}
                      size="sm"
                      borderRadius="md"
                      maxLength={20}
                    />
                  </FormControl>

                  {/* Reasoning field */}
                  <FormControl id="reason">
                    <FormLabel fontSize="sm" color="#1D61AA">
                      Reason
                    </FormLabel>
                    <Select
                      placeholder="Select reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      size="sm"
                      borderRadius="md"
                    >
                      <option value="Relay">Relay</option>
                      <option value="Tight Load">Tight Load</option>
                      <option value="Thank You">Thank You</option>
                      <option value="Customer Recognition">
                        Customer Recognition
                      </option>
                      <option value="Flexibility with Customer Changes">
                        Flexibility with Customer Changes
                      </option>
                    </Select>
                  </FormControl>
                </HStack>
                {/* Rating and Comment FormControls */}
                <HStack align="top">
                  {/* Rating field */}
                  <FormControl id="rating" flex={1}>
                    <FormLabel fontSize="sm" color="#1D61AA">
                      Rating (How the Call Went)
                    </FormLabel>
                    <HStack spacing="3px">
                      {/* Emoji buttons for rating */}
                      <Button
                        variant="ghost"
                        size="lg"
                        onClick={() => setRating(1)}
                        bg={rating === 1 ? "gray.400" : "white"}
                      >
                        😞
                      </Button>
                      <Button
                        variant="ghost"
                        size="lg"
                        onClick={() => setRating(2)}
                        bg={rating === 2 ? "gray.400" : "white"}
                      >
                        😐
                      </Button>
                      <Button
                        variant="ghost"
                        size="lg"
                        onClick={() => setRating(3)}
                        bg={rating === 3 ? "gray.400" : "white"}
                      >
                        😊
                      </Button>
                    </HStack>
                  </FormControl>

                  {/* Comment textarea */}
                  <FormControl id="comment" flex={1}>
                    <FormLabel fontSize="sm" color="#1D61AA">
                      Comments
                    </FormLabel>
                    <Textarea
                      placeholder="Add your note here..."
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      size="sm"
                      borderRadius="md"
                      maxLength={999}
                      isDisabled={isSubmitted || data?.Completed ? true : false}
                    />
                  </FormControl>
                </HStack>

                {/* Submit button */}
                <Button
                  mt="10px"
                  colorScheme="whatsapp"
                  isLoading={isSubmitted}
                  alignSelf="flex-end"
                  width="100px"
                  onClick={handleSubmit}
                  isDisabled={data?.Completed ? true : false || isSubmitted}
                >
                  Submit
                </Button>
              </VStack>
            </Box>
          </>
        )}
      </VStack>

      {/* Display receipt component */}
      {showReceipt && apiResponse && (
        <Receipt data={apiResponse} onClose={() => setShowReceipt(false)} />
      )}
    </Box>
  );
};

export default OutreachDashboard;
