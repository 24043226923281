import React from 'react';
import {
	Document,
	Page,
	Text,
	StyleSheet,
	View,
	Image,
} from '@react-pdf/renderer';

interface ReportData {
	authors: string;
	sponsors: string;
	businessProblem: string;
	analysis: string;
	solution: string;
	goals: string;
	deliverables: string;
	strategicAlignment: string;
}

interface MyPDFDocumentProps {
	data: ReportData;
}

const getDate = (): string => {
	const options: Intl.DateTimeFormatOptions = {
	  year: 'numeric',
	  month: 'long',
	  day: 'numeric',
	  weekday: 'long',
	  hour: '2-digit',
	  minute: '2-digit',
	  timeZone: 'America/Chicago',
	  hour12: true, // Use 12-hour clock with AM/PM
	};
  
	return new Intl.DateTimeFormat('en-US', options).format(new Date());
  };



const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'flex-start',
	},
	logoAndTitleContainer: {
		alignItems: 'center',
		marginBottom: 30,
		width: '100%',
	},
	logo: {
		height: 30,
		marginVertical: 10,
	},
	title: {
		fontSize: 15,
		paddingBottom: 5
	},
	section: {
		padding: '20px',
		borderBottomWidth: '1px',
		borderStyle: 'dashed',
		borderColor: 'blue',
		alignSelf: 'stretch',
		width: '90%'
	},
	header: {
		fontSize: 13,
		marginBottom: 10,
        color: 'black'
	},
	content: {
		fontSize: 11,
		color: 'gray'
	},
});

interface MyPDFDocumentProps {
	data: ReportData;
}

const MyPDFDocument: React.FC<MyPDFDocumentProps> = ({ data }) => (
	<Document>
		<Page size='A4' style={styles.page}>
			<View style={styles.logoAndTitleContainer}>
				<Image style={styles.logo} src='/logo.png' />
				<Text style={styles.title}>Enterprise Purchase Proposal</Text>
				<Text style={styles.content}>{getDate()}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Author(s)</Text>
				<Text style={styles.content}>{data.authors}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Sponsor(s)</Text>
				<Text style={styles.content}>{data.sponsors}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Business Problem</Text>
				<Text style={styles.content}>{data.businessProblem}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Analysis</Text>
				<Text style={styles.content}>{data.analysis}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Solution</Text>
				<Text style={styles.content}>{data.solution}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Goals</Text>
				<Text style={styles.content}>{data.goals}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Deliverables</Text>
				<Text style={styles.content}>{data.deliverables}</Text>
			</View>

			<View style={styles.section}>
				<Text style={styles.header}>Strategic Alignment</Text>
				<Text style={styles.content}>{data.strategicAlignment}</Text>
			</View>
		</Page>
	</Document>
);

export default MyPDFDocument;
