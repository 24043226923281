import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Text,
  Divider,
  VStack,
  Input,
  Select,
  Button,
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Import Types for the Driver Board
import { driverBoardTypes } from "../../../types/Types";

// Import Information and Props
import {
  cardProps,
  cardHeaderProps,
  editGridProps,
  editLabelProps,
} from "./GeneralFields";

// Import the API
import { apiWrite } from "../../../api/driverBoardAPI";

// import the States data
import states from "../../../lists/States";

type BasicInfoType = {
  profileData: driverBoardTypes | null;
  refreshProfileData: () => void;
  userEmail: string;
  onRefresh: () => void;
};
const BasicInfo = ({
  profileData,
  refreshProfileData,
  userEmail,
  onRefresh,
}: BasicInfoType) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [FirstName, setFirstName] = useState(profileData?.FirstName || "");
  const [LastName, setLastName] = useState(profileData?.LastName || "");
  const [DOB, setDOB] = useState(() => {
    if (profileData?.DOB) {
      const date = new Date(profileData.DOB);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  });
  const [Nickname, setNickname] = useState(profileData?.Nickname || "");
  const [Email, setEmail] = useState(profileData?.Email || "");
  const [Phone, setPhone] = useState(profileData?.Phone || "");
  const [Address, setAddress] = useState(profileData?.Address || "");
  const [City, setCity] = useState(profileData?.City || "");
  const [State, setState] = useState(profileData?.State || "");
  const [ZIP, setZip] = useState(profileData?.ZIP || "");
  const [License, setLicense] = useState(profileData?.License || "");
  const [LicenseState, setLicenseState] = useState(
    profileData?.LicenseState || ""
  );
  const [Veteran, setVeteran] = useState(profileData?.Veteran || "");
  // med exp, date, same treatment as DOB
  const [MedicalExp, setMedicalExp] = useState(() => {
    if (profileData?.MedicalExp) {
      const date = new Date(profileData.MedicalExp);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  });
  const [Physical, setPhysical] = useState(profileData?.Physical || "");

  const saveChangesOrientation = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }

    const isAnyFieldEmpty = Object.values({
      FirstName,
      LastName,
      DOB,
      Email,
      Phone,
      Address,
      City,
      State,
      ZIP,
      License,
      LicenseState,
      Veteran,
      MedicalExp,
      Physical,
    }).some((value) => !value.trim());
    if (isAnyFieldEmpty) {
      toast({
        title: "Warning",
        description: "Please fill out all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "update basic",
      userEmail: userEmail,
      form: {
        FirstName,
        LastName,
        DOB,
        Nickname,
        Email,
        Phone,
        Address,
        City,
        State,
        ZIP,
        License,
        LicenseState,
        Veteran,
        MedicalExp,
        Physical,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      onClose();
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const resetForm = () => {
    setFirstName(profileData?.FirstName || "");
    setLastName(profileData?.LastName || "");
    setDOB(() => {
      if (profileData?.DOB) {
        const date = new Date(profileData.DOB);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      } else {
        return "";
      }
    });
    setNickname(profileData?.Nickname || "");
    setEmail(profileData?.Email || "");
    setPhone(profileData?.Phone || "");
    setAddress(profileData?.Address || "");
    setCity(profileData?.City || "");
    setState(profileData?.State || "");
    setZip(profileData?.ZIP || "");
    setLicense(profileData?.License || "");
    setLicenseState(profileData?.LicenseState || "");
    setVeteran(profileData?.Veteran || "");
    setMedicalExp(() => {
      if (profileData?.MedicalExp) {
        const date = new Date(profileData.MedicalExp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      } else {
        return "";
      }
    });
    setPhysical(profileData?.Physical || "");

    setChanged(false);
  };

  return (
    <>
      <VStack w="100%" height="100%" spacing={1}>
        <VStack {...cardProps}>
          <HStack w="100%" justifyContent="flex-start">
            <Text {...cardHeaderProps}>Change Driver Information</Text>
            <Text
              fontSize="xs"
              fontWeight="semibold"
              color="red.500"
              style={{ visibility: changed ? "visible" : "hidden" }}
            >
              - Unsaved Changes
            </Text>
          </HStack>
          <Divider />
          <Grid {...editGridProps}>
            {/* FirstName  */}
            <GridItem>
              <Text {...editLabelProps}>First Name</Text>
              <Input
                value={FirstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* LastName */}
            <GridItem>
              <Text {...editLabelProps}>Last Name</Text>
              <Input
                value={LastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* DOB */}
            <GridItem>
              <Text {...editLabelProps}>Date of Birth</Text>
              <Input
                type="date"
                value={DOB}
                onChange={(e) => {
                  setDOB(e.target.value);
                  setChanged(true);
                }}
                max={
                  new Date(
                    new Date().getFullYear() - 18,
                    new Date().getMonth(),
                    new Date().getDate()
                  )
                    .toISOString()
                    .split("T")[0]
                }
              />
            </GridItem>
            {/* Nickname */}
            <GridItem>
              <Text {...editLabelProps}>Nickname</Text>
              <Input
                value={Nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Email */}
            <GridItem>
              <Text {...editLabelProps}>Email</Text>
              <Input
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Phone */}
            <GridItem>
              <Text {...editLabelProps}>Phone</Text>
              <Input
                value={Phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Address */}
            <GridItem>
              <Text {...editLabelProps}>Address</Text>
              <Input
                value={Address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* City */}
            <GridItem>
              <Text {...editLabelProps}>City</Text>
              <Input
                value={City}
                onChange={(e) => {
                  setCity(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* State using Select */}
            <GridItem>
              <Text {...editLabelProps}>State</Text>
              <Select
                value={State}
                onChange={(e) => {
                  setState(e.target.value);
                  setChanged(true);
                }}
              >
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </Select>
            </GridItem>
            {/* ZIP */}
            <GridItem>
              <Text {...editLabelProps}>ZIP</Text>
              <Input
                value={ZIP}
                maxLength={5}
                onChange={(e) => {
                  setZip(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* License */}
            <GridItem>
              <Text {...editLabelProps}>License</Text>
              <Input
                value={License}
                onChange={(e) => {
                  setLicense(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* LicenseState */}
            <GridItem>
              <Text {...editLabelProps}>License State</Text>
              <Select
                value={LicenseState}
                onChange={(e) => {
                  setLicenseState(e.target.value);
                  setChanged(true);
                }}
              >
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </Select>
            </GridItem>
            {/* Veteran select, I can add the options after */}
            <GridItem>
              <Text {...editLabelProps}>Veteran</Text>
              <Select
                value={Veteran}
                onChange={(e) => {
                  setVeteran(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Not a Veteran">Not a Veteran</option>
                <option value="Air Force">Air Force</option>
                <option value="Army">Army</option>
                <option value="Coast Guard">Coast Guard</option>
                <option value="Marines">Marines</option>
                <option value="Navy">Navy</option>
                <option value="National Guard">National Guard</option>
                <option value="Reserves">Reserves</option>
                <option value="Space Force">Space Force</option>
              </Select>
            </GridItem>
            {/* MedicalExp */}
            <GridItem>
              <Text {...editLabelProps}>Medical Exp</Text>
              <Input
                value={MedicalExp}
                type="date"
                onChange={(e) => {
                  setMedicalExp(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Physical yes no drop down */}
            <GridItem>
              <Text {...editLabelProps}>Physical</Text>
              <Select
                value={Physical}
                onChange={(e) => {
                  setPhysical(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </GridItem>
          </Grid>
          <HStack
            w="100%"
            justifyContent="flex-end"
            p={2}
            spacing={2}
            mt="50px"
            style={{ visibility: changed ? "visible" : "hidden" }}
          >
            <Button onClick={resetForm} width="100px">
              Cancel
            </Button>
            <Button colorScheme="whatsapp" onClick={onOpen} width="100px">
              Submit
            </Button>
          </HStack>
        </VStack>
      </VStack>

      {/* Recruiter Confirmation Alert Box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to submit the changes?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                onClick={saveChangesOrientation}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default BasicInfo;
