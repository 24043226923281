import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Text,
  Divider,
  VStack,
  Input,
  Select,
  Checkbox,
  Button,
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

// Import Types for the Driver Board
import { driverBoardTypes } from "../../../types/Types";

// Import Information and Props
import {
  cardProps,
  cardHeaderProps,
  editGridProps,
  editLabelProps,
} from "./GeneralFields";

// Import the API
import { apiWrite } from "../../../api/driverBoardAPI";

type ProcessorType = {
  profileData: driverBoardTypes | null;
  refreshProfileData: () => void;
  userEmail: string;
  onRefresh: () => void;
};

const ProcessorForm = ({
  profileData,
  refreshProfileData,
  userEmail,
  onRefresh,
}: ProcessorType) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  // useStates for the Processor Form
  const [Departure, setDeparture] = useState(profileData?.Departure || "");
  const [BackgroundETA, setBackgroundETA] = useState(
    profileData?.BackgroundETA || ""
  );
  const [AgreedCost, setAgreedCost] = useState(profileData?.AgreedCost || "");
  const [RentalStatus, setRentalStatus] = useState(
    profileData?.RentalStatus || ""
  );

  const [FinalCost, setFinalCost] = useState(profileData?.FinalCost || "");
  const [Flight, setFlight] = useState(profileData?.Flight || "");
  const [FlightStatus, setFlightStatus] = useState(
    profileData?.FlightStatus || ""
  );
  const [ConfirmationTicket, setConfirmationTicket] = useState(
    profileData?.ConfirmationTicket || ""
  );

  const [BackgroundPercentage, setBackgroundPercentage] = useState(
    profileData?.BackgroundPercentage || ""
  );
  const [BackgroundStatus, setBackgroundStatus] = useState(
    profileData?.BackgroundStatus || ""
  );

  const [ProcessorAS400, setProcessorAS400] = useState(
    profileData?.ProcessorAS400 || false
  );

  const [RecruiterAS400, setRecruiterAS400] = useState(
    profileData?.RecruiterAS400 || false
  );

  const saveChangesProcessor = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "processing",
      userEmail: userEmail,
      form: {
        Departure: Departure,
        RentalStatus: RentalStatus,
        AgreedCost: AgreedCost,
        FinalCost: FinalCost,
        Flight: Flight,
        FlightStatus: FlightStatus,
        ConfirmationTicket: ConfirmationTicket,
        BackgroundETA: BackgroundETA,
        BackgroundPercentage: BackgroundPercentage,
        BackgroundStatus: BackgroundStatus,
        ProcessorAS400: ProcessorAS400,
        RecruiterAS400: RecruiterAS400,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      onClose();
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function so that when cancel is pressed, it returns all the values to the default values

  const resetForm = () => {
    setDeparture(profileData?.Departure || "");
    setRentalStatus(profileData?.RentalStatus || "");
    setAgreedCost(profileData?.AgreedCost || "");
    setFinalCost(profileData?.FinalCost || "");
    setFlight(profileData?.Flight || "");
    setFlightStatus(profileData?.FlightStatus || "");
    setConfirmationTicket(profileData?.ConfirmationTicket || "");
    setBackgroundETA(profileData?.BackgroundETA || "");
    setBackgroundPercentage(profileData?.BackgroundPercentage || "");
    setBackgroundStatus(profileData?.BackgroundStatus || "");
    setProcessorAS400(profileData?.ProcessorAS400 || false);
    setRecruiterAS400(profileData?.RecruiterAS400 || false);
    setChanged(false);
  };

  return (
    <>
      <VStack w="100%" height="100%" spacing={1}>
        <VStack {...cardProps}>
          <HStack w="100%" justifyContent="flex-start">
            <Text {...cardHeaderProps}>Update Processing Information</Text>
            <Text
              fontSize="xs"
              fontWeight="semibold"
              color="red.500"
              style={{ visibility: changed ? "visible" : "hidden" }}
            >
              - Unsaved Changes
            </Text>
          </HStack>
          <Divider />

          <Grid {...editGridProps}>
            {/* Departure */}
            <GridItem>
              <Text {...editLabelProps}>Departure</Text>
              <Input
                value={Departure}
                size="md"
                type="date"
                onChange={(e) => {
                  setDeparture(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>

            {/* Agreed Cost */}
            <GridItem>
              <Text {...editLabelProps}>Agreed Cost</Text>
              <Input
                value={AgreedCost}
                size="md"
                type="number"
                onChange={(e) => {
                  setAgreedCost(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>

            {/* Final Cost */}
            <GridItem>
              <Text {...editLabelProps}>Final Cost</Text>
              <Input
                value={FinalCost}
                size="md"
                type="number"
                onChange={(e) => {
                  setFinalCost(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>

            {/* Flight Status select options*/}
            <GridItem>
              <Text {...editLabelProps}>Flight Status</Text>
              <Select
                placeholder="Select option"
                value={FlightStatus}
                size="md"
                onChange={(e) => {
                  setFlightStatus(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Completed">Completed</option>
                <option value="Flight Credit">Flight Credit</option>
                <option value="No Refund/No Credit">No Refund/No Credit</option>
                <option value="Refund">Refund</option>
              </Select>
            </GridItem>

            {/* Flight */}
            <GridItem>
              <Text {...editLabelProps}>Flight</Text>
              <Input
                value={Flight}
                size="md"
                type="text"
                onChange={(e) => {
                  setFlight(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>

            {/* Confirmation Ticket */}
            <GridItem>
              <Text {...editLabelProps}>Confirmation/Ticket Number</Text>
              <Input
                value={ConfirmationTicket}
                size="md"
                type="text"
                onChange={(e) => {
                  setConfirmationTicket(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Background using select*/}
            <GridItem>
              <Text {...editLabelProps}>Background Status</Text>
              <Select
                placeholder="Select option"
                value={BackgroundStatus}
                size="md"
                onChange={(e) => {
                  setBackgroundStatus(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="In-Progress">In-Progress</option>
                <option value="Completed">Completed</option>
                <option value="Manager Approval">Manager Approval</option>
              </Select>
            </GridItem>

            {/* Background ETA In Date */}
            <GridItem>
              <Text {...editLabelProps}>Background ETA</Text>
              <Input
                value={BackgroundETA}
                size="md"
                type="date"
                onChange={(e) => {
                  setBackgroundETA(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Background Percentage */}
            <GridItem>
              <Text {...editLabelProps}>Background Percentage</Text>
              <Text fontSize="sm" fontWeight="semibold">
                {BackgroundPercentage}%
              </Text>
              <Slider
                aria-label="slider-ex-1"
                value={Number(BackgroundPercentage)}
                onChange={(e) => {
                  setBackgroundPercentage(e.toString());
                  setChanged(true);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </GridItem>

            {/* Rental Status select options*/}
            <GridItem>
              <Text {...editLabelProps}>Rental Status</Text>
              <Select
                placeholder="Select option"
                value={RentalStatus}
                size="md"
                onChange={(e) => {
                  setRentalStatus(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Open Ticket">Open Ticket</option>
                <option value="No Show">No Show</option>
                <option value="Has Rental">Has Rental</option>
                <option value="Rental Returned">Rental Returned</option>
              </Select>
            </GridItem>

            {/* Processor AS400 using checkbox */}
            <GridItem>
              <Text {...editLabelProps}>Processor AS400</Text>
              <Checkbox
                size="lg"
                isChecked={ProcessorAS400 === true}
                onChange={(e) => {
                  setProcessorAS400(e.target.checked ? true : false);
                  setChanged(true);
                }}
              />
            </GridItem>

            {/* Recruiter AS400 using checkbox */}
            <GridItem>
              <Text {...editLabelProps}>Recruiter AS400</Text>
              <Checkbox
                size="lg"
                isChecked={RecruiterAS400 === true}
                onChange={(e) => {
                  setRecruiterAS400(e.target.checked ? true : false);
                  setChanged(true);
                }}
              />
            </GridItem>
          </Grid>

          <HStack
            w="100%"
            justifyContent="flex-end"
            p={2}
            spacing={2}
            mt="50px"
            style={{ visibility: changed ? "visible" : "hidden" }}
          >
            <Button onClick={resetForm} width="100px">
              Cancel
            </Button>
            <Button colorScheme="whatsapp" onClick={onOpen} width="100px">
              Submit
            </Button>
          </HStack>
        </VStack>
      </VStack>

      {/* Recruiter Confirmation Alert Box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to submit the changes?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                onClick={saveChangesProcessor}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ProcessorForm;
