import React, { useEffect, useState } from "react";
import { userInfo } from "../../types/Types";
import {
  Box,
  Text,
  VStack,
  useToast,
  HStack,
  Image,
  Grid,
  GridItem,
  Divider,
  Input,
  Flex,
  Button,
  Select as Dropdown,
  Textarea,
} from "@chakra-ui/react";
import Select from "react-select";

// Import the API
import expressAPI from "../../api/expressAPI";
import API from "../../api/API";

// Import date-fns
import { format } from "date-fns";

// Import Types
import { adUsersTypes } from "../../types/Types";

// Form Specific Types

interface TDOFormProps {
  DriverCode?: string | null;
  DriverName?: string | null;
  CurrentCostCenter?: string | null;
  OrientationLocation?: string | null;
  OrientationDate?: string | null;
  TerminationReason?: string | null;
  TerminationDate?: string | null;
  RequesterName?: string | null;
  RequesterEmail?: string | null;
  Comments?: string | null;
}

// Interface for Driver Data to be used in the drop down
interface DriverData {
  DriverCode: string;
  DriverName: string;
  CurrentCostCenter: string;
}

// Types for the Driver Code Search Drop down
interface SelectOptions {
  label: string;
  value: string;
}

const TDOForm: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  // End Point
  const endpoint = "core/forms/formsAPI";

  // Components State
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const toast = useToast();

  // Support data for the form
  const [drivers, setDrivers] = useState<DriverData[]>([]);
  const [DropDownedDriver, setDropDownedDriver] = useState<SelectOptions[]>([]);

  // Form Data
  const [formData, setFormData] = useState<TDOFormProps>({
    DriverCode: null,
    DriverName: null,
    CurrentCostCenter: null,
    OrientationLocation: null,
    OrientationDate: null,
    TerminationReason: null,
    TerminationDate: null,
    RequesterName: null,
    RequesterEmail: null,
    Comments: null,
  });

  // props for the label
  const labelProps = {
    fontSize: "xs",
    fontWeight: "semibold",
    color: "gray.500",
    ml: "2px",
  };

  // Fetch the drivers
  const fetchDrivers = async () => {
    const payload = {
      process: "status change driver info",
    };

    const response = await expressAPI(endpoint, payload);

    if (response) {
      setDrivers(response);
      const options = response.map((driver: DriverData) => ({
        label: `${driver.DriverCode} - ${driver.DriverName}`,
        value: driver.DriverCode,
      }));
      setDropDownedDriver(options);
    }
  };

  // Handle the select of a driver
  const handleSelectChange = (selectedOption: any) => {
    const selectedDriver = drivers.find(
      (driver) => driver.DriverCode === selectedOption.value
    );
    if (selectedDriver) {
      setFormData({
        ...formData,
        DriverCode: selectedDriver.DriverCode,
        DriverName: selectedDriver.DriverName,
        CurrentCostCenter: selectedDriver.CurrentCostCenter,
      });
    }
  };

  // Function to submit form
  const submitForm = async () => {
    if (
      !formData.DriverCode ||
      !formData.DriverName ||
      !formData.CurrentCostCenter ||
      !formData.OrientationLocation ||
      !formData.OrientationDate ||
      !formData.TerminationReason ||
      !formData.TerminationDate ||
      !formData.Comments
    ) {
      toast({
        title: "Error",
        description:
          "Please fill in all the required fields before submitting the form",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitted(true);
    const payload = {
      process: "TDO Form Submit",
      userName,
      userEmail,
      formData,
    };
    const response = await API(payload);
    if (response) {
      setIsSubmitted(false);
      toast({
        title: "Form Submitted",
        description: "The form has been submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Reset form data
      setFormData({
        DriverCode: null,
        DriverName: null,
        CurrentCostCenter: null,
        OrientationLocation: null,
        OrientationDate: null,
        TerminationReason: null,
        TerminationDate: null,
        RequesterName: null,
        RequesterEmail: null,
        Comments: null,
      });
    }
  };

  // useEffect for initial render
  useEffect(() => {
    document.title = "Term During Orientation";
    fetchDrivers();
  }, []);

  return (
    <Box
      height="calc(100% - 90px)"
      m="2vh"
      justifyContent="center"
      alignItems="flex-start"
      display="flex"
    >
      <VStack
        width="1200px"
        height="100%"
        borderRadius="3xl"
        background="whiteAlpha.900"
        shadow="md"
        padding="20px"
        bg="white"
        overflow="auto"
      >
        <Box width="100%">
          <HStack height="40px">
            <Image src="/logo.png" alt="Logo" height="100%" />
            <Text fontSize="lg" fontWeight="semibold" color="#1D61AA" ml="10px">
              Term During Orientation
            </Text>
          </HStack>
          <Divider mt="10px" />
        </Box>

        <Grid
          templateColumns="repeat(4, 1fr)"
          gap={4}
          w="100%"
          h="100%"
          mt="50px"
          alignContent="flex-start"
        >
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Driver Code *
            </Text>
            <Select
              maxMenuHeight={100}
              options={DropDownedDriver}
              isSearchable
              placeholder="Search by Code"
              noOptionsMessage={() => "No matches found"}
              onChange={handleSelectChange}
              value={
                DropDownedDriver.find(
                  (option) => option.value === formData.DriverCode
                ) || null
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Driver Name *
            </Text>
            <Input
              value={formData.DriverName || ""}
              onChange={(e) => {
                setFormData({ ...formData, DriverName: e.target.value });
              }}
              placeholder="Driver Name"
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Cost Center *
            </Text>
            <Input
              value={formData.CurrentCostCenter || ""}
              placeholder="Cost Center"
              onChange={(e) =>
                setFormData({ ...formData, CurrentCostCenter: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Orientation Location *
            </Text>
            <Dropdown
              placeholder="Select Orientation Location"
              value={formData.OrientationLocation || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  OrientationLocation: e.target.value,
                })
              }
            >
              <option value="Dubuque">Dubuque</option>
              <option value="Denton">Denton</option>
              <option value="Dover">Dover</option>
              <option value="Sapulpa">Sapulpa</option>
            </Dropdown>
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Orientation Date *
            </Text>
            <Input
              value={formData.OrientationDate || ""}
              type="date"
              onChange={(e) =>
                setFormData({ ...formData, OrientationDate: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Termination Reason *
            </Text>
            <Dropdown
              placeholder="Select Termination Reason"
              value={formData.TerminationReason || ""}
              onChange={(e) =>
                setFormData({ ...formData, TerminationReason: e.target.value })
              }
            >
              <option value="TDO-Ops">TDO-Ops</option>
              <option value="TDO-PHY">TDO-PHY</option>
              <option value="TDO-CMS">TDO-CMS</option>
              <option value="TDO-OTH">TDO-OTH</option>
              <option value="TDO-H">TDO-H</option>
              <option value="TDO-LFT">TDO-LFT</option>
              <option value="TDO-FRT">TDO-FRT</option>
              <option value="TDO-DNQ">TDO-DNQ</option>
              <option value="TDO-BGPEND">TDO-BGPEND</option>
              <option value="TDO-BGDNQ">TDO-BGDNQ</option>
              <option value="TDO-VOEDNQ">TDO-VOEDNQ</option>
              <option value="TDO-HPE">TDO-HPE</option>
              <option value="TDO-CUL">TDO-CUL</option>
              <option value="TDO-VOLLEF">TDO-VOLLEF</option>
              <option value="TDO-RESCHE">TDO-RESCHE</option>
              <option value="TDO-MEDCARD">TDO-MEDCARD</option>
            </Dropdown>
          </GridItem>
          <GridItem colSpan={1}>
            <Text {...labelProps} mb="6px">
              Termination Date *
            </Text>
            <Input
              value={formData.TerminationDate || ""}
              type="date"
              onChange={(e) =>
                setFormData({ ...formData, TerminationDate: e.target.value })
              }
            />
          </GridItem>
          <GridItem colSpan={4}>
            <Text {...labelProps} mb="6px">
              Comments *
            </Text>
            <Textarea
              placeholder="Comments"
              value={formData.Comments || ""}
              onChange={(e) =>
                setFormData({ ...formData, Comments: e.target.value })
              }
            />
          </GridItem>
        </Grid>
        <Flex w="100%" justifyContent="flex-end" mt="50px">
          <Button size="lg" onClick={submitForm} isLoading={isSubmitted}>
            Submit
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default TDOForm;
