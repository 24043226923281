import React, { useEffect, useState, useRef } from "react";
import { userInfo } from "../../../types/Types";
import {
  VStack,
  HStack,
  Box,
  IconButton,
  Text,
  Divider,
  Textarea,
} from "@chakra-ui/react";

//Import API
import { apiRead, apiWrite } from "../../../api/driverBoardAPI";
//Import Icons
import { IoIosSend } from "react-icons/io";

interface noteType {
  ID: number | null;
  DriverCode: string | null;
  Notes: string | null;
  Created: string | null;
  CreatedBy: string | null;
  CreatedByEmail: string | null;
}

interface notesProps {
  userEmail: string;
  userName: string;
  userDepartment: string;
  id: string | number;
}

const DriverBoardNotes: React.FC<notesProps> = ({
  userName,
  userEmail,
  userDepartment,

  id,
}) => {
  // Note Section
  const [noteLoading, setNoteLoading] = useState<boolean>(false);
  const [data, setData] = useState<noteType[]>([]);
  const endOfNotesRef = useRef<HTMLDivElement>(null);
  const [notes, setNotes] = useState<string>("");

  // Fetch Trainer Notes
  const fetchNotes = async (driverCode: string) => {
    setNoteLoading(true);
    setData([]);
    try {
      const response = await apiRead({
        process: "fetch driver board notes",
        id: id.toString(),
      });
      setData(response);
      setNoteLoading(false);
    } catch (error) {
      console.error("Error fetching notes:", error);
      setNoteLoading(false);
    }
  };

  // Add the note to the database
  const handleSendNote = async () => {
    if (!id || !notes.trim()) return;
    setNoteLoading(true);
    try {
      await apiWrite({
        Process: "add driver board notes",
        id: id.toString(),
        Notes: notes,
        userName: userName,
        userEmail: userEmail,
      });
      setNotes("");
      if (id) {
        fetchNotes(id.toString());
      }
    } catch (error) {
      console.error("Error sending note:", error);
    } finally {
      setNoteLoading(false);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      endOfNotesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      fetchNotes(id.toString());
    }
  }, [id]);

  return (
    <VStack width="100%" height="calc(100vh - 170px)" spacing={4}>
      <Box width="100%" height="100%" overflowY="auto" pb="20px">
        {data
          .sort(
            (a, b) =>
              new Date(a.Created as string).getTime() -
              new Date(b.Created as string).getTime()
          )
          .slice(0, 20)
          .map((note, index) => (
            <Box key={note.ID}>
              <Text fontWeight="semibold" fontSize="xs" color="blue.600">
                {note.CreatedBy} &middot;{" "}
                {new Date(note.Created as string).toLocaleString()}
              </Text>
              <Text mb={2} mt={2} fontSize="md">
                {note.Notes}
              </Text>
              {index < 19 && (
                <Divider borderColor="gray.300" mt="20px" mb="20px" />
              )}
            </Box>
          ))}
        <div ref={endOfNotesRef}></div>
      </Box>
      <HStack
        width="100%"
        height="100px"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add note..."
          resize="none"
          width="100%"
          borderColor="blackAlpha.300"
          maxLength={999}
        />
        <IconButton
          icon={<IoIosSend />}
          colorScheme="facebook"
          aria-label="Send"
          size="sm"
          borderRadius="full"
          isLoading={noteLoading}
          isDisabled={!notes.trim()}
          onClick={handleSendNote}
        />
      </HStack>
    </VStack>
  );
};

export default DriverBoardNotes;
