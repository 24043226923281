import React, { useState, useCallback, useEffect, createRef } from "react";
import {
  VStack,
  Box,
  Text,
  useToast,
  Link,
  Spinner,
  Divider,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import API from "../../api/API";
import { CloseIcon, AttachmentIcon } from "@chakra-ui/icons";

interface FileManagementProps {
  userName: string;
  userEmail: string;
  userDepartment: string;
  id: number | undefined;
  drivercode: string | undefined;
}

interface RetrievedFile {
  fileName: string;
  content: string;
}

const FileManagement: React.FC<FileManagementProps> = ({
  userName,
  userEmail,
  userDepartment,
  id,
  drivercode,
}) => {
  const [dragging, setDragging] = useState(false);
  const [retrievedFiles, setRetrievedFiles] = useState<RetrievedFile[]>([]);
  const fileInputRef = createRef<HTMLInputElement>();
  const [loading, setloading] = useState(false);
  const toast = useToast();

  // File upload validation
  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const maxFileSize = 50 * 1024 * 1024;

  // Function to retrieve files from the API
  const getFilesFromAPI = async () => {
    setloading(true);
    try {
      const response = await API({
        process: "fleet foundations files",
        action: "stream",
        id: id,
        drivercode: drivercode,
      });
      setloading(true);
      if (Array.isArray(response)) {
        setRetrievedFiles(response);
        setloading(false);
      } else {
        setRetrievedFiles([]);
        setloading(false);
      }
    } catch (error) {
      setRetrievedFiles([]);
      setloading(false);
    }
  };

  // Function to convert base64 to blob
  const base64ToBlob = (base64String: string, mimeType: string) => {
    const byteString = atob(base64String);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  };

  // Function to open file in new tab
  const openFileInNewTab = (base64: any) => {
    let base64String = "";
    let mimeType = "";

    if (typeof base64 === "object") {
      if (base64["$content"]) {
        base64String = base64["$content"];
      }
      if (base64["$content-type"]) {
        mimeType = base64["$content-type"];
      }
    } else if (typeof base64 === "string") {
      base64String = base64.split(",")[1];
      mimeType = base64.split(",")[0].split(":")[1].split(";")[0];
    }

    if (!base64String) {
      toast({
        title: "Error opening file",
        description: "Invalid file format",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const blob = base64ToBlob(base64String, mimeType);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  // Send file to API
  const sendFileToAPI = async (file: File) => {
    setloading(true);

    const base64 = await convertToBase64(file);
    const payload = {
      process: "fleet foundations files",
      action: "upload",
      user: userName,
      userEmail: userEmail,
      fileName: file.name,
      base64FileContent: base64,
      id: id,
      drivercode: drivercode,
    };

    try {
      const response = await API(payload);
      if (response.success === "true") {
        toast({
          title: "File uploaded",
          description: "File uploaded successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getFilesFromAPI();
      } else {
        toast({
          title: "File upload failed",
          description:
            "File with the same name already exists. Please rename the file and retry.",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        getFilesFromAPI();
      }
    } catch (error) {
      toast({
        title: "Error uploading file",
        description: "There was an error uploading your file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      getFilesFromAPI();
    }
  };

  const handleFileSelection = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;

    if (!files) return;

    if (files.length > 1) {
      toast({
        title: "Multiple Files Detected",
        description: "Please select only one file at a time.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const file = files[0];

    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Unsupported file type",
        description: "Only PNG, JPEG, PDF, or Word documents are accepted.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else if (file.size > maxFileSize) {
      toast({
        title: "File too large",
        description: "File size should be less than 50MB.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      await sendFileToAPI(file);
    }
  };

  // Drag and drop handlers
  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (!loading) {
        setDragging(true);
      }
    },
    [loading]
  );

  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

  const convertToBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setDragging(false);

      if (loading) {
        return;
      }

      const files = e.dataTransfer.files;
      if (files.length > 1) {
        toast({
          title: "Multiple Files Detected",
          description: "Please drag and drop only one file at a time.",
          status: "warning",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const file = files[0];

      if (!allowedTypes.includes(file.type)) {
        toast({
          title: "Unsupported file type",
          description: "Only PNG, JPEG, PDF, or Word documents are accepted.",
          status: "warning",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else if (file.size > maxFileSize) {
        toast({
          title: "File too large",
          description: "File size should be less than 50MB.",
          status: "warning",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        await sendFileToAPI(file);
      }
    },
    [userName, userEmail, loading, toast]
  );

  // Function to remove a file by its name
  const removeFile = async (fileName: string) => {
    setloading(true);

    const payload = {
      process: "fleet foundations files",
      action: "delete",
      user: userName,
      userEmail: userEmail,
      fileName: fileName,
      id: id,
      drivercode: drivercode,
    };

    try {
      const response = await API(payload);
      if (response.success === "true") {
        toast({
          title: "File removed",
          description: "File removed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getFilesFromAPI();
      } else {
        toast({
          title: "File removal failed",
          description: "Failed to remove the file. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getFilesFromAPI();
      }
    } catch (error) {
      toast({
        title: "Error removing file",
        description: "There was an error removing the file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      getFilesFromAPI();
    }
  };

  useEffect(() => {
    getFilesFromAPI();
  }, []);

  return (
    <VStack width="100%" height="100%" spacing={4}>
      {loading ? (
        <Spinner
          size="lg"
          color="green.500"
          emptyColor="gray.200"
          thickness="2px"
        />
      ) : (
        <Box
          p={5}
          borderWidth="2px"
          borderColor={dragging ? "teal.300" : "gray.300"}
          borderStyle="dashed"
          rounded="md"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          width="100%"
          pointerEvents={loading ? "none" : "auto"}
          cursor={loading ? "not-allowed" : "pointer"}
          onClick={() => {
            // Trigger the file input click when the box is clicked
            fileInputRef.current?.click();
          }}
        >
          <input
            type="file"
            accept=".png, .jpeg, .jpg, .pdf, .doc, .docx"
            multiple={false}
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileSelection}
          />
          <Text color="blackAlpha.500" fontWeight="semibold">
            Click or drag and drop one file at a time. Maximum file size is
            50MB. We accept PNG, JPEG, PDF, or Word documents.
          </Text>
        </Box>
      )}

      {!loading && retrievedFiles.length > 0 && (
        <VStack
          align="flex-start"
          spacing={2}
          width="100%"
          padding="15px"
          borderRadius="xl"
          boxShadow="inset 0 0 2px rgba(0, 0, 0, 0.2)"
          height="100%"
          overflowY="auto"
          overflowX="hidden"
        >
          <Flex width="100%" alignItems="center" justifyContent="flex-start">
            <AttachmentIcon />
            <Text color="blackAlpha.700" fontWeight="semibold" ml="5px">
              Uploaded File(s)
            </Text>
          </Flex>
          <Divider mb="10px" borderColor="gray.500" borderWidth="1px" />
          {retrievedFiles.map((file) => (
            <Flex
              key={file.fileName}
              alignItems="center"
              justifyContent="flex-start"
            >
              <IconButton
                aria-label="Remove file"
                variant="link"
                size="xs"
                mr="5px"
                onClick={() => removeFile(file.fileName)}
                isLoading={loading}
                icon={<CloseIcon color="red.700" />}
              >
                Remove
              </IconButton>
              <Link
                color="gray.500"
                fontWeight="medium"
                fontSize="small"
                onClick={() => openFileInNewTab(file.content)}
                ml="10px"
              >
                {file.fileName}
              </Link>
            </Flex>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default FileManagement;
