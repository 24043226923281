// In EnterpriseFormModal.tsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  HStack,
  Text,
  VStack,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { purchaseAPI } from "../../api/purchasingAPI";

interface EnterpriseFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  userEmail: string;
  userDepartment: string;
}

interface CostCenter {
  DIVISION: string;
  COST_CENTER: string;
  Description: string;
  Cost_Center_Description: string;
  COMPANY_ID: string;
  BUSINESS_SEGMENT: string;
  REGION: string;
}

export const RegularFormModal: React.FC<EnterpriseFormModalProps> = ({
  isOpen,
  onClose,
  userEmail,
  userDepartment,
}) => {
  // Define variables here
  const [location, setLocation] = useState<string>("Corporate");
  const [costCenter, setCostCenter] = useState<string>("");
  const [priority, setPriority] = useState<string>("Low");
  const [cost, setCost] = useState<number>(0.0);
  const [frequency, setFrequency] = useState<string>("Once");
  const [description, setDescription] = useState<string>("");
  const [justification, setJustification] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [costCentersArray, setCostCentersArray] = useState<CostCenter[]>([]);
  const toast = useToast();

  const resetForm = () => {
    setLocation("Corporate");
    setCostCenter("");
    setPriority("Low");
    setCost(0.0);
    setFrequency("Once");
    setDescription("");
    setJustification("");
  };

  const validateForm = (): boolean => {
    if (
      location.trim() === "" ||
      costCenter.trim() === "" ||
      priority.trim() === "" ||
      cost === 0 ||
      frequency.trim() === "" ||
      description.trim() === "" ||
      justification.trim() === ""
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const getCostCenters = async () => {
      try {
        const response: any = await purchaseAPI(
          "",
          "getCostCenters",
          userEmail
        );
        setCostCentersArray(response.data);
      } catch {}
    };

    getCostCenters();
  }, []);

  const submitRequest = async () => {
    setIsLoading(true);

    if (validateForm()) {
      const object = {
        requestor: userEmail,
        department: userDepartment,
        location: location,
        costCenter: costCenter,
        priority: priority,
        cost: cost,
        frequency: frequency,
        description: description,
        justification: justification,
      };

      try {
        const response: any = await purchaseAPI(
          object,
          "submitRegularRequest",
          userEmail
        );

        if (response?.status === 200) {
          resetForm();
          toast({
            title: "Success",
            description: "Request has been submitted",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          setIsLoading(false);
          onClose();
        } else {
          resetForm();
          toast({
            title: "Error",
            description: "Submission failed.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          setIsLoading(false);
          onClose();
        }
      } catch {}
    } else {
      toast({
        title: "Error",
        description: "You have not filled out the required fields.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });

      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Purchase Request</ModalHeader>

        <ModalBody>
          <VStack width="100%" display="flex" justifyContent="space-evenly">
            {/* requestor Input */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Requestor</Text>
              <Text color="orange.600" width="80%">
                {userEmail}
              </Text>
            </HStack>

            {/* Department Input */}
            <HStack
              width="100%"
              display="flex"
              justifyContent="space-between"
              marginBottom="5"
            >
              <Text color="blackAlpha.600">Department</Text>
              <Text color="orange.600" width="80%">
                {userDepartment}
              </Text>
            </HStack>

            {/* Location Select */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Location</Text>
              <Select
                defaultValue={"Corporate"}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value="Corporate">Corporate</option>
                <option value="Eagan Office">Eagan Office</option>
                <option value="Recruiting Office">Recruiting Office</option>
                <option value="Salt Lake Office">Salt Lake Office</option>
                <option value="Sapulpa Office">Sapulpa Office</option>
                <option value="Spokane Office">Spokane Office</option>
                <option value="Orientation/Driver Services">
                  Orientation/Driver Services
                </option>
                <option value="East Dubuque Terminal">
                  East Dubuque Terminal
                </option>
                <option value="Kansas City Terminal">
                  Kansas City Terminal
                </option>
                <option value="Joliet Terminal">Joliet Terminal</option>
                <option value="Dover Terminal">Dover Terminal</option>
                <option value="Denton Terminal">Denton Terminal</option>
                <option value="Springfield Terminal">
                  Springfield Terminal
                </option>
                <option value="Monmouth Terminal">Monmouth Terminal</option>
                <option value="Solutions">Solutions</option>
              </Select>
            </HStack>

            {/* Cost Center Input */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Cost Center</Text>
              <Select
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
                onChange={(e) => setCostCenter(e.target.value)}
              >
                {costCentersArray?.map((item) => (
                  <option key={item.COST_CENTER} value={item.COST_CENTER}>
                    {item.COST_CENTER}
                  </option>
                ))}
              </Select>
            </HStack>

            {/* Priority Select */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Priority</Text>
              <Select
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
                onChange={(e) => setPriority(e.target.value)}
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Select>
            </HStack>

            {/* Cost NumberInput */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Cost ($)</Text>
              <NumberInput
                defaultValue={0}
                precision={2}
                step={5}
                allowMouseWheel
                borderColor="blackAlpha.300"
                width="80%"
                onChange={(valueString) => setCost(parseFloat(valueString))}
              >
                <NumberInputField
                  _hover={{ borderColor: "blackAlpha.300" }}
                  _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                  _active={{ borderColor: "blackAlpha.300" }}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </HStack>

            {/* Frequency Select */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Frequency</Text>
              <Select
                defaultValue="Once"
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
                onChange={(e) => setFrequency(e.target.value)}
              >
                <option value="Once">Once</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Semi-Annually">Semi-Annually</option>
                <option value="Annually">Annually</option>
              </Select>
            </HStack>

            {/* Description Textarea */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Description</Text>
              <Textarea
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
                onChange={(e) => setDescription(e.target.value)}
              />
            </HStack>

            {/* Justification Textarea */}
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Justification</Text>
              <Textarea
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
                onChange={(e) => setJustification(e.target.value)}
              />
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isLoading}
            colorScheme="orange"
            onClick={submitRequest}
          >
            {" "}
            {isLoading ? <Spinner size="sm" color="white" /> : "Submit"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
