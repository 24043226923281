import React, { useEffect, useState } from "react";
import { userInfo } from "../../types/Types";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  VStack,
  Divider,
  HStack,
  Image,
  Card,
  CardBody,
  Grid,
  GridItem,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import brtAPI from "../../api/brtAPI";

// BRT Action Item Types

interface BRTActionItemProps {
  DriverName: string;
  DriverCode: string;
  HiredDate: string | null;
  TruckNumber: string | null;
  Phone: string | null;
  Email: string | null;
  Completed: string | null;
}

const BRTActionItem: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const { ID } = useParams<{ ID: string }>();
  const [comment, setComment] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BRTActionItemProps | null>(null);
  const toast = useToast();

  // Function to fetch the data from the API
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const payload = {
        id: ID,
        action: "Fetch",
      };
      const response = await brtAPI(payload);
      setData(response[0]);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    try {
      if (rating === 0 || !comment) {
        toast({
          title: "Incomplete information",
          description:
            "Please rate the conversation and provide some details about it.",
          status: "warning",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const payload = {
        id: ID,
        userEmail: userEmail,
        action: "Submit",
        notes: comment,
        sentiment: rating,
      };
      const response = await brtAPI(payload);
      if (response) {
        setData(response[0]);
        setComment("");
        setRating(0);
        toast({
          title: "Action item submitted.",
          description: "Thank you for submitting the action item.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else {
        throw new Error("No response received");
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description:
          "Error submitting the action item. Please try again or contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsSubmitted(false);
    }
  };

  // useEffect for initial render
  useEffect(() => {
    document.title = "Building Relationships & Trust";
    fetchData();
  }, [ID]);

  return (
    <Box
      height="calc(100% - 90px)"
      m="2vh"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <VStack
        width="900px"
        borderRadius="3xl"
        background="whiteAlpha.900"
        shadow="lg"
        padding="20px"
      >
        <Box width="100%">
          <HStack height="40px">
            <Image src="/logo.png" alt="Logo" height="100%" />
            <Text fontSize="lg" fontWeight="semibold" color="#1D61AA" ml="10px">
              Building Relationships & Trust
            </Text>
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="md"
              alignSelf="center"
              visibility={isLoading ? "visible" : "hidden"}
            />
          </HStack>
          <Divider mt="10px" />
        </Box>

        <Card
          width="100%"
          mt="10px"
          variant="filled"
          background="blackAlpha.50"
          visibility={isLoading ? "hidden" : "visible"}
        >
          <CardBody>
            <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
              DRIVER INFORMATION
            </Text>

            <Grid templateColumns="repeat(5, 1fr)" gap={3} mt="20px">
              {/* Driver Name */}
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                  Driver Name
                </Text>
                <Text fontSize="md" color="blackAlpha">
                  {data?.DriverName}
                </Text>
              </GridItem>
              {/* Driver Code */}
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                  Driver Code
                </Text>
                <Text fontSize="md" color="blackAlpha">
                  {data?.DriverCode}
                </Text>
              </GridItem>
              {/* Hired Date */}
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                  Hired Date
                </Text>
                <Text fontSize="md" color="blackAlpha">
                  {data?.HiredDate
                    ? new Date(data?.HiredDate).toLocaleDateString()
                    : ""}
                </Text>
              </GridItem>
              {/* Truck Number */}
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                  Truck Number
                </Text>
                <Text fontSize="md" color="blackAlpha">
                  {data?.TruckNumber ? data?.TruckNumber : "N/A"}
                </Text>
              </GridItem>
              {/* Phone */}
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                  Phone
                </Text>
                {/* Format phone number to US format */}
                <Text fontSize="md" color="blackAlpha">
                  {data?.Phone
                    ? `(${data?.Phone.slice(0, 3)}) ${data?.Phone.slice(
                        3,
                        6
                      )}-${data?.Phone.slice(6)}`
                    : "N/A"}
                </Text>
              </GridItem>
              {/* Email */}
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                  Email
                </Text>
                <Text fontSize="md" color="blackAlpha">
                  {data?.Email ? data?.Email : "N/A"}
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>

        <Card
          width="100%"
          mt="10px"
          visibility={isLoading ? "hidden" : "visible"}
          bg={data?.Completed ? "green.100" : "gray.100"}
        >
          <CardBody>
            <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
              STATUS
            </Text>
            <Text fontSize="md" color="blackAlpha">
              {data?.Completed
                ? `This action item has been completed on ${new Date(
                    data?.Completed
                  ).toLocaleDateString()}`
                : "This action item has not been completed"}
            </Text>
          </CardBody>
        </Card>
        <Card
          width="100%"
          mt="10px"
          visibility={isLoading ? "hidden" : "visible"}
        >
          <CardBody>
            <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
              ACTION ITEM
            </Text>
            <Grid templateColumns="repeat(1, 1fr)" gap={3} mt="20px">
              {/* Comments using textarea, height locked to not be adjustable, and a max length of 999 characters */}
              <GridItem>
                <FormControl id="comment">
                  <FormLabel fontSize="sm" color="#1D61AA">
                    Comments
                  </FormLabel>
                  <Textarea
                    placeholder="Add your note here..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    size="sm"
                    resize="none"
                    borderRadius="md"
                    maxLength={999}
                    isDisabled={isSubmitted || data?.Completed ? true : false}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}></GridItem>
              {/* Rating using sad, neutral, happy face, emoji at xl font size, when selected, set rating to a number, 1, 2, 3, inside a form control with the label rating, and the background turns light gray to indicate that an emoji has been selected, keep the background the same, based on whether the item has been selected or not */}
              <GridItem>
                <FormControl id="rating">
                  <FormLabel fontSize="sm" color="#1D61AA">
                    Rating
                  </FormLabel>
                  <HStack spacing="10px">
                    <Button
                      variant="ghost"
                      size="xl"
                      onClick={() => setRating(1)}
                      bg={rating === 1 ? "gray.200" : "white"}
                      fontSize="4xl"
                    >
                      😞
                    </Button>
                    <Button
                      variant="ghost"
                      size="xl"
                      onClick={() => setRating(2)}
                      bg={rating === 2 ? "gray.200" : "white"}
                      fontSize="4xl"
                    >
                      😐
                    </Button>
                    <Button
                      variant="ghost"
                      size="xl"
                      onClick={() => setRating(3)}
                      bg={rating === 3 ? "gray.200" : "white"}
                      fontSize="4xl"
                    >
                      😊
                    </Button>
                  </HStack>
                </FormControl>
              </GridItem>
            </Grid>
            {/* Submit button on the right side of the card */}
          </CardBody>
          <Button
            mt="10px"
            colorScheme="whatsapp"
            isLoading={isSubmitted}
            alignSelf={"flex-end"}
            width="100px"
            m="20px"
            onClick={handleSubmit}
            isDisabled={data?.Completed ? true : false || isSubmitted}
          >
            Submit
          </Button>
        </Card>
      </VStack>
    </Box>
  );
};

export default BRTActionItem;
