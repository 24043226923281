import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./CompanyActionItems.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const CompanyActionItems = ({ powerBiReportToken, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Company Compliance Report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: "2442dfa1-4ceb-4114-8679-c1e58feb1c96",
              embedUrl:
                "https://app.powerbi.com/reportEmbed?reportId=2442dfa1-4ceb-4114-8679-c1e58feb1c96&groupId=ecb9a6b8-e180-4c70-b372-c10af3b56714&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
              accessToken: powerBiReportToken,
              tokenType: models.TokenType.Aad,
              settings: {
                panes: {
                  height: 100,
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                ["loaded", function () {}],
                ["rendered", function () {}],
                ["error", function (event) {}],
                ["visualClicked", () => console.log("visual clicked")],
                ["pageChanged", (event) => console.log(event)],
              ])
            }
            cssClassName="Embeded-container"
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CompanyActionItems;
