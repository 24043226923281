import React, { useEffect, useState, useRef } from "react";
import { userInfo } from "../../types/Types";
import {
  VStack,
  HStack,
  Input,
  Select,
  Box,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Text,
  Divider,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Button,
  Flex,
  Toast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

//Import API
import { apiRead, apiWrite } from "../../api/driverBoardAPI";

//Import CSVLink
import { CSVLink } from "react-csv";

//Import Icons
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { IoIosSend } from "react-icons/io";
import { GrRefresh } from "react-icons/gr";
import { FaFileExcel } from "react-icons/fa";
import { set } from "date-fns";

interface trainerInformation {
  DriverCode: string | null;
  Driver: string | null;
  Email: string | null;
  TruckNumber: string | null;
  DriverLeader: string | null;
  DriverLeaderEmail: string | null;
  TrainerFlag: string | null;
  TrainerStatus: string | null;
  Pet: boolean | null;
  Smoker: boolean | null;
  Gender: string | null;
  Notes: string | null;
  Division: string | null;
  CostCenter: string | null;
  Location: string | null;
  NextAvailableDate: string | null;
}

interface noteType {
  ID: number | null;
  DriverCode: string | null;
  Notes: string | null;
  Created: string | null;
  CreatedBy: string | null;
  CreatedByEmail: string | null;
}

const TrainerDashBoard: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const btnRef = useRef(null);
  const [trainerStatusSearch, setTrainerStatusSearch] = useState<string>("");
  const [trainerData, setTrainerData] = useState<trainerInformation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [trainerSearch, setTrainerSearch] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTrainer, setSelectedTrainer] =
    useState<trainerInformation | null>(null);
  const [noteLoading, setNoteLoading] = useState<boolean>(false);
  const [data, setData] = useState<noteType[]>([]);
  const endOfNotesRef = useRef<HTMLDivElement>(null);
  const [notes, setNotes] = useState<string>("");
  const [trainerFilter, setTrainerFilter] = useState<string>("");
  const [petFilter, setPetFilter] = useState<string>("");
  const [smokeFilter, setSmokeFilter] = useState<string>("");
  const [divisionFilter, setDivisionFilter] = useState<string>("");
  const [costCenterFilter, setCostCenterFilter] = useState<string>("");
  const [filteredTrainerData, setFilteredTrainerData] = useState<
    trainerInformation[]
  >([]);
  const [filterLocation, setFilterLocation] = useState<
    "All" | "Sapulpa" | "Dubuque"
  >("All");

  // Fetch Trainer Data
  const fetchTrainers = async () => {
    try {
      setIsLoading(true);
      const response = await apiRead({ process: "trainers" });
      setTrainerData(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching trainers:", error);
      setIsLoading(false);
    }
  };

  // Filter Trainer Data
  const filterTrainerData = () => {
    const filteredData = trainerData.filter((trainer) => {
      const searchTerm = trainerSearch.trim().toLowerCase();
      const searchMatch =
        trainer.DriverCode?.toLowerCase().includes(searchTerm) ||
        trainer.Driver?.toLowerCase().includes(searchTerm);

      const statusMatch = trainerStatusSearch
        ? trainer.TrainerStatus?.trim() === trainerStatusSearch
        : true;

      // Convert string filter value to boolean for comparison
      const smokeMatch = smokeFilter
        ? trainer.Smoker === (smokeFilter === "true")
        : true;
      const petMatch = petFilter
        ? trainer.Pet === (petFilter === "true")
        : true;

      const genderMatch = trainerFilter
        ? trainer.Gender === trainerFilter
        : true;
      const divisionMatch = divisionFilter
        ? trainer.Division === divisionFilter
        : true;
      const costCenterMatch = costCenterFilter
        ? trainer.CostCenter?.includes(costCenterFilter)
        : true;

      const locationMatch =
        filterLocation === "All" ? true : trainer.Location === filterLocation;

      return (
        searchMatch &&
        statusMatch &&
        smokeMatch &&
        petMatch &&
        genderMatch &&
        divisionMatch &&
        costCenterMatch &&
        locationMatch
      );
    });
    setFilteredTrainerData(filteredData);
  };

  // Fetch Trainer Notes
  const fetchNotes = async (driverCode: string) => {
    setNoteLoading(true);
    setData([]);
    try {
      const response = await apiRead({
        process: "fetch trainers notes",
        driverCode,
      });
      setData(response);
      setNoteLoading(false);
    } catch (error) {
      console.error("Error fetching notes:", error);
      setNoteLoading(false);
    }
  };

  // Handle Row Selection
  const handleRowSelection = (trainer: trainerInformation) => {
    setSelectedTrainer(trainer);
    setSelectedDate(
      trainer.NextAvailableDate
        ? new Date(trainer.NextAvailableDate)
        : new Date()
    );
    onOpen();
    if (trainer.DriverCode) fetchNotes(trainer.DriverCode);
  };

  // Add the note to the database
  const handleSendNote = async () => {
    if (!selectedTrainer || !notes.trim()) return;
    setNoteLoading(true);
    try {
      await apiWrite({
        Process: "add trainer notes",
        TrainerCode: selectedTrainer.DriverCode,
        Notes: notes,
        userName: userName,
        userEmail: userEmail,
      });
      setNotes("");
      if (selectedTrainer.DriverCode) fetchNotes(selectedTrainer.DriverCode);
    } catch (error) {
      console.error("Error sending note:", error);
    } finally {
      setNoteLoading(false);
    }
  };

  // Handle send next available date, if server respond, close the drawer and refetch the trainer data
  const handleSendNextAvailableDate = async () => {
    if (!selectedTrainer || !selectedDate) return;
    setNoteLoading(true);
    try {
      await apiWrite({
        Process: "add trainer next available date",
        TrainerCode: selectedTrainer.DriverCode,
        NextAvailableDate: selectedDate,
        userName: userName,
        userEmail: userEmail,
      });
      setNotes("");
      fetchTrainers();
      onClose();
      Toast({
        title: "Success",
        description: "Next Available Date Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      Toast({
        title: "Error",
        description: "Error updating Next Available Date",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setNoteLoading(false);
    }
  };

  // Close the drawer
  const onDrawerClose = () => {
    onClose();
    setSelectedTrainer(null);
  };

  useEffect(() => {
    document.title = "Driver Board - Trainers";
    fetchTrainers();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      endOfNotesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [data]);

  const splitDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  };
  // useEffect to update filtered data whenever filter criteria change
  useEffect(() => {
    filterTrainerData();
  }, [
    trainerData,
    trainerSearch,
    trainerStatusSearch,
    smokeFilter,
    petFilter,
    trainerFilter,
    divisionFilter,
    costCenterFilter,
    filterLocation,
  ]);

  return (
    <>
      <VStack
        h="calc(100vh - 55px)"
        w="100%"
        bg="linear-gradient(90deg, #fafafa, #faf7f7, #f0f0f0)"
        p="20px"
        align="flex-start"
        justify="flex-start"
      >
        {/* Filter bar for searching by code or name and trainer status */}
        <HStack
          w="100%"
          justify="flex-start"
          spacing="20px"
          pl="20px"
          pr="20px"
        >
          <Input
            w="200px"
            placeholder="Code or Name"
            variant="flushed"
            value={trainerSearch}
            onChange={(e) => setTrainerSearch(e.target.value)}
          />
          <Select
            variant="flushed"
            w="120px"
            value={trainerStatusSearch}
            placeholder="Status"
            onChange={(e) => setTrainerStatusSearch(e.target.value)}
          >
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="P">P</option>
            <option value="T">T</option>
            <option value="U">U</option>
          </Select>
          {/* Drop down to filter Gender, All, M, F */}
          <Select
            variant="flushed"
            w="120px"
            value={trainerFilter}
            onChange={(e) => setTrainerFilter(e.target.value)}
          >
            <option value="">Gender</option>
            <option value="M">M</option>
            <option value="F">F</option>
          </Select>

          {/* Drop down to filter Pet, All, Yes, No */}
          <Select
            variant="flushed"
            w="120px"
            value={petFilter}
            onChange={(e) => setPetFilter(e.target.value)}
          >
            <option value="">Pet</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Select>

          {/* Drop down to filter Smoke, All, Yes, No */}
          <Select
            variant="flushed"
            w="120px"
            value={smokeFilter}
            onChange={(e) => setSmokeFilter(e.target.value)}
          >
            <option value="">Smoke</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Select>

          {/* Drop down to filter Division */}
          <Select
            variant="flushed"
            w="120px"
            value={divisionFilter}
            onChange={(e) => setDivisionFilter(e.target.value)}
          >
            <option value="">Division</option>
            <option value="OTR">OTR</option>
            <option value="Dedicated">Dedicated</option>
          </Select>

          {/* Drop down to filter Location */}
          <Select
            variant="flushed"
            w="120px"
            value={filterLocation}
            onChange={(e) =>
              setFilterLocation(e.target.value as "All" | "Sapulpa" | "Dubuque")
            }
          >
            <option value="All">Location</option>
            <option value="Sapulpa">Sapulpa</option>
            <option value="Dubuque">Dubuque</option>
          </Select>

          {/* Input to type in Cost Center for Search */}
          <Input
            w="100px"
            placeholder="Cost Center"
            variant="flushed"
            value={costCenterFilter}
            onChange={(e) => setCostCenterFilter(e.target.value)}
          />

          <IconButton
            aria-label="Refresh"
            icon={<GrRefresh />}
            onClick={fetchTrainers}
            isLoading={isLoading}
            colorScheme="blue"
          />
          <CSVLink
            data={filteredTrainerData}
            filename={`TrainerData_${new Date().toLocaleDateString()}.csv`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <IconButton
              aria-label="Export to CSV"
              icon={<FaFileExcel />}
              colorScheme="green"
            />
          </CSVLink>
        </HStack>
        <Box
          mt="10px"
          w="100%"
          h="100%"
          bg="white"
          p="20px"
          borderRadius="10px"
          shadow="sm"
          overflow="auto"
        >
          <Table variant="striped" size="sm" w="100%" mb="20px">
            <Thead>
              <Tr>
                <Th w="100px">Code</Th>
                <Th w="200px">Driver</Th>
                <Th w="100px">Truck</Th>
                <Th w="200px">Leader</Th>
                <Th w="50px">Status</Th>
                <Th w="50px">Gender</Th>
                <Th w="50px">Pet</Th>
                <Th w="50px"> Smoke</Th>
                <Th w="100px">Division</Th>
                <Th w="250px">Cost Center</Th>
                <Th w="200px">Location</Th>
                <Th w="200px">Next Available Date</Th>
                <Th w="500px">Notes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredTrainerData.map((trainer, index) => (
                <Tr
                  key={index}
                  _hover={{ bg: "gray.100" }}
                  cursor="pointer"
                  bg={selectedTrainer === trainer ? "gray.100" : "white"}
                  onClick={() => handleRowSelection(trainer)}
                >
                  <Td>{trainer.DriverCode}</Td>
                  <Td>{trainer.Driver}</Td>
                  <Td>{trainer.TruckNumber}</Td>
                  <Td>{trainer.DriverLeader}</Td>
                  <Td>{trainer.TrainerStatus}</Td>
                  <Td>{trainer.Gender}</Td>
                  <Td>{trainer.Pet ? "Yes" : "No"}</Td>
                  <Td>{trainer.Smoker ? "Yes" : "No"}</Td>
                  <Td>{trainer.Division}</Td>
                  <Td>{trainer.CostCenter}</Td>
                  <Td>{trainer.Location}</Td>
                  <Td>
                    {trainer.NextAvailableDate
                      ? splitDate(trainer.NextAvailableDate)
                      : ""}
                  </Td>
                  <Td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {trainer.Notes}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <IconButton
          position="fixed"
          size="md"
          colorScheme="blackAlpha"
          aria-label="Refresh"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate("/driverboard")}
          bottom="10px"
          left="10px"
          fontSize="30px"
        />
      </VStack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onDrawerClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Tabs position="relative" variant="unstyled">
              <TabList>
                <Tab>Notes</Tab>
                <Tab>Next Available Date</Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <VStack width="100%" height="calc(100vh - 90px)" spacing={4}>
                    <Box width="100%" height="100%" overflowY="auto">
                      {data
                        .sort(
                          (a, b) =>
                            new Date(a.Created as string).getTime() -
                            new Date(b.Created as string).getTime()
                        )
                        .slice(0, 20)
                        .map((note, index) => (
                          <Box key={note.ID}>
                            <Text
                              fontWeight="semibold"
                              fontSize="xs"
                              color="blue.600"
                            >
                              {note.CreatedBy} &middot;{" "}
                              {new Date(
                                note.Created as string
                              ).toLocaleString()}
                            </Text>
                            <Text mb={2} mt={2} fontSize="md">
                              {note.Notes}
                            </Text>
                            {index < 19 && (
                              <Divider
                                borderColor="gray.300"
                                mt="20px"
                                mb="20px"
                              />
                            )}
                          </Box>
                        ))}
                      <div ref={endOfNotesRef}></div>
                    </Box>
                    <HStack
                      width="100%"
                      height="100px"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      <Textarea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Add note..."
                        resize="none"
                        width="100%"
                        borderColor="blackAlpha.300"
                        maxLength={999}
                      />
                      <IconButton
                        icon={<IoIosSend />}
                        colorScheme="facebook"
                        aria-label="Send"
                        size="sm"
                        borderRadius="full"
                        isLoading={noteLoading}
                        isDisabled={!notes.trim()}
                        onClick={handleSendNote}
                      />
                    </HStack>
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <Input
                    type="date"
                    value={selectedDate?.toISOString().split("T")[0]}
                    onChange={(e) => setSelectedDate(new Date(e.target.value))}
                  />
                  <Flex mt="10px" justifyContent="flex-end">
                    <Button
                      mt="10px"
                      onClick={handleSendNextAvailableDate}
                      isLoading={noteLoading}
                    >
                      Submit
                    </Button>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default TrainerDashBoard;
