import { graphConfig } from "./authConfig";
import axios from "axios";

/**
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    const response = await fetch(graphConfig.graphMeEndpoint, options);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

/**
 * @param accessToken
 */
export async function getUserProfilePhoto(accessToken) {
  try {
    const response = await axios.get(graphConfig.graphUserPhotoEndpoint, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    return url;
  } catch (error) {
    console.error(error);
    return null;
  }
}
