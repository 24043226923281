import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  StackDivider,
  VStack,
  Text,
  Image,
  useDisclosure,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  SimpleGrid,
  Spinner,
  Button,
  useToast,
} from "@chakra-ui/react";
import { RegularFormModal } from "../../components/purchasing/RegularFormModal";
import { EnterpriseFormModal } from "../../components/purchasing/EnterpriseFormModal";
import { purchaseAPI } from "../../api/purchasingAPI";

// Import Types
import { userInfo } from "../../types/Types";
import { motion } from "framer-motion";
const PurchasingDashboard: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {

  // Interface for purchase request pulled in for approval/rejection.
  interface PurchaseRequest {
    "@odata.context": string;
    "@odata.etag": string;
    ItemInternalId: string;
    ID: number;
    STATUS: string;
    COST: string;
    COST_CENTER: string;
    CREATED_DATE: string;
    DEPARTMENT: string;
    DESCRIPTION: string;
    FREQUENCY: string;
    JUSTIFICATION: string;
    LOCATION: string;
    PRIORITY: string;
    REQUESTOR: string;
  }

  // variables defined here
  const [screen, setScreen] = useState<string>("Request");
  const [request, setRequest] = useState<PurchaseRequest>();
  const [type, setType] = useState<string>("");
  const [requestID, setRequestID] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReviewed, setIsReviewed] = useState<boolean>(false);
  const toast = useToast();

  const {
    isOpen: isEnterpriseFormOpen,
    onOpen: onEnterpriseFormOpen,
    onClose: onEnterpriseFormClose,
  } = useDisclosure();

  const {
    isOpen: isVendorSupplierFormOpen,
    onOpen: onVendorSupplierFormOpen,
    onClose: onVendorSupplierFormClose,
  } = useDisclosure();

  // Retrieve request info if the screen is for approval/rejection.
  const getRequest = async (id: string) => {
    setIsLoading(true);

    try {
      const response = await purchaseAPI(id, "getRegularRequest", userEmail);
      setRequest(response?.data?.Table1[0]);

      if (response?.data?.Table1[0]?.REVIEW_DATE) {
        setIsReviewed(true);
      }
    } catch {
    } finally {
    }

    setIsLoading(false);
  };

  // Submit request decision.
  const submitRequestDecision = async (decision: string) => {
    setIsLoading(true);

    const data = {
      requestID: requestID,
      type: type,
      decision: decision,
    };

    try {
      const response = await purchaseAPI(
        data,
        "submitRequestDecision",
        userEmail
      );

      if (response?.status === 200) {
        toast({
          title: "Success.",
          description: "Your decision has been submitted.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
          variant: "subtle",
        });
      }
    } catch {
      toast({
        title: "Error.",
        description: "Your decision was not submitted.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      setIsReviewed(false);
    } finally {
    }

    setIsLoading(false);
  };

  // Check if the URL contains metadata for a request to be approved/rejected.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const type = urlParams.get("type");

    if (id) {
      setScreen("Approval");
      setRequestID(id);
      getRequest(id);
    }
    if (type) {
      setType(type);
    }
  }, []);

  
  return (
    <Box
      h="calc(100vh - 55px)"
      w="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {screen === "Request" ? (
        <Box
          h="100%"
          w="90%"
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          flexDirection="row"
        >
          <Box width="100%">
            <motion.div
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              <Text
                color="black"
                fontSize={["25px", "30px", "35px", "40px"]}
                fontWeight="light"
                width="100%"
                textAlign="left"
              >
                What is your purchasing request?
              </Text>
            </motion.div>
          </Box>
          <Box
            height="80%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <motion.div
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <VStack
                height="70vh"
                width="50vw"
                display="flex"
                justifyContent="space-evenly"
              >
                <Box
                  onClick={onEnterpriseFormOpen}
                  borderWidth="1px"
                  borderColor="blackAlpha.50"
                  height="40%"
                  width="100%"
                  padding="2%"
                  borderRadius="20px"
                  boxShadow="md"
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  _hover={{
                    boxShadow: "lg",
                    color: "orange",
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                  }}
                  color="blackAlpha.700"
                  fontWeight="normal"
                  bg="whiteAlpha.700"
                >
                  <Image src="/team.png" height="200px"></Image>
                  <VStack width="50%">
                    <Text
                      fontSize={["15px", "20px", "25px", "30px"]}
                      width="100%"
                      textAlign="center"
                    >
                      Regular
                    </Text>
                    <Text
                      fontSize={["5px", "10px", "15px", "20px"]}
                      width="100%"
                      textAlign="center"
                      color="blackAlpha.500"
                    >
                      One-time or recurring purchases.
                    </Text>
                  </VStack>
                </Box>
                <Box
                  onClick={onVendorSupplierFormOpen}
                  borderWidth="1px"
                  borderColor="blackAlpha.50"
                  height="40%"
                  width="100%"
                  padding="2%"
                  borderRadius="20px"
                  boxShadow="md"
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  _hover={{
                    boxShadow: "lg",
                    color: "orange",
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                  }}
                  color="blackAlpha.700"
                  fontWeight="normal"
                  bg="whiteAlpha.700"
                >
                  <Image src="/shake-hands.png" height="200px"></Image>
                  <VStack width="50%">
                    <Text
                      fontSize={["15px", "20px", "25px", "30px"]}
                      width="100%"
                      textAlign="center"
                    >
                      Enterprise
                    </Text>
                    <Text
                      fontSize={["5px", "10px", "15px", "20px"]}
                      width="100%"
                      textAlign="center"
                      color="blackAlpha.500"
                    >
                      Requires vendor or supplier involvement.
                    </Text>
                  </VStack>
                </Box>
                <RegularFormModal
                  isOpen={isEnterpriseFormOpen}
                  onClose={onEnterpriseFormClose}
                  userEmail={userEmail}
                  userDepartment={userDepartment}
                />
                <EnterpriseFormModal
                  isOpen={isVendorSupplierFormOpen}
                  onClose={onVendorSupplierFormClose}
                  userEmail={userEmail}
                  userDepartment={userDepartment}
                />
              </VStack>
            </motion.div>
          </Box>
        </Box>
      ) : (
        <Box height="70%" width="80%">
          <Card height="100%" width="100%">
            <CardHeader>
              <Heading size="lg" fontWeight="normal">
                Purchase Request
              </Heading>
            </CardHeader>

            <CardBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflowY="auto"
            >
              {isLoading ? (
                <Spinner size="xl" color="orange" />
              ) : (
                <Stack
                  divider={<StackDivider />}
                  spacing="4"
                  width="100%"
                  height="100%"
                >
                  <SimpleGrid columns={7} spacing={10}>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Requestor
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.REQUESTOR || "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Department
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.DEPARTMENT || "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Location
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.LOCATION || "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Cost Center
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.COST_CENTER || "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Priority
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.PRIORITY || "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Cost
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.COST ? `$${request.COST}` : "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Frequency
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.FREQUENCY || "N/A"}
                      </Text>
                    </Box>
                  </SimpleGrid>
                  <Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Description
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.DESCRIPTION || "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Heading pt="4" size="xs" textTransform="uppercase">
                        Justification
                      </Heading>
                      <Text pt="2" fontSize="sm">
                        {request?.JUSTIFICATION || "N/A"}
                      </Text>
                    </Box>
                  </Box>
                </Stack>
              )}
            </CardBody>
            {!isLoading && request && !isReviewed && (
              <CardFooter display="flex" justifyContent="space-evenly">
                <Button
                  width="10%"
                  color="red"
                  bg="blackAlpha.50"
                  _hover={{ bg: "blackAlpha.100" }}
                  onClick={(e) => {
                    submitRequestDecision("Rejected");
                    setIsReviewed(true);
                  }}
                >
                  Reject
                </Button>
                <Button
                  width="10%"
                  color="green"
                  bg="blackAlpha.50"
                  _hover={{ bg: "blackAlpha.100" }}
                  onClick={(e) => {
                    submitRequestDecision("Approved");
                    setIsReviewed(true);
                  }}
                >
                  Approve
                </Button>
              </CardFooter>
            )}
            {!isLoading && request && isReviewed && (
              <Box
                height="20%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text color="red" fontSize="xl">
                  This request has been reviewed.
                </Text>
              </Box>
            )}
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default PurchasingDashboard;
