import React, { useState } from "react";
import {
  VStack,
  Button,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Popover,
  PopoverTrigger,
  PopoverAnchor,
  HStack,
  PopoverContent,
  PopoverBody,
  IconButton,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

// Icons
import { AddIcon } from "@chakra-ui/icons";
import { MdAllInclusive } from "react-icons/md";
import { IoMdPerson, IoIosPeople, IoIosSearch } from "react-icons/io";

// Types for the side bar
export interface SideBarProps {
  viewState: string;
  setSearchValue: (value: string) => void;
  processes: string[];
  assignees: string[];
  selectedAssignee: string;
  selectedProcess: string;
  onAssigneeFilterChange: (value: string) => void;
  onProcessFilterChange: (value: string) => void;
  onViewStateChange: (newViewState: string) => void;
}

type PopoverName = "process" | "assignee";

function Sidebar({
  viewState,
  setSearchValue,
  processes,
  assignees,
  onAssigneeFilterChange,
  onProcessFilterChange,
  onViewStateChange,
}: SideBarProps) {
  const { drivercode } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [selectedProcess, setSelectedProcess] = useState<string | string[]>(
    "Processes"
  );
  const [selectedAssignee, setSelectedAssignee] = useState<string | string[]>(
    "Assignees"
  );
  const [activePopover, setActivePopover] = useState<PopoverName | null>(null);

  const handleSearchInputChange = (event: any) => {
    const newValue = event.target.value;
    setSearchInput(newValue);
    setSearchValue(newValue);
  };

  // Function for the reset button
  const handleReset = () => {
    setSelectedProcess("Processes");
    setSelectedAssignee("Assignees");
    setSearchInput("");
    setSearchValue("");
    onAssigneeFilterChange("Assignees");
    onProcessFilterChange("Processes");
  };

  const handlePopoverOpen = (popoverName: PopoverName) => {
    setActivePopover(popoverName);
  };

  const handlePopoverClose = () => {
    setActivePopover(null);
  };

  const handleViewStateChange = (newViewState: string) => {
    onViewStateChange(newViewState);
  };

  const isProcessPopoverOpen = activePopover === "process";
  const isAssigneePopoverOpen = activePopover === "assignee";

  // Function to toggle selection of a process
  const toggleProcessSelection = (process: string) => {
    if (Array.isArray(selectedProcess)) {
      if (selectedProcess.includes(process)) {
        const updatedProcesses = selectedProcess.filter((p) => p !== process);
        setSelectedProcess(
          updatedProcesses.length > 0 ? updatedProcesses : "Processes"
        );
      } else {
        setSelectedProcess([...selectedProcess, process]);
      }
    } else {
      setSelectedProcess([process]);
    }
  };

  // Function to toggle selection of an assignee
  const toggleAssigneeSelection = (assignee: string) => {
    if (Array.isArray(selectedAssignee)) {
      if (selectedAssignee.includes(assignee)) {
        const updatedAssignees = selectedAssignee.filter((a) => a !== assignee);
        setSelectedAssignee(
          updatedAssignees.length > 0 ? updatedAssignees : "Assignees"
        );
      } else {
        setSelectedAssignee([...selectedAssignee, assignee]);
      }
    } else {
      setSelectedAssignee([assignee]);
    }
  };
  return (
    <VStack spacing={1} direction="column">
      <Button
        leftIcon={<IoMdPerson />}
        colorScheme="gray"
        variant="ghost"
        width="100%"
        justifyContent="left"
        fontWeight="normal"
        fontSize="md"
        onClick={() => handleViewStateChange("user")}
        isActive={viewState === "user"}
        _hover={{ bg: "blackAlpha.300" }}
        _active={{ bg: "blackAlpha.100" }}
      >
        My Items
      </Button>
      <Button
        leftIcon={<IoIosPeople />}
        colorScheme="gray"
        variant="ghost"
        width="100%"
        justifyContent="left"
        fontWeight="normal"
        fontSize="md"
        onClick={() => handleViewStateChange("department")}
        isActive={viewState === "department"}
        _hover={{ bg: "blackAlpha.300" }}
        _active={{ bg: "blackAlpha.100" }}
      >
        My Department
      </Button>
      {drivercode && (
        <Button
          leftIcon={<MdAllInclusive />}
          colorScheme="gray"
          variant="ghost"
          width="100%"
          justifyContent="left"
          fontWeight="normal"
          fontSize="md"
          onClick={() => handleViewStateChange("driver")}
          isActive={viewState === "driver"}
          _hover={{ bg: "blackAlpha.300" }}
          _active={{ bg: "blackAlpha.100" }}
        >
          Driver
        </Button>
      )}
      <Divider borderColor={"blackAlpha.400"} margin={"10px"} />
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IoIosSearch />
        </InputLeftElement>
        <Input
          variant="filled"
          placeholder="Search"
          _hover={{ bg: "transparent" }}
          focusBorderColor="blackAlpha.300"
          borderWidth={2}
          borderColor={"blackAlpha.300"}
          bg="transparent"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
      </InputGroup>
      <VStack spacing={2} direction="column" paddingTop="2">
        {/* Dropdown for selecting a process with pop */}
        <Popover
          isOpen={isProcessPopoverOpen}
          onOpen={() => handlePopoverOpen("process")}
          onClose={handlePopoverClose}
          isLazy
          lazyBehavior="keepMounted"
        >
          <HStack>
            <PopoverAnchor>
              <Select
                variant="flushed"
                value={selectedProcess}
                width="230px"
                onChange={(e) => {
                  setSelectedProcess(e.target.value);
                  onProcessFilterChange(e.target.value);
                }}
              >
                <option value="Processes">Processes</option>
                {processes.map((process) => (
                  <option key={process} value={process}>
                    {process}
                  </option>
                ))}
              </Select>
            </PopoverAnchor>
            <PopoverTrigger>
              <IconButton
                aria-label="Select multiples"
                size="xs"
                icon={<AddIcon />}
                variant="ghost"
              />
            </PopoverTrigger>
          </HStack>
          {/* Inside the PopoverContent for processes */}
          <PopoverContent style={{ maxWidth: "290px", left: "16px" }}>
            <PopoverBody>
              <VStack spacing={2} align="stretch">
                {processes.map((process) => (
                  <Checkbox
                    colorScheme="green"
                    key={process}
                    fontSize="xs"
                    isChecked={selectedProcess.includes(process)}
                    onChange={() => toggleProcessSelection(process)}
                  >
                    {process}
                  </Checkbox>
                ))}
              </VStack>
              <HStack spacing={2} mt={5}>
                <Button
                  colorScheme="gray"
                  size="xs"
                  onClick={() => {
                    // Apply button is clicked for processes
                    const selectedProcessValue =
                      Array.isArray(selectedProcess) &&
                      selectedProcess.length > 0
                        ? selectedProcess
                        : "Processes";
                    onProcessFilterChange(selectedProcessValue as string);
                    handlePopoverClose();
                  }}
                >
                  Apply
                </Button>
                <Button
                  colorScheme="gray"
                  size="xs"
                  onClick={handlePopoverClose}
                >
                  Cancel
                </Button>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {/* Dropdown for selecting an assignee with pop */}
        <Popover
          isOpen={isAssigneePopoverOpen}
          onOpen={() => handlePopoverOpen("assignee")}
          onClose={handlePopoverClose}
          isLazy
          lazyBehavior="keepMounted"
        >
          <HStack>
            <PopoverAnchor>
              <Select
                variant="flushed"
                value={selectedAssignee}
                width="230px"
                onChange={(e) => {
                  setSelectedAssignee(e.target.value);
                  onAssigneeFilterChange(e.target.value);
                }}
              >
                <option value="Assignees">Assignees</option>
                {assignees.map((assignee) => (
                  <option key={assignee} value={assignee}>
                    {assignee}
                  </option>
                ))}
              </Select>
            </PopoverAnchor>
            <PopoverTrigger>
              <IconButton
                aria-label="Select multiples"
                size="xs"
                icon={<AddIcon />}
                variant="ghost"
              />
            </PopoverTrigger>
          </HStack>
          <PopoverContent style={{ maxWidth: "290px", left: "16px" }}>
            <PopoverBody>
              <VStack spacing={2} align="stretch">
                {assignees.map((assignee) => (
                  <Checkbox
                    colorScheme="green"
                    key={assignee}
                    fontSize="xs"
                    isChecked={selectedAssignee.includes(assignee)}
                    onChange={() => toggleAssigneeSelection(assignee)}
                  >
                    {assignee}
                  </Checkbox>
                ))}
              </VStack>
              <HStack spacing={2} mt={5}>
                <Button
                  colorScheme="gray"
                  size="xs"
                  onClick={() => {
                    // Apply button is clicked for assignees
                    const selectedAssigneeValue =
                      Array.isArray(selectedAssignee) &&
                      selectedAssignee.length > 0
                        ? selectedAssignee
                        : "Assignees";
                    onAssigneeFilterChange(selectedAssigneeValue as string);
                    handlePopoverClose();
                  }}
                >
                  Apply
                </Button>
                <Button
                  colorScheme="gray"
                  size="xs"
                  onClick={handlePopoverClose}
                >
                  Cancel
                </Button>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </VStack>
      <Button
        colorScheme="gray"
        variant="outline"
        size="md"
        marginTop="5"
        onClick={handleReset}
      >
        Reset
      </Button>
    </VStack>
  );
}

export default Sidebar;
