import React, { useState, useEffect } from "react";
import {
  HStack,
  Text,
  VStack,
  useToast,
  Button,
  Input,
  Box,
  Select,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import API from "../../api/API";
import Loading from "../global/Loading";
import { motion } from "framer-motion";

type TableRowData = {
  ACTUAL_ASSIGNMENT: string;
  ASSIGNEE_1: string;
  ASSIGNEE_2: string;
  ASSIGNEE_3: string;
  AUDITED: number;
  DIVISION: string;
  NUMBER_OF_STOPS: string;
  ORDER_CUSTOMER: string;
  STOP: string;
  ID: number;
};

type CSRAssignmentData = {
  ACTUAL_ASSIGNMENT: string;
  USER_EMAIL: string;
};

type CustomerTableProps = {
  userEmail: string;
};

const CustomerTable: React.FC<CustomerTableProps> = ({ userEmail }) => {
  const [data, setData] = useState<TableRowData[]>([]);
  const [csrAssignments, setCSRAssignments] = useState<CSRAssignmentData[]>([]);
  const [csrFilter, setCSRFilter] = useState(userEmail);
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [inputFilter, setInputFilter] = useState<string>("");
  const [auditedFilter, setAuditedFilter] = useState<string>("All");

  const currentData: TableRowData[] = data
    ?.filter((row) =>
      row.STOP.toLowerCase().includes(inputFilter.toLowerCase())
    )
    .filter((row) => {
      if (auditedFilter === "All") {
        return true;
      } else if (auditedFilter === "Complete") {
        return row.AUDITED == 1;
      } else {
        return row.AUDITED != 1;
      }
    });

  const FetchCSRList = async () => {
    try {
      const payload = {
        process: "getcustextinfolist",
        userEmail: csrFilter,
      };
      setLoading(true);
      const response = await API(payload);

      if (response) {
        setData(response);
        setLoading(false);
      } else {
        console.error("API request failed:", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("API request failed:", error);
      setLoading(false);
    }
  };

  const FetchCSRAssignments = async () => {
    try {
      const payload = {
        process: "getCSRAssignments",
      };
      const response = await API(payload);

      if (response) {
        setCSRAssignments(response.Table1);
      } else {
        console.error("API request failed:", response);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const countAuditedItems = (data: any) => {
    return data?.filter((item: { AUDITED: number }) => item.AUDITED == 1)
      .length;
  };

  useEffect(() => {
    FetchCSRList();
    FetchCSRAssignments();
  }, [csrFilter]);

  const handleAudited = async (id: number) => {
    try {
      const payload = {
        process: "updateCSRList",
        id: id,
        userEmail: csrFilter,
      };
      setData([]);
      setLoading(true);
      const response = await API(payload);
      if (response) {
        FetchCSRList();
        toast({
          title: "CSR Assignment Updated",
          description: "The CSR assignment has been updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      } else {
        console.error("API request failed:", response);
        toast({
          title: "CSR Assignment failed to update",
          description: "The CSR assignment has failed to update.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("API request failed:", error);
      toast({
        title: "CSR Assignment failed to update",
        description: "The CSR assignment has failed to update.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <>
        <VStack height="80vh">
          <Text fontWeight="semibold" fontSize="20px">
            CSR Audit Assignment
          </Text>
          <Text>Please audit and complete the following assignments.</Text>
          <HStack w="60vw">
            <Input
              w="50%"
              placeholder="Search by Code..."
              value={inputFilter}
              onChange={(e) => setInputFilter(e.target.value)}
              focusBorderColor="gray.300"
            ></Input>
            <Select
              w="50%"
              value={auditedFilter}
              onChange={(e) => setAuditedFilter(e.target.value)}
              focusBorderColor="gray.300"
            >
              <option value="All">All</option>
              <option value="Complete">Complete</option>
              <option value="Incomplete">Incomplete</option>
            </Select>
            <Select
              w="50%"
              value={csrFilter}
              onChange={(e) => setCSRFilter(e.target.value)}
              focusBorderColor="gray.300"
            >
              {csrAssignments?.map((row) => (
                <option value={row.USER_EMAIL}>{row.ACTUAL_ASSIGNMENT}</option>
              ))}
            </Select>
            <Text textAlign="center" width="15vw" fontWeight="semibold">
              Items: {currentData?.length}
            </Text>
            <Text textAlign="center" width="15vw" fontWeight="semibold">
              Completed: {countAuditedItems(currentData)}
            </Text>
          </HStack>
          {data?.length > 0 && !loading ? (
            <>
              <Grid
                templateColumns="repeat(6, 1fr)" // Adjust the number of columns as needed
                gap={4}
                width="70vw"
                height="60vh"
                marginTop="5%"
                overflowY="scroll"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#555",
                    },
                  },
                }}
              >
                {currentData?.map((row, index) => (
                  <React.Fragment key={index}>
                    <GridItem>
                      <Text textAlign="center">{row.STOP}</Text>
                      <Button
                        colorScheme="gray"
                        variant="outline"
                        size="sm"
                        width="100%"
                        onClick={(e) => handleAudited(row.ID)}
                        backgroundColor={row.AUDITED == 1 ? "green.200" : ""}
                      >
                        {row.AUDITED == 1 ? "Complete" : "Incomplete"}
                      </Button>
                    </GridItem>
                  </React.Fragment>
                ))}
              </Grid>
            </>
          ) : (
            <Box
              marginTop="5%"
              width="50vw"
              height="40vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {loading ? (
                <Loading />
              ) : (
                <Text color="blackAlpha.700">There are currently no items</Text>
              )}
            </Box>
          )}
        </VStack>
      </>
    </motion.div>
  );
};

export default CustomerTable;
