// Cards Information for driver info, company info, and orientation info
const driverInfo = [
  { label: "First Name", value: "FirstName", colSpan: 1, type: "text" },
  { label: "Last Name", value: "LastName", colSpan: 1, type: "text" },
  { label: "Nickname", value: "Nickname", colSpan: 1, type: "text" },
  { label: "Date of Birth", value: "DOB", colSpan: 1, type: "date" },
  { label: "Phone", value: "Phone", colSpan: 1, type: "text" },
  { label: "Email", value: "Email", colSpan: 1, type: "text" },
  { label: "Address", value: "Address", colSpan: 1, type: "text" },
  { label: "City", value: "City", colSpan: 1, type: "text" },
  { label: "State", value: "State", colSpan: 1, type: "text" },
  { label: "ZIP Code", value: "ZIP", colSpan: 1, type: "text" },
  { label: "License", value: "License", colSpan: 1, type: "text" },
  { label: "License State", value: "LicenseState", colSpan: 1, type: "text" },
  { label: "Veteran", value: "Veteran", colSpan: 1, type: "text" },
  {
    label: "Medical Expiration",
    value: "MedicalExp",
    colSpan: 1,
    type: "date",
  },
  { label: "Physical", value: "Physical", colSpan: 1, type: "text" },
];

const companyInfo = [
  { label: "Recruiter", value: "Recruiter", colSpan: 1, type: "text" },
  { label: "Recruiter Tag", value: "Tag", colSpan: 1, type: "text" },
  { label: "Board", value: "Board", colSpan: 1, type: "text" },
  {
    label: "Driver Leader",
    value: "DriverLeader",
    colSpan: 1,
    type: "text",
  },
  { label: "Driver Code", value: "DriverCode", colSpan: 1, type: "text" },
  { label: "Driver Type", value: "DriverType", colSpan: 1, type: "text" },
  { label: "Cost Center", value: "CostCenter", colSpan: 1, type: "text" },
  { label: "Division", value: "Division", colSpan: 1, type: "text" },
  { label: "Source", value: "Source", colSpan: 1, type: "text" },
  { label: "Referral", value: "DriverReferral", colSpan: 1, type: "text" },
  { label: "Team Driver", value: "TeamDriver", colSpan: 1, type: "text" },
  { label: "Team With", value: "TeamWith", colSpan: 1, type: "text" },
  { label: "2nd Seat", value: "SecondSeat", colSpan: 1, type: "text" },

  {
    label: "Willing to Train",
    value: "WillingtoTrain",
    colSpan: 1,
    type: "text",
  },
];

const orientationInfo = [
  {
    label: "Registration",
    value: "Registration",
    colSpan: 1,
    type: "text",
  },
  {
    label: "Orientation Date",
    value: "OrientationDate",
    colSpan: 1,
    type: "date",
  },
  {
    label: "Orientation Location",
    value: "OrientationLocation",
    colSpan: 1,
    type: "text",
  },
  {
    label: "Transportation",
    value: "Transportation",
    colSpan: 1,
    type: "text",
  },
  { label: "Hotel", value: "Hotel", colSpan: 1, type: "text" },
  { label: "Passenger", value: "Passenger", colSpan: 1, type: "text" },
  { label: "Pet", value: "Pet", colSpan: 1 },
  { label: "Arrival", value: "Arrival", colSpan: 1, type: "datetime" },
  { label: "Truck Request", value: "TruckRequest", colSpan: 4, type: "text" },
  {
    label: "Truck Preferences",
    value: "TruckPreference",
    colSpan: 4,
    type: "text",
  },
  { label: "Note", value: "Note", colSpan: 4 },
];

// Driver Profile Props
const cardProps = {
  borderRadius: "xl",
  mt: "10px",
  borderWidth: "1px",
  borderColor: "blackAlpha.200",
  shadow: "sm",
  p: "10px",
  w: "100%",
  align: "start",
  justify: "start",
  spacing: 2,
};

const cardHeaderProps = {
  fontSize: "md",
  fontWeight: "semibold",
  color: "blackAlpha.700",
};

const gridProps = {
  rowGap: 5,
  columnGap: 10,
  width: "100%",
  templateColumns: "repeat(4, 1fr)",
};

const labelProps = {
  fontSize: "xs",
  color: "facebook.500",
  fontWeight: "semibold",
};

const dataProps = {
  fontSize: "md",
  color: "black",
};

// Processor For Props
const editGridProps = {
  rowGap: 5,
  columnGap: 10,
  width: "100%",
  templateColumns: "repeat(3, 1fr)",
};

const editLabelProps = {
  fontSize: "xs",
  color: "facebook.500",
  fontWeight: "semibold",
  mb: "5px",
};

// Steps for the progress stepper
const steps = [
  { title: "New" },
  { title: "Approve" },
  { title: "Schedule" },
  { title: "Show" },
  { title: "Seating" },
];

export {
  driverInfo,
  companyInfo,
  orientationInfo,
  steps,
  cardProps,
  cardHeaderProps,
  gridProps,
  labelProps,
  dataProps,
  editGridProps,
  editLabelProps,
};
