import React, { useEffect } from "react";
import { Box, HStack } from "@chakra-ui/react";
import { userInfo } from "../../types/Types";
import { motion } from "framer-motion";
import CustomerTable from "../../components/customerextinfo/CustomerTable";

const CustomerExtInfo: React.FC<userInfo> = ({ userEmail }) => {
  useEffect(() => {
    document.title = "Customer Extension Update";
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Box
        height="calc(100vh - 90px)"
        m="2vh"
        justifyContent="center"
        display="flex"
      >
        <Box
          display="flex"
          justifyContent="center"
          height="90vh"
          width="80vw"
          boxShadow="0 4px 10px rgba(0, 0, 0, 0.2)"
        >
          <HStack>
            <CustomerTable userEmail={userEmail} />
          </HStack>
        </Box>
      </Box>
    </motion.div>
  );
};

export default CustomerExtInfo;
