import React, { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  Text,
  Divider,
  VStack,
  Input,
  Select,
  Checkbox,
  Button,
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  Stack,
  MenuButton,
  Menu,
  MenuList,
  MenuItemOption,
  Switch,
  Box,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Th,
  Td,
  Tr,
  Thead,
  Tbody,
  ModalFooter,
} from "@chakra-ui/react";

import DatePicker from "react-datepicker";

// Import Types for the Driver Board
import { driverBoardTypes, trainerInformation } from "../../../types/Types";

// Icons
import { FaBarsProgress } from "react-icons/fa6";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaBarcode } from "react-icons/fa";
import { HiTruck } from "react-icons/hi2";
import { FaDollarSign } from "react-icons/fa";
import { SiTrainerroad } from "react-icons/si";
import { GrCompliance } from "react-icons/gr";

// Import Information and Props
import {
  cardProps,
  cardHeaderProps,
  editGridProps,
  editLabelProps,
} from "./GeneralFields";

// Import the API
import { apiWrite, apiRead } from "../../../api/driverBoardAPI";

type ProcessorType = {
  profileData: driverBoardTypes | null;
  trainers: trainerInformation[] | null;
  refreshProfileData: () => void;
  userEmail: string;
  onRefresh: () => void;
};

// Options for Truck Request
const truckRequestOptions = ["Freightliner", "International"].sort();

// Options for Truck Preference
const truckPreferenceOptions = [
  "APU",
  "Double Bunk",
  "Newest Available",
  "Non-Pet",
  "Non-Smoke",
  "Oldest Available",
].sort();

const TDOs = [
  "TDO-Ops",
  "TDO-PHY",
  "TDO-CMS",
  "TDO-OTH",
  "TDO-H",
  "TDO-LFT",
  "TDO-FRT",
  "TDO-DNQ",
  "TDO-BGPEND",
  "TDO-BGDNQ",
  "TDO-VOEDNQ",
  "TDO-HPE",
  "TDO-CUL",
  "TDO-VOLLEF",
  "TDO-RESCHE",
  "TDO-MEDCARD",
].sort();

// Compliance Options
const complianceTest = [
  { label: "Yes", value: "Yes", color: "green.200" },
  { label: "No", value: "No", color: "red.100" },
];

const OrientationInfoForm = ({
  profileData,
  refreshProfileData,
  userEmail,
  onRefresh,
  trainers,
}: ProcessorType) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isShowStatusOpen,
    onOpen: onShowStatusOpen,
    onClose: onShowStatusClose,
  } = useDisclosure();

  const {
    isOpen: isComplianceOpen,
    onOpen: onComplianceOpen,
    onClose: onCloseCompliance,
  } = useDisclosure();

  const {
    isOpen: isTDOOpen,
    onOpen: onTDOOpen,
    onClose: onCloseTDO,
  } = useDisclosure();

  const {
    isOpen: isDriverCodeOpen,
    onOpen: onDriverCodeOpen,
    onClose: onCloseDriverCode,
  } = useDisclosure();

  const {
    isOpen: isTruckInfoOpen,
    onOpen: onTruckInfoOpen,
    onClose: onCloseTruckInfo,
  } = useDisclosure();

  const {
    isOpen: isSeatingOpen,
    onOpen: onSeatingOpen,
    onClose: onSeatingClose,
  } = useDisclosure();

  const {
    isOpen: isBonusOpen,
    onOpen: onBonusOpen,
    onClose: onCloseBonus,
  } = useDisclosure();

  const {
    isOpen: isTrainerOpen,
    onOpen: onTrainerOpen,
    onClose: onTrainerClose,
  } = useDisclosure();

  const {
    isOpen: isTrainerSearchOpen,
    onOpen: onTrainerSearchOpen,
    onClose: onTrainerSearchClose,
  } = useDisclosure();

  const {
    isOpen: isTravelRequestOpen,
    onOpen: onTravelRequestOpen,
    onClose: onTravelRequestClose,
  } = useDisclosure();

  const {
    isOpen: isTravelRequestConfirmOpen,
    onOpen: onTravelRequestConfirmOpen,
    onClose: onTravelRequestConfirmClose,
  } = useDisclosure();

  const {
    isOpen: isTravelRequestCancelOpen,
    onOpen: onTravelRequestCancelOpen,
    onClose: onTravelRequestCancelClose,
  } = useDisclosure();

  const [recommendedDriverCode, setRecommendedDriverCode] = useState<
    string | ""
  >("");
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [blockedDates, setBlockedDates] = useState<string[]>([]);
  const [OrientationDate, setOrientationDate] = useState(
    profileData?.OrientationDate || ""
  );
  const [OrientationLocation, setOrientationLocation] = useState(
    profileData?.OrientationLocation || ""
  );
  const [Arrival, setArrival] = useState(() => {
    if (!profileData?.Arrival || isNaN(Date.parse(profileData.Arrival))) {
      return "";
    }
    const date = new Date(profileData.Arrival);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  });
  const [Transportation, setTransportation] = useState(
    profileData?.Transportation || ""
  );
  const [Reimbursement, setReimbursement] = useState(
    profileData?.Reimbursement || ""
  );
  const [Hotel, setHotel] = useState(profileData?.Hotel || "");
  const [Passenger, setPassenger] = useState(profileData?.Passenger || "");
  const [Pet, setPet] = useState(profileData?.Pet || "");
  const [TruckRequest, setTruckRequest] = useState(
    profileData?.TruckRequest || ""
  );
  const [TruckPreference, setTruckPreference] = useState(
    profileData?.TruckPreference || ""
  );
  const [DriverCode, setDriverCode] = useState(profileData?.DriverCode || "");
  const [DriverCodeRegister, setDriverCodeRegister] = useState(
    profileData?.DriverCodeRegistered || false
  );
  const [TruckStatus, setTruckStatus] = useState(
    profileData?.TruckStatus || ""
  );
  const [TruckNumber, setTruckNumber] = useState(
    profileData?.TruckNumber || ""
  );
  const [TravelToTruck, setTravelToTruck] = useState(
    profileData?.TravelToTruck
  );

  const [TravelToTruckCost, setTravelToTruckCost] = useState(
    profileData?.TravelToTruckCost || ""
  );

  const [TruckLocation, setTruckLocation] = useState(
    profileData?.TruckLocation || ""
  );
  const [SeatingDate, setSeatingDate] = useState(() => {
    if (profileData?.SeatingDate) {
      const date = new Date(profileData.SeatingDate);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  });

  const [SignOnBonusStatus, setSignOnBonusStatus] = useState(
    profileData?.SignOnBonusStatus || ""
  );

  const [ReimbursementStatus, setReimbursementStatus] = useState(
    profileData?.ReimbursementStatus || ""
  );

  const [OrientationBonusStatus, setOrientationBonusStatus] = useState(
    profileData?.OrientationBonusStatus || ""
  );
  const [TDOReason, setTDOReason] = useState(profileData?.TDOReason || "");
  const [TDOComment, setTDOComment] = useState(profileData?.TDOComment || "");
  const [Trainer, setTrainer] = useState(profileData?.Trainer || "");
  const [TrainerEmail, setTrainerEmail] = useState(
    profileData?.TrainerEmail || ""
  );
  const [trainerSearch, setTrainerSearch] = useState<string>("");
  const [trainerStatusSearch, setTrainerStatusSearch] = useState<string>("A");
  const [Registration, setRegistration] = useState(
    profileData?.Registration || ""
  );

  const [trainerLocationSearch, setTrainerLocationSearch] =
    useState<string>("");

  // Travel Request Fields
  const [preferTravelType, setPreferTravelType] = useState<string>("");
  const [leaveDate, setLeaveDate] = useState<string>("");
  const [pickUpTime, setPickUpTime] = useState<string>("");
  const [preferOrientationLocation, setPreferOrientationLocation] =
    useState<string>("");
  const [travelRequestComment, setTravelRequestComment] = useState<string>("");

  // Function to save the changes in the orientation
  const saveChangesOrientation = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }

    const isAnyFieldEmpty = Object.values({
      Registration,
      OrientationDate,
      OrientationLocation,
    }).some((value) => !value.trim());

    if (isAnyFieldEmpty) {
      toast({
        title: "Warning",
        description: "Please fill out all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "update orientation",
      userEmail: userEmail,
      form: {
        Registration,
        OrientationDate,
        OrientationLocation,
        Arrival,
        Transportation,
        Reimbursement,
        Hotel,
        Passenger,
        Pet,
        TruckRequest,
        TruckPreference,
        TravelToTruckCost,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      onClose();
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to handle the change of the Truck Preference
  const handleTruckPreferenceChange = (option: string) => {
    setChanged(true);
    let preferencesArray = TruckPreference.split(", ");
    if (preferencesArray.length === 1 && preferencesArray[0] === "") {
      preferencesArray = [];
    }
    if (preferencesArray.includes(option)) {
      preferencesArray = preferencesArray.filter((pref) => pref !== option);
    } else {
      preferencesArray.push(option);
    }
    setTruckPreference(preferencesArray.join(", "));
  };

  // Function to handle the change of the Truck Request
  const handleTruckRequestChange = (option: string) => {
    setChanged(true);
    let requestArray = TruckRequest.split(", ");
    if (requestArray.length === 1 && requestArray[0] === "") {
      requestArray = [];
    }
    if (requestArray.includes(option)) {
      requestArray = requestArray.filter((req) => req !== option);
    } else {
      requestArray.push(option);
    }
    setTruckRequest(requestArray.join(", "));
  };

  // Resets the edit form to the original values
  const resetForm = () => {
    setOrientationDate(profileData?.OrientationDate || "");
    setOrientationLocation(profileData?.OrientationLocation || "");
    setArrival(() => {
      if (profileData?.Arrival) {
        const date = new Date(profileData.Arrival);
        let year = date.getFullYear();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      }
      return "";
    });
    setTransportation(profileData?.Transportation || "");
    setReimbursement(profileData?.Reimbursement || "");
    setHotel(profileData?.Hotel || "");
    setPassenger(profileData?.Passenger || "");
    setPet(profileData?.Pet || "");
    setTruckRequest(profileData?.TruckRequest || "");
    setTruckPreference(profileData?.TruckPreference || "");
    setChanged(false);
  };

  // Function to update show or no show status
  const handleStatusChange = async (status: string) => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "show status",
      userEmail: userEmail,
      form: {
        Status: status,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to update compliance option
  const complianceUpdate = async (status: string) => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "compliance update",
      userEmail: userEmail,
      form: {
        ComplianceStatus: status,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to term drivers from orientation
  const handleTDOChange = async (status: string) => {
    if (!profileData?.ID || !TDOReason) {
      toast({
        title: "Error",
        description: "Please select a reason for the TDO.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "term during orientation",
      userEmail: userEmail,
      form: {
        TDOReason,
        TDOComment,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to get the recommended driver code
  const getRecommendedDriverCode = async () => {
    if (
      !profileData?.DriverType ||
      !profileData?.FirstName ||
      !profileData?.LastName ||
      profileData?.DriverCode ||
      profileData?.Status === "New" ||
      profileData?.Status === "TDO" ||
      profileData?.Status === "Withdrawn" ||
      profileData?.Status === "Approved"
    ) {
      return;
    }
    const payload = {
      process: "recommend driver code",
      DriverType:
        profileData.Registration === "Experienced" ||
        profileData.Registration === "Upgrading"
          ? profileData.DriverType
          : "CDL Student",
      FirstName: profileData.FirstName,
      LastName: profileData.LastName,
    };

    try {
      const response = await apiRead(payload);
      if (response && response.length > 0) {
        setRecommendedDriverCode(response[0].Driver_Code);
      }
    } catch (error) {
      console.error("Error getting recommended driver code", error);
    }
  };

  // Reset Driver Code Form
  const closeDriverCode = () => {
    setDriverCode(profileData?.DriverCode || "");
    setDriverCodeRegister(profileData?.DriverCodeRegistered || false);
    onCloseDriverCode();
  };

  // Function to submit the driver code
  const submitDriverCode = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    if (DriverCode === "" || DriverCode.length < 4) {
      toast({
        title: "Warning",
        description: "Please enter a valid driver code.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "driver code",
      userEmail: userEmail,
      form: {
        DriverCode,
        DriverCodeRegistered: DriverCodeRegister,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      onCloseDriverCode();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to submit the truck information
  const submitTruckInfo = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    if (TruckNumber === "" || TruckStatus === "" || TruckLocation === "") {
      toast({
        title: "Warning",
        description: "Please fill out all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "truck info",
      userEmail: userEmail,
      form: {
        TruckNumber,
        TruckStatus,
        TruckLocation,
        SeatingDate,
        TravelToTruck,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      onCloseTruckInfo();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to submit seating status
  const submitSeatingStatus = async (status: string) => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "seating status",
      userEmail: userEmail,
      form: {
        Status: status,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      onSeatingClose();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };
  // Function to submit the bonus form
  const submitSignOnBonus = async (status: string) => {
    if (
      !profileData?.ID ||
      SignOnBonusStatus == null ||
      ReimbursementStatus == null ||
      OrientationBonusStatus == null
    ) {
      toast({
        title: "Error",
        description: "Please make sure all fields are filled out.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "sign on bonus",
      userEmail: userEmail,
      form: {
        SignOnBonusStatus,
        ReimbursementStatus,
        OrientationBonusStatus,
      },
    };
    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      onCloseBonus();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const confirmTrainer = async (trainer: string, email: string) => {
    if (!trainer || !email) {
      toast({
        title: "Missing Data",
        description:
          "Please fill out all required fields or contact support for assistance.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "trainer",
      userEmail,
      form: {
        Trainer: trainer,
        TrainerEmail: email,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setLoading(false);
        refreshProfileData();
        onRefresh();
        onTrainerClose();
        toast({
          title: "Success",
          description: "The changes have been submitted.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to handle request for travel
  const handleTravelRequest = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    if (
      !travelRequestComment ||
      preferTravelType === "" ||
      leaveDate === "" ||
      pickUpTime === "" ||
      preferOrientationLocation === ""
    ) {
      toast({
        title: "Warning",
        description: "Please fill out all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "travel request",
      userEmail: userEmail,
      preferTravelType,
      leaveDate,
      pickUpTime,
      preferOrientationLocation,
      Comment: travelRequestComment,
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      onTravelRequestClose();
      setPreferTravelType("");
      setLeaveDate("");
      setPickUpTime("");
      setPreferOrientationLocation("");
      setTravelRequestComment("");

      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to Confirm Travel Request
  const handleTravelRequestStatusConfirm = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "travel request confirm",
      userEmail: userEmail,
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to Confirm Travel Request
  const handleTravelRequestStatusCancel = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "travel request cancel",
      userEmail: userEmail,
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const splitDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    getRecommendedDriverCode();
  }, []);

  return (
    <>
      <VStack w="100%" height="100%" spacing={1}>
        <VStack {...cardProps}>
          <HStack w="100%" justifyContent="flex-start">
            <Text {...cardHeaderProps}>Orientation Progress</Text>
          </HStack>
          <Divider />
          <Grid {...editGridProps}>
            <GridItem colSpan={1}>
              <Text {...editLabelProps}>Status</Text>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  leftIcon={<FaBarsProgress />}
                  width="100%"
                  rightIcon={<ChevronDownIcon />}
                  bg={
                    profileData?.Status === "Scheduled"
                      ? "blue.100"
                      : profileData?.Status === "Showed"
                      ? "blue.100"
                      : profileData?.Status === "Unseated"
                      ? "yellow.100"
                      : profileData?.Status === "TDO"
                      ? "red.100"
                      : "green.200"
                  }
                >
                  {profileData?.Status === "Scheduled"
                    ? "Pending"
                    : profileData?.Status}
                </MenuButton>
                <MenuList minWidth="240px">
                  <MenuItemOption onClick={onShowStatusOpen}>
                    Update Show Status
                  </MenuItemOption>
                  <MenuItemOption onClick={onSeatingOpen}>
                    Seating Status (AS400)
                  </MenuItemOption>
                  <MenuItemOption onClick={onTDOOpen}>
                    Term During Orientation (TDO)
                  </MenuItemOption>
                </MenuList>
              </Menu>
            </GridItem>

            <GridItem colSpan={1}>
              <Text {...editLabelProps}>Driver Code</Text>
              <Button
                width="100%"
                leftIcon={<FaBarcode />}
                onClick={onDriverCodeOpen}
                bg={profileData?.DriverCode ? "green.200" : "gray.100"}
              >
                {DriverCode || "Pending"}
              </Button>
            </GridItem>

            {/* Button for Orientation to Mark Complete all Compliance Related */}
            <GridItem colSpan={1}>
              <Text {...editLabelProps}>Compliance</Text>
              <Button
                width="100%"
                leftIcon={<GrCompliance />}
                onClick={onComplianceOpen}
                bg={
                  profileData?.ComplianceStatus === "Yes"
                    ? "green.200"
                    : profileData?.ComplianceStatus === "No"
                    ? "red.100"
                    : "gray.100"
                }
              >
                {profileData?.ComplianceStatus || "Pending"}
              </Button>
            </GridItem>

            {profileData?.Registration !== "Experienced" &&
              profileData?.Registration !== "Upgrading" && (
                <GridItem colSpan={1}>
                  <Text {...editLabelProps}>Trainer</Text>
                  <Button
                    width="100%"
                    leftIcon={<SiTrainerroad />}
                    onClick={onTrainerOpen}
                    bg={profileData?.Trainer ? "green.200" : "gray.100"}
                  >
                    {profileData?.Trainer || "Pending"}
                  </Button>
                </GridItem>
              )}

            <GridItem colSpan={1}>
              <Text {...editLabelProps}>Truck Information</Text>
              <Button
                width="100%"
                leftIcon={<HiTruck />}
                onClick={onTruckInfoOpen}
                bg={profileData?.TruckNumber ? "green.200" : "gray.100"}
              >
                {profileData?.TruckNumber || "Pending"}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Text {...editLabelProps}>Settlements</Text>
              <Button
                width="100%"
                leftIcon={<FaDollarSign />}
                onClick={onBonusOpen}
                bg={profileData?.SignOnBonusStatus ? "green.200" : "gray.100"}
              >
                {!profileData?.Bonus ? "Pending" : "Completed"}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Text {...editLabelProps}>Travel Request</Text>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  leftIcon={<FaBarsProgress />}
                  width="100%"
                  rightIcon={<ChevronDownIcon />}
                  bg={
                    profileData?.TravelRequestStatus === "Cancelled"
                      ? "green.100"
                      : profileData?.TravelRequestStatus === "Booked"
                      ? "green.100"
                      : profileData?.TravelRequestStatus === "Requested"
                      ? "blue.100"
                      : "gray.100"
                  }
                >
                  {profileData?.TravelRequestStatus || "N/A"}
                </MenuButton>
                <MenuList minWidth="240px">
                  <MenuItemOption onClick={onTravelRequestOpen}>
                    Make Request
                  </MenuItemOption>
                  <MenuItemOption onClick={onTravelRequestConfirmOpen}>
                    Request Completed
                  </MenuItemOption>
                  <MenuItemOption onClick={onTravelRequestCancelOpen}>
                    Request Cancelled
                  </MenuItemOption>
                </MenuList>
              </Menu>
            </GridItem>
          </Grid>
        </VStack>

        {/* Show Status Modal */}
        <VStack {...cardProps}>
          <HStack w="100%" justifyContent="flex-start">
            <Text {...cardHeaderProps}>Change Orientation Information</Text>
            <Text
              fontSize="xs"
              fontWeight="semibold"
              color="red.500"
              style={{ visibility: changed ? "visible" : "hidden" }}
            >
              - Unsaved Changes
            </Text>
          </HStack>
          <Divider />
          <Grid {...editGridProps}>
            {/* Add in Registration drop down with the following values, Experienced, 2 Weeks, and 4 Weeks */}
            <GridItem>
              <Text {...editLabelProps}>Registration</Text>
              <Select
                value={Registration}
                size="md"
                onChange={(e) => {
                  setRegistration(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Experienced">Experienced</option>
                <option value="2 Weeks Trainee">2 Weeks Trainee</option>
                <option value="4 Weeks Trainee">4 Weeks Trainee</option>
              </Select>
            </GridItem>
            {/* Orientation Location Select Options */}
            <GridItem>
              <Text {...editLabelProps}>Orientation Location</Text>
              <Select
                value={OrientationLocation}
                size="md"
                onChange={(e) => {
                  setOrientationLocation(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Dubuque">Dubuque</option>
                <option value="Denton">Denton</option>
                <option value="Dover">Dover</option>
                <option value="Sapulpa">Sapulpa</option>
                <option value="Travel to Truck">Travel to Truck</option>
              </Select>
            </GridItem>
            {/* Orientation Date */}
            <GridItem>
              <Text {...editLabelProps}>Orientation Date</Text>
              <DatePicker
                selected={
                  OrientationDate ? new Date(OrientationDate + "T00:00") : null
                }
                onChange={(date: Date | null) => {
                  if (date) {
                    setOrientationDate(date.toISOString().split("T")[0]);
                    setChanged(true);
                  } else {
                    setOrientationDate("");
                  }
                }}
                customInput={<Input />}
              />
            </GridItem>
            {/* Arrival Time */}
            <GridItem>
              <Text {...editLabelProps}>Arrival Time</Text>
              <Input
                value={Arrival}
                size="md"
                type="datetime-local"
                onChange={(e) => {
                  setArrival(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Transportation select option */}
            <GridItem>
              <Text {...editLabelProps}>Transportation</Text>
              <Select
                value={Transportation || ""}
                size="md"
                onChange={(e) => {
                  setTransportation(e.target.value);
                  setChanged(true);
                }}
                placeholder="Select option"
              >
                <option value="Bus">Bus</option>
                <option value="Driving">Driving</option>
                <option value="Flight - AA">Flight - AA</option>
                <option value="Flight - Delta">Flight - Delta</option>
                <option value="Flight - Other">Flight - Other</option>
                <option value="Rental Car - ENT">Rental Car - ENT</option>
                <option value="Rental Car - HERTZ">Rental Car - HERTZ</option>
                <option value="Uhaul">Uhaul</option>
                <option value="Other">Other</option>
              </Select>
            </GridItem>
            {/* Reimbursement amount */}
            <GridItem>
              <Text {...editLabelProps}>Reimbursement</Text>
              <Input
                value={Reimbursement}
                size="md"
                type="number"
                onChange={(e) => {
                  setReimbursement(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Travel to Truck Cost Grid Item with an input that takes in the amount of money */}
            <GridItem>
              <Text {...editLabelProps}>Travel to Truck Cost</Text>
              <Input
                value={TravelToTruckCost}
                size="md"
                type="number"
                onChange={(e) => {
                  setTravelToTruckCost(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            <GridItem colSpan={3}>
              <Text {...editLabelProps}>Accommodations</Text>
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                <Checkbox
                  size="md"
                  colorScheme="green"
                  isChecked={Hotel === "Yes"}
                  onChange={(e) => {
                    setHotel(e.target.checked ? "Yes" : "");
                    setChanged(true);
                  }}
                >
                  Hotel
                </Checkbox>
                <Checkbox
                  size="md"
                  colorScheme="green"
                  isChecked={Passenger === "Yes"}
                  onChange={(e) => {
                    setPassenger(e.target.checked ? "Yes" : "");
                    setChanged(true);
                  }}
                >
                  Passenger
                </Checkbox>
                <Checkbox
                  size="md"
                  colorScheme="green"
                  isChecked={Pet === "Yes"}
                  onChange={(e) => {
                    setPet(e.target.checked ? "Yes" : "");
                    setChanged(true);
                  }}
                >
                  Pet
                </Checkbox>
              </Stack>
            </GridItem>
            {/* Truck Request */}
            <GridItem colSpan={3}>
              <Text {...editLabelProps}>Truck Request</Text>
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                {truckRequestOptions.map((option) => (
                  <Checkbox
                    key={option}
                    size="md"
                    colorScheme="green"
                    isChecked={TruckRequest.split(", ").includes(option)}
                    onChange={() => handleTruckRequestChange(option)}
                  >
                    {option}
                  </Checkbox>
                ))}
              </Stack>
            </GridItem>
            {/* Truck Preferences */}
            <GridItem colSpan={3}>
              <Text {...editLabelProps}>Truck Preferences</Text>
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                {truckPreferenceOptions.map((option) => (
                  <Checkbox
                    key={option}
                    size="md"
                    colorScheme="green"
                    isChecked={TruckPreference.split(", ").includes(option)}
                    onChange={() => handleTruckPreferenceChange(option)}
                  >
                    {option}
                  </Checkbox>
                ))}
              </Stack>
            </GridItem>
          </Grid>
          <HStack
            w="100%"
            justifyContent="flex-end"
            p={2}
            spacing={2}
            mt="50px"
            style={{ visibility: changed ? "visible" : "hidden" }}
          >
            <Button onClick={resetForm} width="100px">
              Cancel
            </Button>
            <Button colorScheme="whatsapp" onClick={onOpen} width="100px">
              Submit
            </Button>
          </HStack>
        </VStack>
      </VStack>
      {/* Recruiter Confirmation Alert Box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to submit the changes?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                onClick={saveChangesOrientation}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Show Status Alert Box */}
      <AlertDialog
        isOpen={isShowStatusOpen}
        leastDestructiveRef={cancelRef}
        onClose={onShowStatusClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Show Status
            </AlertDialogHeader>
            <AlertDialogBody>
              <Button
                bg="green.100"
                _hover={{ bg: "green.300" }}
                onClick={() => handleStatusChange("Showed")}
                width="100%"
                mb={3}
                isDisabled={loading}
              >
                Show
              </Button>
              <Button
                bg="red.100"
                _hover={{ bg: "red.300" }}
                onClick={() => handleStatusChange("No Show")}
                width="100%"
                mb={3}
                isDisabled={loading}
              >
                No Show
              </Button>
              <Button
                onClick={onShowStatusClose}
                width="100%"
                mb={3}
                isDisabled={loading}
                mt="50px"
              >
                Cancel
              </Button>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Seating Status Alert Box */}
      <AlertDialog
        isOpen={isSeatingOpen}
        leastDestructiveRef={cancelRef}
        onClose={onSeatingClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Seating Status
            </AlertDialogHeader>
            <AlertDialogBody>
              <Button
                bg="green.100"
                _hover={{ bg: "green.300" }}
                onClick={() => submitSeatingStatus("Seated")}
                width="100%"
                mb={3}
                isDisabled={loading}
              >
                Seated
              </Button>
              <Button
                bg="red.100"
                _hover={{ bg: "red.300" }}
                onClick={() => submitSeatingStatus("Unseated")}
                width="100%"
                mb={3}
                isDisabled={loading}
              >
                Unseated
              </Button>
              <Button
                onClick={onSeatingClose}
                width="100%"
                mb={3}
                isDisabled={loading}
                mt="50px"
              >
                Cancel
              </Button>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* TDO */}
      <AlertDialog
        isOpen={isTDOOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseTDO}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Term During Orientation
            </AlertDialogHeader>
            <AlertDialogBody>
              <Select
                placeholder="Select option"
                value={TDOReason}
                size="md"
                onChange={(e) => {
                  setTDOReason(e.target.value);
                  setChanged(true);
                }}
              >
                {TDOs.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </Select>
              <Textarea
                mt="10px"
                value={TDOComment}
                size="md"
                onChange={(e) => {
                  setTDOComment(e.target.value);
                  setChanged(true);
                }}
                placeholder="Add note..."
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onCloseTDO}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                onClick={() => handleTDOChange(TDOReason)}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Compliance Alert Box */}
      <AlertDialog
        isOpen={isComplianceOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseCompliance}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Compliance Cleared Driver to Seat?
            </AlertDialogHeader>
            <AlertDialogBody>
              {complianceTest.map((test) => (
                <Button
                  key={test.value}
                  bg={test.color}
                  _hover={{ bg: test.color.replace("100", "300") }}
                  onClick={() => complianceUpdate(test.value)}
                  width="100%"
                  mb={3}
                  isDisabled={loading}
                >
                  {test.label}
                </Button>
              ))}
              <Button
                onClick={onCloseCompliance}
                width="100%"
                mb={3}
                isDisabled={loading}
                mt="50px"
              >
                Cancel
              </Button>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Driver Code Alert Box */}
      <AlertDialog
        isOpen={isDriverCodeOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDriverCode}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Driver Code
            </AlertDialogHeader>
            <AlertDialogBody>
              <Input
                value={DriverCode}
                size="md"
                onChange={(e) => {
                  setDriverCode(e.target.value);
                  setChanged(true);
                }}
              />
              <Button onClick={onCloseDriverCode} width="100%" mb={3}>
                Cancel
              </Button>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isDriverCodeOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDriverCode}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Driver Code
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack spacing={2} align="stretch">
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>
                    Recommendation (Press to Apply)
                  </Text>
                </HStack>

                <Box bg="blackAlpha.50" h="40px" w="100%" borderRadius="md">
                  <Button
                    isDisabled={recommendedDriverCode === ""}
                    onClick={() => setDriverCode(recommendedDriverCode)}
                    width="100%"
                    h="100%"
                    variant="unstyled"
                    _hover={{ bg: "blackAlpha.100" }}
                  >
                    {recommendedDriverCode}
                  </Button>
                </Box>
                <Text {...editLabelProps}>Driver Code</Text>
                <Input
                  placeholder="Enter Driver Code"
                  value={DriverCode}
                  maxLength={6}
                  size="md"
                  onChange={(e) => {
                    setDriverCode(e.target.value);
                  }}
                />
                <Text {...editLabelProps}>Driver Code Registration</Text>
                <Switch
                  size="lg"
                  colorScheme="green"
                  isChecked={DriverCodeRegister}
                  onChange={(e) => {
                    setDriverCodeRegister(e.target.checked);
                  }}
                >
                  {DriverCodeRegister ? "Yes" : "No"}
                </Switch>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={closeDriverCode}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                onClick={submitDriverCode}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Truck Info Alert Box */}
      <AlertDialog
        isOpen={isTruckInfoOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseTruckInfo}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Truck Information
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack spacing={2} align="stretch">
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>Truck Status</Text>
                </HStack>
                <Select
                  value={TruckStatus}
                  placeholder="Select Truck Status"
                  size="md"
                  onChange={(e) => {
                    setTruckStatus(e.target.value);
                  }}
                >
                  <option value="In Shop (Z)">In Shop</option>
                  <option value="Detail (F)">Detail</option>
                  <option value="Ready (R)">Ready</option>
                </Select>
                <Text {...editLabelProps}>Truck Number</Text>
                <Input
                  placeholder="Enter Truck Number"
                  value={TruckNumber}
                  maxLength={20}
                  size="md"
                  onChange={(e) => {
                    setTruckNumber(e.target.value);
                  }}
                />
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>Travel to Truck</Text>
                </HStack>
                <Switch
                  size="lg"
                  colorScheme="green"
                  isChecked={TravelToTruck}
                  onChange={(e) => {
                    setTravelToTruck(e.target.checked);
                  }}
                >
                  {TravelToTruck ? "Yes" : "No"}
                </Switch>
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>Truck Location</Text>
                </HStack>
                <Select
                  value={TruckLocation}
                  placeholder="Select Truck Location"
                  size="md"
                  onChange={(e) => {
                    setTruckLocation(e.target.value);
                  }}
                >
                  <option value="Denton">Denton</option>
                  <option value="Dover">Dover</option>
                  <option value="Dubuque">Dubuque</option>
                  <option value="Joliet">Joliet</option>
                  <option value="Kansas City">Kansas City</option>
                  <option value="Monmouth">Monmouth</option>
                  <option value="Sapulpa">Sapulpa</option>
                  <option value="Springfield">Springfield</option>
                </Select>
                <Text {...editLabelProps}>Seating Date</Text>
                <Input
                  value={SeatingDate}
                  size="md"
                  type="date"
                  onChange={(e) => {
                    setSeatingDate(e.target.value);
                  }}
                />
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onCloseTruckInfo}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                width="100px"
                onClick={submitTruckInfo}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Bonus Alert Box */}
      <AlertDialog
        isOpen={isBonusOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseBonus}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Bonus Information
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack spacing={2} align="stretch">
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>Sign On Bonus</Text>
                </HStack>
                <Select
                  value={SignOnBonusStatus}
                  placeholder="Select option"
                  size="md"
                  onChange={(e) => {
                    setSignOnBonusStatus(e.target.value);
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="Ineligible">Ineligible</option>
                </Select>
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>Orientation Bonus</Text>
                </HStack>
                <Select
                  value={OrientationBonusStatus}
                  placeholder="Select option"
                  size="md"
                  onChange={(e) => {
                    setOrientationBonusStatus(e.target.value);
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="Ineligible">Ineligible</option>
                </Select>
                <HStack w="100%" justifyContent="flex-start">
                  <Text {...editLabelProps}>Reimbursement</Text>
                </HStack>
                <Select
                  value={ReimbursementStatus}
                  placeholder="Select option"
                  size="md"
                  onChange={(e) => {
                    setReimbursementStatus(e.target.value);
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onCloseBonus}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                width="100px"
                onClick={() => {
                  submitSignOnBonus(SignOnBonusStatus);
                }}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isTrainerSearchOpen}
        leastDestructiveRef={cancelRef}
        onClose={onTrainerSearchClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Trainer Confirmation
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Please confirm that you would like to select this trainer
              </Text>
              <Text mt="20px" size="sm" color="blackAlpha.700">
                Trainer Email
              </Text>
              <Input
                value={TrainerEmail}
                size="md"
                type="email"
                onChange={(e) => setTrainerEmail(e.target.value)}
                placeholder="Trainer Email"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onTrainerSearchClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                width="100px"
                onClick={() => confirmTrainer(Trainer, TrainerEmail)}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isTrainerOpen}
        onClose={onTrainerClose}
        size="full"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Trainer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w="100%" justifyContent="flex-start">
              <Input
                placeholder="Trainer Name or Code"
                value={trainerSearch}
                w="200px"
                variant="filled"
                maxLength={6}
                size="md"
                onChange={(e) => setTrainerSearch(e.target.value)}
              />
              {/* Drop Down for Trainer Status */}
              <Select
                variant="filled"
                w="200px"
                value={trainerStatusSearch}
                placeholder="Select Status"
                onChange={(e) => setTrainerStatusSearch(e.target.value)}
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="P">P</option>
                <option value="T">T</option>
                <option value="U">U</option>
              </Select>
              {/* Drop down with 3 options, All, Sapulpa, and Dubuque */}
              <Select
                variant="filled"
                w="200px"
                value={trainerLocationSearch}
                placeholder="Select Location"
                onChange={(e) => setTrainerLocationSearch(e.target.value)}
              >
                <option value="Sapulpa">Sapulpa</option>
                <option value="Dubuque">Dubuque</option>
              </Select>
            </HStack>
            <Box w="100%" overflow="auto" p="20px" mt="10px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Driver Code</Th>
                    <Th>Driver</Th>
                    <Th>Truck Number</Th>
                    <Th>Driver Leader</Th>
                    <Th>Division</Th>
                    <Th>Cost Center</Th>
                    <Th>Location</Th>
                    <Th>Next Available Date</Th>
                    <Th>Trainer Status</Th>
                    <Th>Gender</Th>
                    <Th>Pet</Th>
                    <Th>Smoker</Th>
                    <Th w="40%">Notes</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {trainers &&
                    trainers
                      .filter(
                        (trainer) =>
                          (trainer.Driver ?? "")
                            .toLowerCase()
                            .includes(trainerSearch.toLowerCase()) ||
                          (trainer.DriverCode ?? "")
                            .toLowerCase()
                            .includes(trainerSearch.toLowerCase())
                      )
                      .filter((trainer) =>
                        trainerStatusSearch
                          ? (trainer.TrainerStatus ?? "").trim() ===
                            trainerStatusSearch
                          : true
                      )
                      .filter((trainer) =>
                        trainerLocationSearch
                          ? trainer.Location === trainerLocationSearch
                          : true
                      )
                      .map((trainer) => (
                        <Tr
                          key={trainer.DriverCode ?? Math.random().toString()}
                          _hover={{ bg: "gray.50" }}
                          cursor="pointer"
                          onClick={() => {
                            onTrainerSearchOpen();
                            setTrainer(trainer?.DriverCode || "N/A");
                            setTrainerEmail(trainer?.Email || "N/A");
                          }}
                        >
                          <Td>{trainer.DriverCode ?? "N/A"}</Td>
                          <Td>{trainer.Driver ?? "N/A"}</Td>
                          <Td>{trainer.TruckNumber}</Td>
                          <Td>{trainer.DriverLeader}</Td>
                          <Td>{trainer.Division}</Td>
                          <Td>{trainer.CostCenter}</Td>
                          <Td>{trainer.Location}</Td>
                          <Td>
                            {trainer.NextAvailableDate
                              ? splitDate(trainer.NextAvailableDate)
                              : ""}
                          </Td>
                          <Td>{trainer.TrainerStatus}</Td>
                          <Td>{trainer.Gender}</Td>
                          <Td>{trainer.Pet ? "Yes" : "No"}</Td>
                          <Td>{trainer.Smoker ? "Yes" : "No"}</Td>
                          <Td>{trainer.Notes}</Td>
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal to sign up for Travel Request */}
      <Modal
        isOpen={isTravelRequestOpen}
        onClose={onTravelRequestClose}
        size="lg"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Travel Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Input for Prefer Type of Travel */}
            <Text {...editLabelProps}>* Preferred Type of Travel</Text>
            <Input
              value={preferTravelType}
              size="md"
              onChange={(e) => setPreferTravelType(e.target.value)}
            />

            {/* Date input for Date Needing to Leave */}
            <Text {...editLabelProps} mt="20px">
              * Date Needing to Leave
            </Text>
            <Input
              type="date"
              size="md"
              onChange={(e) => setLeaveDate(e.target.value)}
              value={leaveDate}
            />

            {/* Time input for Time Needing to Leave */}
            <Text {...editLabelProps} mt="20px">
              * Pick-Up Time
            </Text>
            <Input
              type="time"
              size="md"
              onChange={(e) => setPickUpTime(e.target.value)}
              value={pickUpTime}
            />

            {/* Prefer Orientation Location, drop down to select a location */}
            <Text {...editLabelProps} mt="20px">
              * Preferred Orientation Location
            </Text>
            <Select
              value={preferOrientationLocation}
              placeholder="Select Location"
              size="md"
              onChange={(e) => setPreferOrientationLocation(e.target.value)}
            >
              <option value="Dubuque">Dubuque</option>
              <option value="Denton">Denton</option>
              <option value="Dover">Dover</option>
              <option value="Sapulpa">Sapulpa</option>
            </Select>

            {/* Travel Request Comment */}
            <Text {...editLabelProps} mt="20px">
              * Additional Comments
            </Text>
            <Textarea
              size="md"
              onChange={(e) => setTravelRequestComment(e.target.value)}
              maxLength={999}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onTravelRequestClose}>Close</Button>
            <Button
              colorScheme="whatsapp"
              onClick={handleTravelRequest}
              isLoading={loading}
              ml={3}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to Confirm that Travel Request is Completed */}
      <AlertDialog
        isOpen={isTravelRequestConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onTravelRequestConfirmClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Travel Status Confirmation
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>Are you sure you would like to confirm the travel?</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onTravelRequestConfirmClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                width="100px"
                onClick={handleTravelRequestStatusConfirm}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Modal to Cancel Travel Request */}
      <AlertDialog
        isOpen={isTravelRequestCancelOpen}
        leastDestructiveRef={cancelRef}
        onClose={onTravelRequestCancelClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Travel Status Cancel
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Are you sure you would like to cancel the travel request?
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onTravelRequestCancelClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                isLoading={loading}
                width="100px"
                onClick={handleTravelRequestStatusCancel}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default OrientationInfoForm;
