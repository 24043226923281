import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Avatar,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ChatIcon } from "@chakra-ui/icons";
import API from "../../api/API";

interface TopBarProps {
  userName: string;
  userPhoto: string | null;
  userNotifications: userNotifications[];
  userEmail: string | null;
  handleSubscribe: () => void;
}

interface userNotifications {
  id: string;
  title: string;
  body: string;
  url: string;
}

const TopBar: React.FC<TopBarProps> = ({
  userName,
  userPhoto,
  userNotifications,
  userEmail,
  handleSubscribe: handleSubscribeProp,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  //bring them back to the action items page
  const handleUnitClick = () => {
    navigate("/");
  };

  const handleSubscribe = () => {
    // Call the parent's subscribeToNotifications function
    handleSubscribeProp();
  };

  // Check Notification Permission
  useEffect(() => {
    const checkNotificationPermission = () => {
      if ("Notification" in window && Notification.permission === "granted") {
      }
    };
    checkNotificationPermission();
  }, []);

  // Update the feedback to the variable
  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newFeedback = event.target.value;
    setFeedback(newFeedback);
  };

  const handleFeedback = async () => {
    setSubmittingFeedback(true);
    try {
      if (!feedback) {
        toast({
          title: "Provide feedback",
          description: "Please provide feedback before submitting",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const payload = {
        process: "feedback",
        user: userName,
        userEmail: userEmail,
        feedback: feedback,
      };

      const response = await API(payload);
      if (response) {
        setSubmittingFeedback(false);
        toast({
          title: "Feedback submitted",
          description: "Thank you for your feedback!",
          status: "success",
          duration: 10000,
          isClosable: true,
          position: "top",
        });

        onClose();
        setFeedback("");
      } else {
        setSubmittingFeedback(false);
        console.error("API request failed:", response);
        toast({
          title: "Feedback failed",
          description: "Please try again later",
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top",
        });
        onClose();
      }
    } catch (error) {
      setSubmittingFeedback(false);
      console.error("API request failed:", error);
      toast({
        title: "Feedback failed",
        description: "Please try again later",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }
  };

  return (
    <Flex
      align="center"
      p={2}
      height="55px"
      css={{
        backdropFilter: "blur(10px)",
      }}
      paddingRight={5}
      background="whiteAlpha.800"
    >
      <Box
        onClick={handleUnitClick}
        style={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
        ml="20px"
      >
        <Image src="/core.png" alt="Logo" maxH={6} />
      </Box>
      <Image
        src="/runner.png"
        alt="Logo"
        maxH={6}
        ml="20px"
        borderRadius="5px"
        p="5px"
      />

      <Flex marginLeft="auto" alignItems="center">
        <IconButton
          aria-label="Feedback"
          variant="solid"
          size="md"
          icon={<ChatIcon color="gray.600" />}
          marginRight="3"
          borderRadius="full"
          colorScheme="gray"
          onClick={onOpen}
        />
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="Notifications"
              fontSize="xl"
              variant="solid"
              size="md"
              icon={
                <Avatar
                  name={userName}
                  src={userPhoto || undefined}
                  borderRadius="full"
                  boxSize="40px"
                  objectFit="cover"
                />
              }
              marginRight="3"
              borderRadius="full"
              colorScheme="gray"
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              color="gray.800"
              bg="white"
              margin="1"
              marginTop="2"
              padding="2"
              boxShadow="2xl"
              borderColor="white"
            >
              <PopoverHeader
                pt={4}
                fontWeight="medium"
                border="1"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text color="blackAlpha.800">{userName}</Text>
              </PopoverHeader>
              <PopoverBody>
                <Button
                  onClick={handleLogout}
                  variant="ghost"
                  size="md"
                  width="100%"
                  textColor="gray.500"
                  fontWeight="medium"
                  justifyContent="flex-start"
                >
                  Sign out
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Please provide your feedback here"
              height="200px"
              value={feedback}
              onChange={handleFeedbackChange}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              onClick={handleFeedback}
              isLoading={submittingFeedback}
              loadingText="Submitting..."
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TopBar;
