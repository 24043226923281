import React from "react";
import { Flex, Text, VStack, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

// Icons
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { SiAzurepipelines } from "react-icons/si";
import { FaCalendarCheck } from "react-icons/fa6";
import { SiProgress } from "react-icons/si";
import { AiFillDatabase } from "react-icons/ai";

interface SideBarProps {
  selectedPage: string;
  setSelectedPage: (page: string) => void;
}

const Sidebar: React.FC<SideBarProps> = ({ selectedPage, setSelectedPage }) => {
  const navigate = useNavigate();

  return (
    <VStack
      width="200px"
      height="100%"
      background="white"
      alignItems="center"
      justifyContent="flex-start"
      shadow="md"
      borderRadius="2xl"
      padding="10px"
    >
      <Flex
        width="100%"
        alignItems="center"
        onClick={() => navigate("/driverboard")}
        style={{ cursor: "pointer" }}
        background="white"
        borderRadius="full"
        padding="10px"
        shadow="sm"
        mb="10px"
        _hover={{ background: "blackAlpha.50" }}
      >
        <ChevronLeftIcon color="#1D61AA" />
        <Text fontWeight="normal" color="#1D61AA" ml="10px">
          Return to Menu
        </Text>
      </Flex>

      <VStack width="100%">
        <Button
          justifyContent="flex-start"
          leftIcon={<AiFillDatabase />}
          colorScheme={selectedPage === "all" ? "red" : "blackAlpha"}
          _hover={{ background: "none" }}
          variant="ghost"
          width="full"
          isDisabled={selectedPage === "all"}
          _disabled={{ color: "messenger", cursor: "default" }}
          onClick={() => setSelectedPage("all")}
        >
          All Records
        </Button>
        <Button
          justifyContent="flex-start"
          leftIcon={<SiAzurepipelines />}
          colorScheme={selectedPage === "pipeline" ? "whatsapp" : "blackAlpha"}
          _hover={{ background: "none" }}
          variant="ghost"
          width="full"
          _disabled={{ color: "whatsapp", cursor: "default" }}
          isDisabled={selectedPage === "pipeline"}
          onClick={() => setSelectedPage("pipeline")}
        >
          Pipeline
        </Button>
        <Button
          justifyContent="flex-start"
          leftIcon={<SiProgress />}
          colorScheme={selectedPage === "processing" ? "orange" : "blackAlpha"}
          _hover={{ background: "none" }}
          variant="ghost"
          width="full"
          _disabled={{ color: "orange", cursor: "default" }}
          isDisabled={selectedPage === "processing"}
          onClick={() => setSelectedPage("processing")}
        >
          Processing
        </Button>
        <Button
          justifyContent="flex-start"
          leftIcon={<FaCalendarCheck />}
          colorScheme={
            selectedPage === "scheduled" ? "messenger" : "blackAlpha"
          }
          _hover={{ background: "none" }}
          variant="ghost"
          width="full"
          isDisabled={selectedPage === "scheduled"}
          _disabled={{ color: "messenger", cursor: "default" }}
          onClick={() => setSelectedPage("scheduled")}
        >
          Scheduled
        </Button>
      </VStack>
    </VStack>
  );
};

export default Sidebar;
