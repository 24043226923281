import React, { useEffect, useState } from "react";
import { userInfo } from "../../types/Types";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  VStack,
  Divider,
  HStack,
  Image,
  Grid,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  useToast,
  Select,
} from "@chakra-ui/react";
import NegativeBalanceAPI from "../../api/negativeBalanceAPI";

interface NegativeBalancePayload {
  Division: string;
  Amount: string;
  Completed_By: string | null;
  Created: string;
  Division_x0020_: string | null;
  Driver_Code: string | null;
  Driver_Leader: string | null;
  Driver_Leader_Name: string | null;
  Driver_Name: string | null;
  Fleet_Manager: string | null;
  ID: number;
  Negative_Balance_1_Week_Ago: string | null;
  Negative_Balance_Current: string | null;
  Resolved: string | null;
  Status: string | null;
  Step: string | null;
  Unique_ID: string | null;
}

const NegativeBalance: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const { ID } = useParams<{ ID: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<NegativeBalancePayload | null>(null);
  const toast = useToast();
  const [underDispatch, setUnderDispatch] = useState("");
  const [upcomingTimeOff, setUpcomingTimeOff] = useState("");
  const [skipPayAvailable, setSkipPayAvailable] = useState("");
  const [negativeReason, setNegativeReason] = useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [response, setResponse] = useState<string>("");
  // Check if all input fields are filled out
  const isFormFilled =
    underDispatch !== "" &&
    upcomingTimeOff !== "" &&
    skipPayAvailable !== "" &&
    negativeReason !== "";

  // Disable submit button if any input field is empty or the form is loading
  const isSubmitDisabled: boolean =
    !isFormFilled || isLoading || !!data?.Resolved;

  // Function to fetch action item from the API
  useEffect(() => {
    fetchActionItem();
  }, [ID]);

  const fetchActionItem = async () => {
    setIsLoading(true);
  
    try {
      const payload = {
        id: ID,
        action: "fetchActionItem",
      };
  
      // Call the API to fetch action item status
      const response = await NegativeBalanceAPI(payload);
  
      // Check if response exists and contains data
      if (
        response &&
        response.data &&
        response.data.Table1 &&
        response.data.Table1.length > 0
      ) {
        const responseData = response.data.Table1[0];
        setData(responseData);
  
        // Check if Status column is "Completed"
        if (responseData.Status === "Completed") {
          // If Status is "Completed", disable the submit button and set status as completed
          setIsSubmitted(true);
        }
      } else {
        throw new Error("API response does not contain expected data");
      }
    } catch (error) {
      // Handle error
      toast({
        title: "Error",
        description: "An error occurred while fetching the action item.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSubmit = async () => {
    if (!isFormFilled) {
      // Form is not filled, display an error message
      toast({
        title: "Incomplete Form",
        description: "Please fill out all the fields before submitting.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        id: ID,
        action: "handleSubmit",
        userEmail: userEmail,
        userName: userName,
        underDispatch,
        upcomingTimeOff,
        skipPayAvailable,
        negativeReason,
      };

      // Call the API to handle submit
      const response = await NegativeBalanceAPI(payload);
      setIsSubmitted(true);
      toast({
        title: "Submission Successful",
        description: "The action item has been submitted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while submitting the action item.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      height="91vh"
      w="100vw"
      justifyContent="center"
      alignItems="center"
      display="flex"
      overflow="hidden"
    >
      <VStack
        width="50vw"
        borderRadius="2xl"
        background="whiteAlpha.900"
        shadow="lg"
        padding="8px"
        height="98%"
        overflow="auto"
      >
        {/* Header Section */}
        <Box width="100%">
          <HStack width="100%">
            <Image src="/logo.png" alt="Logo" height="50px" />
            <Box w="60%">
              <Text fontSize="lg" fontWeight="semibold" color="#1D61AA" ml="10px">
                Driver With a Negative Balance
              </Text>
            </Box>
            {isLoading ? (
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
                alignSelf="center"
              />
            ) : (
              <Box
                width="40%"
                mt="8px"
                shadow="md"
                p="3"
                borderRadius="md"
                bg={isSubmitted ? "green.100" : "yellow.100"}
              >
                <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold" textAlign="center">
                  {isSubmitted ? "STATUS Completed" : "STATUS Incomplete"}
                </Text>
              </Box>
            )}
          </HStack>
        </Box>

        <Divider mt="10px" />

        {/* Driver Information Section */}
        <Box
          width="100%"
          mt="8px"
          background="blackAlpha.50"
          p="3"
          borderRadius="md"
        >
          <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
            DRIVER INFORMATION
          </Text>
          <Grid templateColumns="repeat(2, 1fr)" gap={5} mt="3">
            {data && (
              <>
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Driver Name
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data.Driver_Name || "N/A"}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Driver Code
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data.Driver_Code || "N/A"}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Division
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data.Division_x0020_ || 'N/A'}
                  </Text>
                </Box>
                <Box>

                  <Box>
                    <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                      Amount
                    </Text>
                    <Text
                      bg="darkred"
                      color="white"
                      fontSize="md"
                      fontWeight="semibold"
                      p="5px 10px"
                      borderRadius="md"
                      boxShadow="md"
                      display="inline-block"
                      border="1px solid darkred"
                    >
                      {data.Amount || 'N/A'}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Fleet Manager
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data.Fleet_Manager || "N/A"}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm" color="#1D61AA" fontWeight="semibold">
                    Driver Leader
                  </Text>
                  <Text fontSize="md" color="blackAlpha">
                    {data.Driver_Leader_Name || 'N/A'}
                  </Text>
                </Box>
              </>
            )}
          </Grid>
        </Box>

        {/* Action Item Section */}
        <Box
          width="100%"
          mt="10px"
          background="blackAlpha.10"
          p="3"
          borderRadius="md"
        >
          <VStack spacing={3} align="stretch">
            <Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
              ACTION ITEM
            </Text>
            {/* Input fields for questions */}
            <FormControl id="underDispatch">
              <FormLabel fontSize="sm" color="#1D61AA">
                Is the driver under dispatch?
              </FormLabel>
              <Select
                placeholder="Select option"
                value={underDispatch}
                onChange={(e) => setUnderDispatch(e.target.value)}
                size="sm"
                borderRadius="md"
                isDisabled={isSubmitted}

              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormControl>
            <FormControl id="upcomingTimeOff">
              <FormLabel fontSize="sm" color="#1D61AA">
                Does the driver have upcoming time off?
              </FormLabel>
              <Select
                placeholder="Select option"
                value={upcomingTimeOff}
                onChange={(e) => setUpcomingTimeOff(e.target.value)}
                size="sm"
                borderRadius="md"
                isDisabled={isSubmitted}

              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormControl>
            <FormControl id="skipPayAvailable">
              <FormLabel fontSize="sm" color="#1D61AA">
                Is there skip pay available?
              </FormLabel>
              <Select
                placeholder="Select option"
                value={skipPayAvailable}
                onChange={(e) => setSkipPayAvailable(e.target.value)}
                size="sm"
                borderRadius="md"
                isDisabled={isSubmitted}

              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormControl>
            <FormControl id="negativeReason">
              <FormLabel fontSize="sm" color="#1D61AA">
                Why is this driver negative?
              </FormLabel>
              <Textarea
                placeholder="Enter reason"
                value={negativeReason}
                onChange={(e) => setNegativeReason(e.target.value)}
                size="sm"
                borderRadius="md"
                isDisabled={isSubmitted}

              />
            </FormControl>

            {/* // Submit button */}
            <Button
              mt="10px"
              colorScheme="whatsapp"
              isLoading={isLoading}
              alignSelf="flex-end"
              width="100px"
              onClick={handleSubmit}
              isDisabled={isSubmitDisabled}
              opacity={isSubmitted ? 0.5 : 1}
            >
              Submit
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default NegativeBalance;
