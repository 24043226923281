import React from "react";
import {
  HStack,
  Text,
  VStack,
  Image,
  Heading,
  Card,
  CardBody,
  Stack,
} from "@chakra-ui/react";
import { userInfo } from "../../types/Types";
import { Link } from "react-router-dom";

const landingPageItems = [
  {
    title: "Action Items",
    description: "View and access all your action items",
    path: "/actionitems",
    image:
      "https://images.unsplash.com/photo-1634078111133-a1e12d6131b6?q=80&w=1860&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    Department: "All",
  },
  {
    title: "Fleet Foundations (Beta)",
    description: "Schedule and manage fleet foundations for your drivers",
    path: "/fleetfoundations",
    image:
      "https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3xMjA3fDB8MHxwaG90by1pYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    Department: "All",
  },
  {
    title: "Driver Board",
    description: "View and manage driver onboarding progress",
    path: "/driverboard",
    image:
      "https://images.unsplash.com/photo-1531482615713-2afd69097998?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3DwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    Department: "All",
  },
];

interface MenuItem {
  title: string;
  description: string;
  path: string;
  image: string;
}

const MenuItemCard: React.FC<MenuItem> = ({
  title,
  description,
  path,
  image,
}) => (
  <Link to={path}>
    <Card
      width="500px"
      borderRadius="3xl"
      background="whiteAlpha.100"
      cursor="pointer"
      shadow="sm"
      transition="all 0.5s"
      _hover={{
        background: "linear-gradient(to bottom, #ffffff, #eeeeee, #ffffff)",
        shadow: "xl",
      }}
    >
      <CardBody>
        <HStack spacing="20px" alignItems="flex-start" justifyContent="center">
          <Image src={image} alt={title} borderRadius="lg" width="50%" />

          <Stack mt="6" spacing="3" height="100%">
            <Heading size="small" color="blackAlpha.700">
              {title}
            </Heading>
            <Text fontSize="medium">{description}</Text>
          </Stack>
        </HStack>
      </CardBody>
    </Card>
  </Link>
);

const LandingPage: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  // Filter the landingPageItems based on userDepartment
  const filteredItems = landingPageItems.filter((item) => {
    return (
      item.Department.includes(userDepartment) ||
      userDepartment === "Strategy" ||
      item.Department.includes("All")
    );
  });

  return (
    <HStack
      height="calc(100vh - 55px)"
      width="100%"
      spacing="20px"
      padding="20px"
      overflow="hidden"
    >
      <VStack
        width="40%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        padding="20px"
        overflow="hidden"
      >
        <Image src="/core.png" alt="Dan Abramov" maxW="400px" />
        <Image src="/runner.png" alt="Dan Abramov" mt="50px" maxW="400px" />
        <Text size="lg" color="blackAlpha.600" mt="20px">
          ⚡ Powered by Process Improvement
        </Text>
      </VStack>
      <VStack
        width="60%"
        height="100%"
        borderRadius="xl"
        alignItems="center"
        justifyContent="center"
        padding="20px"
        overflowY="auto"
        overflowX="hidden"
      >
        <Stack>
          {filteredItems.map((menuItem, index) => (
            <MenuItemCard
              key={index}
              title={menuItem.title}
              description={menuItem.description}
              path={menuItem.path}
              image={menuItem.image}
            />
          ))}
        </Stack>
      </VStack>
    </HStack>
  );
};

export default LandingPage;
