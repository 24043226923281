import React, { useEffect, useState } from "react";
import { userInfo } from "../../types/Types";
import {
  Box,
  Text,
  VStack,
  useToast,
  HStack,
  Image,
  Grid,
  GridItem,
  Divider,
  Input,
  Select as DropDown,
  Switch,
  Flex,
  Button,
  Textarea,
} from "@chakra-ui/react";
import Select from "react-select";

// Import the API
import API from "../../api/API";
import { format } from "date-fns";

// import state list
import states from "../../lists/States";

interface TerminationFormProps {
  // Driver Information
  DriverCode?: string | null;
  DriverName?: string | null;
  DriverID?: string | null;
  HireDate?: string | null;
  BusinessSegment?: string | null;
  CostCenter?: string | null;
  DriverLeader?: string | null;
  DriverLeaderEmail?: string | null;

  // Termination Information
  TerminationReason?: string | null;
  TerminationDate?: string | null;
  LastDayWorked?: string | null;
  Comments?: string | null;

  // Location Information
  LocationAddress?: string | null;
  LocationCity?: string | null;
  LocationState?: string | null;
  LocationZIP?: string | null;

  // Equipment Information
  TrailerNumber?: string | null;
  TrailerLocationCity?: string | null;
  TrailerLocationState?: string | null;
  TractorNumber?: string | null;
  TractorLocationCity?: string | null;
  TractorLocationState?: string | null;

  // Check Boxes
  RecoverTrailer?: boolean;
  Rehire?: boolean;
  RecoverTractor?: boolean;
  DAC?: boolean;
  DACReason: string | null;
}

// Interface for Driver Data to be used in the drop down
interface DriverData {
  DriverName: string;
  DriverID: string;
  DriverCode: string;
  HireDate: string | null;
  DriverLeader: string | null;
  SupervisorEmail: string | null;
  BusinessSegment: string | null;
  CostCenter: string | null;
}

interface SelectOptions {
  label: string;
  value: string;
}

const TerminationForm: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const toast = useToast();

  // Support data for the form
  const [drivers, setDrivers] = useState<DriverData[]>([]);
  const [DropDownedDriver, setDropDownedDriver] = useState<SelectOptions[]>([]);

  //  Form Data
  const [formData, setFormData] = useState<TerminationFormProps>({
    DriverCode: null,
    DriverName: null,
    DriverID: null,
    HireDate: null,
    BusinessSegment: null,
    CostCenter: null,
    DriverLeader: null,
    DriverLeaderEmail: null,

    // Termination Information
    TerminationReason: null,
    TerminationDate: null,
    LastDayWorked: null,
    Comments: null,

    // Location Information
    LocationAddress: null,
    LocationCity: null,
    LocationState: null,
    LocationZIP: null,

    // Equipment Information
    TrailerNumber: null,
    TrailerLocationCity: null,
    TrailerLocationState: null,
    TractorNumber: null,
    TractorLocationCity: null,
    TractorLocationState: null,

    // Check Boxes
    RecoverTrailer: false,
    Rehire: false,
    RecoverTractor: false,
    DAC: false,
    DACReason: null,
  });

  // props for the label
  const labelProps = {
    fontSize: "xs",
    fontWeight: "semibold",
    color: "gray.500",
    ml: "2px",
  };

  // Fetch the drivers

  const fetchDrivers = async () => {
    const payload = {
      process: "driver info",
    };
    const response = await API(payload);

    if (response) {
      setDrivers(response);
      const options = response.map((driver: DriverData) => ({
        label: driver.DriverCode + " - " + driver.DriverName,
        value: driver.DriverCode,
      }));
      setDropDownedDriver(options);
    }
  };

  // Handle the select of a driver
  const handleSelectChange = (selectedOption: any) => {
    const selectedDriver = drivers.find(
      (driver) => driver.DriverCode === selectedOption.value
    );
    if (selectedDriver) {
      setFormData({
        ...formData,
        DriverCode: selectedDriver.DriverCode,
        DriverID: selectedDriver.DriverID,
        DriverName: selectedDriver.DriverName,
        HireDate: selectedDriver.HireDate
          ? format(new Date(selectedDriver.HireDate), "yyyy-MM-dd")
          : null,
        BusinessSegment: selectedDriver.BusinessSegment,
        CostCenter: selectedDriver.CostCenter,
        DriverLeader: selectedDriver.DriverLeader,
        DriverLeaderEmail: selectedDriver.SupervisorEmail,
      });
    }
  };

  // Function to submit form
  const submitForm = async () => {
    if (
      !formData.DriverCode ||
      !formData.DriverName ||
      !formData.TractorNumber ||
      !formData.TractorLocationCity ||
      !formData.TractorLocationState ||
      !formData.TerminationDate ||
      !formData.DriverLeaderEmail ||
      !formData.TerminationReason ||
      !formData.TerminationDate
    ) {
      toast({
        title: "Error",
        description:
          "Driver Information and Termination information are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitted(true);
    const payload = {
      process: "termination submit",
      userName,
      userEmail,
      formData,
    };
    const response = await API(payload);
    if (response) {
      setIsSubmitted(false);
      toast({
        title: "Form Submitted",
        description: "The form has been submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Reset form data
      setFormData({
        DriverCode: null,
        DriverName: null,
        DriverID: null,
        HireDate: null,
        BusinessSegment: null,
        CostCenter: null,
        DriverLeader: null,
        DriverLeaderEmail: null,
        TerminationReason: null,
        TerminationDate: null,
        LastDayWorked: null,
        Comments: null,
        LocationAddress: null,
        LocationCity: null,
        LocationState: null,
        LocationZIP: null,
        TrailerNumber: null,
        TrailerLocationCity: null,
        TrailerLocationState: null,
        TractorNumber: null,
        TractorLocationCity: null,
        TractorLocationState: null,
        RecoverTrailer: false,
        Rehire: false,
        RecoverTractor: false,
        DAC: false,
        DACReason: null,
      });
    }
  };

  // useEffect for initial render
  useEffect(() => {
    document.title = "Termination Form";
    fetchDrivers();
  }, []);

  return (
    <Box
      height="calc(100% - 90px)"
      m="2vh"
      justifyContent="center"
      alignItems="flex-start"
      display="flex"
    >
      <VStack
        width="1200px"
        height="100%"
        borderRadius="3xl"
        background="whiteAlpha.900"
        shadow="md"
        padding="20px"
        bg="white"
        overflow="auto"
      >
        <Box width="100%">
          <HStack height="40px">
            <Image src="/logo.png" alt="Logo" height="100%" />
            <Text fontSize="lg" fontWeight="semibold" color="#1D61AA" ml="10px">
              Driver Termination Form
            </Text>
          </HStack>
          <Divider mt="10px" />
        </Box>

        <Grid
          templateColumns="repeat(5, 1fr)"
          gap={4}
          w="100%"
          h="100%"
          mt="50px"
        >
          <GridItem colSpan={2}>
            <Text {...labelProps} mb="6px">
              Driver Code *
            </Text>
            <Select
              maxMenuHeight={100}
              options={DropDownedDriver}
              isSearchable
              placeholder="Search by Code"
              noOptionsMessage={() => "No matches found"}
              onChange={handleSelectChange}
              value={
                DropDownedDriver.find(
                  (option) => option.value === formData.DriverCode
                ) || null
              }
            />
          </GridItem>
          {/* Driver Name */}
          <GridItem>
            <Text {...labelProps}>Driver Name *</Text>
            <Input
              mt="5px"
              value={formData.DriverName || ""}
              onChange={(e) =>
                setFormData({ ...formData, DriverName: e.target.value })
              }
            />
          </GridItem>

          {/* Hire Date */}
          <GridItem>
            <Text {...labelProps}>Hire Date *</Text>
            <Input
              type="date"
              mt="5px"
              value={formData.HireDate || ""}
              onChange={(e) =>
                setFormData({ ...formData, HireDate: e.target.value })
              }
            />
          </GridItem>
          {/* Business Segment */}
          <GridItem>
            <Text {...labelProps}>Business Segment *</Text>
            <Input
              mt="5px"
              value={formData.BusinessSegment || ""}
              onChange={(e) =>
                setFormData({ ...formData, BusinessSegment: e.target.value })
              }
            />
          </GridItem>
          {/* Cost Center */}
          <GridItem>
            <Text {...labelProps}>Cost Center *</Text>
            <Input
              mt="5px"
              value={formData.CostCenter || ""}
              onChange={(e) =>
                setFormData({ ...formData, CostCenter: e.target.value })
              }
            />
          </GridItem>
          {/* Driver Leader */}
          <GridItem>
            <Text {...labelProps}>Driver Leader *</Text>
            <Input
              mt="5px"
              value={formData.DriverLeader || ""}
              onChange={(e) =>
                setFormData({ ...formData, DriverLeader: e.target.value })
              }
            />
          </GridItem>
          {/* Driver Leader Email */}
          <GridItem>
            <Text {...labelProps}>Driver Leader Email *</Text>
            <Input
              mt="5px"
              value={formData.DriverLeaderEmail || ""}
              onChange={(e) =>
                setFormData({ ...formData, DriverLeaderEmail: e.target.value })
              }
            />
          </GridItem>
          {/* Grid item spanning 3 containing a divider */}
          <GridItem colSpan={5} mb="10px" mt="10px">
            <Divider />
          </GridItem>
          {/* Termination Reason, Termination Date, Last Day worked */}
          <GridItem colSpan={2}>
            <Text {...labelProps}>Termination Reason *</Text>
            <DropDown
              mt="5px"
              placeholder="Select a reason"
              value={formData.TerminationReason || ""}
              onChange={(e) =>
                setFormData({ ...formData, TerminationReason: e.target.value })
              }
            >
              <option value="Reassignment - Change Driver Code">
                Reassignment - Change Driver Code
              </option>
              <option value="Medical Issues - Quit">
                Medical Issues - Quit
              </option>
              <option value="Operations Termination - Released">
                Operations Termination - Released
              </option>
              <option value="Safety Termination - Release">
                Safety Termination - Release
              </option>
              <option value="Termed During Orientation">
                Termed During Orientation
              </option>
              <option value="Termed During Training">
                Termed During Training
              </option>
              <option value="Driver Left Voluntarily">
                Driver Left Voluntarily
              </option>
              <option value="T-Comp">T-Comp</option>
            </DropDown>
          </GridItem>
          {/* Termination Date */}
          <GridItem>
            <Text {...labelProps}>Termination Date *</Text>
            <Input
              type="date"
              mt="5px"
              value={formData.TerminationDate || ""}
              onChange={(e) =>
                setFormData({ ...formData, TerminationDate: e.target.value })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Last Day Worked</Text>
            <Input
              type="date"
              mt="5px"
              value={formData.LastDayWorked || ""}
              onChange={(e) =>
                setFormData({ ...formData, LastDayWorked: e.target.value })
              }
            />
          </GridItem>
          {/* Divider */}
          <GridItem colSpan={5} mb="10px" mt="10px">
            <Divider />
          </GridItem>
          {/* Current Location Info */}

          <GridItem colSpan={2}>
            <Text {...labelProps}>Location Address</Text>
            <Input
              mt="5px"
              value={formData.LocationAddress || ""}
              onChange={(e) =>
                setFormData({ ...formData, LocationAddress: e.target.value })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Location City</Text>
            <Input
              mt="5px"
              value={formData.LocationCity || ""}
              onChange={(e) =>
                setFormData({ ...formData, LocationCity: e.target.value })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Location State</Text>
            <DropDown
              mt="5px"
              placeholder="Select a state"
              value={formData.LocationState || ""}
              onChange={(e) =>
                setFormData({ ...formData, LocationState: e.target.value })
              }
            >
              {states.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </DropDown>
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Location ZIP</Text>
            <Input
              mt="5px"
              value={formData.LocationZIP || ""}
              onChange={(e) =>
                setFormData({ ...formData, LocationZIP: e.target.value })
              }
            />
          </GridItem>
          {/* Divider */}
          <GridItem colSpan={5} mb="10px" mt="10px">
            <Divider />
          </GridItem>
          {/* Equipment Info */}
          <GridItem colSpan={2}>
            <Text {...labelProps}>Trailer Number</Text>
            <Input
              mt="5px"
              value={formData.TrailerNumber || ""}
              onChange={(e) =>
                setFormData({ ...formData, TrailerNumber: e.target.value })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Trailer Location City</Text>
            <Input
              mt="5px"
              value={formData.TrailerLocationCity || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  TrailerLocationCity: e.target.value,
                })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Trailer Location State</Text>
            <DropDown
              mt="5px"
              placeholder="Select a state"
              value={formData.TrailerLocationState || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  TrailerLocationState: e.target.value,
                })
              }
            >
              {states.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </DropDown>
          </GridItem>
          {/* Empty grid item */}
          <GridItem />
          <GridItem colSpan={2}>
            <Text {...labelProps}>Tractor Number *</Text>
            <Input
              mt="5px"
              value={formData.TractorNumber || ""}
              onChange={(e) =>
                setFormData({ ...formData, TractorNumber: e.target.value })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Tractor Location City *</Text>
            <Input
              mt="5px"
              value={formData.TractorLocationCity || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  TractorLocationCity: e.target.value,
                })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Tractor Location State *</Text>
            <DropDown
              mt="5px"
              placeholder="Select a state"
              value={formData.TractorLocationState || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  TractorLocationState: e.target.value,
                })
              }
            >
              {states.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </DropDown>
          </GridItem>
          {/* Divider */}
          <GridItem colSpan={5} mb="10px" mt="10px">
            <Divider />
          </GridItem>
          {/* Check Boxes, all switches */}
          <GridItem>
            <Text {...labelProps}>Recover Trailer</Text>
            <Switch
              mt="5px"
              colorScheme="blue"
              isChecked={formData.RecoverTrailer}
              onChange={(e) =>
                setFormData({ ...formData, RecoverTrailer: e.target.checked })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Recover Tractor</Text>
            <Switch
              mt="5px"
              colorScheme="blue"
              isChecked={formData.RecoverTractor}
              onChange={(e) =>
                setFormData({ ...formData, RecoverTractor: e.target.checked })
              }
            />
          </GridItem>
          <GridItem>
            <Text {...labelProps}>Rehire</Text>
            <Switch
              mt="5px"
              colorScheme="blue"
              isChecked={formData.Rehire}
              onChange={(e) =>
                setFormData({ ...formData, Rehire: e.target.checked })
              }
            />
          </GridItem>

          <GridItem>
            <Text {...labelProps}>DAC</Text>
            <Switch
              mt="5px"
              colorScheme="blue"
              isChecked={formData.DAC}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  DAC: e.target.checked,
                  DACReason: null,
                })
              }
            />
          </GridItem>
          {/* If Dac is true then visible otherwise no */}

          <GridItem>
            <Text {...labelProps}>DAC Reason</Text>
            <DropDown
              isDisabled={!formData.DAC}
              mt="5px"
              placeholder="Select DAC reason"
              value={formData.DACReason || ""}
              onChange={(e) =>
                setFormData({ ...formData, DACReason: e.target.value })
              }
            >
              <option value="Quit Under Dispatch">Quit Under Dispatch</option>
              <option value="Abandonment">Abandonment</option>
              <option value="Both">Both</option>
            </DropDown>
          </GridItem>

          {/* Divider */}
          <GridItem colSpan={5}>
            <Text {...labelProps}>Comments</Text>
            <Textarea
              mt="5px"
              value={formData.Comments || ""}
              onChange={(e) =>
                setFormData({ ...formData, Comments: e.target.value })
              }
            />
          </GridItem>
        </Grid>
        <Flex w="100%" justifyContent="flex-end" mt="50px">
          <Button size="lg" onClick={submitForm} isLoading={isSubmitted}>
            Submit
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default TerminationForm;
