import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { driverBoardTypes } from "../../../types/Types";

const AccordianItems = [
  {
    index: 1,
    day: "Monday",
  },
  {
    index: 2,
    day: "Tuesday",
  },
  {
    index: 3,
    day: "Wednesday",
  },
  {
    index: 4,
    day: "Thursday",
  },
  {
    index: 5,
    day: "Friday",
  },
  {
    index: 6,
    day: "Saturday",
  },
  {
    index: 7,
    day: "Sunday",
  },
];

const headerProps = {
  color: "#1D61AA",
};

const tableHeaders = [
  {
    key: "Registration",
    label: "Registration",
    dataType: "string",
  },
  {
    key: "OrientationLocation",
    label: "Location",
    dataType: "string",
  },
  { key: "DriverName", label: "Name", dataType: "string" },
  { key: "Status", label: "Status", dataType: "string" },

  {
    key: "DriverCode",
    label: "Driver Code",
    dataType: "string",
  },
  {
    key: "Phone",
    label: "Phone",
    dataType: "string",
  },
  {
    key: "Email",
    label: "Email",
    dataType: "string",
  },
  {
    key: "Source",
    label: "Source",
    dataType: "string",
  },
  {
    key: "CostCenter",
    label: "Cost Center",
    dataType: "string",
  },
  {
    key: "DriverType",
    label: "Type",
    dataType: "string",
  },
  {
    key: "Division",
    label: "Division",
    dataType: "string",
  },
  {
    key: "DriverLeader",
    label: "Driver Leader",
    dataType: "string",
  },

  {
    key: "BackgroundStatus",
    label: "Background",
    dataType: "string",
  },
  {
    key: "Compliance",
    label: "Compliance ",
    dataType: "string",
  },
  {
    key: "TruckNumber",
    label: "Truck Number",
    dataType: "string",
  },
  {
    key: "Bonus",
    label: "Bonus",
    dataType: "string",
  },
  {
    key: "Notes",
    label: "Note",
    dataType: "string",
  },
  {
    key: "LatestUpdate",
    label: "Latest Update",
    dataType: "string",
  },
];

interface Props {
  data: driverBoardTypes[];
  selectedID: number | null;
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  handleSort: (column: keyof driverBoardTypes) => void;
  handleSelect: (id: number) => void;
  selectedStatus: "Active" | "Withdrawn";
}

const OrientationDataTable: React.FC<Props> = ({
  data,
  selectedID,
  sortState,
  handleSort,
  handleSelect,
  selectedStatus,
}) => {
  return (
    <Box
      h="calc(100vh - 145px)"
      mt="5px"
      width="100%"
      pl="5px"
      pr="5px"
      pb="5px"
    >
      <Box
        w="100%"
        height="100%"
        background="whiteAlpha.900"
        blur="10px"
        borderRadius="xl"
        shadow="md"
        overflow="auto"
        p="5px"
      >
        <Accordion
          minWidth="3600px"
          allowMultiple
          defaultIndex={Array.from(
            { length: AccordianItems.length },
            (_, i) => i
          )}
        >
          {AccordianItems.sort((a, b) => a.index - b.index).map((item) => {
            const itemCount = data.filter((row) => {
              if (selectedStatus === "Active") {
                return (
                  row.WeekDay === item.day &&
                  row.Status !== "Withdrawn" &&
                  row.Status !== "New" &&
                  row.Status !== "Approved"
                );
              } else if (selectedStatus === "Withdrawn") {
                return row.WeekDay === item.day && row.Status === "Withdrawn";
              }
              return false;
            }).length;

            if (itemCount > 0) {
              return (
                <AccordionItem key={item.index}>
                  <h2>
                    <AccordionButton
                      bg="blackAlpha.50"
                      _hover={{ bg: "blackAlpha.50" }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight="semibold"
                        color="#EE7700"
                      >
                        {`${item.day} (${itemCount})`}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          {tableHeaders.map((header) => (
                            <Th
                              fontSize="xs"
                              cursor="pointer"
                              w={
                                header.key === "Notes" ||
                                header.key === "LatestUpdate"
                                  ? "900px"
                                  : "220px"
                              }
                              key={header.key}
                              {...headerProps}
                              onClick={() =>
                                handleSort(header.key as keyof driverBoardTypes)
                              }
                            >
                              {header.label}
                              <span
                                style={{
                                  visibility:
                                    sortState.column === header.key
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                {sortState.direction === "asc" ? "↑" : "↓"}
                              </span>
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data
                          .filter((row) => {
                            if (selectedStatus === "Active") {
                              return (
                                row.WeekDay === item.day &&
                                row.Status !== "Withdrawn" &&
                                row.Status !== "New" &&
                                row.Status !== "Approved"
                              );
                            } else if (selectedStatus === "Withdrawn") {
                              return (
                                row.WeekDay === item.day &&
                                row.Status === "Withdrawn"
                              );
                            }
                            return false;
                          })
                          .map((row) => (
                            <Tr
                              key={row.ID ? row.ID : undefined}
                              style={{
                                userSelect: "none",
                                cursor: "pointer",
                              }}
                              _hover={{
                                bg:
                                  row.Status === "TDO" ||
                                  row.Status === "No Show"
                                    ? "red.200"
                                    : row.Status === "Seated" ||
                                      row.Status === "Upgraded"
                                    ? "green.200"
                                    : row.Status === "Unseated"
                                    ? "yellow.200"
                                    : "blackAlpha.200",
                              }}
                              bg={
                                selectedID === row.ID
                                  ? row.Status === "TDO" ||
                                    row.Status === "No Show"
                                    ? "red.300"
                                    : row.Status === "Seated" ||
                                      row.Status === "Upgraded"
                                    ? "green.300"
                                    : row.Status === "Unseated"
                                    ? "yellow.300"
                                    : "blackAlpha.200"
                                  : row.Status === "TDO" ||
                                    row.Status === "No Show"
                                  ? "red.100"
                                  : row.Status === "Seated" ||
                                    row.Status === "Upgraded"
                                  ? "green.100"
                                  : row.Status === "Unseated"
                                  ? "yellow.100"
                                  : "white"
                              }
                              onClick={() => row.ID && handleSelect(row.ID)}
                              fontWeight="normal"
                            >
                              <Td>{row.Registration}</Td>
                              <Td>{row.OrientationLocation}</Td>
                              <Td>{row.DriverName}</Td>
                              <Td>
                                {row.Status === "TDO" ||
                                row.Status === "No Show"
                                  ? "🔴"
                                  : row.Status === "Seated" ||
                                    row.Status === "Upgraded"
                                  ? "🟢"
                                  : "🟡"}
                                {row.Status}
                              </Td>
                              <Td>
                                {row.DriverCodeRegistered
                                  ? "🟢" + row.DriverCode
                                  : row.DriverCode
                                  ? "🟡" + row.DriverCode
                                  : "🟡 Pending"}
                              </Td>
                              <Td>{row.Phone}</Td>
                              <Td>{row.Email}</Td>
                              <Td>{row.Source || ""}</Td>
                              <Td>{row.CostCenter || "Missing"}</Td>
                              <Td>{row.DriverType}</Td>
                              <Td>{row.Division}</Td>
                              <Td>{row.DriverLeader || " Pending"}</Td>
                              <Td>{row.BackgroundStatus || "Not Started"}</Td>
                              <Td>
                                {row.ComplianceStatus === "Yes"
                                  ? "🟢"
                                  : row.ComplianceStatus === "No"
                                  ? "🔴"
                                  : row.ComplianceStatus || "Pending"}
                              </Td>

                              <Td>{row.TruckNumber || "Pending"}</Td>
                              <Td>{row.Bonus ? "Completed" : "Pending"}</Td>
                              <Td>{row.Note}</Td>
                              <Td>{row.LatestUpdate}</Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </AccordionPanel>
                </AccordionItem>
              );
            }
            return null;
          })}
        </Accordion>
      </Box>
    </Box>
  );
};

export default OrientationDataTable;
