import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Input,
  Text,
  Spinner,
  useClipboard,
  Button,
  Icon,
  Stack,
  useToast,
  IconButton,
  Image,
} from "@chakra-ui/react";
import API from "../../api/API";
import { userInfo } from "../../types/Types";
import { motion } from "framer-motion";
import { CopyIcon } from "@chakra-ui/icons";
import { GrPowerReset } from "react-icons/gr";

const CustomerLookUp: React.FC<userInfo> = ({ userEmail }) => {
  const [recipientString, setRecipientString] = useState<string>("");
  const [ccString, setCCString] = useState<string>("");
  const { hasCopied: recipientHasCopied, onCopy: onRecipientCopy } =
    useClipboard(recipientString);
  const { hasCopied: ccHasCopied, onCopy: onCcCopy } = useClipboard(ccString);
  const [currentStore, setCurrentStore] = useState<string>("");
  const toast = useToast();
  const [input, setInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await queryAndProcess();
    }
  };

  const queryAndProcess = async () => {
    try {
      setCurrentStore(input);
      setLoading(true);
      setRecipientString("");
      const payload = {
        process: "getCustomerInfo",
        body: input,
      };

      const response = await API(payload);
      if (
        response &&
        response.Table1 &&
        response.Table1[0] &&
        Object.keys(response.Table1[0]).length > 0
      ) {
        const STORE = response?.Table1[0].STORE;
        const DISTRICT = response?.Table1[0].DISTRICT;
        const REGION = response?.Table1[0].REGION;

        const storeString = STORE.toString();
        const districtString = DISTRICT.toString();

        const stlength = storeString.length;
        const districtStringLength = districtString.length;

        const zerosToPad = 5 - stlength;
        const zerosToPad2 = 3 - districtStringLength;

        const finalStringST = `ST${"0".repeat(
          zerosToPad
        )}${storeString}@dollartree.com`;

        const finalStringSM = `SM${"0".repeat(
          zerosToPad
        )}${storeString}@dollartree.com`;

        const finalStringDM = `DM${"0".repeat(
          zerosToPad2
        )}${districtString}@dollartree.com`;

        const finalStringRegion = `Region${REGION}@dollartree.com`;

        setRecipientString(
          `${finalStringST.toLowerCase()}; ${finalStringSM.toLowerCase()}; ${finalStringDM.toLowerCase()}; dc6delivery@dollartree.com`
        );
        setCCString(
          `${finalStringRegion.toLowerCase()}; mhuth@familydollar.com; dollartreepa@hirschbach.com`
        );
        setLoading(false);
      } else {
        toast({
          title: "No recipient found",
          description:
            "Please check the store number, or contact Process Improvement for assistance.",
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("API request failed:", error);
    } finally {
      setInput("");
    }
  };

  useEffect(() => {
    document.title = "Customer Lookup";
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Box
        height="calc(100vh - 90px)"
        m="2vh"
        justifyContent="center"
        display="flex"
      >
        <Box
          display="flex"
          justifyContent="center"
          height="70vh"
          width="100%"
          borderRadius="xl"
          background="whiteAlpha.900"
          shadow="md"
          padding="20px"
          overflow="auto"
        >
          <VStack display="flex" width="80%">
            <Image
              src="/dollar.png"
              alt="Dollar Tree Logo"
              height="100px"
              width="200px"
            />
            <HStack
              width="100%"
              justifyContent="center"
              alignItems="center"
              display="flex"
              pl="45px"
            >
              <Text fontSize="2xl" color="blackAlpha.700" fontWeight="semibold">
                Customer Lookup
              </Text>
              <IconButton
                aria-label="Refresh"
                icon={<GrPowerReset fontSize="20px" color="gray" />}
                borderRadius="full"
                onClick={() => {
                  setInput("");
                  setRecipientString("");
                }}
                size="md"
                variant={"ghost"}
              />
            </HStack>
            <Box height="50%" width="100%" mt="50px">
              <Stack spacing={4}>
                <Input
                  width="100%"
                  placeholder="Store Number"
                  variant="flushed"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleEnter}
                  focusBorderColor="orange.400"
                  isDisabled={loading}
                  maxLength={10}
                />
              </Stack>
              <VStack
                marginTop="5%"
                height="100%"
                display="flex"
                width="100%"
                alignItems="flex-start"
              >
                <Text fontSize="md" fontWeight="normal" ml="5px">
                  {recipientString
                    ? "Recipient for " + currentStore
                    : "Recipient"}
                </Text>
                <HStack width="100%">
                  <Box
                    width="100%"
                    background="blackAlpha.50"
                    padding="10px"
                    minHeight="40px"
                    textColor={recipientString ? "black" : "blackAlpha.700"}
                    shadow="xs"
                    display="flex"
                    alignItems="flex"
                    justifyContent="space-between"
                    borderRadius="md"
                  >
                    <div>
                      {loading ? (
                        <Spinner
                          thickness="2px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="md"
                        />
                      ) : (
                        recipientString ||
                        "Recipient will appear here after you type in a store number and press ENTER"
                      )}
                    </div>
                    <Button
                      onClick={() => {
                        onRecipientCopy();
                      }}
                      ml={2}
                      colorScheme="whatsapp"
                      fontWeight="normal"
                      size="sm"
                      isDisabled={!recipientString}
                    >
                      <Icon as={CopyIcon} mr="5px" />
                      {recipientHasCopied ? "Copied" : "Copy"}
                    </Button>
                  </Box>
                </HStack>
                <Text fontSize="md" fontWeight="normal" ml="5px">
                  {recipientString ? "CC for " + currentStore : "CC"}
                </Text>
                <Box
                  width="100%"
                  background="blackAlpha.50"
                  padding="10px"
                  minHeight="40px"
                  textColor={recipientString ? "black" : "blackAlpha.700"}
                  shadow="xs"
                  display="flex"
                  alignItems="flex"
                  justifyContent="space-between"
                  borderRadius="md"
                >
                  <div>
                    {loading ? (
                      <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                      />
                    ) : (
                      ccString ||
                      "CC will appear here after you type in a store number and press ENTER"
                    )}
                  </div>
                  <Button
                    onClick={() => {
                      onCcCopy();
                    }}
                    ml={2}
                    colorScheme="whatsapp"
                    fontWeight="normal"
                    size="sm"
                    isDisabled={!ccString}
                  >
                    <Icon as={CopyIcon} mr="5px" />
                    {ccHasCopied ? "Copied" : "Copy"}
                  </Button>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Box>
    </motion.div>
  );
};

export default CustomerLookUp;
