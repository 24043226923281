import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";
import {
  Text,
  VStack,
  Heading,
  Box,
  Stack,
  Container,
  HStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Checkbox,
  CheckboxGroup,
  Textarea,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import TypeIt from "typeit-react";
import { userInfo } from "../../types/Types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { startOfYear, startOfMonth } from "date-fns";

// Types for the Menu Items
interface MenuItem {
  title: string;
  description: string;
  link: string;
}

// Menu Items
const menuItems: MenuItem[] = [
  {
    title: "Sign-up",
    description: "Register a driver for a fleet foundations",
    link: "/fleetfoundations/signup",
  },
  {
    title: "Manage",
    description: "Review and manage fleet foundations",
    link: "/fleetfoundations/manage",
  },
  {
    title: "Schedule",
    description: "View scheduled Fleet Foundations",
    link: "/fleetfoundations/scheduled",
  },
];

// Fleet Foundations Options
const options = [
  {
    Name: "Safety/Driver Skills",
    items: [
      "Close Quarters Backing",
      "Following Distance",
      "Speeding",
      "Turning Radius",
      "Distracted Driving",
    ],
  },
  {
    Name: "Truck/Trailers",
    items: [
      "Reefer Operation",
      "Coupling/Uncoupling",
      "Sliding Tandems",
      "Fifth Wheel",
    ],
  },
  {
    Name: "Trip Planning",
    items: ["Atlas Review", "Hours of Service"],
  },
  {
    Name: "Tablet Review",
    items: ["Accepting Loads", "Certifying Logs", "Hours of Service"],
  },
  {
    ID: 5,
    Name: "Payroll",
    items: [
      "How to Interpret Settlements",
      "Impact of Advances",
      "Reimbursements",
      "Paperwork Scanning",
    ],
  },
];

// Drivercode Status
type DrivercodeStatus = "UnConfirm" | "Available" | "Unavailable";

// Drivercode States
const drivercodeStates = {
  UnConfirm: "🟡 Unconfirm",
  Available: "🟢 Available",
  Unavailable: "🔴 Unavailable",
};

// Main Function
const FleetFoundations: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [drivercode, setDrivercode] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedOptionsString, setSelectedOptionsString] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [drivercodeStatus, setDrivercodeStatus] =
    useState<DrivercodeStatus>("UnConfirm");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const calculateDynamicExclusion = () => {
    const currentYear = new Date().getFullYear();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const holidays = [
      // New Year's Day (January 1st)
      startOfYear(new Date(currentYear)),

      // Memorial Day (last Monday in May)
      // Initially set to the last day of May
      new Date(currentYear, 4, 31),
    ];

    // Find the last Monday in May for Memorial Day
    while (holidays[1].getDay() !== 1) {
      holidays[1].setDate(holidays[1].getDate() - 1);
    }

    // Independence Day (July 4th)
    holidays.push(new Date(currentYear, 6, 4));

    // Labor Day (first Monday in September)
    holidays.push(startOfMonth(new Date(currentYear, 8)));

    // Find the first Monday in September for Labor Day
    while (holidays[3].getDay() !== 1) {
      holidays[3].setDate(holidays[3].getDate() + 1);
    }

    // Thanksgiving Day (fourth Thursday in November)
    holidays.push(startOfMonth(new Date(currentYear, 10)));

    let count = 0;
    while (count < 3) {
      while (holidays[4].getDay() !== 4) {
        holidays[4].setDate(holidays[4].getDate() + 1);
      }
      holidays[4].setDate(holidays[4].getDate() + 7);
      count++;
    }
    // Set to the fourth Thursday of November
    if (holidays[4].getDay() !== 4) {
      holidays[4].setDate(holidays[4].getDate() + 1);
    }

    // Christmas Day (December 25th)
    holidays.push(new Date(currentYear, 11, 25));

    // Add today's date to the holidays array
    holidays.push(today);

    return holidays;
  };

  // Handle Menu Item Click
  const handleMenuItemClick = (menuItem: MenuItem) => {
    if (menuItem.title === "Sign-up") {
      onOpen();
      setDrivercode("");
      setDrivercodeStatus("UnConfirm");
      setSelectedOptions([]);
      setSelectedOptionsString("");
      setDate("");
    } else {
      navigate(menuItem.link);
    }
  };

  // Update the Tab Title
  useEffect(() => {
    document.title = "Fleet Foundations";
  });

  // Function to handle option selection
  const handleOptionChange = (selected: string[]) => {
    const sortedOptions = [...selected].sort();
    setSelectedOptions(sortedOptions);
    setSelectedOptionsString(sortedOptions.join(", "));
  };

  // Function to handle driver code input change
  const handleDriverCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newDriverCode = event.target.value.toUpperCase();
    setDrivercode(newDriverCode);
    setDrivercodeStatus("UnConfirm");
    if (newDriverCode.length >= 4) {
      const timeoutId = setTimeout(async () => {
        try {
          const payload = {
            process: "CheckFleetFoundations",
            drivercode: newDriverCode,
          };
          const response = await API(payload);

          if (Object.keys(response).length === 0) {
            setDrivercodeStatus("Unavailable");
          } else {
            setDrivercodeStatus("Available");
          }
        } catch (error) {
          setDrivercodeStatus("Unavailable");
        }
      }, 500);

      setTypingTimeout(timeoutId);
    }
  };

  // Function to send the API request to register fleet foundations
  const registerFleetFoundations = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        drivercode: drivercode,
        options: selectedOptionsString,
        user: userName,
        userEmail: userEmail,
        date: date,
        note: additionalInfo,
        process: "RegisterFleetFoundations",
      };

      const response = await API(payload);
      if (response.success === "true") {
        toast({
          title: "Success",
          description: "Fleet Foundations registration submitted successfully!",
          status: "success",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: "API Error",
          description: "An error occurred while registering Fleet Foundations.",
          status: "error",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "API Error",
        description: "An error occurred while registering Fleet Foundations.",
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle Submission
  const handleSubmit = () => {
    const errors = [];
    if (drivercodeStatus !== "Available") {
      errors.push("Driver code is invalid or unavailable");
    }
    if (!date || new Date(date) <= new Date()) {
      errors.push("A date is not selected or is in the past.");
    }
    if (selectedOptions.length === 0) {
      errors.push("One fleet foundations option needs to be selected.");
    }

    if (errors.length > 0) {
      const errorList = (
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );

      toast({
        title: "Validation",
        description: errorList,
        status: "warning",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    } else {
      registerFleetFoundations();
    }
  };

  return (
    <>
      <VStack height="calc(100vh - 55px)" width="100%">
        <Container maxW="5xl">
          <Stack textAlign="center" align="center" spacing="10">
            <TypeIt options={{ cursor: false }}>
              <Heading fontWeight="semibold" fontSize="6xl">
                Best in{" "}
                <Text as="span" color="#EE7700">
                  Class
                </Text>
              </Heading>
            </TypeIt>
            <Box>
              <Text color="gray.800" fontSize="lg">
                We are committed to providing the most reliable and efficient
                transportation solutions, empowering drivers to excel in their
                operations, and delivering exceptional services to our valued
                customers.
              </Text>
            </Box>
          </Stack>
        </Container>
        <HStack
          width="100%"
          height="100%"
          alignItems="flex-start"
          justifyContent="center"
          mt="10"
        >
          {menuItems.map((menuItem, index) => (
            <Flex
              key={index}
              direction="column"
              align="center"
              justify="center"
              background="blackAlpha.50"
              borderRadius="2xl"
              width="400px"
              height="200px"
              margin="1rem"
              shadow="md"
              transition="all 0.5s"
              _hover={{
                boxShadow: "xl",
                cursor: "pointer",
                background: "whiteAlpha.100",
                blur: "2px",
              }}
              onClick={() => handleMenuItemClick(menuItem)}
            >
              <Text fontSize="2xl" fontWeight="semibold">
                {menuItem.title}
              </Text>
              <Text fontSize="lg" color="gray.600" mt="10px">
                {menuItem.description}
              </Text>
            </Flex>
          ))}
        </HStack>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register Fleet Foundations</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              width="100%"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Text fontWeight="semibold" color="blackAlpha.700">
                1. Begin by providing the driver's code
              </Text>
              <HStack width="100%" mt="5px">
                <Input
                  placeholder="Driver Code"
                  value={drivercode}
                  variant="flushed"
                  width="500px"
                  maxLength={6}
                  onChange={handleDriverCodeChange}
                />
                <Text fontSize="sm" ml="5px" fontWeight="semibold">
                  {drivercodeStates[drivercodeStatus]}
                </Text>
              </HStack>
              <Text fontWeight="semibold" color="blackAlpha.700" mt="20px">
                2. Provide an ETA to the East Dubuque, IL terminal (This is not
                the set appointment)
              </Text>
              <DatePicker
                customInput={<Input variant="flushed" width="500px" mt="5px" />}
                selected={date ? new Date(date) : null}
                onChange={(date) => {
                  if (date) {
                    setDate(date.toISOString());
                  } else {
                    setDate("");
                  }
                }}
                minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                }
                dateFormat="MM/dd/yyyy"
                placeholderText="Select a date"
                excludeDates={calculateDynamicExclusion()}
              />
              <Text fontWeight="semibold" color="blackAlpha.700" mt="20px">
                3. Select relevant Fleet Foundations
              </Text>
              <CheckboxGroup
                colorScheme="green"
                defaultValue={[]}
                value={selectedOptions}
                onChange={handleOptionChange}
              >
                <Stack spacing={[2, 3]} direction={["column", "row"]}>
                  {options.map((option, index) => (
                    <label
                      key={index}
                      style={{
                        width: "100%",
                        display: "block",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        key={index}
                        p="4"
                        borderRadius="xl"
                        boxShadow="md"
                        width="210px"
                        height="100%"
                        transition="all 0.3s"
                        _hover={{ shadow: "2xl", cursor: "pointer" }}
                      >
                        <Checkbox
                          value={option.Name}
                          fontWeight="semibold"
                          color="blackAlpha.800"
                        >
                          {option.Name}
                        </Checkbox>
                        <ul
                          style={{
                            marginTop: "20px",
                            paddingLeft: "20px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          {option.items.map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      </Box>
                    </label>
                  ))}
                </Stack>
              </CheckboxGroup>
              <Text fontWeight="semibold" color="blackAlpha.700" mt="20px">
                4. Additional information
              </Text>
              <Textarea
                variant="flushed"
                mt="5px"
                maxLength={200}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setAdditionalInfo(e.target.value);
                  }
                }}
              />
              <ModalFooter alignItems="flex-end" width="100%">
                <Button onClick={handleSubmit} isDisabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </ModalFooter>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FleetFoundations;
