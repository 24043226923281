import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const apiKey = process.env.REACT_APP_FIREBASE_KEY;
const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "the-hub-1bcac.firebaseapp.com",
  projectId: "the-hub-1bcac",
  storageBucket: "the-hub-1bcac.appspot.com",
  messagingSenderId: "701395245520",
  appId: "1:701395245520:web:343d26ef4854b7736ac1d5",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

function requestNotificationPermission() {
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, { vapidKey: vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            return currentToken;
          } else {
            throw new Error("No registration token available.");
          }
        }
      );
    } else {
      throw new Error("Notification permission denied.");
    }
  });
}

export { requestNotificationPermission };
