import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Text,
  Divider,
  VStack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";

// Import Types for the Driver Board
import { driverBoardTypes } from "../../../types/Types";

// Import Information and Props
import {
  driverInfo,
  companyInfo,
  orientationInfo,
  steps,
  cardProps,
  cardHeaderProps,
  gridProps,
  labelProps,
  dataProps,
} from "./GeneralFields";

// Import the API
import { apiWrite } from "../../../api/driverBoardAPI";

// import Functions
import { formatDateValue } from "./generalFunctions";

// Props for the component
interface ProfileType {
  profileData: driverBoardTypes | null;
  userEmail: string;
  refreshProfileData: () => void;
  onRefresh: () => void;
  documentCount: number;
  isApprover: boolean;
  isRecruiter: boolean;
}

const DriverInfo = ({
  profileData,
  refreshProfileData,
  userEmail,
  onRefresh,
  documentCount,
  isApprover,
  isRecruiter,
}: ProfileType) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const toast = useToast();

  let activeStep: number;

  switch (profileData?.Status) {
    case "New":
      activeStep = 1;
      break;
    case "Approved":
      activeStep = 2;
      break;
    case "Scheduled":
      activeStep = 3;
      break;
    case "Showed":
      activeStep = 4;
      break;
    case "Seated":
      activeStep = 5;
      break;
    case "Upgraded":
      activeStep = 5;
      break;
    default:
      activeStep = 0;
  }

  // Function to handle click on the stepper

  const handleStepClick = (index: number) => {
    // Check for the New status and user authorization for approval
    if (index === 1 && profileData?.Status === "New") {
      if (isApprover) {
        onOpen();
      } else {
        toast({
          title: "Unauthorized",
          description: "Only authorized users can approve drivers.",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }

    if (index === 2 && profileData?.Status === "Approved") {
      onConfirmOpen();
    }
  };

  const approveDriver = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }
    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "approval",
      userEmail: userEmail,
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        throw new Error("No response from the server");
      }

      toast({
        title: "Driver Approved",
        description: "Driver has been approved",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: `Error loading driver, please try again: ${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } finally {
      onClose();
      setLoading(false);
      refreshProfileData();
      onRefresh();
    }
  };

  // Function to confirm the orientation

  // Function to confirm the orientation
  const confirmOrientation = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }

    // Check if document count is 0 before proceeding
    if (documentCount === 0) {
      toast({
        title: "Documents Required",
        description:
          "Please ensure all necessary documents are uploaded before confirming the driver for orientation.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "confirm",
      userEmail: userEmail,
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        throw new Error("No response from the server");
      }
      onClose();
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Driver Scheduled for Orientation",
        description: "Driver has been Scheduled for orientation",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: `Error confirming orientation, please try again: ${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <VStack w="100%" height="100%" spacing={1}>
        {/* Stepper to show driver's status */}
        <Stepper width="100%" index={activeStep} colorScheme="green">
          {steps.map((step, index) => (
            <Step
              key={index}
              style={{
                cursor:
                  index === activeStep && (index === 1 || index === 2)
                    ? "pointer"
                    : "default",
              }}
              onClick={() => handleStepClick(index)}
            >
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        {/* Basic Information Card */}
        <VStack {...cardProps}>
          <Text {...cardHeaderProps}>Basic Information</Text>
          <Divider />
          <Grid {...gridProps}>
            {driverInfo.map((field) => (
              <GridItem key={field.value} colSpan={field.colSpan}>
                <Text {...labelProps}>{field.label}</Text>
                <Text {...dataProps}>
                  {profileData
                    ? field.type === "date" || field.type === "datetime"
                      ? typeof profileData[
                          field.value as keyof driverBoardTypes
                        ] === "boolean"
                        ? "N/A"
                        : formatDateValue(
                            profileData[
                              field.value as keyof driverBoardTypes
                            ] as string | number,
                            field.type
                          )
                      : profileData[field.value as keyof driverBoardTypes] ||
                        "N/A"
                    : "N/A"}
                </Text>
              </GridItem>
            ))}
          </Grid>
        </VStack>

        {/* Company Information Card */}
        <VStack {...cardProps}>
          <Text {...cardHeaderProps}>Company Information</Text>
          <Divider />
          <Grid {...gridProps}>
            {companyInfo.map((field) => (
              <GridItem key={field.value} colSpan={field.colSpan}>
                <Text {...labelProps}>{field.label}</Text>
                <Text {...dataProps}>
                  {profileData
                    ? field.type === "date" || field.type === "datetime"
                      ? typeof profileData[
                          field.value as keyof driverBoardTypes
                        ] === "boolean"
                        ? "N/A"
                        : formatDateValue(
                            profileData[
                              field.value as keyof driverBoardTypes
                            ] as string | number,
                            field.type
                          )
                      : profileData[field.value as keyof driverBoardTypes] ||
                        "N/A"
                    : "N/A"}
                </Text>
              </GridItem>
            ))}
          </Grid>
        </VStack>
        {/* Orientation Information Card */}
        <VStack {...cardProps}>
          <Text {...cardHeaderProps}>Orientation Information</Text>
          <Divider />
          <Grid {...gridProps}>
            {orientationInfo.map((field) => (
              <GridItem key={field.value} colSpan={field.colSpan}>
                <Text {...labelProps}>{field.label}</Text>
                <Text {...dataProps}>
                  {profileData
                    ? field.type === "date" || field.type === "datetime"
                      ? profileData[field.value as keyof driverBoardTypes] !==
                        null
                        ? typeof profileData[
                            field.value as keyof driverBoardTypes
                          ] === "boolean"
                          ? "N/A"
                          : isNaN(
                              Date.parse(
                                profileData[
                                  field.value as keyof driverBoardTypes
                                ] as string
                              )
                            )
                          ? "N/A"
                          : formatDateValue(
                              profileData[
                                field.value as keyof driverBoardTypes
                              ] as string | number,
                              field.type
                            )
                        : "N/A"
                      : profileData[field.value as keyof driverBoardTypes] ||
                        "N/A"
                    : "N/A"}
                </Text>
              </GridItem>
            ))}
            {/* Conditional Rendering for Withdrawn or TDO Status */}
            {profileData?.Status === "Withdrawn" && (
              <GridItem colSpan={4}>
                <Text {...labelProps}>Withdraw Comment</Text>
                <Text {...dataProps}>
                  {profileData.WithdrawalComment || "N/A"}
                </Text>
              </GridItem>
            )}
            {profileData?.Status === "TDO" && (
              <GridItem colSpan={4}>
                <Text {...labelProps}>TDO Comment</Text>
                <Text {...dataProps}>{profileData.TDOComment || "N/A"}</Text>
              </GridItem>
            )}
          </Grid>
        </VStack>
      </VStack>
      {/* Manager Approval Alert Box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Approve Driver
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to approve this driver?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} isDisabled={loading} width="100px">
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                onClick={approveDriver}
                ml={3}
                isLoading={loading}
                width="100px"
              >
                Approve
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Recruiter Confirmation Alert Box */}
      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onConfirmClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Driver for Orientation
            </AlertDialogHeader>

            <AlertDialogBody>
              Please check and confirm all the details before confirming the
              driver for orientation.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                onClick={onConfirmClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                onClick={confirmOrientation}
                ml={3}
                isLoading={loading}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DriverInfo;
