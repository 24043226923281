import React, { useState, useEffect, useMemo } from "react";
import { Divider, VStack, useDisclosure } from "@chakra-ui/react";

// Import types
import { driverBoardTypes, recruitersTypes } from "../../../types/Types";

// Import components
import ToolBar from "./pipeline/ToolBar";
import PipelineDataTable from "./pipeline/PipelineDataTable";
import NewForm from "./pipeline/NewForm";
import EditForm from "../shared/EditForm";

// Import hooks
import useFetchProfileData from "../../../hooks/driverboard/fetchProfiledata";
import useFetchHistory from "../../../hooks/driverboard/fetchHistory";

interface props {
  userName: string;
  userEmail: string;
  userDepartment: string;
  searchText: string;
  selectedView: "All" | "Personal";
  data: driverBoardTypes[];
  isLoading: boolean;
  lastUpdated: Date | null;
  refresh: boolean;
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  setSelectedView: (value: "All" | "Personal") => void;
  setSearchText: (value: string) => void;
  setRefresh: (value: boolean) => void;
  setSortState: (value: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  }) => void;
  subTeam: "Tasha" | "Austin" | "All";
  setSubTeam: (value: "Tasha" | "Austin" | "All") => void;
  recruiters: recruitersTypes[];
}

const Pipeline: React.FC<props> = ({
  userName,
  userEmail,
  userDepartment,
  searchText,
  selectedView,
  data,
  isLoading,
  lastUpdated,
  refresh,
  sortState,
  setSelectedView,
  setSearchText,
  setRefresh,
  setSortState,
  subTeam,
  setSubTeam,
  recruiters,
}) => {
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const [manualRefresh, setManualRefresh] = useState<boolean>(false);
  const [statusView, setStatusView] = useState<
    | "All"
    | "Scheduled"
    | "Showed"
    | "No Show"
    | "TDO"
    | "Seated"
    | "Upgraded"
    | "Withdrawn"
  >("All");
  const [selectedRegistration, setSelectedRegistration] =
    useState<string>("All");

  // Selected Week
  const [selectedWeek, setSelectedWeek] = useState<string>(() => {
    const currentDate = new Date();
    const day = currentDate.getDay();
    const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(currentDate.setDate(diff));
    const currentYear = monday.getFullYear();
    const currentMonth = ("0" + (monday.getMonth() + 1)).slice(-2);
    const currentDay = ("0" + monday.getDate()).slice(-2);
    return `${currentMonth}/${currentDay}/${currentYear}`;
  });

  // Orientation Location Filter string
  const [orientationLocationFilter, setOrientationLocationFilter] =
    useState<string>("");

  // Select Division State
  const [selectedDivision, setSelectedDivision] = useState<
    "All Division" | "OTR" | "Dedicated" | "Student"
  >("All Division");

  // Cost Center filter
  const [selectedCostCenter, setSelectedCostCenter] = useState<string>("");

  // Recruiter Filter
  const [recruiterFilter, setRecruiterFilter] = useState<string>("");

  // Fetch Versioning History
  const { historyLoading, changeLog } = useFetchHistory(
    selectedID,
    manualRefresh
  );

  // Fetch Profile
  const { profileLoading, profileData } = useFetchProfileData(
    selectedID,
    manualRefresh
  );

  // Function to refresh profile data manually
  const refreshProfileData = () => {
    setManualRefresh(!manualRefresh);
  };

  // New Form State
  const {
    isOpen: isNewFormOpen,
    onOpen: onNewFormOpen,
    onClose: onNewFormClose,
  } = useDisclosure();

  // Edit Form State
  const {
    isOpen: isEditFormOpen,
    onOpen: onEditFormOpen,
    onClose: onEditFormClose,
  } = useDisclosure();

  // Function to handle row selection
  const handleSelect = (id: number) => {
    setSelectedID(id);
    onEditFormOpen();
  };

  // Function to close the edit form
  const handleEditFormClose = () => {
    onEditFormClose();
    setSelectedID(null);
  };

  // Filter specific data based on statusView, subTeam, and selectedRegistration
  const filteredData = useMemo(() => {
    return data.filter((item) => {
      // View Status
      const statusMatch = statusView === "All" || item.Status === statusView;

      // Sub Team
      const subTeamMatch = subTeam === "All" || item.Subteam === subTeam;

      // Registration Type
      const registrationMatch =
        selectedRegistration === "All" ||
        (selectedRegistration === "All Trainees" &&
          (item.Registration === "2 Weeks Trainee" ||
            item.Registration === "4 Weeks Trainee")) ||
        item.Registration === selectedRegistration;

      // Orientation Location Filter
      const orientationLocationMatch =
        orientationLocationFilter === "" ||
        item.OrientationLocation === orientationLocationFilter;

      // Recruiter Filter
      const recruiterMatch =
        recruiterFilter === "" || item.Recruiter === recruiterFilter;

      // Division Filter
      const divisionMatch =
        selectedDivision === "All Division" ||
        item.Division === selectedDivision;

      // Cost Center Filter

      const costCenterMatch =
        selectedCostCenter === "" ||
        (item.CostCenter &&
          item.CostCenter.toLowerCase().includes(
            selectedCostCenter.toLowerCase()
          ));

      // Week Filter
      const weekMatch = item.Week === selectedWeek;

      return (
        statusMatch &&
        subTeamMatch &&
        registrationMatch &&
        orientationLocationMatch &&
        recruiterMatch &&
        divisionMatch &&
        costCenterMatch &&
        weekMatch
      );
    });
  }, [
    statusView,
    selectedRegistration,
    orientationLocationFilter,
    recruiterFilter,
    selectedDivision,
    selectedCostCenter,
    selectedWeek,
    subTeam,
    data,
  ]);

  useEffect(() => {
    document.title = "Driver Board - Pipeline";
  }, []);

  return (
    <>
      <VStack
        width="calc(100% - 200px)"
        height="100%"
        background="white"
        shadow="md"
        borderRadius="2xl"
        padding="10px"
      >
        <ToolBar
          selectedView={selectedView}
          searchText={searchText}
          data={filteredData}
          isLoading={isLoading}
          lastUpdated={lastUpdated}
          setSelectedView={setSelectedView}
          setSearchText={setSearchText}
          onNewFormOpen={onNewFormOpen}
          onRefresh={() => setRefresh(!refresh)}
          setStatusView={setStatusView}
          statusView={statusView}
          subTeam={subTeam}
          setSubTeam={setSubTeam}
          selectedRegistration={selectedRegistration}
          setSelectedRegistration={setSelectedRegistration}
          setOrientationLocationFilter={setOrientationLocationFilter}
          orientationLocationFilter={orientationLocationFilter}
          recruiters={recruiters}
          recruiterFilter={recruiterFilter}
          setRecruiterFilter={setRecruiterFilter}
          selectedDivision={selectedDivision}
          setSelectedDivision={setSelectedDivision}
          setCostCenter={setSelectedCostCenter}
          selectedCostCenter={selectedCostCenter}
          setSelectedWeek={setSelectedWeek}
          selectedWeek={selectedWeek}
        />
        <Divider />
        <PipelineDataTable
          data={filteredData}
          selectedID={selectedID}
          sortState={sortState}
          handleSelect={handleSelect}
          handleSort={(column) => {
            if (sortState.column === column) {
              setSortState({
                column,
                direction: sortState.direction === "asc" ? "desc" : "asc",
              });
            } else {
              setSortState({ column, direction: "asc" });
            }
          }}
        />
      </VStack>
      <NewForm
        isNewFormOpen={isNewFormOpen}
        onNewFormClose={onNewFormClose}
        userEmail={userEmail}
        onRefresh={() => setRefresh(!refresh)}
        userName={userName}
        userDepartment={userDepartment}
        recruiters={recruiters}
      />
      <EditForm
        userName={userName}
        userEmail={userEmail}
        userDepartment={userDepartment}
        isOpen={isEditFormOpen}
        onClose={handleEditFormClose}
        onRefresh={() => setRefresh(!refresh)}
        selectedID={selectedID}
        profileLoading={profileLoading}
        profileData={profileData}
        refreshProfileData={refreshProfileData}
        setSelectedID={setSelectedID}
        historyLoading={historyLoading}
        changeLog={changeLog}
      />
    </>
  );
};

export default Pipeline;
