import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { driverBoardTypes } from "../../../../types/Types";

const AccordianItems = [
  {
    index: 1,
    day: "Monday",
  },
  {
    index: 2,
    day: "Tuesday",
  },
  {
    index: 3,
    day: "Wednesday",
  },
  {
    index: 4,
    day: "Thursday",
  },
  {
    index: 5,
    day: "Friday",
  },
  {
    index: 6,
    day: "Saturday",
  },
  {
    index: 7,
    day: "Sunday",
  },
];

const headerProps = {
  color: "#1D61AA",
};

const tableHeaders = [
  {
    key: "Registration",
    label: "Registration",
    width: "100px",
    dataType: "string",
  },
  {
    key: "TravelRequestStatus",
    label: "Travel Request",
    width: "120px",
    dataType: "string",
  },
  { key: "Status", label: "Status", width: "100px", dataType: "string" },
  { key: "Name", label: "Name", width: "150px", dataType: "string" },
  { key: "DriverCode", label: "Code", width: "100px", dataType: "string" },
  {
    key: "DriverType",
    label: "Driver Type",
    width: "100px",
    dataType: "string",
  },
  { key: "Division", label: "Division", width: "100px", dataType: "string" },
  {
    key: "CostCenter",
    label: "Cost Center",
    width: "100px",
    dataType: "string",
  },
  { key: "Recruiter", label: "Recruiter", width: "100px", dataType: "string" },
  {
    key: "OrientationDate",
    label: "Orientation",
    width: "100px",
    dataType: "string",
  },
  {
    key: "OrientationLocation",
    label: "Location",
    width: "100px",
    dataType: "string",
  },
];

interface Props {
  selectedView: "All" | "Personal";
  data: driverBoardTypes[];
  selectedID: number | null;
  selectedWeek: string;
  selectedDivision: string;
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  handleSort: (column: keyof driverBoardTypes) => void;
  handleSelect: (id: number) => void;
  backgroundCheckFilter:
    | "All"
    | "In-Progress"
    | "Completed"
    | "Manager Approval";
  subTeam: "Tasha" | "Austin" | "All";
  selectedRegistration: string;
  selectedLocation: string;
  selectedCostCenter: string;
  recruiterFilter: string;
}

const ScheduleDataTable: React.FC<Props> = ({
  data,
  selectedID,
  sortState,
  selectedWeek,
  selectedDivision,
  handleSort,
  handleSelect,
  backgroundCheckFilter,
  subTeam,
  selectedRegistration,
  selectedLocation,
  selectedCostCenter,
  selectedView,
  recruiterFilter,
}) => {
  // Function to format date as MM/dd/yyyy
  const formatDate = (dateString: string): string => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1].padStart(2, "0");
      const day = parts[2].padStart(2, "0");
      return `${month}/${day}/${year}`;
    }
    return dateString; // Return original date string if format is not recognized
  };

  return (
    <Box
      height="calc(100vh - 180px)"
      mt="10px"
      fontWeight="semibold"
      overflow="auto"
      width="100%"
    >
      <Accordion
        allowMultiple
        defaultIndex={Array.from(
          { length: AccordianItems.length },
          (_, i) => i
        )}
      >
        {AccordianItems.sort((a, b) => a.index - b.index).map((item) => {
          const itemCount = data.filter(
            (row) =>
              row.WeekDay === item.day &&
              row.Week === selectedWeek &&
              (backgroundCheckFilter === "All" ||
                row.BackgroundStatus === backgroundCheckFilter) &&
              (row.Status === "Scheduled" ||
                row.Status === "Showed" ||
                row.Status === "No Show" ||
                row.Status === "TDO" ||
                row.Status === "Seated" ||
                row.Status === "Upgraded") &&
              (backgroundCheckFilter === "All" ||
                row.BackgroundStatus === backgroundCheckFilter) &&
              (row.Subteam === subTeam || subTeam === "All") &&
              ((selectedRegistration === "All Trainees" &&
                (row.Registration === "2 Weeks Trainee" ||
                  row.Registration === "4 Weeks Trainee")) ||
                row.Registration === selectedRegistration ||
                selectedRegistration === "All") &&
              (row.Division === selectedDivision ||
                selectedDivision === "All Division") &&
              (selectedLocation === "" ||
                row.OrientationLocation === selectedLocation) &&
              // Add Cost Center filter, which basically use include to search for rows with the cost center closest to the selectedCostCenter if row.CostCenter is not empty
              (selectedCostCenter === "" ||
                (row.CostCenter &&
                  row.CostCenter.toLowerCase().includes(
                    selectedCostCenter.toLowerCase()
                  ))) &&
              (recruiterFilter === "" || row.Recruiter === recruiterFilter)
          ).length;

          if (itemCount > 0) {
            return (
              <AccordionItem key={item.index}>
                <h2>
                  <AccordionButton
                    bg="blackAlpha.50"
                    _hover={{ bg: "blackAlpha.50" }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight="semibold"
                      color="#EE7700"
                    >
                      {`${item.day} (${itemCount})`}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        {tableHeaders.map(
                          (header) =>
                            (selectedView === "Personal" ||
                              header.key !== "TravelRequestStatus") && (
                              <Th
                                cursor="pointer"
                                w={header.width}
                                key={header.key}
                                {...headerProps}
                                onClick={() =>
                                  handleSort(
                                    header.key as keyof driverBoardTypes
                                  )
                                }
                              >
                                {header.label}
                                <span
                                  style={{
                                    visibility:
                                      sortState.column === header.key
                                        ? "visible"
                                        : "hidden",
                                  }}
                                >
                                  {sortState.direction === "asc" ? "↑" : "↓"}
                                </span>
                              </Th>
                            )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data
                        .filter(
                          (row) =>
                            row.WeekDay === item.day &&
                            row.Week === selectedWeek &&
                            (row.Status === "Scheduled" ||
                              row.Status === "Showed" ||
                              row.Status === "No Show" ||
                              row.Status === "TDO" ||
                              row.Status === "Seated" ||
                              row.Status === "Upgraded") &&
                            (row.Division === selectedDivision ||
                              selectedDivision === "All Division") &&
                            (backgroundCheckFilter === "All" ||
                              row.BackgroundStatus === backgroundCheckFilter) &&
                            (row.Subteam === subTeam || subTeam === "All") &&
                            ((selectedRegistration === "All Trainees" &&
                              (row.Registration === "2 Weeks Trainee" ||
                                row.Registration === "4 Weeks Trainee")) ||
                              row.Registration === selectedRegistration ||
                              selectedRegistration === "All") &&
                            (selectedLocation === "" ||
                              row.OrientationLocation === selectedLocation) &&
                            (selectedCostCenter === "" ||
                              (row.CostCenter &&
                                row.CostCenter.toLowerCase().includes(
                                  selectedCostCenter.toLowerCase()
                                )))  &&
                            (recruiterFilter === "" ||
                              row.Recruiter === recruiterFilter)
                        )
                        .map((row) => (
                          <Tr
                            key={row.ID ? row.ID : undefined}
                            style={{ userSelect: "none", cursor: "pointer" }}
                            _hover={{
                              bg:
                                row.Status === "TDO" || row.Status === "No Show"
                                  ? "red.200"
                                  : row.Status === "Seated" ||
                                    row.Status === "Upgraded"
                                  ? "green.200"
                                  : row.Status === "Unseated"
                                  ? "yellow.200"
                                  : "blackAlpha.200",
                            }}
                            bg={
                              selectedID === row.ID
                                ? row.Status === "TDO" ||
                                  row.Status === "No Show"
                                  ? "red.300"
                                  : row.Status === "Seated" ||
                                    row.Status === "Upgraded"
                                  ? "green.300"
                                  : row.Status === "Unseated"
                                  ? "yellow.300"
                                  : "blackAlpha.200"
                                : row.Status === "TDO" ||
                                  row.Status === "No Show"
                                ? "red.100"
                                : row.Status === "Seated" ||
                                  row.Status === "Upgraded"
                                ? "green.100"
                                : row.Status === "Unseated"
                                ? "yellow.100"
                                : "white"
                            }
                            onClick={() => row.ID && handleSelect(row.ID)}
                            fontWeight="normal"
                          >
                            <Td>{row.Registration}</Td>

                            {selectedView === "Personal" && (
                              <Td>
                                {row.TravelRequestStatus === "Booked"
                                  ? "🟢 Booked"
                                  : row.TravelRequestStatus === "Requested"
                                  ? "🟡 Requested"
                                  : row.TravelRequestStatus === "Cancelled"
                                  ? "🔴 Cancelled"
                                  : ""}
                              </Td>
                            )}
                            <Td>{row.Status}</Td>
                            <Td>{row.DriverName}</Td>
                            <Td>{row.DriverCode}</Td>
                            <Td>{row.DriverType}</Td>
                            <Td>{row.Division}</Td>
                            <Td>{row.CostCenter}</Td>
                            <Td>{row.Recruiter}</Td>
                            <Td>
                              {row.OrientationDate
                                ? formatDate(row.OrientationDate)
                                : ""}
                            </Td>
                            <Td>{row.OrientationLocation}</Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </AccordionPanel>
              </AccordionItem>
            );
          }
          return null;
        })}
      </Accordion>
    </Box>
  );
};

export default ScheduleDataTable;
