import React, { useState, useEffect } from "react";
import {
  HStack,
  IconButton,
  ButtonGroup,
  Input,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { driverBoardTypes } from "../../../../types/Types";

// Date Picker
import DatePicker from "react-datepicker";

// CSV Link
import { CSVLink } from "react-csv";

// Icons
import { GrRefresh } from "react-icons/gr";
import { FaFileExcel } from "react-icons/fa";
import { recruitersTypes } from "../../../../types/Types";
// ToolBar Types
interface ToolBarTypes {
  searchText: string;
  selectedView: string;
  isLoading: boolean;
  data: driverBoardTypes[];
  lastUpdated: Date | null;
  selectedDivision: string;
  setSelectedView: (value: "All" | "Personal") => void;
  setSearchText: (value: string) => void;
  onRefresh: () => void;
  setSelectedDivision: (
    value: "All Division" | "OTR" | "Dedicated" | "Student"
  ) => void;
  setSelectedWeek: (value: string) => void;
  selectedWeek: string;
  backgroundCheckFilter:
    | "All"
    | "In-Progress"
    | "Completed"
    | "Manager Approval";
  setBackgroundCheckFilter: (
    value: "All" | "In-Progress" | "Completed" | "Manager Approval"
  ) => void;
  subTeam: "Tasha" | "Austin" | "All";
  setSubTeam: (value: "Tasha" | "Austin" | "All") => void;
  selectedRegistration: string;
  setSelectedRegistration: (value: string) => void;
  selectedLocation: string;
  setSelectedLocation: (value: string) => void;
  setCostCenter: (value: string) => void;
  selectedCostCenter: string;
  recruiters: recruitersTypes[];
  recruiterFilter: string;
  setRecruiterFilter: (value: string) => void;
}

const ToolBar: React.FC<ToolBarTypes> = ({
  selectedView,
  searchText,
  isLoading,
  lastUpdated,
  selectedDivision,
  setSelectedWeek,
  selectedWeek,
  setSelectedView,
  setSearchText,
  onRefresh,
  setSelectedDivision,
  backgroundCheckFilter,
  setBackgroundCheckFilter,
  subTeam,
  setSubTeam,
  data,
  selectedRegistration,
  setSelectedRegistration,
  selectedLocation,
  setSelectedLocation,
  selectedCostCenter,
  setCostCenter,
  recruiters,
  recruiterFilter,
  setRecruiterFilter,
}) => {
  const isDataAvailable = data.length > 0;
  const [processedData, setProcessedData] = useState<driverBoardTypes[]>([]);

  // Process data
  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((item) => {
        if (!item.Week) {
          return false;
        }

        if (
          item.Status === "Withdrawn" ||
          item.Status === "New" ||
          item.Status === "Approved"
        ) {
          return false;
        }

        const itemWeek = new Date(item.Week);
        const selectedWeekDate = new Date(selectedWeek);

        return (
          itemWeek.getFullYear() === selectedWeekDate.getFullYear() &&
          itemWeek.getMonth() === selectedWeekDate.getMonth() &&
          itemWeek.getDate() === selectedWeekDate.getDate()
        );
      });

      const modifiedData = filteredData.map((item) => {
        const modifiedArrival = item.Arrival
          ? new Date(item.Arrival).toLocaleString()
          : "null";

        const modifiedCreated = item.Created
          ? new Date(item.Created).toLocaleString()
          : null;
        return {
          ...item,
          Arrival: modifiedArrival,
          Created: modifiedCreated,
        };
      });

      setProcessedData(modifiedData);
    }
  }, [data, selectedWeek]);

  return (
    <HStack
      width="100%"
      borderRadius="2xl"
      spacing="10px"
      justifyContent="space-between"
    >
      <ButtonGroup spacing="10px">
        <Input
          variant="filled"
          placeholder="Name or ID"
          width="200px"
          focusBorderColor="orange.400"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />

        <Tooltip
          label={
            lastUpdated ? `Last Updated: ${lastUpdated.toLocaleString()}` : ""
          }
          aria-label="Refresh"
          openDelay={500}
        >
          <IconButton
            aria-label="Refresh"
            icon={<GrRefresh />}
            shadow="md"
            colorScheme="blue"
            tabIndex={-1}
            onClick={onRefresh}
            isLoading={isLoading}
          />
        </Tooltip>
        <CSVLink
          data={processedData}
          filename={"driverboard.csv"}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Tooltip
            label="Export what you see to CSV"
            aria-label="Export to CSV"
            openDelay={500}
          >
            <IconButton
              aria-label="Export to CSV"
              icon={<FaFileExcel />}
              shadow="md"
              colorScheme="green"
              isDisabled={!isDataAvailable}
              tabIndex={-1}
            />
          </Tooltip>
        </CSVLink>
      </ButtonGroup>

      <HStack spacing="10px">
        {/* Registration Filter */}
        <Select
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedRegistration}
          onChange={(e) => setSelectedRegistration(e.target.value)}
          size="sm"
          width="100px"
        >
          <option value="All">All Registrations</option>
          <option value="Experienced">Experienced</option>
          <option value="All Trainees">All Trainees</option>
          <option value="2 Weeks Trainee">2 Weeks Trainee</option>
          <option value="4 Weeks Trainee">4 Weeks Trainee</option>
        </Select>

        {/* Orientation Week */}
        <DatePicker
          customInput={
            <Input
              size="sm"
              width="100px"
              focusBorderColor="orange.400"
              variant="filled"
            />
          }
          selected={new Date(selectedWeek)}
          onChange={(date) =>
            date
              ? setSelectedWeek(
                  date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })
                )
              : setSelectedWeek("")
          }
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          dateFormat="MM/dd/yyyy"
          filterDate={(date) => date.getDay() === 1}
        />

        {/* Location Filter */}
        <Select
          size="sm"
          width="100px"
          placeholder="All Locations"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
        >
          <option value="Dubuque">Dubuque</option>
          <option value="Denton">Denton</option>
          <option value="Dover">Dover</option>
          <option value="Sapulpa">Sapulpa</option>
          <option value="Travel to Truck">Travel to Truck</option>
        </Select>

        {/* Division Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedDivision}
          onChange={(e) =>
            setSelectedDivision(
              e.target.value as "All Division" | "OTR" | "Dedicated"
            )
          }
        >
          <option value="All Division">All Division</option>
          <option value="OTR">OTR</option>
          <option value="Dedicated">Dedicated</option>
          <option value="Student">Student</option>
        </Select>

        {/* Cost Center Search */}
        <Input
          size="sm"
          variant="filled"
          placeholder="Cost Center"
          width="120px"
          focusBorderColor="orange.400"
          value={selectedCostCenter}
          onChange={(e) => setCostCenter(e.target.value)}
        />

        {/* Background Check Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={backgroundCheckFilter}
          onChange={(e) =>
            setBackgroundCheckFilter(
              e.target.value as
                | "All"
                | "In-Progress"
                | "Completed"
                | "Manager Approval"
            )
          }
        >
          <option value="All">All Background</option>
          <option value="In-Progress">In-Progress</option>
          <option value="Completed">Completed</option>
          <option value="Manager Approval">Manager Approval</option>
        </Select>

        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedView}
          onChange={(e) => {
            setSelectedView(e.target.value as "All" | "Personal");
            setSubTeam("All");
          }}
        >
          <option value="Personal">Personal</option>
          <option value="All">All Records</option>
        </Select>
        <Select
          size="sm"
          width="100px"
          isDisabled={selectedView === "Personal"}
          focusBorderColor="orange.400"
          variant="filled"
          value={subTeam}
          onChange={(e) =>
            setSubTeam(e.target.value as "Tasha" | "Austin" | "All")
          }
        >
          <option value="All">All Team</option>
          <option value="Tasha">Tasha</option>
          <option value="Austin">Austin</option>
        </Select>

        {/* Recruiter Filter */}
        <Select
          size="sm"
          width="100px"
          isDisabled={selectedView === "Personal"}
          placeholder="All Recruiters"
          focusBorderColor="orange.400"
          variant="filled"
          value={recruiterFilter}
          onChange={(e) => setRecruiterFilter(e.target.value)}
        >
          {recruiters.map((recruiter) => (
            <option key={recruiter.Email} value={recruiter.Recruiter}>
              {recruiter.Recruiter}
            </option>
          ))}
        </Select>
      </HStack>
    </HStack>
  );
};

export default ToolBar;
