import React, { useState, useEffect } from "react";
import { VStack, useDisclosure } from "@chakra-ui/react";

// Import types
import { driverBoardTypes, recruitersTypes } from "../../../types/Types";

// Import components
import ToolBar from "./processing/ToolBar";
import EditForm from "../shared/EditForm";
import ProcessingDataTable from "./processing/ProcessingDataTable";

// Import hooks
import useFetchProfileData from "../../../hooks/driverboard/fetchProfiledata";
import useFetchHistory from "../../../hooks/driverboard/fetchHistory";

interface props {
  userName: string;
  userEmail: string;
  userDepartment: string;
  searchText: string;
  selectedView: "All" | "Personal";
  data: driverBoardTypes[];
  isLoading: boolean;
  lastUpdated: Date | null;
  refresh: boolean;
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  setSelectedView: (value: "All" | "Personal") => void;
  setSearchText: (value: string) => void;
  setRefresh: (value: boolean) => void;
  setSortState: (value: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  }) => void;
  subTeam: "Tasha" | "Austin" | "All";
  setSubTeam: (value: "Tasha" | "Austin" | "All") => void;
  recruiters: recruitersTypes[];
}

const Processing: React.FC<props> = ({
  userName,
  userEmail,
  userDepartment,
  searchText,
  selectedView,
  data,
  isLoading,
  lastUpdated,
  refresh,
  sortState,
  setSelectedView,
  setSearchText,
  setRefresh,
  setSortState,
  subTeam,
  setSubTeam,
  recruiters,
}) => {
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const [manualRefresh, setManualRefresh] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const currentDay = ("0" + currentDate.getDate()).slice(-2);
    return `${currentYear}-${currentMonth}-${currentDay}`;
  });

  // Function to refresh profile data manually
  const refreshProfileData = () => {
    setManualRefresh(!manualRefresh);
  };

  // Select Division State
  const [selectedDivision, setSelectedDivision] = useState<
    "All Division" | "OTR" | "Dedicated" | "Student"
  >("All Division");

  // Background check filter with the types as In-Progress, Completed, and Manager Approval
  const [backgroundCheckFilter, setBackgroundCheckFilter] = useState<
    "All" | "In-Progress" | "Completed" | "Manager Approval"
  >("All");

  // Travel Request Status Filter
  const [travelRequestFilter, setTravelRequestFilter] = useState<
    "All" | "Requested" | "Booked" | "Cancelled"
  >("All");

  // Orientation Location Filter string
  const [orientationLocationFilter, setOrientationLocationFilter] =
    useState<string>("");

  // Recruiter Filter
  const [recruiterFilter, setRecruiterFilter] = useState<string>("");

  const {
    isOpen: isEditFormOpen,
    onOpen: onEditFormOpen,
    onClose: onEditFormClose,
  } = useDisclosure();

  // Fetch Versioning History
  const { historyLoading, changeLog } = useFetchHistory(
    selectedID,
    manualRefresh
  );

  // Fetch Profile
  const { profileLoading, profileData } = useFetchProfileData(
    selectedID,
    manualRefresh
  );

  // Function to handle row selection
  const handleSelect = (id: number) => {
    setSelectedID(id);
    onEditFormOpen();
  };

  // Function to close the edit form
  const handleEditFormClose = () => {
    onEditFormClose();
    setSelectedID(null);
  };

  useEffect(() => {
    document.title = "Driver Board - Pipeline";
  }, []);

  return (
    <>
      <VStack
        width="calc(100% - 200px)"
        height="100%"
        background="white"
        shadow="md"
        borderRadius="2xl"
        padding="10px"
        alignItems="flex-start"
      >
        <ToolBar
          selectedView={selectedView}
          searchText={searchText}
          data={data}
          isLoading={isLoading}
          lastUpdated={lastUpdated}
          setSelectedView={setSelectedView}
          setSearchText={setSearchText}
          onRefresh={() => setRefresh(!refresh)}
          setSelectedDivision={setSelectedDivision}
          selectedDivision={selectedDivision}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          backgroundCheckFilter={backgroundCheckFilter}
          setBackgroundCheckFilter={setBackgroundCheckFilter}
          subTeam={subTeam}
          setSubTeam={setSubTeam}
          travelRequestFilter={travelRequestFilter}
          setTravelRequestFilter={setTravelRequestFilter}
          orientationLocationFilter={orientationLocationFilter}
          setOrientationLocationFilter={setOrientationLocationFilter}
          recruiters={recruiters}
          recruiterFilter={recruiterFilter}
          setRecruiterFilter={setRecruiterFilter}
        />
        <ProcessingDataTable
          subTeam={subTeam}
          data={data}
          selectedID={selectedID}
          selectedDate={selectedDate}
          selectedDivision={selectedDivision}
          sortState={sortState}
          handleSort={(column) => {
            if (sortState.column === column) {
              setSortState({
                column,
                direction: sortState.direction === "asc" ? "desc" : "asc",
              });
            } else {
              setSortState({
                column,
                direction: "asc",
              });
            }
          }}
          handleSelect={handleSelect}
          backgroundCheckFilter={backgroundCheckFilter}
          travelRequestFilter={travelRequestFilter}
          orientationLocationFilter={orientationLocationFilter}
          recruiterFilter={recruiterFilter}
        />
      </VStack>
      <EditForm
        userName={userName}
        userEmail={userEmail}
        userDepartment={userDepartment}
        isOpen={isEditFormOpen}
        onClose={handleEditFormClose}
        onRefresh={() => setRefresh(!refresh)}
        selectedID={selectedID}
        profileLoading={profileLoading}
        profileData={profileData}
        refreshProfileData={refreshProfileData}
        setSelectedID={setSelectedID}
        historyLoading={historyLoading}
        changeLog={changeLog}
      />
    </>
  );
};

export default Processing;
