import React, { useState, useRef, useEffect } from "react";
import { OpenAIClient, AzureKeyCredential } from "@azure/openai";

import {
  Box,
  IconButton,
  VStack,
  Text,
  Textarea,
  Flex,
  useToast,
  Tooltip,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import { CSVLink } from "react-csv";
import { IoIosSend } from "react-icons/io";
import { VscClearAll } from "react-icons/vsc";
import { FaFileExcel } from "react-icons/fa";
import { userInfo } from "../../types/Types";

interface Message {
  role: "system" | "user" | "assistant";
  content: string;
}

const initialSystemMessage: Message = {
  role: "system",
  content:
    "You are a helpful assistant named, 'Hirschbach Assistant.' When a user mentions Process Improvement support ticket or experiences issues like chat not helping, connection problems, or needing human assistance, briefly acknowledge their request and include the special instruction ##SystemCommand: TicketForm## in your response, but say nothing else about it. The response should simply be: 'Please submit a ticket here.'",
};

const OpenAIChat: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  const [messages, setMessages] = useState<Message[]>([initialSystemMessage]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userInput, setUserInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const endOfNotesRef = useRef<HTMLDivElement>(null);
  const endpoint = process.env.REACT_APP_AZURE_OPENAI_ENDPOINT;
  const azureApiKey = process.env.REACT_APP_AZURE_OPENAI_KEY;

  const openAI = async (): Promise<void> => {
    if (!endpoint || !azureApiKey) {
      toast({
        title: "An error occurred.",
        description: "Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const newUserMessage: Message = {
      role: "user",
      content: userInput,
    };

    setMessages([...messages, newUserMessage]);
    setIsLoading(true);
    setUserInput("");

    const client = new OpenAIClient(
      endpoint,
      new AzureKeyCredential(azureApiKey)
    );
    const deploymentId = "openAI";

    const contextMessages = [
      initialSystemMessage,
      ...messages.slice(-10),
      newUserMessage,
    ];

    try {
      const result = await client.getChatCompletions(
        deploymentId,
        contextMessages
      );
      if (result.choices && result.choices.length > 0) {
        let messageContent = result.choices[0].message?.content as string;

        const [processedContent, commandProcessed] =
          processGPTResponse(messageContent);

        if (commandProcessed) {
        }

        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: processedContent,
          },
        ]);
      }
      setIsLoading(false);
    } catch (err) {
      toast({
        title: "An error occurred.",
        description: "Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      openAI();
    }
  };

  const processGPTResponse = (content: string): [string, boolean] => {
    const commandPattern = /##SystemCommand: (\w+)##/;
    const matches = content.match(commandPattern);
    let commandFound = false;

    if (matches) {
      const command = matches[1];
      switch (command) {
        case "TicketForm":
          setTimeout(() => {
            onOpen();
          }, 1500);
          commandFound = true;
          break;
        default:
      }

      content = content.replace(commandPattern, "");
    }

    return [content, commandFound];
  };

  const renderFormattedText = (text: string) => {
    const paragraphs = text.split("\n\n");

    const paragraphElements = paragraphs.map((paragraph, paraIndex) => {
      const lines = paragraph.split("\n").map((line, lineIndex) => {
        if (line.trim().startsWith("-")) {
          return (
            <li key={`line-${paraIndex}-${lineIndex}`}>
              {line.substring(1).trim()}
            </li>
          );
        }
        return (
          <span key={`line-${paraIndex}-${lineIndex}`}>
            {line}
            <br />
            <br />
          </span>
        );
      });

      return <p key={`paragraph-${paraIndex}`}>{lines}</p>;
    });

    return <div>{paragraphElements}</div>;
  };

  const resetChat = () => {
    setMessages([initialSystemMessage]);
  };

  useEffect(() => {
    endOfNotesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <VStack
        height="calc(100vh - 55px)"
        spacing={0}
        padding="20px"
        background="white"
      >
        <Flex
          w="1200px"
          alignItems="center"
          justifyContent="flex-start"
          background="blackAlpha.100"
          height="45px"
          borderRadius="full"
          pl="10px"
        >
          <Tooltip
            label="Clear the chat session"
            aria-label="Reset Chat"
            openDelay={800}
          >
            <IconButton
              icon={<VscClearAll size="20px" />}
              colorScheme="linkedin"
              variant="ghost"
              size="md"
              aria-label="Reset"
              borderRadius="full"
              onClick={resetChat}
              isDisabled={messages.length === 1 || isLoading}
            />
          </Tooltip>

          <CSVLink
            data={messages}
            headers={[
              { label: "Role", key: "role" },
              { label: "Content", key: "content" },
            ]}
            filename={
              "hirschbach-assistant-chat " +
              new Date().toISOString() +
              userEmail +
              ".csv"
            }
            style={{ textDecoration: "none" }}
          >
            <Tooltip
              label="Export chat to CSV"
              aria-label="Export to CSV"
              openDelay={800}
            >
              <IconButton
                icon={<FaFileExcel size="20px" />}
                colorScheme="whatsapp"
                variant="ghost"
                size="md"
                aria-label="Export to CSV"
                borderRadius="full"
                isDisabled={messages.length === 1 || isLoading}
              />
            </Tooltip>
          </CSVLink>

          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
            alignSelf="center"
            visibility={!isLoading ? "hidden" : "visible"}
          />
        </Flex>
        <Box
          w="1200px"
          flex="1"
          overflowY="auto"
          p={4}
          alignSelf="center"
          background="white"
        >
          {messages.map((message, index) => {
            if (message.role === "system") {
              return null;
            }
            return (
              <Flex
                key={index}
                flexDirection={message.role === "user" ? "row-reverse" : "row"}
                mb={5}
              >
                <VStack
                  alignItems={
                    message.role === "user" ? "flex-end" : "flex-start"
                  }
                >
                  <Text fontSize="md" color="black" mr="10px">
                    {message.role === "user"
                      ? userName
                      : "Hirschbach Assistant"}{" "}
                  </Text>
                  <Box
                    p={3}
                    bg={message.role === "user" ? "#0B93F6" : "#E5E5EA"}
                    textColor={message.role === "user" ? "white" : "black"}
                    borderRadius="lg"
                  >
                    {message.role === "assistant" ? (
                      renderFormattedText(message.content)
                    ) : (
                      <Text>{message.content}</Text>
                    )}
                  </Box>
                </VStack>
              </Flex>
            );
          })}
          <div ref={endOfNotesRef} />
        </Box>
        <Flex
          w="1200px"
          alignSelf="center"
          height="100px"
          padding="10px"
          background="white"
        >
          <Textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder={
              userDepartment === "Strategy"
                ? "Type your message here..."
                : "You do not have permission to use this. Please contact Process Improvement for more information."
            }
            resize="none"
            mr="10px"
            flexGrow={1}
            onKeyDown={handleKeyDown}
            isDisabled={userDepartment !== "Strategy"}
          />

          <IconButton
            icon={<IoIosSend />}
            colorScheme="blue"
            aria-label="Send"
            onClick={openAI}
            isLoading={isLoading}
            isDisabled={!userInput.trim() || userDepartment !== "Strategy"}
            borderRadius="full"
          />
        </Flex>
        <Text color="blue.700" fontWeight="semibold" fontSize="small">
          Hirschbach Assistant can make mistakes. Consider verify important
          information before utilizing it.
        </Text>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Type your message here..."
              resize="none"
              height="500px"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="whatsapp" onClick={onClose}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OpenAIChat;
