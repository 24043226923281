import axios from "axios";

export interface Payload {
  [key: string]: any;
}

const expressAPI = async (endpoint: string, payload: Payload) => {
  const baseURL = "https://process-api-01.azure-api.net/expressapi/";
  const url = `${baseURL}${endpoint}`;

  try {
    const apiKey = process.env.REACT_APP_LOGIC_APP_DRIVER_BOARD_PIPELINE_KEY;

    const config = {
      headers: {
        "Ocp-Apim-Subscription-Key": apiKey,
      },
    };

    if (!apiKey) {
      throw new Error("API Key is not defined in environment variables");
    }

    const response = await axios.post(url, payload, config);

    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export default expressAPI;
