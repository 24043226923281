import React from "react";
import { Spinner } from "@chakra-ui/react";
import { motion } from "framer-motion";

const Loading = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(12px)",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Spinner
        size="lg"
        color="#EE7700"
        thickness="2px"
        speed="0.65s"
        emptyColor="gray.200"
      />
    </motion.div>
  );
};

export default Loading;
