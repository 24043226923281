import { useState, useEffect, useCallback } from "react";
import { apiRead } from "../../api/driverBoardAPI";

import { driverBoardTypes, ChangeLogEntry } from "../../types/Types";

interface DriverBoardTransaction extends driverBoardTypes {
  [key: string]: any;
  TransactionCreated: string;
  TransactionUserEmail: string;
}

const useFetchHistory = (selectedID: number | null, manualRefresh: boolean) => {
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [changeLog, setChangeLog] = useState<ChangeLogEntry[]>([]);

  const processChanges = useCallback(
    (transactions: DriverBoardTransaction[]): ChangeLogEntry[] => {
      let changes: ChangeLogEntry[] = [];
      transactions.sort(
        (a, b) =>
          new Date(a.TransactionCreated).getTime() -
          new Date(b.TransactionCreated).getTime()
      );

      for (let i = 1; i < transactions.length; i++) {
        const current = transactions[i];
        const previous = transactions[i - 1];

        Object.keys(current).forEach((key) => {
          if (key === "TransactionCreated" || key === "TransactionUserEmail")
            return;
          if (current[key] !== previous[key]) {
            changes.push({
              field: key,
              oldValue: previous[key],
              newValue: current[key],
              changedBy: current.TransactionUserEmail || "Unknown",
              changedOn: current.TransactionCreated,
            });
          }
        });
      }
      return changes;
    },
    []
  );

  const fetchData = useCallback(async () => {
    if (selectedID === null) return;
    setChangeLog([]);
    setHistoryLoading(true);
    try {
      const payload = { id: selectedID.toString(), process: "version" };
      const response = await apiRead(payload);
      if (response && response.length > 0) {
        const processedChanges = processChanges(response);
        setChangeLog(processedChanges);
      } else {
        setChangeLog([]);
      }
    } catch (error) {
      console.error("Failed to fetch history:", error);
    } finally {
      setHistoryLoading(false);
    }
  }, [selectedID, processChanges]);

  useEffect(() => {
    fetchData();
  }, [fetchData, manualRefresh]);

  return { historyLoading, changeLog };
};

export default useFetchHistory;
