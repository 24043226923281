import axios from "axios";

export const purchaseAPI = async (
    body: string | object,
    action: string,
    userEmail: string
  ) => {
    const url = 'https://prod-14.eastus.logic.azure.com:443/workflows/f64666c9d3604a01ad3512723aa6cde8/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=FAF0039gvnJkqjcOydjA9hhoFNurFvPvEJYJ_heKrkE';
  
    const data = {
        body: body,
        action: action,
        userEmail: userEmail
    }

    const config = {
      headers: {
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;

    } catch (error) {
      console.error("API request failed:", error);
    }
  };

