export const formatDateValue = (
  value: string | number | null,
  type: "date" | "datetime"
) => {
  if (!value) return "N/A";

  const date = new Date(value);
  switch (type) {
    case "date":
      return `${("0" + (date.getUTCMonth() + 1)).slice(-2)}/${(
        "0" + date.getUTCDate()
      ).slice(-2)}/${date.getUTCFullYear()}`;
    case "datetime":
      return `${date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })} ${date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`;
    default:
      return value;
  }
};
