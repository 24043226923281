import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Text,
  Divider,
  VStack,
  Input,
  Select,
  Button,
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Import Types for the Driver Board
import {
  driverBoardTypes,
  sourcesTypes,
  costCentersTypes,
  recruitersTypes,
  adUsersTypes,
} from "../../../types/Types";

// Import Information and Props
import {
  cardProps,
  cardHeaderProps,
  editGridProps,
  editLabelProps,
} from "./GeneralFields";

// Import the API
import { apiWrite } from "../../../api/driverBoardAPI";

type CompanyInfoType = {
  profileData: driverBoardTypes | null;
  refreshProfileData: () => void;
  userEmail: string;
  onRefresh: () => void;
  sources: sourcesTypes[] | null;
  costCenters: costCentersTypes[] | null;
  recruiters: recruitersTypes[] | null;
  driverleaders: adUsersTypes[] | null;
};
const CompanyInfo = ({
  profileData,
  refreshProfileData,
  userEmail,
  onRefresh,
  sources,
  costCenters,
  recruiters,
  driverleaders,
}: CompanyInfoType) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [Recruiter, setRecruiter] = useState(profileData?.Recruiter || "");
  const [RecruiterEmail, setRecruiterEmail] = useState(
    profileData?.RecruiterEmail || ""
  );
  const [DriverType, setDriverType] = useState(profileData?.DriverType || "");
  const [CostCenter, setCostCenter] = useState(profileData?.CostCenter || "");
  const [Division, setDivision] = useState(profileData?.Division || "");
  const [Source, setSource] = useState(profileData?.Source || "");
  const [DriverReferral, setDriverReferral] = useState(
    profileData?.DriverReferral || ""
  );
  const [TeamDriver, setTeamDriver] = useState(profileData?.TeamDriver || "");
  const [TeamWith, setTeamWith] = useState(profileData?.TeamWith || "");
  const [SecondSeat, setSecondSeat] = useState(profileData?.SecondSeat || "");
  const [WillingtoTrain, setWillingtoTrain] = useState(
    profileData?.WillingtoTrain || ""
  );

  // Driver leader and Board's state
  const [DriverLeader, setDriverLeader] = useState(
    profileData?.DriverLeader || ""
  );
  const [Board, setBoard] = useState(profileData?.Board || "");

  const [DriverLeaderEmail, setDriverLeaderEmail] = useState(
    profileData?.DriverLeaderEmail || ""
  );

  const saveChangesOrientation = async () => {
    if (!profileData?.ID) {
      console.error("Error: Missing driver ID");
      return;
    }

    const isAnyFieldEmpty = Object.values({
      Recruiter,
      DriverType,
      CostCenter,
      Source,
      TeamDriver,
      WillingtoTrain,
    }).some((value) => !value.trim());
    if (isAnyFieldEmpty) {
      toast({
        title: "Warning",
        description: "Please fill out all the fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setLoading(true);
    const payload = {
      id: profileData.ID,
      Process: "update company",
      userEmail: userEmail,
      form: {
        Recruiter,
        RecruiterEmail,
        DriverType,
        CostCenter,
        Division,
        Source,
        DriverReferral,
        TeamDriver,
        TeamWith,
        SecondSeat,
        WillingtoTrain,
        DriverLeader,
        Board,
        DriverLeaderEmail,
      },
    };

    try {
      const response = await apiWrite(payload);
      if (!response) {
        toast({
          title: "Error",
          description: "Error submitting changes, please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
      onClose();
      setLoading(false);
      refreshProfileData();
      onRefresh();
      toast({
        title: "Success",
        description: " The changes have been submitted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error submitting changes, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function so that when cancel is pressed, it returns all the values to the default values
  const resetForm = () => {
    setRecruiter(profileData?.Recruiter || "");
    setRecruiterEmail(profileData?.RecruiterEmail || "");
    setDriverType(profileData?.DriverType || "");
    setCostCenter(profileData?.CostCenter || "");
    setDivision(profileData?.Division || "");
    setSource(profileData?.Source || "");
    setDriverReferral(profileData?.DriverReferral || "");
    setTeamDriver(profileData?.TeamDriver || "");
    setTeamWith(profileData?.TeamWith || "");
    setSecondSeat(profileData?.SecondSeat || "");
    setWillingtoTrain(profileData?.WillingtoTrain || "");
    setDriverLeader(profileData?.DriverLeader || "");
    setBoard(profileData?.Board || "");
    setDriverLeaderEmail(profileData?.DriverLeaderEmail || "");
    setChanged(false);
  };

  return (
    <>
      <VStack w="100%" height="100%" spacing={1}>
        <VStack {...cardProps}>
          <HStack w="100%" justifyContent="flex-start">
            <Text {...cardHeaderProps}>Change Company Information</Text>
            <Text
              fontSize="xs"
              fontWeight="semibold"
              color="red.500"
              style={{ visibility: changed ? "visible" : "hidden" }}
            >
              - Unsaved Changes
            </Text>
          </HStack>
          <Divider />
          <Grid {...editGridProps}>
            {/* Recruiter  */}
            <GridItem>
              <Text {...editLabelProps}>Recruiter</Text>
              <Select
                value={Recruiter}
                size="md"
                onChange={(e) => {
                  setRecruiter(e.target.value);
                  setChanged(true);
                  setRecruiterEmail(
                    recruiters?.find(
                      (recruiter) => recruiter.Recruiter === e.target.value
                    )?.Email || ""
                  );
                }}
              >
                {recruiters?.map((recruiter) => (
                  <option key={recruiter.ID} value={recruiter.Recruiter}>
                    {recruiter.Recruiter}
                  </option>
                ))}
              </Select>
            </GridItem>
            {/* Driver Type */}
            <GridItem>
              <Text {...editLabelProps}>Driver Type</Text>
              <Select
                value={DriverType}
                size="md"
                onChange={(e) => {
                  setDriverType(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Company">Company</option>
                <option value="Lease">Lease</option>
              </Select>
            </GridItem>
            {/* Cost Center */}
            <GridItem>
              <Text {...editLabelProps}>Cost Center</Text>
              <Select
                value={CostCenter}
                size="md"
                onChange={(e) => {
                  setCostCenter(e.target.value);
                  setDriverLeader("");
                  setDriverLeaderEmail("");
                  setBoard("");
                  setChanged(true);
                  setDivision(
                    costCenters?.find((cc) => cc.Name === e.target.value)
                      ?.Division || ""
                  );
                }}
              >
                {costCenters
                  ?.sort((a, b) => a.Name.localeCompare(b.Name))
                  .map((costCenter) => (
                    <option key={costCenter.ID} value={costCenter.Name}>
                      {costCenter.Name}
                    </option>
                  ))}
              </Select>
            </GridItem>

            {/* Source */}
            <GridItem>
              <Text {...editLabelProps}>Source</Text>
              <Select
                value={Source}
                size="md"
                onChange={(e) => {
                  setSource(e.target.value);
                  setChanged(true);
                }}
              >
                {sources
                  ?.sort((a, b) => a.Title.localeCompare(b.Title))
                  .map((source) => (
                    <option key={source.ID} value={source.Title}>
                      {source.Title}
                    </option>
                  ))}
              </Select>
            </GridItem>

            {/* Referral */}
            <GridItem>
              <Text {...editLabelProps}>Referral</Text>
              <Input
                value={DriverReferral}
                size="md"
                onChange={(e) => {
                  setDriverReferral(e.target.value);
                  setChanged(true);
                }}
              />
            </GridItem>
            {/* Team Driver drop down select */}
            <GridItem>
              <Text {...editLabelProps}>Team Driver</Text>
              <Select
                value={TeamDriver}
                size="md"
                onChange={(e) => {
                  setTeamDriver(e.target.value);
                  setChanged(true);
                  if (e.target.value === "No Team") {
                    setSecondSeat("No");
                    setTeamWith("");
                  }
                }}
              >
                <option value="No Team">No Team</option>
                <option value="New">New</option>
                <option value="Existing">Existing</option>
              </Select>
              {/* Only show if Team Driver is not "No Team" */}
            </GridItem>

            {/* Team With */}
            {TeamDriver !== "No Team" && (
              <>
                <GridItem>
                  <Text {...editLabelProps}>Team With</Text>
                  <Input
                    value={TeamWith}
                    size="md"
                    onChange={(e) => {
                      setTeamWith(e.target.value);
                      setChanged(true);
                    }}
                  />
                </GridItem>
                {/* Second Seat drop down yes, or null*/}
                <GridItem>
                  <Text {...editLabelProps}>2nd Seat</Text>
                  <Select
                    placeholder="Select a Second Seat"
                    value={SecondSeat}
                    size="md"
                    onChange={(e) => {
                      setSecondSeat(e.target.value);
                      setChanged(true);
                    }}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
                </GridItem>
              </>
            )}
            {/* Willing to Train Select Drop Down */}
            <GridItem>
              <Text {...editLabelProps}>Willing to Train</Text>
              <Select
                value={WillingtoTrain}
                size="md"
                onChange={(e) => {
                  setWillingtoTrain(e.target.value);
                  setChanged(true);
                }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Possible">Possible</option>
              </Select>
            </GridItem>
            {/* Conditional rendering based on userEmail */}
            {userEmail.toLowerCase().endsWith("@hirschbach.com") ? (
              /* Driver Leader Dropdown for users with @hirschbach.com */
              <GridItem>
                <Text {...editLabelProps}>Driver Leader</Text>
                <Select
                  value={DriverLeader}
                  placeholder="Select a Driver Leader"
                  size="md"
                  onChange={(e) => {
                    setDriverLeader(e.target.value);
                    setChanged(true);
                    setBoard(
                      driverleaders?.find(
                        (leader) => leader.DisplayName === e.target.value
                      )?.Code || ""
                    );
                    setDriverLeaderEmail(
                      driverleaders?.find(
                        (leader) => leader.DisplayName === e.target.value
                      )?.EmailAddress || ""
                    );
                  }}
                >
                  {driverleaders
                    ?.sort((a, b) =>
                      (a.DisplayName || "").localeCompare(b.DisplayName || "")
                    )
                    .map((leader) => (
                      <option
                        value={leader.DisplayName || ""}
                        key={leader.EmailAddress}
                      >
                        {leader.DisplayName}
                      </option>
                    ))}
                </Select>
              </GridItem>
            ) : (
              /* Manual Input for users without @hirschbach.com */
              <>
                <GridItem>
                  <Text {...editLabelProps}>Driver Leader Name</Text>
                  <Input
                    placeholder="First and Last Name"
                    value={DriverLeader}
                    size="md"
                    onChange={(e) => {
                      setDriverLeader(e.target.value);
                      setChanged(true);
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Text {...editLabelProps}>Driver Leader Email</Text>
                  <Input
                    placeholder="Email Address"
                    value={DriverLeaderEmail}
                    size="md"
                    onChange={(e) => {
                      setDriverLeaderEmail(e.target.value);
                      setChanged(true);
                    }}
                  />
                </GridItem>
              </>
            )}
            {/* Board */}
            <GridItem>
              <Text {...editLabelProps}>Board</Text>
              <Input
                value={Board}
                maxLength={10}
                size="md"
                onChange={(e) => {
                  setBoard(e.target.value.toUpperCase());
                  setChanged(true);
                }}
              />
            </GridItem>
          </Grid>
          <HStack
            w="100%"
            justifyContent="flex-end"
            p={2}
            spacing={2}
            mt="50px"
            style={{ visibility: changed ? "visible" : "hidden" }}
          >
            <Button onClick={resetForm} width="100px">
              Cancel
            </Button>
            <Button colorScheme="whatsapp" onClick={onOpen} width="100px">
              Submit
            </Button>
          </HStack>
        </VStack>
      </VStack>

      {/* Recruiter Confirmation Alert Box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to submit the changes?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                isDisabled={loading}
                width="100px"
              >
                Cancel
              </Button>
              <Button
                colorScheme="whatsapp"
                ml={3}
                isLoading={loading}
                onClick={saveChangesOrientation}
                width="100px"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CompanyInfo;
