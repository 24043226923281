import { useState, useEffect, useCallback, useMemo } from "react";
import { apiPipeline } from "../../api/driverBoardAPI";
import { driverBoardTypes } from "../../types/Types";

// Sort Types
interface SortState {
  column: keyof driverBoardTypes | null;
  direction: "asc" | "desc";
}

// View Types
type ViewTypes = "All" | "Personal";

const useFetchData = (
  refresh: boolean,
  userName: string,
  userEmail: string,
  userTitle: string,
  searchText: string,
  selectedView: ViewTypes,
  sortState: SortState
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rawData, setRawData] = useState<driverBoardTypes[]>([]);
  const [data, setData] = useState<driverBoardTypes[]>([]);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiPipeline();
      if (response) {
        setRawData(response);
        setLastUpdated(new Date());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [refresh, fetchData]);

  const sortedAndFilteredData = useMemo(() => {
    const filterAndSortData = rawData
      .filter((row) => {
        // If view is "All", no further filtering is applied here
        if (selectedView === "All") {
          return true;
        }

        if (selectedView === "Personal") {
          if (userTitle === "Recruiting Processor") {
            return row.Processor === userName;
          } else if (userTitle !== "Manager - BP Engineering") {
            return row.RecruiterEmail === userEmail;
          }
        }
        return true;
      })
      .filter((row) => {
        // Further filtering based on debouncedSearchText
        return debouncedSearchText
          ? row.DriverName?.toLowerCase().includes(
              debouncedSearchText.toLowerCase()
            ) ||
              row.ID?.toString().includes(debouncedSearchText) ||
              row.DriverCode?.toString().includes(debouncedSearchText)
          : true;
      })
      .sort((a, b) => {
        if (!sortState.column) return 0;

        const { column, direction } = sortState;
        const aValue = a[column];
        const bValue = b[column];

        if (aValue === bValue) return 0;
        if (aValue == null) return 1;
        if (bValue == null) return -1;

        return typeof aValue === "number" && typeof bValue === "number"
          ? (aValue - bValue) * (direction === "asc" ? 1 : -1)
          : aValue.toString().localeCompare(bValue.toString()) *
              (direction === "asc" ? 1 : -1);
      });

    return filterAndSortData;
  }, [
    rawData,
    debouncedSearchText,
    selectedView,
    sortState,
    userName,
    userEmail,
    userTitle,
  ]);

  useEffect(() => {
    setData(sortedAndFilteredData);
  }, [sortedAndFilteredData]);

  return { isLoading, data, lastUpdated };
};

export default useFetchData;
