import React, { useState, useEffect } from "react";
import {
  HStack,
  IconButton,
  ButtonGroup,
  Input,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { driverBoardTypes, recruitersTypes } from "../../../../types/Types";

// Icons
import { AddIcon } from "@chakra-ui/icons";
import { FaFileExcel } from "react-icons/fa";
import { GrRefresh } from "react-icons/gr";

// Date Picker
import DatePicker from "react-datepicker";

interface ToolBarTypes {
  searchText: string;
  selectedView: string;
  isLoading: boolean;
  data: driverBoardTypes[];
  lastUpdated: Date | null;
  statusView:
    | "All"
    | "Scheduled"
    | "Showed"
    | "No Show"
    | "TDO"
    | "Seated"
    | "Upgraded"
    | "Withdrawn";
  setStatusView: (
    value:
      | "All"
      | "Scheduled"
      | "Showed"
      | "No Show"
      | "TDO"
      | "Seated"
      | "Upgraded"
      | "Withdrawn"
  ) => void;
  setSelectedView: (value: "All" | "Personal") => void;
  setSearchText: (value: string) => void;
  onNewFormOpen: () => void;
  onRefresh: () => void;
  subTeam: "Tasha" | "Austin" | "All";
  setSubTeam: (value: "Tasha" | "Austin" | "All") => void;
  selectedRegistration: string;
  setSelectedRegistration: (value: string) => void;
  setOrientationLocationFilter: (value: string) => void;
  orientationLocationFilter: string;
  recruiters: recruitersTypes[];
  recruiterFilter: string;
  setRecruiterFilter: (value: string) => void;
  selectedDivision: string;
  setSelectedDivision: (
    value: "All Division" | "OTR" | "Dedicated" | "Student"
  ) => void;
  setCostCenter: (value: string) => void;
  selectedCostCenter: string;
  setSelectedWeek: (value: string) => void;
  selectedWeek: string;
}

const ToolBar: React.FC<ToolBarTypes> = ({
  selectedView,
  searchText,
  data,
  isLoading,
  lastUpdated,
  statusView,
  setStatusView,
  setSelectedView,
  setSearchText,
  onNewFormOpen,
  onRefresh,
  subTeam,
  setSubTeam,
  selectedRegistration,
  setSelectedRegistration,
  setOrientationLocationFilter,
  orientationLocationFilter,
  recruiters,
  recruiterFilter,
  setRecruiterFilter,
  selectedDivision,
  setSelectedDivision,
  setCostCenter,
  selectedCostCenter,
  setSelectedWeek,
  selectedWeek,
}) => {
  const isDataAvailable = data && data.length > 0;
  const [processedData, setProcessedData] = useState<driverBoardTypes[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Manipulate columns and convert timezone here
      const modifiedData = data.map((item) => {
        const modifiedArrival = item.Arrival
          ? new Date(item.Arrival).toLocaleString()
          : "null";

        const modifiedCreated = item.Created
          ? new Date(item.Created).toLocaleString()
          : null;
        return {
          ...item,
          Arrival: modifiedArrival,
          Created: modifiedCreated,
        };
      });
      setProcessedData(modifiedData);
    }
  }, [data]);

  return (
    <HStack
      width="100%"
      borderRadius="2xl"
      spacing="10px"
      justifyContent="space-between"
    >
      <ButtonGroup spacing="10px">
        <Input
          variant="filled"
          placeholder="Name or ID"
          width="200px"
          focusBorderColor="orange.400"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Tooltip
          label="Add a driver to the pipeline"
          aria-label="Add New"
          openDelay={500}
        >
          <IconButton
            aria-label="Add New"
            icon={<AddIcon />}
            shadow="md"
            colorScheme="orange"
            tabIndex={-1}
            onClick={onNewFormOpen}
          />
        </Tooltip>
        <Tooltip
          label={
            lastUpdated ? `Last Updated: ${lastUpdated.toLocaleString()}` : ""
          }
          aria-label="Refresh"
          openDelay={500}
        >
          <IconButton
            aria-label="Refresh"
            icon={<GrRefresh />}
            shadow="md"
            colorScheme="blue"
            tabIndex={-1}
            onClick={onRefresh}
            isLoading={isLoading}
          />
        </Tooltip>
        <CSVLink
          data={processedData}
          filename={"driverboard.csv"}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Tooltip
            label="Export what you see to CSV"
            aria-label="Export to CSV"
            openDelay={500}
          >
            <IconButton
              aria-label="Export to CSV"
              icon={<FaFileExcel />}
              shadow="md"
              colorScheme="green"
              isDisabled={!isDataAvailable}
              tabIndex={-1}
            />
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
      <HStack spacing="10px">
        {/* Orientation Week */}
        <DatePicker
          customInput={
            <Input
              size="sm"
              width="100px"
              focusBorderColor="orange.400"
              variant="filled"
            />
          }
          selected={new Date(selectedWeek)}
          onChange={(date) =>
            date
              ? setSelectedWeek(
                  date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })
                )
              : setSelectedWeek("")
          }
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          dateFormat="MM/dd/yyyy"
          filterDate={(date) => date.getDay() === 1}
        />

        {/* Orientation Location Filter */}
        <Select
          size="sm"
          width="100px"
          placeholder="All Location"
          focusBorderColor="orange.400"
          variant="filled"
          value={orientationLocationFilter}
          onChange={(e) => setOrientationLocationFilter(e.target.value)}
        >
          <option value="Dubuque">Dubuque</option>
          <option value="Denton">Denton</option>
          <option value="Dover">Dover</option>
          <option value="Sapulpa">Sapulpa</option>
          <option value="Travel to Truck">Travel to Truck</option>
        </Select>

        {/* Registration Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedRegistration}
          onChange={(e) => setSelectedRegistration(e.target.value)}
        >
          <option value="All">All Registrations</option>
          <option value="Experienced">Experienced</option>
          <option value="All Trainees">All Trainees</option>
          <option value="2 Weeks Trainee">2 Weeks Trainee</option>
          <option value="4 Weeks Trainee">4 Weeks Trainee</option>
        </Select>

        {/* Status Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={statusView}
          onChange={(e) =>
            setStatusView(
              e.target.value as
                | "All"
                | "Scheduled"
                | "Showed"
                | "No Show"
                | "TDO"
                | "Seated"
                | "Upgraded"
                | "Withdrawn"
            )
          }
        >
          <option value="All">All Statuses</option>
          <option value="Scheduled">Scheduled</option>
          <option value="Showed">Showed</option>
          <option value="No Show">No Show</option>
          <option value="TDO">TDO</option>
          <option value="Seated">Seated</option>
          <option value="Upgraded">Upgraded</option>
          <option value="Withdrawn">Withdrawn</option>
        </Select>

        {/* Division Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedDivision}
          onChange={(e) =>
            setSelectedDivision(
              e.target.value as "All Division" | "OTR" | "Dedicated"
            )
          }
        >
          <option value="All Division">All Division</option>
          <option value="OTR">OTR</option>
          <option value="Dedicated">Dedicated</option>
          <option value="Student">Student</option>
        </Select>

        {/* Cost Center Search */}
        <Input
          size="sm"
          variant="filled"
          placeholder="Cost Center"
          width="120px"
          focusBorderColor="orange.400"
          value={selectedCostCenter}
          onChange={(e) => setCostCenter(e.target.value)}
        />

        {/* Personal or All Records Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedView}
          onChange={(e) => {
            setSelectedView(e.target.value as "All" | "Personal");
            setSubTeam("All");
            setRecruiterFilter("");
          }}
        >
          <option value="Personal">Personal</option>
          <option value="All">All Records</option>
        </Select>

        {/* Team Filter */}
        <Select
          size="sm"
          width="100px"
          isDisabled={selectedView !== "All"}
          focusBorderColor="orange.400"
          variant="filled"
          value={subTeam}
          onChange={(e) =>
            setSubTeam(e.target.value as "Tasha" | "Austin" | "All")
          }
        >
          <option value="All">All Team</option>
          <option value="Tasha">Tasha</option>
          <option value="Austin">Austin</option>
        </Select>

        {/* Recruiter Filter */}
        <Select
          size="sm"
          width="100px"
          isDisabled={selectedView === "Personal"}
          placeholder="All Recruiters"
          focusBorderColor="orange.400"
          variant="filled"
          value={recruiterFilter}
          onChange={(e) => setRecruiterFilter(e.target.value)}
        >
          {recruiters.map((recruiter) => (
            <option key={recruiter.Email} value={recruiter.Recruiter}>
              {recruiter.Recruiter}
            </option>
          ))}
        </Select>
      </HStack>
    </HStack>
  );
};

export default ToolBar;
