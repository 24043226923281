import React, { useEffect, useState } from "react";
import { HStack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

// Import types
import { userInfo, driverBoardTypes, recruitersTypes } from "../../types/Types";

// Import components
import Sidebar from "../../components/driverboard/recruiting/SideBar";
import Pipeline from "../../components/driverboard/recruiting/Pipeline";
import Scheduled from "../../components/driverboard/recruiting/Scheduled";
import Processing from "../../components/driverboard/recruiting/Processing";
import AllView from "../../components/driverboard/recruiting/All";
import useFetchData from "../../hooks/driverboard/fetchData";

// Import API
import { apiRead } from "../../api/driverBoardAPI";

const RecruitingDashboard: React.FC<userInfo> = ({
  userName,
  userEmail,
  userTitle,
  userDepartment,
  powerBiReportToken,
}) => {
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState<string>("all");
  const [searchText, setSearchText] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<"All" | "Personal">("All");
  const [subTeam, setSubTeam] = useState<"Tasha" | "Austin" | "All">("All");
  const [recruiters, setRecruiters] = useState<recruitersTypes[]>([]);

  // Sort state
  const [sortState, setSortState] = useState<{
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  }>({
    column: "ID",
    direction: "asc",
  });

  // Fetch pipeline data
  const { isLoading, data, lastUpdated } = useFetchData(
    refresh,
    userName,
    userEmail,
    userTitle,
    searchText,
    selectedView,
    sortState
  );

  // Fetch recruiters data
  const fetchRecruiters = async () => {
    try {
      const payload = {
        process: "recruiter",
      };
      const response = await apiRead(payload);
      setRecruiters(response);
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "Driver Board - Recruiting";

    const searchParams = new URLSearchParams(location.search);
    const ID = searchParams.get("ID");
    if (ID) {
      setSearchText(ID);
    }

    // Refresh data every 30 seconds
    const interval = setInterval(() => {
      setRefresh(!refresh);
    }, 30000);
    return () => clearInterval(interval);
  }, [location, refresh]);

  useEffect(() => {
    fetchRecruiters();
  }, []);

  return (
    <>
      <HStack
        height="calc(100vh - 55px)"
        width="100%"
        padding="10px"
        spacing="10px"
      >
        <Sidebar
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
        {selectedPage === "pipeline" && (
          <Pipeline
            userName={userName}
            userEmail={userEmail}
            userDepartment={userDepartment}
            searchText={searchText}
            setSearchText={setSearchText}
            data={data}
            isLoading={isLoading}
            lastUpdated={lastUpdated}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            sortState={sortState}
            setSortState={setSortState}
            refresh={refresh}
            setRefresh={setRefresh}
            subTeam={subTeam}
            setSubTeam={setSubTeam}
            recruiters={recruiters}
          />
        )}
        {selectedPage === "scheduled" && (
          <Scheduled
            userName={userName}
            userEmail={userEmail}
            userDepartment={userDepartment}
            searchText={searchText}
            setSearchText={setSearchText}
            data={data}
            isLoading={isLoading}
            lastUpdated={lastUpdated}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            sortState={sortState}
            setSortState={setSortState}
            refresh={refresh}
            setRefresh={setRefresh}
            subTeam={subTeam}
            setSubTeam={setSubTeam}
            recruiters={recruiters}
          />
        )}
        {selectedPage === "processing" && (
          <Processing
            userName={userName}
            userEmail={userEmail}
            userDepartment={userDepartment}
            searchText={searchText}
            setSearchText={setSearchText}
            data={data}
            isLoading={isLoading}
            lastUpdated={lastUpdated}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            sortState={sortState}
            setSortState={setSortState}
            refresh={refresh}
            setRefresh={setRefresh}
            subTeam={subTeam}
            setSubTeam={setSubTeam}
            recruiters={recruiters}
          />
        )}
        {selectedPage === "all" && (
          <AllView
            userName={userName}
            userEmail={userEmail}
            userDepartment={userDepartment}
            searchText={searchText}
            setSearchText={setSearchText}
            data={data}
            isLoading={isLoading}
            lastUpdated={lastUpdated}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            sortState={sortState}
            setSortState={setSortState}
            refresh={refresh}
            setRefresh={setRefresh}
            subTeam={subTeam}
            setSubTeam={setSubTeam}
            recruiters={recruiters}
          />
        )}
      </HStack>
    </>
  );
};

export default RecruitingDashboard;
