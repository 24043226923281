import React, { useState, useEffect } from "react";
import {
  HStack,
  IconButton,
  ButtonGroup,
  Input,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { driverBoardTypes, recruitersTypes } from "../../../../types/Types";

// CSV
import { CSVLink } from "react-csv";

// Icons
import { GrRefresh } from "react-icons/gr";
import { FaHotel } from "react-icons/fa6";
import { FaShuttleVan } from "react-icons/fa";

// ToolBar Types
interface ToolBarTypes {
  searchText: string;
  selectedView: string;
  isLoading: boolean;
  data: driverBoardTypes[];
  lastUpdated: Date | null;
  selectedDivision: string;
  setSelectedDivision: (
    value: "All Division" | "OTR" | "Dedicated" | "Student"
  ) => void;
  selectedDate: string;
  setSelectedView: (value: "All" | "Personal") => void;
  setSearchText: (value: string) => void;
  onRefresh: () => void;

  setSelectedDate: (value: string) => void;
  backgroundCheckFilter:
    | "All"
    | "In-Progress"
    | "Completed"
    | "Manager Approval";
  setBackgroundCheckFilter: (
    value: "All" | "In-Progress" | "Completed" | "Manager Approval"
  ) => void;
  subTeam: "Tasha" | "Austin" | "All";
  setSubTeam: (value: "Tasha" | "Austin" | "All") => void;
  travelRequestFilter: "All" | "Requested" | "Booked" | "Cancelled";
  setTravelRequestFilter: (
    value: "All" | "Requested" | "Booked" | "Cancelled"
  ) => void;
  setOrientationLocationFilter: (value: string) => void;
  orientationLocationFilter: string;
  recruiters: recruitersTypes[];
  recruiterFilter: string;
  setRecruiterFilter: (value: string) => void;
}

interface HotelDataTypes {
  OrientationLocation: string | null;
  OrientationDate: string | null;
  DriverName: string | null;
  Arrival: string | null;
  Departure: string | null;
  Hotel: string | null;
  Passenger: string | null;
  Pet: string | null;
}

interface ShuttleDataTypes {
  OrientationLocation: string | null;
  OrientationDate: string | null;
  DriverName: string | null;
  Arrival: string | null;
  Departure: string | null;
  Phone: string | null;
  Email: string | null;
  Transportation: string | null;
  FlightStatus: string | null;
  Flight: string | null;
  ConfirmationTicket: string | null;
  RentalStatus: string | null;
  AgreedCost: string | null;
  FinalCost: string | null;
}

const ToolBar: React.FC<ToolBarTypes> = ({
  selectedView,
  searchText,
  isLoading,
  lastUpdated,
  selectedDivision,
  selectedDate,
  setSelectedView,
  setSearchText,
  onRefresh,
  setSelectedDivision,
  setSelectedDate,
  backgroundCheckFilter,
  setBackgroundCheckFilter,
  subTeam,
  setSubTeam,
  data,
  travelRequestFilter,
  setTravelRequestFilter,
  setOrientationLocationFilter,
  orientationLocationFilter,
  recruiters,
  recruiterFilter,
  setRecruiterFilter,
}) => {
  const isDataAvailable = data && data.length > 0;
  const [hotelData, setHotelData] = useState<HotelDataTypes[]>([]);
  const [shuttleData, setShuttleData] = useState<ShuttleDataTypes[]>([]);

  // Further filter inside the tool bar
  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data.filter((item) => {
        if (!item.OrientationDate) {
          return false;
        }

        if (
          item.Status === "TDO" ||
          item.Status === "Withdrawn" ||
          item.Status === "No Show" ||
          item.Status === "New" ||
          item.Status === "Approved"
        ) {
          return false;
        }

        const itemDate = new Date(item.OrientationDate);
        const selectedDateCheck = new Date(selectedDate);

        return (
          itemDate.getFullYear() === selectedDateCheck.getFullYear() &&
          itemDate.getMonth() === selectedDateCheck.getMonth() &&
          itemDate.getDate() === selectedDateCheck.getDate()
        );
      });

      // Hotel data
      const selectedHotelColumns = filteredData.map((item) => ({
        Week: item.Week,
        OrientationLocation: item.OrientationLocation,
        OrientationDate: item.OrientationDate,
        Status: item.Status,
        DriverName: item.DriverName,
        Recruiter: item.Recruiter,
        Arrival: item.Arrival
          ? new Date(item.Arrival).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : null,
        Departure: item.Departure
          ? new Date(item.Departure).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : null,
        Hotel: item.Hotel,
        Passenger: item.Passenger,
        Pet: item.Pet,
      }));
      selectedHotelColumns.sort((a, b) => {
        if (a.OrientationDate && b.OrientationDate) {
          return (
            new Date(a.OrientationDate).getTime() -
            new Date(b.OrientationDate).getTime()
          );
        }
        return 0;
      });
      setHotelData(selectedHotelColumns);

      // Shuttle data
      const selectedShuttleColumns = filteredData.map((item) => ({
        Week: item.Week,
        OrientationLocation: item.OrientationLocation,
        OrientationDate: item.OrientationDate,
        Status: item.Status,
        DriverName: item.DriverName,
        Recruiter: item.Recruiter,
        Arrival: item.Arrival
          ? new Date(item.Arrival).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : null,
        Departure: item.Departure
          ? new Date(item.Departure).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          : null,

        Phone: item.Phone,
        Email: item.Email,
        Transportation: item.Transportation,
        FlightStatus: item.FlightStatus,
        Flight: item.Flight,
        ConfirmationTicket: item.ConfirmationTicket,
        RentalStatus: item.RentalStatus,
        AgreedCost: item.AgreedCost,
        FinalCost: item.FinalCost,
      }));
      selectedShuttleColumns.sort((a, b) => {
        if (a.OrientationDate && b.OrientationDate) {
          return (
            new Date(a.OrientationDate).getTime() -
            new Date(b.OrientationDate).getTime()
          );
        }
        return 0;
      });
      setShuttleData(selectedShuttleColumns);
    }
  }, [data, selectedDate]);

  return (
    <HStack
      width="100%"
      borderRadius="2xl"
      spacing="10px"
      justifyContent="space-between"
    >
      <ButtonGroup spacing="10px">
        <Input
          variant="filled"
          placeholder="Name or ID"
          width="200px"
          focusBorderColor="orange.400"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />

        <Tooltip
          label={
            lastUpdated ? `Last Updated: ${lastUpdated.toLocaleString()}` : ""
          }
          aria-label="Refresh"
          openDelay={500}
        >
          <IconButton
            aria-label="Refresh"
            icon={<GrRefresh />}
            shadow="md"
            colorScheme="blue"
            tabIndex={-1}
            onClick={onRefresh}
            isLoading={isLoading}
          />
        </Tooltip>
        <CSVLink
          data={hotelData}
          filename={`Hotel List ${selectedDate}.csv`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Tooltip
            label="Export the Hotel List"
            aria-label="Export to CSV"
            openDelay={500}
          >
            <IconButton
              aria-label="Export to CSV"
              icon={<FaHotel />}
              shadow="md"
              colorScheme="green"
              isDisabled={!isDataAvailable}
              tabIndex={-1}
            />
          </Tooltip>
        </CSVLink>
        <CSVLink
          data={shuttleData}
          filename={`Shuttle List ${selectedDate}.csv`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Tooltip
            label="Export the Shuttle List"
            aria-label="Export to CSV"
            openDelay={500}
          >
            <IconButton
              aria-label="Export to CSV"
              icon={<FaShuttleVan />}
              shadow="md"
              colorScheme="green"
              isDisabled={!isDataAvailable}
              tabIndex={-1}
            />
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
      <HStack spacing="10px">
        {/* Orientation Date Filter */}
        <Input
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />

        {/* Orientation Location Filter */}
        <Select
          size="sm"
          width="100px"
          placeholder="All Location"
          focusBorderColor="orange.400"
          variant="filled"
          value={orientationLocationFilter}
          onChange={(e) => setOrientationLocationFilter(e.target.value)}
        >
          <option value="Dubuque">Dubuque</option>
          <option value="Denton">Denton</option>
          <option value="Dover">Dover</option>
          <option value="Sapulpa">Sapulpa</option>
          <option value="Travel to Truck">Travel to Truck</option>
        </Select>

        {/* Division Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedDivision}
          onChange={(e) =>
            setSelectedDivision(
              e.target.value as "All Division" | "OTR" | "Dedicated"
            )
          }
        >
          <option value="All Division">All Division</option>
          <option value="OTR">OTR</option>
          <option value="Dedicated">Dedicated</option>
          <option value="Student">Student</option>
        </Select>

        {/* Travel Request Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={travelRequestFilter}
          onChange={(e) =>
            setTravelRequestFilter(
              e.target.value as "All" | "Requested" | "Booked" | "Cancelled"
            )
          }
        >
          <option value="All">All Travel</option>
          <option value="Requested">Requested</option>
          <option value="Booked">Booked</option>
          <option value="Cancelled">Cancelled</option>
        </Select>

        {/* Background Check Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={backgroundCheckFilter}
          onChange={(e) =>
            setBackgroundCheckFilter(
              e.target.value as
                | "All"
                | "In-Progress"
                | "Completed"
                | "Manager Approval"
            )
          }
        >
          <option value="All">All Background</option>
          <option value="In-Progress">In-Progress</option>
          <option value="Completed">Completed</option>
          <option value="Manager Approval">Manager Approval</option>
        </Select>

        {/* View Filter */}
        <Select
          size="sm"
          width="100px"
          focusBorderColor="orange.400"
          variant="filled"
          value={selectedView}
          onChange={(e) => {
            setSelectedView(e.target.value as "All" | "Personal");
            setSubTeam("All");
            setRecruiterFilter("");
          }}
        >
          <option value="Personal">Personal</option>
          <option value="All">All Records</option>
        </Select>
        <Select
          size="sm"
          width="100px"
          isDisabled={selectedView === "Personal"}
          focusBorderColor="orange.400"
          variant="filled"
          value={subTeam}
          onChange={(e) =>
            setSubTeam(e.target.value as "Tasha" | "Austin" | "All")
          }
        >
          <option value="All">All Team</option>
          <option value="Tasha">Tasha</option>
          <option value="Austin">Austin</option>
        </Select>

        {/* Recruiter Filter */}
        <Select
          size="sm"
          width="100px"
          isDisabled={selectedView === "Personal"}
          placeholder="All Recruiters"
          focusBorderColor="orange.400"
          variant="filled"
          value={recruiterFilter}
          onChange={(e) => setRecruiterFilter(e.target.value)}
        >
          {recruiters.map((recruiter) => (
            <option key={recruiter.Email} value={recruiter.Recruiter}>
              {recruiter.Recruiter}
            </option>
          ))}
        </Select>
      </HStack>
    </HStack>
  );
};

export default ToolBar;
