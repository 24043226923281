import React, { useEffect } from "react";
import { userInfo } from "../../types/Types";
import { Heading, Text, Box, VStack, SimpleGrid } from "@chakra-ui/react";
import { Link } from "react-router-dom";

// Import Icons from React Icons
import { MdPeople } from "react-icons/md";
import { FaTruckMoving } from "react-icons/fa";
import { HiOutlineUserAdd } from "react-icons/hi";
import { SiTrainerroad } from "react-icons/si";
import { MdManageAccounts } from "react-icons/md";

const dashboardItems = [
  {
    title: "Recruiting",
    description: "Manage drivers onboarding processes",
    link: "/driverboard/recruiting",
    icon: <MdPeople />,
  },
  {
    title: "Orientation",
    description: "Track the progress of drivers orientation",
    link: "/driverboard/orientation",
    icon: <HiOutlineUserAdd />,
  },
  {
    title: "Seating",
    description: "Monitor and manage driver seating",
    link: "/driverboard/seating",
    icon: <FaTruckMoving />,
  },
  {
    title: "Trainers",
    description: "View and add information about trainers",
    link: "/driverboard/trainers",
    icon: <SiTrainerroad />,
  },
  {
    title: "Operations",
    description: "Assign driver leaders to drivers",
    link: "/driverboard/operations",
    icon: <MdManageAccounts />,
  },
];

const DriverBoardMenu: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  powerBiReportToken,
}) => {
  useEffect(() => {
    document.title = "Driver Board";
  }, []);

  return (
    <Box
      height="calc(100vh - 55px)"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <VStack
        spacing="10px"
        height="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
        padding="50px"
        overflow="auto"
      >
        <Heading color="gray.800" size="lg" fontWeight="medium">
          Driver Board
        </Heading>
        <Text color="gray.600" fontSize="md">
          Welcome to the Driver Board, {userName}.
        </Text>

        <SimpleGrid
          columns={{ sm: 2, md: 4, lg: 5 }}
          spacing={5}
          pt={5}
          w="full"
        >
          {dashboardItems.map((item, index) => (
            <Box
              key={index}
              as={Link}
              to={item.link}
              p={5}
              shadow="sm"
              borderWidth="1px"
              borderRadius="xl"
              bg="white"
              color="gray.800"
              transition="all 0.3s"
              _hover={{
                boxShadow: "lg",
                cursor: "pointer",
                blur: "2px",
                color: "#EE7700",
              }}
            >
              <VStack spacing={3}>
                <Box fontSize="3xl" color="#1D61AA">
                  {item.icon}
                </Box>
                <Text fontWeight="semibold">{item.title}</Text>
                <Text fontSize="sm" color="gray.800">
                  {item.description}
                </Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default DriverBoardMenu;
