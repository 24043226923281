import React from "react";
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  Image,
  Divider,
} from "@chakra-ui/react";

// Define types for the props passed to the Receipt component
interface ReceiptProps {
  data: BRTActionItemProps;
  onClose: () => void;
}

// Define interface for the data object passed to the Receipt component
interface BRTActionItemProps {
  Driver_x0020_Name: string;
  Driver_x0020_Code: string;
  Assigned: string | null;
  Created: string | null;
  Load_x0020_Number: string | null;
  Reason: string | null;
  Rating: string | null;
  Comments: string | null;
  Completed: string | null;
}

// Receipt component
const Receipt: React.FC<ReceiptProps> = ({ data, onClose }) => {
  // Function to format dates
  const formatDate = (dateString: string | null) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    // Modal for displaying receipt details
    <Modal isOpen={true} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent
        bg="white"
        borderRadius="xl"
        boxShadow="lg"
        textAlign="center"
      >
        <ModalBody>
          <Center>
            {/* Box containing receipt details */}
            <Box
              marginTop="10"
              width="30%"
              p={8}
              borderRadius="xl"
              boxShadow="lg"
              position="relative"
            >
              <Box mt={4} textAlign="left">
                {/* Logo */}
                <Center mb={4}>
                  <Image
                    src="/logo2.png"
                    alt="Logo"
                    objectFit="contain"
                    maxH="70px"
                    mx="auto"
                  />
                </Center>
                {/* Divider */}
                <Divider
                  orientation="horizontal"
                  borderColor="orange.300"
                  my={4}
                  opacity={0.6}
                />
                {/* Submission Details with green checkmark */}
                <Center marginBottom="10">
                  <Text fontWeight="bold" color="green" fontSize="18">
                    ✓ Submission Details
                  </Text>
                </Center>
                {/* Driver name */}
                <Text fontWeight="semibold" color="inkwell">
                  Driver Name:
                </Text>
                <Text>{data?.Driver_x0020_Name || "N/A"}</Text>
              </Box>
              {/* Display other details */}
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Driver Code:
                </Text>
                <Text>{data?.Driver_x0020_Code || "N/A"}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Assigned To:
                </Text>
                <Text>{data?.Assigned || "N/A"}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Load Number:
                </Text>
                <Text>{data?.Load_x0020_Number || "N/A"}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Reason:
                </Text>
                <Text>{data?.Reason || "N/A"}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Rating:
                </Text>
                <Text>{data?.Rating || "N/A"}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Comments:
                </Text>
                <Text>{data?.Comments || "N/A"}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Created:
                </Text>
                <Text>{formatDate(data?.Created)}</Text>
              </Box>
              <Box mt={4} textAlign="left">
                <Text fontWeight="semibold" color="inkwell">
                  Completed:
                </Text>
                <Text>{formatDate(data?.Completed)}</Text>
              </Box>
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Receipt;
