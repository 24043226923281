import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";

interface EmulatorProps {
  isEmulatorOpen: boolean;
  onEmulatorClose: () => void;
}

interface EmulateOption {
  department: string;
  value: string;
}

const Emulator: React.FC<EmulatorProps> = ({
  isEmulatorOpen,
  onEmulatorClose,
}) => {
  const [selectedOption, setSelectedOption] = useState<EmulateOption | null>(
    null
  );

  const [options, setOptions] = useState<EmulateOption[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedOption(null);
    const getEmulateUserOptions = async () => {
      try {
        const apiResponse = await API({
          process: "emulate",
        });

        setOptions(apiResponse);
      } catch (error) {
        console.error("API request failed:", error);
      }
    };

    getEmulateUserOptions();
  }, []);

  const handleSelectChange = (selectedOption: EmulateOption | null) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      const { department, value } = selectedOption;
      const encodedDepartment = encodeURIComponent(department);
      const encodedEmail = encodeURIComponent(value);
      navigate(`/actionitems/emulate/${encodedDepartment}/${encodedEmail}`);
    }
  };

  // navigate back to action items and close the modal
  const handdleExitEmulate = () => {
    navigate(`/actionitems`);
    onEmulatorClose();
    setSelectedOption(null);
  };

  return (
    <Modal
      isOpen={isEmulatorOpen}
      onClose={onEmulatorClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Emulate</ModalHeader>
        <ModalBody>
          <Select
            options={options}
            isSearchable
            placeholder="Search and select..."
            noOptionsMessage={() => "No matches found"}
            onChange={handleSelectChange}
            value={selectedOption}
          />
        </ModalBody>
        <ModalFooter alignItems="center" justifyContent="center">
          {selectedOption && (
            <Button onClick={handdleExitEmulate} colorScheme="red">
              Stop Emulate
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Emulator;
