// In EnterpriseFormModal.tsx
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  HStack,
  Text,
  VStack,
  Textarea,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import MyPDFDocument from "./BusinessCasePDF";
import { purchaseAPI } from "../../api/purchasingAPI";
import { pdf } from "@react-pdf/renderer";

interface VendorSupplierFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  userEmail: string;
  userDepartment: string;
}

export const EnterpriseFormModal: React.FC<VendorSupplierFormModalProps> = ({
  isOpen,
  onClose,
  userEmail,
  userDepartment,
}) => {
  // Set variables here
  const [authors, setAuthors] = useState<string>("");
  const [sponsors, setSponsors] = useState<string>("");
  const [businessProblem, setBusinessProblem] = useState<string>("");
  const [analysis, setAnalysis] = useState<string>("");
  const [solution, setSolution] = useState<string>("");
  const [goals, setGoals] = useState<string>("");
  const [deliverables, setDeliverables] = useState<string>("");
  const [strategicAlignment, setStrategicAlignment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  // Reset Form
  const resetForm = () => {
    setAuthors("");
    setSponsors("");
    setBusinessProblem("");
    setAnalysis("");
    setSolution("");
    setGoals("");
    setDeliverables("");
    setStrategicAlignment("");
  };

  const validateForm = (): boolean => {
    if (
      authors.trim() === "" ||
      sponsors.trim() === "" ||
      businessProblem.trim() === "" ||
      analysis.trim() === "" ||
      solution.trim() === "" ||
      goals.trim() === "" ||
      deliverables.trim() === "" ||
      strategicAlignment.trim() === ""
    ) {
      return false;
    }
    return true;
  };

  // Submit PDF
  const SubmitPDF = async () => {
    setIsLoading(true);

    if (validateForm()) {
      const pdfObject = {
        authors: authors,
        sponsors: sponsors,
        businessProblem: businessProblem,
        analysis: analysis,
        solution: solution,
        goals: goals,
        deliverables: deliverables,
        strategicAlignment: strategicAlignment,
      };

      try {
        const blob = await pdf(<MyPDFDocument data={pdfObject} />).toBlob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = async () => {
          const base64data = reader.result;

          if (typeof base64data === "string") {
            const base64Content = base64data.split(",")[1];

            const requestObject = {
              pdfObject: pdfObject,
              pdfFile: base64Content,
            };

            const response: any = await purchaseAPI(
              requestObject,
              "submitEnterpriseRequest",
              userEmail
            );

            if (response?.status === 200) {
              resetForm();
              toast({
                title: "Success",
                description: "Request has been submitted",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top",
              });
              setIsLoading(false);
              onClose();
            } else {
              resetForm();
              toast({
                title: "Error",
                description: "Submission failed.",
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top",
              });
              setIsLoading(false);
              onClose();
            }
          } else {
            console.error("Failed to read the file as a base64 string");
          }
        };
      } catch {}
    } else {
      toast({
        title: "Error",
        description: "You have not filled out the required fields.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });

      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Purchase Request</ModalHeader>

        <ModalBody>
          <VStack width="100%" display="flex" justifyContent="space-evenly">
            <Text color="red.600" paddingBottom="5">
              Your request will be submitted to an ELC member. Be sure the
              submission meets professional standards.
            </Text>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Author(s)*</Text>
              <Input
                value={authors}
                onChange={(e) => setAuthors(e.target.value)}
                placeholder="John Smith, Jane Smith"
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Sponsor(s)*</Text>
              <Input
                value={sponsors}
                onChange={(e) => setSponsors(e.target.value)}
                placeholder="Jane Smith, John Smith"
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Business Problem*</Text>
              <Textarea
                value={businessProblem}
                onChange={(e) => setBusinessProblem(e.target.value)}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Analysis*</Text>
              <Textarea
                value={analysis}
                onChange={(e) => setAnalysis(e.target.value)}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Solution*</Text>
              <Textarea
                value={solution}
                onChange={(e) => setSolution(e.target.value)}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Goals*</Text>
              <Textarea
                value={goals}
                onChange={(e) => setGoals(e.target.value)}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Deliverables*</Text>
              <Textarea
                value={deliverables}
                onChange={(e) => setDeliverables(e.target.value)}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
            <HStack width="100%" display="flex" justifyContent="space-between">
              <Text color="blackAlpha.600">Strategic Alignment*</Text>
              <Textarea
                value={strategicAlignment}
                onChange={(e) => setStrategicAlignment(e.target.value)}
                borderColor="blackAlpha.300"
                _hover={{ borderColor: "blackAlpha.300" }}
                _focus={{ borderColor: "blackAlpha.300", boxShadow: "none" }}
                _active={{ borderColor: "blackAlpha.300" }}
                width="80%"
              />
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isLoading}
            colorScheme="orange"
            onClick={SubmitPDF}
            width="10%"
          >
            {isLoading ? <Spinner size="sm" color="white" /> : "Submit"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
