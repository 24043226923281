import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  Textarea,
  Select,
  FormErrorMessage,
  useToast,
  Text,
  IconButton,
  Checkbox,
  Stack,
  InputGroup,
  InputLeftElement,
  CloseButton,
  DrawerCloseButton,
} from "@chakra-ui/react";

// Import Libraries for API, File Upload, and UUID
import { v4 as uuidv4 } from "uuid";
import { apiRead, apiWrite } from "../../../../api/driverBoardAPI";
import { BlobServiceClient } from "@azure/storage-blob";
import DatePicker from "react-datepicker";

// import icons
import { CloseIcon } from "@chakra-ui/icons";
import { FaFilePdf, FaFileWord, FaFileImage, FaFileAlt } from "react-icons/fa";

// Import Types
import {
  sourcesTypes,
  costCentersTypes,
  recruitersTypes,
  driverBoardTypes,
} from "../../../../types/Types";

// Import US States
import states from "../../../../lists/States";

const steps = [
  { title: "Driver", description: "" },
  { title: "Identification", description: "" },
  { title: "Company Profile", description: "" },
  { title: "Orientation", description: "" },
  { title: "Submit", description: "" },
];

// Setting for File Upload
const ACCEPTED_FILE_TYPES = ".png, .jpeg, .jpg, .pdf, .doc, .docx";
const ALLOWED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const INSTRUCTION_TEXT =
  "Click or drag and drop up to 5 files. Maximum file size is 50MB. We accept PNG, JPEG, PDF, or Word documents.";

// Options for Truck Request
const truckRequestOptions = ["Freightliner", "International"].sort();

// Options for Truck Preference
const truckPreferenceOptions = [
  "APU",
  "Double Bunk",
  "Newest Available",
  "Non-Pet",
  "Non-Smoke",
  "Oldest Available",
].sort();

const initialFormData: driverBoardTypes = {
  ID: null,
  FirstName: "",
  LastName: "",
  DriverName: "",
  Nickname: "",
  Phone: "",
  Email: "",
  Address: "",
  City: "",
  State: "",
  ZIP: "",
  License: "",
  LicenseState: "",
  Veteran: "",
  DOB: "",
  MedicalExp: "",
  Physical: "",
  DriverType: "",
  CostCenter: "",
  Division: "",
  TeamDriver: "",
  TeamWith: "",
  WillingtoTrain: "",
  DriverReferral: "",
  Source: "",
  TruckPreference: "",
  TruckRequest: "",
  OrientationLocation: "",
  OrientationDate: "",
  Arrival: "",
  Transportation: "",
  Hotel: "",
  Passenger: "",
  Pet: "",
  Reimbursement: "",
  Luma: "",
  Recruiter: "",
  RecruiterEmail: "",
  Team: "",
  Subteam: "",
  Processor: "",
  ProcessorEmail: "",
  Note: "",
  Approval: "",
  Registration: "",
  Created: "",
  Status: "",
  ApprovedBy: "",
  Departure: "",
  RentalStatus: "",
  Flight: "",
  FlightStatus: "",
  BackgroundETA: "",
  BackgroundPercentage: "",
  BackgroundStatus: "",
  AgreedCost: "",
  ConfirmationTicket: "",
  FinalCost: "",
  ProcessorAS400: false,
  RecruiterAS400: false,
  Week: "",
  WeekDay: "",
  SecondSeat: "No",
  Withdrawal: "",
  WithdrawalReason: "",
  WithdrawalComment: "",
  WithdrawnBy: "",
  Show: "",
  DriverCode: "",
  DriverCodeRegistered: false,
  HairTest: "",
  HairTestStatus: "",
  UrineTest: "",
  UrineTestStatus: "",
  Seated: "",
  TDO: "",
  TDOReason: "",
  TDOComment: "",
  OrientationBonusStatus: "",
  Board: "",
  DriverLeader: "",
  DriverLeaderEmail: "",
  TruckNumber: "",
  TruckStatus: "",
  TravelToTruck: false,
  TravelToTruckCost: "",
  TruckLocation: "",
  SeatingDate: "",
  SignOnBonusStatus: "",
  ReimbursementStatus: "",
  Bonus: "",
  Trainer: "",
  TrainerEmail: "",
  Tag: "",
  TravelRequest: "",
  TravelRequestStatus: "",
  LatestUpdate: "",
  Compliance: "",
  ComplianceStatus: "",
};

interface NewFormType {
  userName: string;
  userEmail: string;
  userDepartment: string;
  isNewFormOpen: boolean;
  onNewFormClose: () => void;
  onRefresh: () => void;
  recruiters: recruitersTypes[];
}

const NewForm: React.FC<NewFormType> = ({
  isNewFormOpen,
  userName,
  userDepartment,
  userEmail,
  onNewFormClose,
  onRefresh,
  recruiters,
}) => {
  const toast = useToast();
  const [formData, setFormData] = useState(initialFormData);
  const [blockedDates, setBlockedDates] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [dragging, setDragging] = useState(false);
  const [sources, setSources] = useState<sourcesTypes[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [costCenters, setCostCenters] = useState<costCentersTypes[]>([]);
  const [isZipValid, setIsZipValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  // Stepper Hooks
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const isLastStep = activeStep === steps.length - 1;
  // Get Sources for the form
  const fetchSources = async () => {
    try {
      const payload = {
        process: "source",
      };
      const response = await apiRead(payload);
      setSources(response);
    } catch (error) {}
  };

  // Get Cost Centers for the form
  const fetchCostCenters = async () => {
    try {
      const payload = {
        process: "cost center",
      };
      const response = await apiRead(payload);
      setCostCenters(response);
    } catch (error) {}
  };

  // Function to handle input change for form fields
  const onInputChange = (key: string, value: string) => {
    let newFormData = { ...formData, [key]: value };

    if (key === "Recruiter") {
      const selectedRecruiter = recruiters.find((r) => r.Recruiter === value);
      if (selectedRecruiter) {
        newFormData = {
          ...newFormData,
          RecruiterEmail: selectedRecruiter.Email,
        };
      }
    }

    if (key === "CostCenter") {
      const selectedCostCenter = costCenters.find((c) => c.Name === value);
      if (selectedCostCenter) {
        newFormData = { ...newFormData, Division: selectedCostCenter.Division };
      }
    }
    setFormData(newFormData);

    if (key === "zip") {
      setIsZipValid(value.length === 5);
    }
  };

  // Function to handle the check box changes for truck preference and truck request
  const handleCheckboxChange = (
    option: string,
    formDataKey: string,
    setFormData: React.Dispatch<React.SetStateAction<any>>
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev: any) => {
        const updatedFormData = { ...prev };
        const prevString = updatedFormData[formDataKey] || "";
        const optionsArray = prevString.split(", ").filter(Boolean);
        const optionIndex = optionsArray.indexOf(option);
        if (event.target.checked) {
          if (optionIndex === -1) {
            optionsArray.push(option);
          }
        } else {
          if (optionIndex !== -1) {
            optionsArray.splice(optionIndex, 1);
          }
        }
        optionsArray.sort();
        updatedFormData[formDataKey] = optionsArray.join(", ");
        return updatedFormData;
      });
    };
  };

  const handleNext = async () => {
    if (!validateCurrentStep()) {
      return;
    }

    if (isLastStep) {
      try {
        await submitForm();
        onRefresh();
        onNewFormClose();
        setActiveStep(0);
      } catch (error) {
        toast({
          title: "Submission Failed",
          description:
            "There was a problem submitting the form. Please try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } else {
      goToNext();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onNewFormClose();
    } else {
      goToPrevious();
    }
  };

  // Function to handle the validation of the form before submission
  const validateCurrentStep = () => {
    if (activeStep === 0) {
      if (
        !formData.FirstName ||
        !formData.LastName ||
        !formData.DOB ||
        !isEmailValid ||
        !formData.Phone ||
        !formData.Address ||
        !formData.City ||
        !formData.State ||
        !formData.ZIP ||
        !isZipValid ||
        !formData.Email ||
        !formData.Registration
      ) {
        toast({
          title: "Error",
          description: "Please fill out all required fields in Driver.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        return false;
      }
    }

    if (activeStep === 1) {
      if (
        !formData.License ||
        !formData.LicenseState ||
        !formData.Veteran ||
        !formData.MedicalExp ||
        !formData.Physical
      ) {
        toast({
          title: "Error",
          description: "Please fill out all required fields in Identification.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        return false;
      }
    }

    if (activeStep === 2) {
      if (
        !formData.DriverType ||
        !formData.CostCenter ||
        !formData.TeamDriver ||
        !formData.WillingtoTrain ||
        !formData.Recruiter ||
        !formData.Source ||
        !formData.SecondSeat
      ) {
        toast({
          title: "Error",
          description:
            "Please fill out all required fields in Company Profile.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        return false;
      }
    }

    if (activeStep === 3) {
      if (!formData.OrientationLocation || !formData.OrientationDate) {
        toast({
          title: "Error",
          description: "Please fill out all required fields in Orientation.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        return false;
      }
    }

    if (activeStep === 4) {
      if (!files.length) {
        toast({
          title: "Error",
          description: "Please upload at least one file.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        return false;
      }
    }

    return true;
  };

  // Function to submit the form
  const submitForm = async () => {
    const dob = formData.DOB ? new Date(formData.DOB) : new Date();
    const currentYear = new Date().getFullYear();
    const dobYear = dob.getFullYear();
    const age = currentYear - dobYear;

    try {
      setIsSubmitting(true);
      const payload = {
        process: "new",
        userName: userName,
        userEmail: userEmail,
        form: formData,
        Age: age,
      };
      const response = await apiWrite(payload);

      if (!response || !response.id) {
        throw new Error("Invalid API response. ID is missing.");
      }
      const { id } = response;

      // Only proceed with file upload if there are files
      if (files.length > 0) {
        for (const file of files) {
          await uploadFileToBlob(file, id);
        }
      }

      setIsSubmitting(false);
      setFormData({ ...initialFormData });
      setFiles([]);
      toast({
        title: "Submission Successful",
        description: "Driver has been scheduled",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: "Submission Failed",
        description:
          "There was a problem submitting the form. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to handle the file upload
  const borderColor = useMemo(
    () => (dragging ? "teal.300" : "gray.300"),
    [dragging]
  );

  // Function to format file size
  const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  };

  // Function to get file icons
  const getFileIcons = (file: File) => {
    const fileName = file.name.toLowerCase();
    const icon = fileName.endsWith(".pdf") ? (
      <FaFilePdf size="20px" color="#E53E3E" />
    ) : fileName.endsWith(".doc") || fileName.endsWith(".docx") ? (
      <FaFileWord size="20px" color="#3182CE" />
    ) : fileName.endsWith(".png") ||
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".jpeg") ? (
      <FaFileImage size="20px" color="#DD6B20" />
    ) : (
      <FaFileAlt size="20px" color="#718096" />
    );

    return <span style={{ marginRight: "10px" }}>{icon}</span>;
  };

  // Function to handle files being dragged
  const handleDrag =
    (isDragging: boolean) => (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(isDragging);
    };
  // Function to handle dropping files
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrag(false)(e);
    const { files } = e.dataTransfer;
    if (files) {
      const fileArray = Array.from(files).filter((file) =>
        ALLOWED_FILE_TYPES.includes(file.type)
      );
      if (fileArray.length > 5) {
        toast({
          title: "Maximum files exceeded",
          description: "You can only upload up to 5 files.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      const validFiles = fileArray.filter((file) => file.size > 0);
      if (validFiles.length > 0) {
        setFiles(validFiles);
      } else {
        toast({
          title: "Invalid File(s)",
          description: "Dropped file(s) are empty or invalid.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  // Function to handle file input change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    // Check if files were selected
    if (files && files.length > 0) {
      const fileArray = Array.from(files).filter((file) =>
        ALLOWED_FILE_TYPES.includes(file.type)
      );
      if (fileArray.length > 5) {
        toast({
          title: "Maximum files exceeded",
          description: "You can only upload up to 5 files.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      const validFiles = fileArray.filter((file) => file.size > 0);
      if (validFiles.length > 0) {
        setFiles(validFiles);
      } else {
        toast({
          title: "Invalid File(s)",
          description: "Selected file(s) are empty or invalid.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  // Function to upload file to Azure Blob Storage
  const uploadFileToBlob = async (file: File, uploadId: string) => {
    // Generate a unique file name using UUID
    const uniqueFileName = `${uuidv4()}-${file.name}`;
    // Get the credentials from the environment variables
    const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
    const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
    const containerName =
      process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME || "";
    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(uniqueFileName);

    // Determine the MIME type of the file
    const contentType = file.type || "application/octet-stream";

    try {
      //  Upload file data with the correct ContentType
      await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024,
        blobHTTPHeaders: {
          blobContentType: contentType,
        },
      });

      // Set metadata for the blob
      const metadata = {
        originalFileName: file.name,
        uploadId: uploadId,
        uploadedBy: userName,
        userEmail: userEmail,
        userDepartment: userDepartment,
        uploadTime: new Date().toISOString(),
      };
      await blockBlobClient.setMetadata(metadata);
      // Set tags for the blob
      const tags = {
        uploadId: uploadId,
      };
      await blockBlobClient.setTags(tags);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCostCenters();
    fetchSources();
  }, []);

  return (
    <Modal
      isOpen={isNewFormOpen}
      onClose={onNewFormClose}
      size="6xl"
      isCentered
    >
      <ModalOverlay />
      <CloseButton onClick={onNewFormClose} />

      <ModalContent
        style={{ height: "80%", overflowX: "hidden", overflowY: "auto" }}
      >
        <DrawerCloseButton onClick={onNewFormClose} />
        <ModalHeader background="blackAlpha.200">
          Register Driver for Pipeline
        </ModalHeader>
        <ModalBody pb={6}>
          <Stepper index={activeStep} colorScheme="orange">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Grid templateColumns="repeat(4, 1fr)" gap={6} mt="20px">
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    value={formData.FirstName || ""}
                    onChange={(e) => onInputChange("FirstName", e.target.value)}
                    borderColor={!formData.FirstName ? undefined : "green.500"}
                    borderWidth={!formData.FirstName ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    value={formData.LastName || ""}
                    onChange={(e) => onInputChange("LastName", e.target.value)}
                    borderColor={!formData.LastName ? undefined : "green.500"}
                    borderWidth={!formData.LastName ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Date of Birth</FormLabel>
                  <Input
                    type="date"
                    value={formData.DOB || ""}
                    onChange={(e) => onInputChange("DOB", e.target.value)}
                    max={
                      new Date(
                        new Date().getFullYear() - 18,
                        new Date().getMonth(),
                        new Date().getDate()
                      )
                        .toISOString()
                        .split("T")[0]
                    }
                    borderColor={!formData.DOB ? undefined : "green.500"}
                    borderWidth={!formData.DOB ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Nickname (optional)</FormLabel>
                  <Input
                    value={formData.Nickname || ""}
                    onChange={(e) => onInputChange("Nickname", e.target.value)}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={!isEmailValid || formData.Email === null}
                >
                  <FormLabel>Email</FormLabel>
                  <Input
                    value={formData.Email || ""}
                    onChange={(e) => {
                      const email = e.target.value;
                      onInputChange("Email", email);
                      const emailRegex =
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                      setIsEmailValid(emailRegex.test(email));
                    }}
                    borderColor={
                      !isEmailValid || formData.Email === ""
                        ? undefined
                        : "green.500"
                    }
                    borderWidth={
                      !isEmailValid || formData.Email === "" ? undefined : "2px"
                    }
                  />
                  <FormErrorMessage>
                    {isEmailValid || formData.Email === ""
                      ? null
                      : "Please enter a valid email address."}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    value={formData.Phone || ""}
                    onChange={(e) => onInputChange("Phone", e.target.value)}
                    borderColor={!formData.Phone ? undefined : "green.500"}
                    borderWidth={!formData.Phone ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isRequired>
                  <FormLabel>Address</FormLabel>
                  <Input
                    value={formData.Address || ""}
                    onChange={(e) => onInputChange("Address", e.target.value)}
                    borderColor={!formData.Address ? undefined : "green.500"}
                    borderWidth={!formData.Address ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>City</FormLabel>
                  <Input
                    value={formData.City || ""}
                    onChange={(e) => onInputChange("City", e.target.value)}
                    borderColor={!formData.City ? undefined : "green.500"}
                    borderWidth={!formData.City ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>State</FormLabel>
                  <Select
                    value={formData.State || ""}
                    onChange={(e) => onInputChange("State", e.target.value)}
                    placeholder="Select state"
                    borderColor={!formData.State ? undefined : "green.500"}
                    borderWidth={!formData.State ? undefined : "2px"}
                  >
                    {states.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={!isZipValid}>
                  <FormLabel>Zip</FormLabel>
                  <Input
                    value={formData.ZIP || ""}
                    onChange={(e) => onInputChange("ZIP", e.target.value)}
                    maxLength={5}
                    borderColor={
                      !formData.ZIP
                        ? undefined
                        : isZipValid
                        ? "green.500"
                        : "red.500"
                    }
                    borderWidth={!formData.ZIP ? undefined : "2px"}
                  />
                  <FormErrorMessage>
                    {isZipValid
                      ? null
                      : "Zip code must have exactly 5 characters."}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Registration</FormLabel>
                  <Select
                    value={formData.Registration || ""}
                    onChange={(e) =>
                      onInputChange("Registration", e.target.value)
                    }
                    placeholder="Select"
                    borderColor={
                      !formData.Registration ? undefined : "green.500"
                    }
                    borderWidth={!formData.Registration ? undefined : "2px"}
                  >
                    <option value="Experienced">Experienced</option>
                    <option value="2 Weeks Trainee">2 Weeks Trainee </option>
                    <option value="4 Weeks Trainee">4 Weeks Trainee </option>
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid templateColumns="repeat(4, 1fr)" gap={6} mt="20px">
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Driver License</FormLabel>
                  <Input
                    value={formData.License || ""}
                    onChange={(e) => onInputChange("License", e.target.value)}
                    borderColor={!formData.License ? undefined : "green.500"}
                    borderWidth={!formData.License ? undefined : "2px"}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Driver License State</FormLabel>
                  <Select
                    value={formData.LicenseState || ""}
                    onChange={(e) =>
                      onInputChange("LicenseState", e.target.value)
                    }
                    placeholder="Select state"
                    borderColor={
                      !formData.LicenseState ? undefined : "green.500"
                    }
                    borderWidth={!formData.LicenseState ? undefined : "2px"}
                  >
                    {states.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Veteran</FormLabel>
                  <Select
                    value={formData.Veteran || ""}
                    onChange={(e) => onInputChange("Veteran", e.target.value)}
                    placeholder="Select"
                    borderColor={!formData.Veteran ? undefined : "green.500"}
                    borderWidth={!formData.Veteran ? undefined : "2px"}
                  >
                    <option value="Not a Veteran">Not a Veteran</option>
                    <option value="Air Force">Air Force</option>
                    <option value="Army">Army</option>
                    <option value="Coast Guard">Coast Guard</option>
                    <option value="Marines">Marines</option>
                    <option value="Navy">Navy</option>
                    <option value="National Guard">National Guard</option>
                    <option value="Reserves">Reserves</option>
                    <option value="Space Force">Space Force</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Medical Exp</FormLabel>
                  <Input
                    value={formData.MedicalExp || ""}
                    type="date"
                    onChange={(e) =>
                      onInputChange("MedicalExp", e.target.value)
                    }
                    borderColor={!formData.MedicalExp ? undefined : "green.500"}
                    borderWidth={!formData.MedicalExp ? undefined : "2px"}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Physical</FormLabel>
                  <Select
                    value={formData.Physical || ""}
                    placeholder="Select"
                    onChange={(e) => onInputChange("Physical", e.target.value)}
                    borderColor={!formData.Physical ? undefined : "green.500"}
                    borderWidth={!formData.Physical ? undefined : "2px"}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid templateColumns="repeat(4, 1fr)" gap={6} mt="20px">
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Driver Type</FormLabel>
                  <Select
                    value={formData.DriverType || ""}
                    placeholder="Select"
                    onChange={(e) =>
                      onInputChange("DriverType", e.target.value)
                    }
                    borderColor={!formData.DriverType ? undefined : "green.500"}
                    borderWidth={!formData.DriverType ? undefined : "2px"}
                  >
                    <option value="Company">Company</option>
                    <option value="Lease">Lease</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Cost Center</FormLabel>
                  <Select
                    value={formData.CostCenter || ""}
                    placeholder="Select"
                    borderColor={!formData.CostCenter ? undefined : "green.500"}
                    borderWidth={!formData.CostCenter ? undefined : "2px"}
                    onChange={(e) =>
                      onInputChange("CostCenter", e.target.value)
                    }
                  >
                    {costCenters
                      .sort((a, b) => a.Name.localeCompare(b.Name))
                      .map((costCenter) => (
                        <option key={costCenter.ID} value={costCenter.Name}>
                          {costCenter.Name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Team Type</FormLabel>
                  <Select
                    value={formData.TeamDriver || ""}
                    placeholder="Select"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value === "No Team") {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          TeamDriver: value,
                          SecondSeat: "No",
                          TeamWith: "",
                        }));
                      } else {
                        onInputChange("TeamDriver", value);
                      }
                    }}
                    borderColor={!formData.TeamDriver ? undefined : "green.500"}
                    borderWidth={!formData.TeamDriver ? undefined : "2px"}
                  >
                    <option value="No Team">No Team</option>
                    <option value="New">New</option>
                    <option value="Existing">Existing</option>
                  </Select>
                </FormControl>
              </GridItem>
              {formData.TeamDriver === "Existing" ||
              formData.TeamDriver === "New" ? (
                <>
                  <GridItem>
                    <FormControl
                      isRequired={
                        formData.TeamDriver === "Existing" ||
                        formData.TeamDriver === "New"
                          ? true
                          : false
                      }
                    >
                      <FormLabel>Team With</FormLabel>
                      <Input
                        value={formData.TeamWith || ""}
                        onChange={(e) =>
                          onInputChange("TeamWith", e.target.value)
                        }
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isRequired>
                      <FormLabel>2nd Seat</FormLabel>
                      <Select
                        borderWidth={!formData.SecondSeat ? undefined : "2px"}
                        borderColor={
                          !formData.SecondSeat ? undefined : "green.500"
                        }
                        value={formData.SecondSeat || "No"}
                        onChange={(e) =>
                          onInputChange("SecondSeat", e.target.value)
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                </>
              ) : null}

              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Willing to Train</FormLabel>
                  <Select
                    value={formData.WillingtoTrain || ""}
                    borderColor={
                      !formData.WillingtoTrain ? undefined : "green.500"
                    }
                    borderWidth={!formData.WillingtoTrain ? undefined : "2px"}
                    placeholder="Select"
                    onChange={(e) =>
                      onInputChange("WillingtoTrain", e.target.value)
                    }
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Possible">Possible</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Driver Referral</FormLabel>
                  <Input
                    value={formData.DriverReferral || ""}
                    onChange={(e) =>
                      onInputChange("DriverReferral", e.target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Recruiter</FormLabel>
                  <Select
                    value={formData.Recruiter || ""}
                    onChange={(e) => onInputChange("Recruiter", e.target.value)}
                    placeholder="Select"
                    borderColor={!formData.Recruiter ? undefined : "green.500"}
                    borderWidth={!formData.Recruiter ? undefined : "2px"}
                  >
                    {recruiters.map((recruiter) => (
                      <option key={recruiter.Email} value={recruiter.Recruiter}>
                        {recruiter.Recruiter}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Source</FormLabel>
                  <Select
                    value={formData.Source || ""}
                    onChange={(e) => onInputChange("Source", e.target.value)}
                    placeholder="Select"
                    borderColor={!formData.Source ? undefined : "green.500"}
                    borderWidth={!formData.Source ? undefined : "2px"}
                  >
                    {sources
                      .filter((source) => source.Title !== "Rehire")
                      .sort((a, b) => a.Title.localeCompare(b.Title))
                      .map((source) => (
                        <option key={source.Title} value={source.Title}>
                          {source.Title}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          )}

          {activeStep === 3 && (
            <Grid templateColumns="repeat(4, 1fr)" gap={6} mt="20px">
              {/* Orientation Location */}
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Orientation Location</FormLabel>
                  <Select
                    value={formData.OrientationLocation || ""}
                    onChange={(e) =>
                      onInputChange("OrientationLocation", e.target.value)
                    }
                    placeholder="Select"
                    borderColor={
                      !formData.OrientationLocation ? undefined : "green.500"
                    }
                    borderWidth={
                      !formData.OrientationLocation ? undefined : "2px"
                    }
                  >
                    <option value="Dubuque">Dubuque</option>
                    <option value="Denton">Denton</option>
                    <option value="Dover">Dover</option>
                    <option value="Sapulpa">Sapulpa</option>
                    <option value="Travel to Truck">Travel to Truck</option>
                  </Select>
                </FormControl>
              </GridItem>

              {/* Orientation Date */}
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Orientation Date</FormLabel>
                  <DatePicker
                    selected={
                      formData.OrientationDate
                        ? new Date(formData.OrientationDate)
                        : null
                    }
                    onChange={(date: Date | null) => {
                      if (date) {
                        onInputChange("OrientationDate", date.toISOString());
                      } else {
                        onInputChange("OrientationDate", "");
                      }
                    }}
                    customInput={
                      <Input
                        width="250px"
                        borderWidth={
                          !formData.OrientationDate ? undefined : "2px"
                        }
                        borderColor={
                          !formData.OrientationDate ? undefined : "green.500"
                        }
                      />
                    }
                    excludeDates={[
                      ...blockedDates.map((dateStr) => new Date(dateStr)),
                      new Date(new Date().setHours(0, 0, 0, 0)),
                    ]}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Arrival Date</FormLabel>
                  <Input
                    type="datetime-local"
                    min={new Date(new Date().setDate(new Date().getDate() + 1))
                      .toISOString()
                      .slice(0, 16)}
                    max={new Date(
                      new Date().setDate(new Date().getDate() + 120)
                    )
                      .toISOString()
                      .slice(0, 16)}
                    value={formData.Arrival || ""}
                    onChange={(e) => onInputChange("Arrival", e.target.value)}
                  />
                </FormControl>
              </GridItem>

              {/* Transportation */}
              <GridItem>
                <FormControl>
                  <FormLabel>Transportation</FormLabel>
                  <Select
                    value={formData.Transportation || ""}
                    onChange={(e) =>
                      onInputChange("Transportation", e.target.value)
                    }
                    placeholder="Select"
                  >
                    <option value="Bus">Bus</option>
                    <option value="Driving">Driving</option>
                    <option value="Flight - AA">Flight - AA</option>
                    <option value="Flight - Delta">Flight - Delta</option>
                    <option value="Flight - Other">Flight - Other</option>
                    <option value="Rental Car - ENT">Rental Car - ENT</option>
                    <option value="Rental Car - HERTZ">
                      Rental Car - HERTZ
                    </option>
                    <option value="Uhaul">Uhaul</option>
                    <option value="Other">Other</option>
                  </Select>
                </FormControl>
              </GridItem>
              {/* Reimpursement Amount in dollars in the USD in front of it */}
              <GridItem>
                <FormControl>
                  <FormLabel>Reimbursement Amount</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Text>$</Text>}
                    />
                    <Input
                      value={formData.Reimbursement || ""}
                      type="number"
                      placeholder="Amount"
                      onChange={(e) =>
                        onInputChange("Reimbursement", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>

              <GridItem colSpan={4}>
                <FormControl>
                  <FormLabel>Accommodation(s)</FormLabel>
                  <Stack spacing={[1, 5]} direction={["column", "row"]}>
                    <Checkbox
                      size="lg"
                      colorScheme="green"
                      isChecked={formData.Hotel === "Yes"}
                      onChange={(e) =>
                        onInputChange("Hotel", e.target.checked ? "Yes" : "")
                      }
                    >
                      Hotel
                    </Checkbox>
                    <Checkbox
                      size="lg"
                      colorScheme="green"
                      isChecked={formData.Passenger === "Yes"}
                      onChange={(e) =>
                        onInputChange(
                          "Passenger",
                          e.target.checked ? "Yes" : ""
                        )
                      }
                    >
                      Passenger
                    </Checkbox>
                    <Checkbox
                      size="lg"
                      colorScheme="green"
                      isChecked={formData.Pet === "Yes"}
                      onChange={(e) =>
                        onInputChange("Pet", e.target.checked ? "Yes" : "")
                      }
                    >
                      Pet
                    </Checkbox>
                  </Stack>
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl>
                  <FormLabel>Truck Request(s)</FormLabel>
                  <Stack spacing={[1, 5]} direction={["column", "row"]}>
                    {truckRequestOptions.map((option) => (
                      <Checkbox
                        key={option}
                        size="lg"
                        colorScheme="green"
                        isChecked={
                          formData.TruckRequest
                            ? formData.TruckRequest.includes(option)
                            : false
                        }
                        onChange={handleCheckboxChange(
                          option,
                          "TruckRequest",
                          setFormData
                        )}
                      >
                        {option}
                      </Checkbox>
                    ))}
                  </Stack>
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl>
                  <FormLabel>Truck Preference(s)</FormLabel>
                  <Stack spacing={[1, 5]} direction={["column", "row"]}>
                    {truckPreferenceOptions.map((option) => (
                      <Checkbox
                        key={option}
                        size="lg"
                        colorScheme="green"
                        isChecked={
                          formData.TruckPreference
                            ? formData.TruckPreference.includes(option)
                            : false
                        }
                        onChange={handleCheckboxChange(
                          option,
                          "TruckPreference",
                          setFormData
                        )}
                      >
                        {option}
                      </Checkbox>
                    ))}
                  </Stack>
                </FormControl>
              </GridItem>
            </Grid>
          )}

          {activeStep === 4 && (
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="20px">
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>
                    New Hire Questionnaire Form & Tenstreet Document (Required
                    During Confirmation for Orientation)
                  </FormLabel>
                  <Box
                    p={5}
                    borderWidth="2px"
                    borderColor={borderColor}
                    borderStyle="dashed"
                    rounded="md"
                    onDragOver={handleDrag(true)}
                    onDragLeave={handleDrag(false)}
                    onDrop={handleDrop}
                    width="100%"
                    onClick={() => fileInputRef.current?.click()}
                    cursor="pointer"
                  >
                    <input
                      type="file"
                      accept={ACCEPTED_FILE_TYPES}
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <Text color="blackAlpha.500" fontWeight="semibold">
                      {INSTRUCTION_TEXT}
                    </Text>
                  </Box>
                  <Text color="blackAlpha.500" fontWeight="semibold" m={2}>
                    {files.length}{" "}
                    {files.length === 1 || files.length === 0
                      ? "File"
                      : "Files"}
                  </Text>
                  <Box
                    height="150px"
                    overflowY="auto"
                    overflowX="hidden"
                    background="blackAlpha.50"
                    padding="5px"
                  >
                    {files.map((file, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        mt="2px"
                      >
                        <IconButton
                          aria-label="Delete"
                          size="xs"
                          variant="ghost"
                          icon={<CloseIcon color="gray.400" />}
                          mr="10px"
                          onClick={(e) => {
                            e.stopPropagation();
                            const newFiles = files.filter(
                              (_, i) => i !== index
                            );
                            setFiles(newFiles);
                            // Reset the file input
                            if (fileInputRef.current) {
                              fileInputRef.current.value = "";
                            }
                          }}
                        />

                        <Box display="flex" alignItems="center">
                          {getFileIcons(file)}
                          {(() => {
                            const objectUrl = URL.createObjectURL(file);
                            return (
                              <a
                                href={objectUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  setTimeout(
                                    () => URL.revokeObjectURL(objectUrl),
                                    60000
                                  );
                                }}
                              >
                                <Text
                                  color="blue.500"
                                  fontWeight="semibold"
                                  fontSize="small"
                                >
                                  {file.name} ({formatFileSize(file.size)})
                                </Text>
                              </a>
                            );
                          })()}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>Additional Information</FormLabel>
                  <Textarea
                    value={formData.Note || ""}
                    onChange={(e) => onInputChange("Note", e.target.value)}
                    height="50px"
                    resize="none"
                  />
                </FormControl>
              </GridItem>
            </Grid>
          )}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button colorScheme="gray" mr={3} onClick={handleBack} width="100px">
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            colorScheme={isLastStep ? "green" : "blue"}
            mr={3}
            onClick={handleNext}
            isLoading={isSubmitting}
            _loading={{ opacity: 0.8 }}
            loadingText="Submitting..."
            width="150px"
          >
            {isLastStep ? "Submit" : "Next"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewForm;
