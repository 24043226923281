import React, { useEffect, useRef, useState } from "react";
import { FaSadTear, FaMeh, FaSmile, FaRegUserCircle } from "react-icons/fa";
import {
  Text,
  CardBody,
  Card,
  Grid,
  GridItem,
  Box,
  List,
  ListItem,
  Divider,
  Flex,
  Button,
  Icon,
  Input,
  FormControl,
  FormLabel,
  Collapse,
  useToast,
  HStack,
} from "@chakra-ui/react";
import expressAPI from "../api/expressAPI";
import { ShopEmployeeDetails } from "../types/Types";
import { ShopEmployeeInteractionHistory } from "../types/Types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../api/API";
import { de } from "date-fns/locale";
import { em } from "@fullcalendar/core/internal-common";

// Define a reusable component for profile details
type ProfileDetailProps = {
  label: string;
  value: string | undefined;
};

const ProfileDetail = ({ label, value }: ProfileDetailProps) => (
  <GridItem>
    <Text fontSize="lg" color="black.300" fontWeight="semibold">
      {label}
    </Text>
    <Text fontSize="md" color="blackAlpha">
      {value || "N/A"}
    </Text>
  </GridItem>
);

const InteractionHistoryDetail = ({ label, value }: ProfileDetailProps) => (
  <GridItem>
    <Text fontSize="md" color="black.800">
      {label} 
    </Text>
    <Text fontSize="md" color="blue.500">
      : {value || "No data available"}
    </Text>
  </GridItem>
);

type QuestionProps = {
  question: string;
};
type EmojiSelection = {
  questionIndex: number; // Store the question index
  value: number; // Store the emoji value
};
type QuestionCommentSelection = {
  questionIndex: number; // Store the question index
  value: string; // Store the question comment value
};

const QuestionItem = ({ question }: QuestionProps) => (
  <ListItem>
    <Text as="span" fontWeight="semibold" fontSize="md">
      •
    </Text>{" "}
    {question}
  </ListItem>
);

const Feedback = () => {
  const endpoint = "core/actionitems/data";
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the navigate hook
  const toast = useToast(); // Create toast instance

  const [shopEmpProfileDetails, setDriverProfileDetails] = useState<
    ShopEmployeeDetails[]
  >([]);
  
  // Interaction History For Shop Emloyee
  const [shopEmployeeInteractionHistory, setShopEmpInteractionHistory] = useState<
  ShopEmployeeInteractionHistory[]
  >([]);
  
  // Initialize state to track the selected emoji for each question
  const [selectedEmojis, setSelectedEmojis] = useState<
    Record<number, EmojiSelection>
  >({});

  const [selectedQuestionComment, setSelectedQuestionComment] = useState<
    Record<number, QuestionCommentSelection>
  >({});

  const [remarks, setRemarks] = useState<string>("");
  const [isEscalated, setIsEscalated] = useState(false); // Track escalation
  const [show, setShow] = useState(false); // State to manage collapse
  const [isInfoSelected, setIsInfoSelected] = useState(false); // Tracks whether required info is selected
  const [loading, setLoading] = useState<boolean>(false);

  const isButtonClicked = useRef(false);

  // Decode the URL to handle any URL-encoded characters like '%20'
  const decodedPath = decodeURIComponent(location.pathname);

  // Split the path segments based on the '/' delimiter
  const pathSegments = decodedPath.split("/");

  // Check if the URL contains "Escalated Shop Employee"
  const isEscalatedShopEmployee = pathSegments.includes(
    "Escalated Shop Employee"
  );
  const isShopEmployeeHistory = pathSegments.includes(
    "Shop Employee History"
  );
  // If "Escalated Shop Employee" is present, remove it from the path segments
  const filteredPathSegments = pathSegments.filter(
    (segment) => segment !== "Escalated Shop Employee"
  );
  
  const idSegment = pathSegments.find((segment) => segment.startsWith("id="));
  // Extract the `id` value, e.g., from "id=1"
  const extractedId = idSegment
    ? parseInt(idSegment.split("=")[1], 10)
    : undefined;

  // Find and extract the "Days" segment from the path (if available)
  const indaysSegment = filteredPathSegments.find((segment) =>
    segment.includes("Days")
  );
  const indays = indaysSegment ? parseInt(indaysSegment.split(" ")[0], 10) : 0;

  useEffect(() => {
    const decodedPath = decodeURIComponent(location.pathname);

    // Split the path segments based on the '/' delimiter
    const pathSegments = decodedPath.split("/");

    // Check if the URL contains "Escalated Shop Employee"
    const isShopEmployeeHistory = pathSegments.includes(
      "Shop Employee History"
    );

    // Check if the URL contains "Escalated Shop Employee"
    const isEscalatedShopEmployeePresent = pathSegments.includes(
      "Escalated Shop Employee"
    );
    
    const isEscalatedShopEmployee = (isEscalatedShopEmployeePresent || isShopEmployeeHistory );     
    // Extract `indays` based on the "Days" keyword, e.g., "90 Days"
    const idSegment = pathSegments.find((segment) => segment.startsWith("id="));
    const id = idSegment ? parseInt(idSegment.split("=")[1], 10) : undefined;
    // Extract `indays` based on the "Days" keyword, e.g., "60 Days"
    const indaysSegment = pathSegments.find((segment) =>
      segment.includes("Days")
    );
    const indays = indaysSegment
      ? parseInt(indaysSegment.split(" ")[0], 10)
      : 0;   
    // Ensure `id` is a valid number before proceeding
    if (id && !isNaN(id)) {
      fetchClaimsStats(indays, id, isEscalatedShopEmployee);
      fetchShopEmployeeInteractionHistory(indays, id);
    } else {
      console.error("Invalid ID found in the URL");
    }
  }, [location]);

  const fetchClaimsStats = async (
    indays: number,
    id: number,
    isEscalatedShopEmployee: boolean
  ) => {
    try {
      // Determine the process type based on `isEscalatedShopEmployee`
      const processType = isEscalatedShopEmployee
        ? "escalatedshopemployeedetail"
        : "shopemployeedetail";

      // Construct the API payload
      const requestData: { process: string; id: number; indays?: number; ishistory?: boolean } = {
        process: processType,
        id: id,
        ishistory: isShopEmployeeHistory
      };

      // Conditionally add `indays` only for "shopemployeedetail"
      if (processType === "shopemployeedetail") {
        requestData.indays = indays; // Include `indays` in the request data
      }

      // Make the API request using the conditionally constructed payload
      const apiResponse = await expressAPI(endpoint, requestData);

      // Handle the response and set the profile details
      setDriverProfileDetails(apiResponse);
    } catch (error) {
      console.error("Failed to fetch driver profile details", error);
    }
  };
  
  // Fetch the Shop Employee Interaction History
  const fetchShopEmployeeInteractionHistory = async (indays: number, id: number) => {    
    try {
      const apiResponse = await expressAPI(endpoint, {
        process: "shopemployeeinteractionhistory",
        id: id
      });

      setShopEmpInteractionHistory(apiResponse);
    } catch (error) {
      console.error("Failed to fetch driver profile details", error);
    }
  };

  // Group driver profile details by DaysDuration
  const groupedByDaysDuration = shopEmpProfileDetails.reduce<
    Record<string, ShopEmployeeDetails[]>
  >((acc, driver) => {
    const { DaysDuration } = driver;
    if (!acc[DaysDuration]) {
      acc[DaysDuration] = [];
    }
    acc[DaysDuration].push(driver);
    return acc;
  }, {});

  // Group Shop Employee profile interaction history details by DaysDuration
  const groupedShopEmpByDaysDuration = shopEmployeeInteractionHistory.reduce<
  Record<string, ShopEmployeeInteractionHistory[]>
  >((acc, driver) => {
    const { DaysDuration } = driver;
    if (!acc[DaysDuration]) {
      acc[DaysDuration] = [];
    }
    acc[DaysDuration].push(driver);
    return acc;
  }, {});

  // Function to handle emoji click and set the value for each question
  const handleEmojiClick = (
    questionIndex: number,
    value: number,
    QuestionId: number
  ) => {
    setIsInfoSelected(true);
    setSelectedEmojis((prev) => ({
      ...prev,
      [QuestionId]: { questionIndex, value }, // Store an object containing both `questionIndex` and `value`
    }));
  };

  // Function to handle question reamrk change and set the value for each question
  const handleQuestonRemarkChange = (
    questionIndex: number,
    value: string,
    QuestionId: number
  ) => {
    setIsInfoSelected(true);
    setSelectedQuestionComment((prev) => ({
      ...prev,
      [QuestionId]: { questionIndex, value }, // Store an object containing both `questionIndex` and `value`
    }));
  };

  // Destructure the first shop employee profile object if available
  const firstShopEmp = shopEmpProfileDetails[0];
  // Split remarks only if `firstDriver` and `firstDriver.Remark` are defined
  const remarksHistory = firstShopEmp?.Remark
    ? firstShopEmp.Remark.split("^^").filter(Boolean)
    : [];
  // Split the recruite emails
  const recruiterEmail = firstShopEmp?.RecruiterEmail
    ? firstShopEmp.RecruiterEmail.split(";").filter(Boolean)
    : [];
  // Function to call the API when the Submit/Complete button is clicked
  const handleSubmit = async (IsEsclate: boolean) => {
    setLoading(true); // Set loading state to true when the button is clicked
    const jsonObj = {
      ID: extractedId, // Replace with the actual ID as needed
      Feedback: Object.entries(groupedByDaysDuration).map(
        ([daysDuration, details]) => ({
          Duration: Number(daysDuration.replace(" days", "")), // Convert duration string to a number
          Remark: remarks, // Add the user-entered remarks
          IsEsclate: IsEsclate, // Set escalation status
          Questions: details.map((detail) => ({
            QuestionId: detail.QuestionId,
            QuestionRating: selectedEmojis[detail.QuestionId]?.value || 0, // Get the rating for each question or default to 0
            QuestionRemark:
              selectedQuestionComment[detail.QuestionId]?.value || "", // Get the remark for each question or default to ''
          })),
        })
      ),
    };

    const emailIds = [firstShopEmp?.AssigneeEmail,firstShopEmp?.AssigneeEmail];
    if(recruiterEmail.length > 0){
      if(firstShopEmp?.DaysDuration != "30"){      
        emailIds[0] = recruiterEmail[0];
        emailIds[1] = recruiterEmail[1];
      }      
    }    

    const emailBody = {
      process: "shopempescalate",
      AssigneeEmail: emailIds[0],
      Candidate: firstShopEmp?.Candidate,
      FeedbackDays: firstShopEmp?.DaysDuration,
      NoteActionItem: remarks,
      RecruiterEmail: emailIds[1]
    };

    const jsonstring = JSON.stringify(jsonObj);
    const feedbackPayload = {
      process: "shopemployeefeedback",
      shopempbody: jsonstring,
    };

    try {
      // First API call for feedbackPayload
      const response = await expressAPI(endpoint, feedbackPayload);
      console.log("Feedback submitted successfully", response);

      // Conditionally make the second API call if `isEscalated` is true
      if (IsEsclate) {
        const bodyResponse = await API(emailBody);
        console.log("Escalation submitted successfully", bodyResponse);
      }

      // Use the toast instance created above to show the success message
      toast({
        title: "Feedback submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Failed to submit feedback", error);
      toast({
        title: "Failed to submit feedback",
        description: "An error occurred while submitting your feedback.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      //Delay navigation to allow the toast to show
      setTimeout(() => {
        navigate(-1);
      }, 2000); // Delay for the same duration as the toast duration (3 seconds)
    }
  };

  const handleSave = async () => {    
    setLoading(true); // Set loading state to true when the button is clicked
    // Extract `indays` based on the "Days" keyword, e.g., "60 Days"
    const indaysSegment = pathSegments.find((segment) =>
      segment.includes("Days")
    );
    const indays = indaysSegment
      ? parseInt(indaysSegment.split(" ")[0], 10)
      : 0;
    const escalateEmployee = {
      process: "escalatedshopemployeefeedback",
      id: extractedId,
      indays: indays,
      remark: remarks

    };
    try {
      // First API call for feedbackPayload
      if (isEscalatedShopEmployee) {
        // If escalated shop employee, create the escalation payload
        const escalateResponse = await expressAPI(endpoint,escalateEmployee);
        console.log("Escalation data submitted successfully", escalateResponse);
      }
      // Use the toast instance created above to show the success message
      toast({
        title: "Feedback submitted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Failed to submit feedback", error);
      toast({
        title: "Failed to submit feedback",
        description: "An error occurred while submitting your feedback.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      //Delay navigation to allow the toast to show
      setTimeout(() => {
        navigate(-1);
      }, 2000); // Delay for the same duration as the toast duration (3 seconds)
    }
  };

  return (
    <Card width="100%" variant="filled" background="#f5f4f4">
      <CardBody>
        <Grid templateColumns="repeat(12, 1fr)" gap={4} mt="10px">
          {/* Left Side (9 columns) */}
          <GridItem colSpan={9}>
            {/* Flex container to align the text and user profile icon */}
            <Flex alignItems="center" mb={3}>
              <Icon as={FaRegUserCircle} boxSize={6} color="blue.500" mr={2} />
              {/* User Profile Icon */}
              <Text fontSize="lg" color="gray.500" fontWeight="bold">
                Shoope Employee Profile
              </Text>
            </Flex>

            {/* Check if the first driver profile is available */}
            {firstShopEmp ? (
              <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                <ProfileDetail label="Title" value={firstShopEmp.Title} />
                <ProfileDetail
                  label="Department"
                  value={firstShopEmp.ShopEmpDepartment}
                />
                <ProfileDetail label="Location" value={firstShopEmp.Location} />
                <ProfileDetail
                  label="Start Date"
                  value={new Date(firstShopEmp.StartDate).toLocaleDateString()}
                />
                <ProfileDetail
                  label="Candidate"
                  value={firstShopEmp.Candidate}
                />
              </Grid>
            ) : (
              <Text>No driver details available</Text>
            )}

            <Card
              borderRadius="md"
              boxShadow="lg"
              padding="10px"
              background="white"
              mb={4}
              mt="35px"
              maxHeight="500px"
              overflowY="auto"
            >
              {Object.entries(groupedByDaysDuration).map(
                ([daysDuration, details], index) => (
                  <CardBody padding="0">
                    {/* Header for Days Duration */}
                    <>                      
                      <Text
                        fontSize="xl"
                        fontWeight="bold"
                        color="gray.700"
                        mb={3}
                      >
                        Days Duration: {daysDuration} days
                      </Text>                     
                      
                      {/* List of questions for the current DaysDuration */}

                      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                        {details.map((detail, detailIndex) => (
                          <GridItem key={detailIndex}>
                            <List spacing={3}>
                              <QuestionItem question={detail.Question} />
                              {/* Render Emojis for feedback options */}
                              <Box
                                display="flex"
                                mt={3}
                                justifyContent="space-around"
                              >
                                <Box
                                  as={FaSadTear}
                                  pointerEvents={
                                    isEscalatedShopEmployee ? "none" : "auto"
                                  }
                                  boxSize={8}
                                  color={
                                    selectedEmojis[detail.QuestionId]?.value ===
                                    1
                                      ? "green.800"
                                      : detail.Question_Rating === 1
                                      ? "green.800"
                                      : "gray.300"
                                  }
                                  cursor="pointer"
                                  onClick={() =>
                                    handleEmojiClick(
                                      detailIndex,
                                      1,
                                      detail.QuestionId
                                    )
                                  }
                                />
                                <Box
                                  as={FaMeh}
                                  boxSize={8}
                                  pointerEvents={
                                    isEscalatedShopEmployee ? "none" : "auto"
                                  }
                                  color={
                                    selectedEmojis[detail.QuestionId]?.value ===
                                    2
                                      ? "green.800"
                                      : detail.Question_Rating === 2
                                      ? "green.800"
                                      : "gray.300"
                                  }
                                  cursor="pointer"
                                  onClick={() =>
                                    handleEmojiClick(
                                      detailIndex,
                                      2,
                                      detail.QuestionId
                                    )
                                  }
                                />
                                <Box
                                  as={FaSmile}
                                  boxSize={8}
                                  pointerEvents={
                                    isEscalatedShopEmployee ? "none" : "auto"
                                  }
                                  color={
                                    selectedEmojis[detail.QuestionId]?.value ===
                                    3
                                      ? "green.800"
                                      : detail.Question_Rating === 3
                                      ? "green.800"
                                      : "gray.300"
                                  }
                                  cursor="pointer"
                                  onClick={() =>
                                    handleEmojiClick(
                                      detailIndex,
                                      3,
                                      detail.QuestionId
                                    )
                                  }
                                />
                              </Box>
                              <Input
                                placeholder={
                                  isEscalatedShopEmployee
                                    ? "No data available"
                                    : "Enter your comment here..."
                                }
                                size="md"
                                defaultValue={detail.Question_Remark || ""}
                                disabled={Boolean(
                                  (detail.Question_Remark === "" &&
                                    isEscalatedShopEmployee) ||
                                    (detail.Question_Remark &&
                                      isEscalatedShopEmployee)
                                )}
                                onChange={(e) =>
                                  handleQuestonRemarkChange(
                                    detailIndex,
                                    e.target.value,
                                    detail.QuestionId
                                  )
                                }
                                sx={{
                                  border: isEscalatedShopEmployee ? "none" : "1px solid lightgray",
                                  backgroundColor:
                                    detail.Question_Remark &&
                                    isEscalatedShopEmployee
                                      ? "#f0f0f0"
                                      : "inherit", // Optional: change background color when disabled
                                  cursor:
                                    detail.Question_Remark &&
                                    isEscalatedShopEmployee
                                      ? "not-allowed"
                                      : "text", // Change cursor to indicate disabled state
                                }}
                              />
                            </List>  
                                                       
                          </GridItem>                          
                        ))}
                      </Grid>
                      
                      {details[0].EscalationRemark &&
                      <Text fontSize="xl"
                        fontWeight="bold"
                        color="blue.400"
                        mb={3}>
                        {daysDuration} Days Escalated Remark: {details[0].EscalationRemark}                        
                      </Text>}
                    </>
                    {isEscalatedShopEmployee && (
                      <Divider borderColor="gray.400" my={5} />
                    )}
                  </CardBody>
                )
              )}

              {!isEscalatedShopEmployee && (
                <Divider borderColor="gray.400" my={5} />
              )}
              {isEscalatedShopEmployee &&
                <FormControl mt={4} mb={4}>
                  <FormLabel fontSize="md" fontWeight="bold" color="gray.600">
                    Remarks
                  </FormLabel>
                  <Input
                    placeholder="Enter your remarks here..."
                    size="md"
                    value={remarks}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                      setIsInfoSelected(true);
                    }} // Handle changes to store in state
                  />
                </FormControl>
              }
              {/* Two buttons aligned: one on the left and one on the right */}
              <Flex
                justifyContent={
                  isEscalatedShopEmployee ? "flex-end" : "space-between"
                }
                mt={4}
              >

                {!isShopEmployeeHistory ?
                <>
                {isEscalatedShopEmployee ? (
                  ""
                ) : (
                  <Button
                    colorScheme="blue"
                    isDisabled={!isInfoSelected}
                    isLoading={loading}
                    onClick={() => {
                      handleSubmit(true);
                      setIsEscalated(!isEscalated); // Toggle the escalation status
                      isButtonClicked.current = true;
                    }}
                  >
                    Escalation
                  </Button>
                )}              
                <Button
                  isLoading={loading}
                  colorScheme="green"
                  isDisabled={!isInfoSelected}
                  onClick={() => {
                    if (isEscalatedShopEmployee) {
                      handleSave();
                    } else {
                      handleSubmit(false);
                      setIsEscalated(!isEscalated); // Toggle the escalation status
                      isButtonClicked.current = true;
                    }
                  }}
                >
                  {isEscalatedShopEmployee
                    ? "Save"
                    : indays === 90
                    ? "Complete"
                    : "Submit"}
                </Button></>:null}
              </Flex>
            </Card>
          </GridItem>

          {/* Right Side (3 columns) */}
          {!isShopEmployeeHistory ? <>
            {!isEscalatedShopEmployee && (
              <GridItem colSpan={3}>
                <Box
                  position="sticky"
                  top="0"
                  right="30px"
                  overflowY="auto"
                  background="white"
                  padding="15px"
                  borderRadius="md"
                  boxShadow="lg"
                >
                  {/* Header Text */}
                  <Flex justifyContent="space-between" align="center">
                    <Text
                      fontSize="md"
                      textAlign="center"
                      fontWeight="semibold"
                      color="black.300"
                    >
                      INTERACTIONS HISTORY
                    </Text>

                    {/* Toggle Button */}
                    <Button size="sm" onClick={() => setShow(!show)}>
                      {show ? "Hide" : "Show"} History
                    </Button>
                  </Flex>

                  {/* Collapsible Content */}
                  <Collapse in={show} animateOpacity>
                    <Box>  
                      {/* Add more history items as needed */}
                      {Object.entries(groupedShopEmpByDaysDuration).map(
                        ([daysDuration, details], index) => (
                        <Card
                        key={index}
                        borderRadius="md"
                        boxShadow="lg"
                        padding="5px"
                        background="white"
                        mb={4}
                        mt="15px"
                        >
                        <CardBody padding="0">
                        {/* Header for Days Duration */}
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          color="gray.700"
                          mb={3}
                        >
                          Days Duration: {daysDuration} days
                        </Text>
                        {/* List of questions for the current DaysDuration */}
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          gap={5}
                          maxHeight="140px"                     
                          overflowY="auto"
                        >
                          {details.map((detail, detailIndex) => (
                          <GridItem key={detailIndex}>
                          <List spacing={1}>				
                            <InteractionHistoryDetail
                            label={(detailIndex+1) + '. ' + detail.Question}
                            value={detail.Question_Remark}
                            />				
                          </List>
                          </GridItem>
                          ))}
                        </Grid>
                        
                        </CardBody>
                        </Card>
                        )
                      )}
                    </Box>
                  </Collapse>
                </Box>
              </GridItem>
            )} </>: null}
        </Grid>
      </CardBody>
    </Card>
  );
};

export default Feedback;
