import React, { useState, useRef, useMemo, useEffect } from "react";
import { Box, Text, useToast, Link, Icon, VStack } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { BlobServiceClient } from "@azure/storage-blob";

// import icons
import { FaFilePdf, FaFileWord, FaFileImage, FaFileAlt } from "react-icons/fa";

const ACCEPTED_FILE_TYPES = ".png, .jpeg, .jpg, .pdf, .doc, .docx";
const ALLOWED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const INSTRUCTION_TEXT =
  "Click or drag 1 file at a time. Maximum file size is 50MB. We accept PNG, JPEG, PDF, or Word documents.";

type DriverDocumentsType = {
  userEmail: string;
  userName: string;
  userDepartment: string;
  id: string | number;
  setDocumentCount: React.Dispatch<React.SetStateAction<number>>;
};

type FileInfo = {
  fileName: string;
  url: string;
  displayName?: string;
  fileType?: string;
};

const DriverDocuments = ({
  userEmail,
  id,
  userDepartment,
  userName,
  setDocumentCount,
}: DriverDocumentsType) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentFiles, setCurrentFiles] = useState<FileInfo[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const toast = useToast();

  const borderColor = useMemo(
    () => (dragging ? "teal.300" : "gray.300"),
    [dragging]
  );

  const fetchBlobs = async () => {
    setCurrentFiles([]);
    const blobNames = await findBlobsByTag(Number(id));
    for (const blobName of blobNames) {
      getBlob(blobName, setCurrentFiles, setError);
    }
  };

  // Function to retrieve files from Azure Blob Storage and display them
  const getFileIcon = (contentType: string | undefined) => {
    if (contentType) {
      if (contentType.includes("pdf")) {
        return <Icon as={FaFilePdf} mr="5px" color="red" />;
      } else if (contentType.includes("word")) {
        return <Icon as={FaFileWord} mr="5px" color="blue.500" />;
      } else if (contentType.includes("image")) {
        return <Icon as={FaFileImage} mr="5px" color="green" />;
      } else {
        return <Icon as={FaFileAlt} mr="5px" color="gray" />;
      }
    }
    return <Icon as={FaFileAlt} />;
  };

  // Function to find blobs by tag
  const findBlobsByTag = async (tagValue: number) => {
    const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
    const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );

    const query = `uploadId = '${tagValue}'`;
    let blobNames = [];
    try {
      const iterator = blobServiceClient.findBlobsByTags(query);
      for await (const blobItem of iterator) {
        blobNames.push(blobItem.name);
      }
    } catch (err) {
      console.error("Error finding blobs by tag:", err);
    }
    return blobNames;
  };

  const getBlob = async (
    fileName: string,
    setFiles: React.Dispatch<React.SetStateAction<FileInfo[]>>,
    setError: React.Dispatch<React.SetStateAction<Error | null>>
  ) => {
    setError(null);
    const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
    const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
    const containerName =
      process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME || "";

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    try {
      const blobClient = containerClient.getBlobClient(fileName);
      const downloadBlockBlobResponse = await blobClient.download();
      const blob = await downloadBlockBlobResponse.blobBody;

      // Fetching blob metadata
      const propertiesResponse = await blobClient.getProperties();
      const metadata = propertiesResponse.metadata;

      let displayName = "";
      if (metadata && metadata["originalfilename"]) {
        displayName = metadata["originalfilename"];
      }

      let fileType = "";
      if (downloadBlockBlobResponse.contentType) {
        fileType = downloadBlockBlobResponse.contentType;
      }

      if (blob) {
        // Create a new Blob with the correct MIME type
        const blobData = new Blob([blob], { type: fileType });
        const blobUrl = URL.createObjectURL(blobData);
        setFiles((prevFiles) => [
          ...prevFiles,
          { fileName, displayName, url: blobUrl, fileType },
        ]);
      } else {
        throw new Error("Blob was not found");
      }
    } catch (err) {
      setError(err as Error);
      toast({
        title: "Error Fetching Blob",
        description: error?.message || "There was an error fetching the blob.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to upload file to Azure Blob Storage and set metadata and tags
  const uploadFileToBlob = async (file: File) => {
    const uniqueFileName = `${uuidv4()}-${file.name}`;
    const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
    const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
    const containerName =
      process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME || "";
    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(uniqueFileName);

    const contentType = file.type || "application/octet-stream";
    try {
      await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024,
        blobHTTPHeaders: {
          blobContentType: contentType,
        },
      });

      const metadata = {
        originalFileName: file.name,
        uploadId: id.toString(),
        uploadedBy: userName,
        userEmail: userEmail,
        userDepartment: userDepartment,
        uploadTime: new Date().toISOString(),
      };
      await blockBlobClient.setMetadata(metadata);
      const tags = {
        uploadId: id.toString(),
      };
      await blockBlobClient.setTags(tags);
      setTimeout(fetchBlobs, 2000);
      toast({
        title: "File Uploaded",
        description: "The file has been successfully uploaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Upload Error",
        description: "There was an error uploading the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Function to handle drag events
  const handleDrag =
    (isDragging: boolean) => (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(isDragging);
    };

  // Function to handle drop events
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    handleDrag(false)(e);
    const { files } = e.dataTransfer;

    if (currentFiles.length >= 5) {
      toast({
        title: "Limit Reached",
        description: "You can only upload up to 5 files.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (files && files.length === 1) {
      const file = files[0];
      if (file.size > 0 && ALLOWED_FILE_TYPES.includes(file.type)) {
        uploadFileToBlob(file);
      } else {
        toast({
          title: "Invalid File",
          description: "Dropped file is empty or invalid.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } else if (files && files.length > 1) {
      toast({
        title: "Multiple Files Detected",
        description: "Please upload one file at a time.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length === 1) {
      const file = files[0];
      if (file.size > 0 && ALLOWED_FILE_TYPES.includes(file.type)) {
        await uploadFileToBlob(file);
      } else {
        toast({
          title: "Invalid File",
          description: "Selected file is empty or invalid.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } else if (files && files.length > 1) {
      toast({
        title: "Multiple Files Detected",
        description: "Please select one file at a time.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    fetchBlobs();
  }, []);

  // Update document count when currentFiles changes
  useEffect(() => {
    setDocumentCount(currentFiles.length);
  }, [currentFiles, setDocumentCount]);

  return (
    <Box>
      <Box
        p={5}
        borderWidth="2px"
        borderColor={borderColor}
        borderStyle="dashed"
        rounded="md"
        onDragOver={handleDrag(true)}
        onDragLeave={handleDrag(false)}
        onDrop={handleDrop}
        width="100%"
        onClick={() => fileInputRef.current?.click()}
        cursor="pointer"
      >
        <input
          type="file"
          accept={ACCEPTED_FILE_TYPES}
          multiple
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={onFileChange}
        />
        <Text color="blackAlpha.500" fontWeight="semibold">
          {INSTRUCTION_TEXT}
        </Text>
      </Box>
      <VStack
        mt={5}
        align="start"
        w="100%"
        bg="blackAlpha.50"
        p="10px"
        visibility={currentFiles.length > 0 ? "visible" : "hidden"}
      >
        {currentFiles.map((file) => (
          <Box key={file.fileName} w="100%">
            {getFileIcon(file.fileType)}
            <Link href={file.url} isExternal>
              {file.displayName}
            </Link>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default DriverDocuments;
