import { Box, Divider, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { driverBoardTypes } from "../../../../types/Types";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

// This component displays a row of data in the table
interface RowProps {
  index: number;
  style: React.CSSProperties;
  data: driverBoardTypes[];
}

// Define your columns
const columns = [
  {
    name: "Registration",
    label: "Registration",
    width: "150px",
    type: "string",
  },
  { name: "Status", label: "Status", width: "100px", type: "string" },
  { name: "DriverName", label: "Name", width: "150px", type: "string" },
  { name: "DriverCode", label: "Code", width: "150px", type: "string" },
  { name: "DriverType", label: "Type", width: "100px", type: "string" },
  { name: "Division", label: "Division", width: "100px", type: "string" },
  { name: "CostCenter", label: "Cost Center", width: "250px", type: "string" },
  { name: "Recruiter", label: "Recruiter", width: "150px", type: "string" },
  {
    name: "OrientationDate",
    label: "Orientation",
    width: "100px",
    type: "date",
  },
  {
    name: "OrientationLocation",
    label: "Location",
    width: "200px",
  },
];

interface Props {
  data: driverBoardTypes[];
  sortState: {
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  };
  handleSort: (column: keyof driverBoardTypes) => void;
  handleSelect: (id: number) => void;
  selectedID: number | null;
}

const AllDataTable: React.FC<Props> = ({
  data,
  sortState,
  handleSort,
  handleSelect,
  selectedID,
}) => {
  // This component displays the table headers
  const TableHeaders: React.FC<{
    handleSort: (column: keyof driverBoardTypes) => void;
  }> = ({ handleSort }) => {
    return (
      <HStack h="30px" pr="10px" pl="10px" w="100%">
        {columns.map((column) => (
          <Stack
            key={column.name}
            spacing={1}
            width={column.width}
            onClick={() => handleSort(column.name as keyof driverBoardTypes)}
            cursor="pointer"
          >
            <Text fontSize="sm" color="#1D61AA">
              {column.label}
              <span
                style={{
                  visibility:
                    sortState.column === column.name ? "visible" : "hidden",
                  marginLeft: "2px",
                }}
              >
                {sortState.direction === "asc" ? "↑" : "↓"}
              </span>
            </Text>
          </Stack>
        ))}
      </HStack>
    );
  };

  // This component displays a row of data in the table
  const DataRow: React.FC<RowProps> = ({ index, style, data }) => {
    const item = data[index];

    // Function to format date as MM/dd/yyyy
    const formatDate = (dateString: string): string => {
      const parts = dateString.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1].padStart(2, "0");
        const day = parts[2].padStart(2, "0");
        return `${month}/${day}/${year}`;
      }
      return dateString; // Return original date string if format is not recognized
    };

    return (
      <VStack
        style={style}
        cursor="pointer"
        align="left"
        borderRadius="md"
        mt="5px"
        pr="10px"
        pl="10px"
        onClick={() => item?.ID && handleSelect(item.ID)}
        _hover={{ background: "blackAlpha.50" }}
        bg={selectedID === item.ID ? "blackAlpha.100" : "white"}
      >
        <HStack height="100%">
          {columns.map((column) => (
            <Stack key={column.name} spacing={1} width={column.width}>
              <Text fontSize="sm" fontWeight="normal">
                {column.type === "date" &&
                item[column.name as keyof driverBoardTypes]
                  ? formatDate(
                      item[column.name as keyof driverBoardTypes] as string
                    )
                  : item[column.name as keyof driverBoardTypes] || "N/A"}
              </Text>
            </Stack>
          ))}
        </HStack>
        <Divider />
      </VStack>
    );
  };

  // Main Table
  return (
    <Box
      height="calc(100vh - 190px)"
      width="100%"
      mt="10px"
      fontWeight="semibold"
    >
      <TableHeaders handleSort={handleSort} />
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={height}
            itemCount={data.length}
            itemSize={50}
            width={width}
            itemData={data}
          >
            {DataRow}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
};

export default AllDataTable;
