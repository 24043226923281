import React, { useEffect, useState } from "react";
import { VStack, IconButton } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import types
import { userInfo, driverBoardTypes } from "../../types/Types";

// Import hooks
import useFetchData from "../../hooks/driverboard/fetchData";
import useFetchProfileData from "../../hooks/driverboard/fetchProfiledata";
import useFetchHistory from "../../hooks/driverboard/fetchHistory";

// Import components
import OperationsFilter from "../../components/driverboard/operations/OperationsFilter";
import OperationsDataTable from "../../components/driverboard/operations/OperationsDataTable";
import EditForm from "../../components/driverboard/shared/EditForm";

// import Icon
import { ChevronLeftIcon } from "@chakra-ui/icons";

const OperationsDashboard: React.FC<userInfo> = ({
  userName,
  userEmail,
  userDepartment,
  userTitle,
  powerBiReportToken,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const [locationFilter, setLocationFilter] = useState<string>("All");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const [filteredData, setFilteredData] = useState<driverBoardTypes[]>([]);
  const [manualRefresh, setManualRefresh] = useState<boolean>(false);
  const [selectedDivision, setSelectedDivision] = useState<string>("All");
  const [filterOption, setFilterOption] = useState<"All" | "Week">("Week");
  const [costCenterFilter, setCostCenterFilter] = useState<string>("");
  const [driverLeaderStatus, setDriverLeaderStatus] =
    useState<string>("Pending");

  // Selected Week
  const [selectedWeek, setSelectedWeek] = useState<string>(() => {
    const currentDate = new Date();
    const day = currentDate.getDay();
    const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(currentDate.setDate(diff));
    const currentYear = monday.getFullYear();
    const currentMonth = ("0" + (monday.getMonth() + 1)).slice(-2);
    const currentDay = ("0" + monday.getDate()).slice(-2);
    return `${currentMonth}/${currentDay}/${currentYear}`;
  });

  // Sort state
  const [sortState, setSortState] = useState<{
    column: keyof driverBoardTypes | null;
    direction: "asc" | "desc";
  }>({
    column: "ID",
    direction: "asc",
  });

  // Fetch Pipeline
  const { isLoading, data, lastUpdated } = useFetchData(
    refresh,
    userName,
    userEmail,
    userTitle,
    searchText,
    "All",
    sortState
  );

  // Fetch Versioning History
  const { historyLoading, changeLog } = useFetchHistory(
    selectedID,
    manualRefresh
  );

  // Fetch Profile
  const { profileLoading, profileData } = useFetchProfileData(
    selectedID,
    manualRefresh
  );

  // Handle Selected ID
  const handleSelect = (id: number) => {
    setSelectedID(id);
  };

  useEffect(() => {
    const filteredData = data.filter((item) => {
      const weekMatch = filterOption === "All" || item.Week === selectedWeek;

      const locationMatch =
        locationFilter === "All" || item.OrientationLocation === locationFilter;

      const divisionMatch =
        selectedDivision === "All" || item.Division === selectedDivision;

      const statusMatch = item.Status !== "Withdrawn";

      const costCenterMatch = costCenterFilter
        ? item.CostCenter?.toLowerCase().includes(
            costCenterFilter.toLowerCase()
          )
        : true;

      const driverLeaderMatch =
        driverLeaderStatus === "All" ||
        (driverLeaderStatus === "Pending" &&
          !item.DriverLeaderEmail &&
          item.Status !== "Registered") ||
        (driverLeaderStatus === "Assigned" && item.DriverLeaderEmail);

      return (
        weekMatch &&
        locationMatch &&
        divisionMatch &&
        statusMatch &&
        costCenterMatch &&
        driverLeaderMatch
      );
    });

    setFilteredData(filteredData);
  }, [
    data,
    selectedWeek,
    locationFilter,
    selectedDivision,
    filterOption,
    costCenterFilter,
    driverLeaderStatus,
  ]);

  useEffect(() => {
    document.title = "Driver Board - Operations";

    const searchParams = new URLSearchParams(location.search);
    const ID = searchParams.get("ID");
    if (ID) {
      setSearchText(ID);

      // Refresh data every 30 seconds
      const interval = setInterval(() => {
        setRefresh(!refresh);
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [location, refresh]);

  return (
    <>
      <VStack
        h="calc(100vh - 55px)"
        w="100%"
        bg="linear-gradient(90deg, #fafafa, #faf7f7, #f0f0f0)"
        p="10px"
        align="flex-start"
        justify="flex-start"
      >
        <OperationsFilter
          filteredData={filteredData}
          searchText={searchText}
          setSearchText={setSearchText}
          locationFilter={locationFilter}
          setLocationFilter={setLocationFilter}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          refresh={refresh}
          setRefresh={setRefresh}
          lastUpdated={lastUpdated}
          isLoading={isLoading}
          selectedDivision={selectedDivision}
          setSelectedDivision={setSelectedDivision}
          filterOption={filterOption}
          setFilterOption={setFilterOption}
          costCenterFilter={costCenterFilter}
          setCostCenterFilter={setCostCenterFilter}
          driverLeaderStatus={driverLeaderStatus}
          setDriverLeaderStatus={setDriverLeaderStatus}
        />
        <OperationsDataTable
          data={filteredData}
          selectedID={selectedID}
          sortState={sortState}
          handleSort={(column) => {
            setSortState({
              column,
              direction:
                sortState.column === column && sortState.direction === "asc"
                  ? "desc"
                  : "asc",
            });
          }}
          handleSelect={handleSelect}
        />
        <IconButton
          position="fixed"
          size="md"
          colorScheme="blackAlpha"
          aria-label="Refresh"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate("/driverboard")}
          bottom="10px"
          left="10px"
          fontSize="30px"
        />
      </VStack>
      <EditForm
        userName={userName}
        userEmail={userEmail}
        userDepartment={userDepartment}
        isOpen={selectedID !== null}
        onClose={() => setSelectedID(null)}
        onRefresh={() => setRefresh(!refresh)}
        selectedID={selectedID}
        profileLoading={profileLoading}
        profileData={profileData}
        refreshProfileData={() => setManualRefresh(!manualRefresh)}
        setSelectedID={setSelectedID}
        historyLoading={historyLoading}
        changeLog={changeLog}
      />
    </>
  );
};

export default OperationsDashboard;
